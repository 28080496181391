import axios from 'axios'
import * as Common from 'services/common'

class TesterService {
  findAll = (project) => {
    const url = `/api/tester/${project}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new TesterService()
export default instance
