const reducer = (state, action) => {
  switch (action.type) {
  case 'loaded':
    return { loading: false, showSuccessMessage: false, settings: action.settings || {} }
  case 'saving':
    return { ...state, loading: true }
  case 'saved':
    return { ...state, showSuccessMessage: true, loading: false }
  default:
    throw new Error()
  }
}

export default reducer
