import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import * as Common from 'services/common'

class TfaCredentialsService {
  load = (project) => {
    const url = `/api/tfa-credentials/${project}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  save = (project, credentials) => {
    const request = isEmpty(credentials.email) || isEmpty(credentials.password) ? {} : credentials
    const url = `/api/tfa-credentials/${project}`
    return axios.post(url, request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new TfaCredentialsService()
export default instance
