import PropTypes from 'prop-types'

import style from './style.module.css'

const DataField = (props) => {
  const { name, value, children } = props
  return (
    <div className={style.dataField}>
      <div>{name}</div>
      {children == null
      && <div>{value}</div>}
      {children}
    </div>
  )
}


DataField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

DataField.defaultProps = {
  value: '',
  children: null,
}

export default DataField
