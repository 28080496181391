import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { withRouter } from 'react-router'

import ConnectionSettings from 'components/configuration/jira/basic/connection'
import FieldSettings from 'components/configuration/jira/basic/fields'
import HeaderButton from 'pages/headerButton'
import TfaCredentials from 'components/configuration/tfaCredentials'


const JiraSettings = ({ match }) => {
  useEffect(() => {
    document.title = 'QiTASC ConQlude - Jira Settings'
  }, [])
  return (
    <Card>
      <CardHeader title="Jira Settings" />
      <CardContent>
        <HeaderButton id="connectionSettings" label="Connection Settings" url={`${match.url}/connection`} />
        <HeaderButton id="tfaCredentials" label="TFA Credentials" url={`${match.url}/tfa-credentials`} />
        <HeaderButton id="fieldSettings" label="Field Settings" url={`${match.url}/fields`} />
        <Switch>
          <Route path={`${match.url}/connection`} component={ConnectionSettings} />
          <Route path={`${match.url}/fields`} component={FieldSettings} />
          <Route path={`${match.url}/tfa-credentials`} component={TfaCredentials} />
        </Switch>
      </CardContent>
    </Card>
  )
}

JiraSettings.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}


export default withRouter(JiraSettings)
