import { useState } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'

import DataField from './dataField'
import Delete from './delete'
import Pin from './pin'
import style from './style.module.css'


const Defects = ({ defects, onSave }) => {
  const [state, setState] = useState({ showEditButtons: false, newDefect: '' })

  const handleDefectIdChange = (e) => {
    setState({ newDefect: e.target.value, showEditButtons: e.target.value !== '' })
  }

  const handleResetDefects = () => {
    setState({ newDefect: '', showEditButtons: false })
  }

  const handlePinChange = (id, pinned) => {
    onSave(defects.map(it => (it.id === id ? ({ id, pinned }) : it)))
  }

  const handleDelete = (id) => {
    onSave(defects.filter(it => it.id !== id))
  }

  const handleSaveDefectId = () => {
    onSave(defects.concat({ id: state.newDefect, pinned: false }))
    setState({ showEditButtons: false, newDefect: '' })
  }

  const handleDefectSubmit = (e) => {
    e.preventDefault()
    handleSaveDefectId()
  }

  return (
    <DataField name="Defect">
      { defects.map(it => (
        <span className={style.tag} key={it.id}>
          <a>{it.id}</a>
          <Pin checked={it.pinned} onToggle={pinned => handlePinChange(it.id, pinned)} />
          <Delete onClick={() => handleDelete(it.id)} />
        </span>
      ))}
      <form onSubmit={handleDefectSubmit} className={style.defectForm}>
        <TextField
          id="DefectField"
          value={state.newDefect}
          className={style.defectField}
          onChange={handleDefectIdChange}
        />
        { state.showEditButtons
          && (
            <span>
              <IconButton onClick={handleSaveDefectId} className={style.iconButton}>
                <UploadSuccessfulIcon />
              </IconButton>
              <IconButton onClick={handleResetDefects} className={style.iconButton}>
                <UploadFailedIcon />
              </IconButton>
            </span>
          )}
      </form>
    </DataField>
  )
}

Defects.propTypes = {
  defects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default Defects
