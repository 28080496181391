import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import style from './style.module.css'

const REGEX = new RegExp(':[0-9,]+ (.*)', 'u')

class ErrorMessage extends Component {
  static summarizeErrorMessage(errorMessage) {
    const lines = errorMessage.split('\n')
    const firstLine = lines[0].trim()
    if (firstLine.startsWith('java.lang.RuntimeException:')) {
      const message = firstLine.substring(27).trim()
      if (message.length > 0 || lines.length === 1) return message
      const extractedMessage = REGEX.exec(lines[1])
      if (extractedMessage == null || extractedMessage.length < 2) {
        return lines[1]
      }
      return extractedMessage[1]
    }
    if (firstLine.startsWith('java.util.concurrent.ExecutionException: java.lang.AssertionError:')) {
      return firstLine.substring(51).trim()
    }
    if (firstLine.startsWith('java.util.concurrent.ExecutionException: java.lang.RuntimeException:')) {
      return firstLine.substring(68).trim()
    }
    if (firstLine.startsWith('java.util.concurrent.ExecutionException: java.lang.')) {
      return firstLine.substring(51).trim()
    }
    if (firstLine.startsWith('java.lang.AssertionError:')) {
      return firstLine.substring(10).trim()
    }
    if (firstLine.startsWith('java.lang.IllegalStateException:')) {
      return firstLine.substring(32).trim()
    }
    if (firstLine.startsWith('java.lang.')) {
      return firstLine.substring(10).trim()
    }
    if (firstLine.startsWith('java.util.concurrent.')) {
      return firstLine.substring(21).trim()
    }
    return firstLine
  }

  constructor(props) {
    super(props)
    this.state = { openDialog: false }
  }

  toggleDialog = state => () => this.setState({ openDialog: state })

  render() {
    const { value } = this.props
    const { openDialog } = this.state
    return (
      <>
        <span
          key="text"
          role="button"
          tabIndex={0}
          className={`${style.errorMessage} link`}
          onClick={this.toggleDialog(true)}
          onKeyPress={this.toggleDialog(true)}
        >
          {ErrorMessage.summarizeErrorMessage(value)}
        </span>
        <Dialog
          open={openDialog}
          maxWidth="lg"
          fullWidth
          className={style.errorDialog}
          onClose={this.toggleDialog(false)}
        >
          <DialogTitle>Error Message</DialogTitle>
          <DialogContent>{value}</DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.toggleDialog(false)}>OK</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

ErrorMessage.propTypes = {
  value: PropTypes.string.isRequired,
}


export default ErrorMessage
