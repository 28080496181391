import ApprovalService from 'services/postprocess/approvalService'
import ExecutionListService from 'services/executionListService'


export function getData(project) {
  return ApprovalService.getApprovalStats(project)
}

export function getCount(project) {
  return ExecutionListService.findAllReadyForApproval(project, 1, 3)
}
