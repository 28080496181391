import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import { UploadSuccessfulIcon, UploadFailedIcon } from 'components/icons'


const TextBoxWithSubmit = ({
  defaultText, onClick, disabled, minRows, maxRows, errorText,
}) => {
  const [text, setText] = useState('')

  useEffect(() => { setText(defaultText) }, [defaultText])

  const hasChanges = () => defaultText !== text

  const handleSubmit = () => {
    if (hasChanges()) {
      onClick(text.trim())
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="TextBoxWithSubmit"
        multiline
        value={text}
        minRows={minRows}
        maxRows={maxRows}
        disabled={disabled}
        label={errorText}
        error={errorText != null}
        onChange={e => setText(e.target.value)}
        sx={{ width: 192 }}
      />
      {hasChanges() && (
        <span>
          <IconButton onClick={handleSubmit}>
            <UploadSuccessfulIcon />
          </IconButton>
          <IconButton onClick={() => setText(defaultText)}>
            <UploadFailedIcon />
          </IconButton>
        </span>
      )}
    </form>
  )
}

TextBoxWithSubmit.propTypes = {
  defaultText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  errorText: PropTypes.string,
}

TextBoxWithSubmit.defaultProps = {
  defaultText: '',
  disabled: false,
  minRows: 2,
  maxRows: 4,
  errorText: null,
}

export default TextBoxWithSubmit
