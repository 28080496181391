import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { withRouter } from 'react-router'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import DateFilter from 'components/reports/common/widgets/dateFilter'
import Spinner from 'components/spinner'
import * as Utils from 'components/utils'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'
import calcTotalPages from 'utils/calcTotalPages'

import Store from './store'
import retrieveList from './actions'
import style from './style.module.css'

class PmsExportReport extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount = () => {
    const { query } = this.props
    const { projectCode } = this.context
    const { defaultPageSize } = this.state

    document.title = 'QiTASC ConQlude - PMS Export Report'
    Store.on('change', this.updateState)
    retrieveList({ ...query, pageSize: query.pageSize || defaultPageSize }, projectCode)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode } = this.context
      const { defaultPageSize } = this.state
      const { query } = this.props
      retrieveList({ ...query, pageSize: query.pageSize || defaultPageSize }, projectCode)
    }
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleDateFilter = (dateFrom, dateUntil) => {
    const { redirect } = this.props
    redirect({ dateFrom, dateUntil, pageNumber: 1 })
  }

  render() {
    const { match } = this.props
    const {
      error, loading, list, totalSize, defaultPageSize,
    } = this.state
    const columns = [
      {
        Header: 'File name',
        accessor: 'fileName',
        Cell: item => (
          <Link to={`/${match.params.projectCode}/pmsExportDetail/${item.row.original.id}`}>
            {item.value || 'Pending Export'}
          </Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Sent date',
        accessor: 'dateSent',
        Cell: item => Utils.formatDate(item.value),
      },
      {
        Header: 'Last Modified date',
        accessor: 'lastModified',
        Cell: item => Utils.formatDate(item.value),
      },
    ]
    if (error != null) {
      return PmsExportReport.renderError(error.message)
    }
    const { query } = this.props
    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

    const dateFrom = query.dateFrom ? new Date(query.dateFrom) : null
    const dateUntil = query.dateUntil ? new Date(query.dateUntil) : null

    return (
      <Spinner spinning={loading}>
        <div className="row">
          <div className="col-md-8">
            <Card className={style.card}>
              <CardHeader title="PMS Export Report" />
              <Table
                id="pmsExport"
                columns={columns}
                data={list}
                totalPages={calcTotalPages({ totalSize, pageSize })}
              />
            </Card>
          </div>
          <div className="col-md-4">
            <DateFilter onSubmit={this.handleDateFilter} initialDateFrom={dateFrom} initialDateUntil={dateUntil} />
          </div>
        </div>
      </Spinner>
    )
  }
}

PmsExportReport.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

PmsExportReport.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

PmsExportReport.contextType = ProjectContext

export default withQuery(withRouter(PmsExportReport))
