import PropTypes from 'prop-types'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FilterListIcon from '@mui/icons-material/FilterList'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import ItemCount from './widgets/itemCount'
import style from './style.module.scss'


const ReportHeader = ({
  title, actions, totalSize, passedCount, failedCount, onToggleFilters, showFilterButton,
}) => (
  <Toolbar className={style.tableHeader}>
    <div className={style.title}>
      <Typography variant="h5">{title}</Typography>
    </div>
    <div className={style.spacer} />
    <div className={style.actions}>
      {passedCount != null && failedCount != null && (
        <div className={style.countLabel}>
          <ItemCount total={totalSize} passed={passedCount} failed={failedCount} />
        </div>
      )}
      {actions}
      {showFilterButton ? (
        <Tooltip title="Filter">
          <IconButton onClick={onToggleFilters}>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Hide filters">
          <IconButton onClick={onToggleFilters}>
            <ChevronRightIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  </Toolbar>
)

ReportHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.node,
  passedCount: PropTypes.number,
  failedCount: PropTypes.number,
  totalSize: PropTypes.number,
  onToggleFilters: PropTypes.func.isRequired,
  showFilterButton: PropTypes.bool,
}

ReportHeader.defaultProps = {
  actions: null,
  totalSize: 0,
  passedCount: null,
  failedCount: null,
  showFilterButton: true,
}

export default ReportHeader
