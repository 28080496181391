import { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'


import FilterChips from 'components/reports/common/widgets/filterChips'
import Header from 'components/reports/common/reportHeader'
import Spinner from 'components/spinner'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import calcTotalPages from 'utils/calcTotalPages'

import * as Actions from './actions'
import Store from './store'
import style from './style.module.scss'
import Table from './table'
import TestSuiteFilterDrawer from './filterDrawer'

class TestSuiteList extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount = () => {
    const { projectCode } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Test Suites'
    Store.on('change', this.updateState)
    Actions.retrieveList(projectCode, query)
  }

  componentDidUpdate(prevProps) {
    const { projectCode } = this.context
    const { query } = this.props
    if (!isEqual(this.props, prevProps)) {
      Actions.updateList(projectCode, query)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleNewFilter = (filter) => {
    const { redirect } = this.props
    redirect(filter)
  }

  handleToggleFilters = () => {
    const { openDrawer } = this.state
    Actions.setDrawerOpen(!openDrawer)
  }

  render() {
    const { query, redirect } = this.props
    const { projectCode } = this.context
    const {
      error, loading, defaultPageSize, list, totalSize, openDrawer,
    } = this.state
    if (error != null) {
      return TestSuiteList.renderError(error)
    }

    const { testName, status } = query
    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

    const filters = {
      testName,
      dateFrom: query.dateFrom ? moment(query.dateFrom) : null,
      dateUntil: query.dateUntil ? moment(query.dateUntil) : null,
      status,
    }

    return (
      <div className={style.container}>
        <div className={style.content}>
          <Header
            title="Test Suites"
            showFilterButton={!openDrawer}
            onToggleFilters={this.handleToggleFilters}
          />
          <FilterChips
            {...filters}
            onRemove={this.handleNewFilter}
          />
          <Spinner spinning={loading}>
            <Table
              items={list}
              projectCode={projectCode}
              totalPages={calcTotalPages({ totalSize, pageSize })}
            />
          </Spinner>
        </div>
        <TestSuiteFilterDrawer
          open={openDrawer}
          filters={filters}
          redirect={redirect}
        />
      </div>
    )
  }
}

TestSuiteList.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

TestSuiteList.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

TestSuiteList.contextType = ProjectContext

export default withQuery(withRouter(TestSuiteList))
