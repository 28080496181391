import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, disconnect } from 'services/messagingService'

const WebSocket = ({ url, children }) => {
  useEffect(() => {
    connect(url)
    return () => disconnect()
  }, [url])
  return (
    <>
      {children}
    </>
  )
}

WebSocket.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default WebSocket
