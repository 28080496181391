import { useEffect, useReducer } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { load, save } from './actions'
import reducer from './reducer'

const initialState = { loading: true, showSuccessMessage: false, credentials: { email: '', password: '' } }

const TfaCredentials = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const errorHandler = useErrorHandler()
  const { projectCode, user } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Two-factor-authentication credentials'
    load(projectCode)
      .then(({ email }) => dispatch({ type: 'load', email }))
      .catch(errorHandler)
  }, [errorHandler, projectCode])
  const handleSave = () => save(projectCode, { email: state.email, password: state.password })
    .then(() => dispatch({ type: 'saved' }))
    .catch(errorHandler)
  return (
    <div>
      <Typography variant="h6">{`Two-factor-authentication credentials for ${user.name}`}</Typography>
      <Spinner spinning={state.loading}>
        <Box paddingTop={2} paddingBottom={2}>
          <Grid container>
            <Grid item md={3}>
              <TextField
                fullWidth
                id="nameField"
                label="Email"
                value={state.email}
                onChange={e => dispatch({ type: 'email change', email: e.target.value })}
              />
              <TextField
                fullWidth
                id="passwordField"
                label="Password"
                type="password"
                value={state.password}
                onChange={e => dispatch({ type: 'password change', password: e.target.value })}
              />
            </Grid>
          </Grid>
        </Box>
        <Button id="saveButton" variant="contained" color="primary" onClick={handleSave}>Save</Button>
        <Snackbar id="snackBar" open={state.showSuccessMessage} message="Credentials were saved successfully." autoHideDuration={10000} />
      </Spinner>
    </div>
  )
}

export default TfaCredentials
