import { dispatcher, events } from 'core/dispatcher'
import ExcelExportService from 'services/excelExportService'

export function retrieveDefaults(project) {
  ExcelExportService.getDefaults(project)
    .then(defaults => dispatcher.dispatch({ type: events.configurationExcelExport.dataReceived, defaults }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationExcelExport.failed, message }) })
}

export function handleValueChange(defaults) {
  dispatcher.dispatch({ type: events.configurationExcelExport.valueChanged, defaults })
}

export function save(project, defaults) {
  ExcelExportService.updateDefaults(project, defaults)
    .catch((message) => { dispatcher.dispatch({ type: events.configurationExcelExport.failed, message }) })
}
