import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

const PageNotFound = () => (
  <Card>
    <CardHeader title="404" />
    <CardContent>
      <p>Sorry, page not found.</p>
    </CardContent>
  </Card>
)

export default PageNotFound
