const reducer = (state, action) => {
  switch (action.type) {
  case 'loaded':
    return {
      ...state,
      settings: action.settings || action.defaultSettings,
      loading: false,
      showSuccessMessage: false,
    }
  case 'submitted':
    return { ...state, showSuccessMessage: false, loading: true }
  case 'saved':
    return { ...state, showSuccessMessage: true, loading: false, settings: action.settings }
  default:
    throw new Error()
  }
}

export default reducer
