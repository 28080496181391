import { dispatcher, events } from 'core/dispatcher'
import get from 'lodash/get'
import moment from 'moment'
import ReportingEventEmitter from 'core/eventEmitter'


const withDefaults = (settings = {}) => ({
  remoteTest: get(settings, 'remoteTest') || '',
  resultName: get(settings, 'resultName') || '',
  template: get(settings, 'template') || '',
  orderName: get(settings, 'orderName') || '',
  tolerance: get(settings, 'tolerance') || '',
  user: get(settings, 'user') || '',
  filesFrom: get(settings, 'filesFrom') || '',
  steps: get(settings, 'steps') || '',
})

const TEMPLATES = 'excelExportTemplates'

const loadFromLocalStorage = () => {
  const templates = localStorage.getItem(TEMPLATES)
  return templates && JSON.parse(templates)
}

const writeToLocalStorage = templates => localStorage.setItem(TEMPLATES, JSON.stringify(templates))

class ExcelExportStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      showDialog: false,
      settings: withDefaults(),
      templates: loadFromLocalStorage() || [],
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.excelExport.dataReceived: {
      this.state.settings = withDefaults(action.defaults)
      this.emitChange()
      break
    }
    case events.excelExport.fieldChecked: {
      this.state.settings = action.settings
      this.emitChange()
      break
    }
    case events.excelExport.toggleFieldSelection: {
      this.state.showDialog = action.show
      this.emitChange()
      break
    }
    case events.excelExport.templateSelected: {
      const { settings } = this.state.templates.find(template => template.id === action.id)
      this.state.settings = withDefaults(settings)
      this.emitChange()
      break
    }
    case events.excelExport.templateRemoved: {
      this.state.templates = this.state.templates.filter(template => template.id !== action.id)
      writeToLocalStorage(this.state.templates)
      this.emitChange()
      break
    }
    case events.excelExport.templateAdded: {
      const settings = { ...this.state.settings }
      const id = moment().format('YYYYMMDD-HH:mm:ss.SSS')
      this.state.templates = [
        ...this.state.templates,
        { id, name: id, settings },
      ]
      writeToLocalStorage(this.state.templates)
      this.state.templateIdGettingRenamed = id
      this.state.templateName = id
      this.emitChange()
      break
    }
    case events.excelExport.templateRenameStarted: {
      this.state.templateIdGettingRenamed = action.id
      this.state.templateName = this.state.templates.find(t => t.id === action.id).name
      this.emitChange()
      break
    }
    case events.excelExport.templateRenameCanceled: {
      this.state.templateIdGettingRenamed = null
      this.state.templateName = null
      this.emitChange()
      break
    }
    case events.excelExport.templateNameChanged: {
      if (action.name.length <= 20) {
        this.state.templateName = action.name
      }
      this.emitChange()
      break
    }
    case events.excelExport.templateRenamed: {
      const template = this.state.templates.find(t => t.id === this.state.templateIdGettingRenamed)
      template.name = this.state.templateName
      writeToLocalStorage(this.state.templates)
      this.state.templateIdGettingRenamed = null
      this.state.templateName = null
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}


const store = new ExcelExportStore()
dispatcher.register(store.handleActions.bind(store))

export default store
