import { useState } from 'react'
import PropTypes from 'prop-types'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import style from './style.module.scss'

const emptyPart = { system: '', code: '' }

const NewExternalCodeRow = ({ onAdd, onCancel }) => {
  const [state, setState] = useState(emptyPart)
  return (
    <div>
      <TextField
        name="system"
        label="External system"
        fullWidth
        className={style.column}
        value={state.system}
        onChange={e => setState({ ...state, system: e.target.value })}
      />
      <TextField
        name="code"
        label="External code "
        fullWidth
        className={style.column}
        value={state.code}
        onChange={e => setState({ ...state, code: e.target.value })}
      />
      <div className={style.buttonsColumn}>
        <IconButton size="small" onClick={() => onAdd(state)}><CheckIcon /></IconButton>
        <IconButton size="small" onClick={onCancel}><ClearIcon /></IconButton>
      </div>
    </div>
  )
}

NewExternalCodeRow.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default NewExternalCodeRow
