import JiraService from 'services/jiraBasic/jiraBasicService'
import { extractMessage } from 'components/utils'

export const loadSettings = project => JiraService.retrieveFieldSettings(project)
  .catch(() => ({ parentIssueFields: {}, executionIssueFields: {}, testIssueFields: {} }))

export const saveSettings = (project, settings) => JiraService.updateFieldSettings(project, settings)

export const loadConfig = async (project) => {
  try {
    const config = await JiraService.retrieveSettingsConfiguration(project)
    return { config }
  } catch (error) {
    if (error.response && error.response.status === 500 && error.response.data && error.response.data.errorCode === 'PROXY') {
      const config = await JiraService.retrieveSettingsFallbackConfiguration(project)
      return { config, useFallback: true }
    }
    throw extractMessage(error)
  }
}
