import { dispatcher, events } from 'core/dispatcher'
import ApprovalService from 'services/postprocess/approvalService'
import CdrService from 'services/cdr/cdrService'
import QonformService from 'services/qonform/qonformService'
import ExecutionDetailsService from 'services/executionDetailsService'
import ExecutionListService from 'services/executionListService'
import HpqcService from 'services/hpqc/hpqcService'
import JiraUploadService from 'services/jira/uploadService'
import HpqcUploadService from 'services/hpqc/uploadService'
import VerificationService from 'services/postprocess/verificationService'
import SuccessConditionService from 'services/successcondition/successConditionService'


export function retrieveDetails(executionId) {
  return ExecutionDetailsService.findExecution(executionId)
    .then((testSuite) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.executionsReceived, testSuite })
      return testSuite
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error }) })
}

function retrieveLogs(executionId, logType) {
  ExecutionDetailsService.findExecutionLogs(executionId, logType)
    .then((response) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.logsReceived, ...response, logType }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.logsFailed, error, logType }) })
}

function retrieveHpqcDetails(projectCode, executionId) {
  HpqcService.getDetails(projectCode, executionId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.hpqcDetailsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.hpqcDetailsFailed, error }) })
}

function retrieveSuccessConditions(projectCode, executionId) {
  SuccessConditionService.getByExecution(projectCode, executionId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.successConditionsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.successConditionsFailed, error }) })
}

function retrieveJiraDetails(projectCode, executionId) {
  JiraUploadService.getDetails(projectCode, executionId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.jiraDetailsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.jiraDetailsFailed, error }) })
}

async function retrieveNewerExecutions(testSuiteId, dateFrom, project) {
  const response = await ExecutionListService.findAll(project, { dateFrom, testSuiteId })
  try {
    dispatcher.dispatch({ type: events.testDetailsSuiteView.newerExecutionsReceived, list: response.items })
  } catch (error) {
    dispatcher.dispatch({ type: events.testDetailsSuiteView.newerExecutionsFailed, error })
  }
}

export function retrieveDetailsAndLogs({ executionId, hpqcEnabled, jiraZephyrEnabled, projectCode }) {
  retrieveDetails(executionId)
    .then((execution) => {
      if (execution.result === 'FAILED') {
        retrieveNewerExecutions(execution.testSuiteId, execution.startTime, execution.projectCode)
      }
      if (!execution.deleted) {
        retrieveLogs(executionId, 'PROTOCOL')
        retrieveLogs(executionId, 'TRACE')
      }
    })
  if (hpqcEnabled) {
    retrieveHpqcDetails(projectCode, executionId)
  }
  if (jiraZephyrEnabled) {
    retrieveJiraDetails(projectCode, executionId)
  }
  retrieveSuccessConditions(projectCode, executionId)
  retrieveLogs(executionId, 'SERVER')
}

export function reloadDetailsAndLogs(params) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.retrieveStarted })
  retrieveDetailsAndLogs(params)
}

function changeApprovalStatus(executionId, status) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.approved })
  ApprovalService.changeApprovalStatus(executionId, status)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.approvalSucceeded })
      retrieveDetails(executionId)
    })
    .catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Approval failed: ${message}` })
    })
}

export function deny(executionId) {
  changeApprovalStatus(executionId, 'DENIED')
}

export function showPhoneInfo(parameters, eventName) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.showPhoneInfo, parameters, eventName })
}

export function closePhoneInfo() {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.closePhoneInfo })
}

export function openMoveDialog() {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.openMoveDialog })
}

export function closeMoveDialog() {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.closeMoveDialog })
}

export function moveReportToOtherProject(executionId, projectCode) {
  ExecutionListService.moveReportsToProject(projectCode, [executionId], true)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.performMove })
      window.location.reload(false)
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Project move failed: ${error}` }) })
}

export function approve(executionId) {
  changeApprovalStatus(executionId, 'APPROVED')
}

function changeVerificationStatus(executionId, status) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.verified })
  VerificationService.changeVerificationStatus(executionId, status)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.verificationSucceeded })
      retrieveDetails(executionId)
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Verification failed: ${message}` })
    })
}

export function verify(executionId) {
  changeVerificationStatus(executionId, 'VERIFIED')
}

export function archive(executionId) {
  changeVerificationStatus(executionId, 'ARCHIVED')
}

export function upload(project, executionId) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.uploaded })
  HpqcUploadService.upload(project, executionId)
    .catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Upload failed: ${message}` })
    })
}

export function uploadToJira(projectCode, executionId) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.uploadedToJira })
  JiraUploadService.upload(projectCode, executionId)
    .then(() => { retrieveJiraDetails(executionId) })
    .catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Upload to Jira failed: ${message}` })
    })
}

export function saveDefects(executionId, defects) {
  ExecutionDetailsService.saveDefects(executionId, defects)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.defectSaved, defects })
      retrieveDetails(executionId)
    })
    .catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Updating defect failed: ${message}` })
    })
}

function changeRetentionStatus(executionId, status) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.deleted })
  ExecutionDetailsService.changeRetentionStatus(executionId, status)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.deletionSucceeded })
      retrieveDetails(executionId)
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Deletion failed: ${message}` })
    })
}

export function saveInternalNote(executionId, note) {
  ExecutionDetailsService.updateNote(executionId, note)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.internalNoteUpdated, note })
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Updating internal note failed: ${message}` })
    })
}


export function scheduleForDeletion(executionId) {
  changeRetentionStatus(executionId, 'SCHEDULED')
}

export function cancelDeletion(executionId) {
  changeRetentionStatus(executionId, 'RETAINED')
}

export function handleTestCategoryChange(executionId, testCategory) {
  ExecutionDetailsService.changeTestCategory(executionId, testCategory)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.deletionSucceeded })
      retrieveDetails(executionId)
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Changing test category failed: ${message}` })
    })
}

export function handleHpqcStatusChange(projectCode, executionId, status) {
  HpqcService.updateHpqcStatus(projectCode, executionId, status)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.hpqcDetailsUpdated, status })
      retrieveDetails(executionId)
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Updating ALM status failed: ${message}` })
    })
}

export function saveHpqcNote(projectCode, executionId, note) {
  HpqcService.updateNote(projectCode, executionId, note)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.hpqcNoteUpdated, note })
      retrieveDetails(executionId)
    }).catch((message) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.failed, error: `Updating ALM comment failed: ${message}` })
    })
}

export function uploadCdrFile(verificationExternalId, executionId, file, projectCode) {
  return ExecutionDetailsService.uploadCdrFile(verificationExternalId, executionId, file, projectCode)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.cdrFileUploaded })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.cdrUploadFailed, error }) })
}

export function retrieveCdrVerifications(executionId, projectCode) {
  return ExecutionDetailsService.retrieveCdrVerifications(executionId, projectCode)
    .then((cdrVerificationsResponse) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.retrievedVerifications, cdrVerificationsResponse })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.retrieveVerificationsFailed, error }) })
}

export function retrieveQonformVerifications(executionId, projectCode) {
  return ExecutionDetailsService.retrieveQonformVerifications(executionId, projectCode)
    .then((qonformVerificationsResponse) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.retrievedQonformVerifications, qonformVerificationsResponse })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.retrieveQonformVerificationsFailed, error }) })
}

export function runVerification(executionId, verificationExternalId, projectCode) {
  return ExecutionDetailsService.runVerification(executionId, verificationExternalId, projectCode)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.verificationStarted, verificationExternalId })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.verificationRunFailed, error }) })
}

export function runQonformVerification(executionId, verificationExternalId, projectCode) {
  return ExecutionDetailsService.runQonformVerification(executionId, verificationExternalId, projectCode)
    .then(() => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.qonformVerificationStarted, verificationExternalId })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.qonformVerificationRunFailed, error }) })
}

export function changeContextOverrides(cdrVerificationId, overrides) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.overridesChanged, cdrVerificationId, overrides })
}

export function changeQonformContextOverrides(qonformVerificationId, overrides) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.qonformOverridesChanged, qonformVerificationId, overrides })
}

export function getContextPreview(executionId, verificationExternalId, projectCode, contextOverrides) {
  return ExecutionDetailsService.getContextPreview(executionId, verificationExternalId, projectCode, contextOverrides)
    .then((contextPreviewFile) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.gotContextPreview, contextPreviewFile, verificationExternalId })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.getContextPreviewFailed, error, verificationExternalId }) })
}

export function getQonformContextPreview(executionId, verificationExternalId, projectCode, contextOverrides) {
  return ExecutionDetailsService.getQonformContextPreview(executionId, verificationExternalId, projectCode, contextOverrides)
    .then((contextPreviewFile) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.gotQonformContextPreview, contextPreviewFile, verificationExternalId })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.getQonformContextPreviewFailed, error, verificationExternalId }) })
}

export function updateContext(executionId, verificationExternalId, projectCode, contextOverrides) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.updateContext, verificationExternalId })
  return ExecutionDetailsService.updateContext(executionId, verificationExternalId, projectCode, contextOverrides)
    .then((cdrVerifications) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.updatedContext, cdrVerifications })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.updateContextFailed, error, verificationExternalId }) })
}

export function updateQonformContext(executionId, verificationExternalId, projectCode, contextOverrides) {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.updateQonformContext, verificationExternalId })
  return ExecutionDetailsService.updateQonformContext(executionId, verificationExternalId, projectCode, contextOverrides)
    .then((qonformVerifications) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.updatedQonformContext, qonformVerifications })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.updateQonformContextFailed, error, verificationExternalId }) })
}

export function closeContextPreview() {
  dispatcher.dispatch({ type: events.testDetailsSuiteView.closeContextPreview })
}

export function retrieveEnabled(projectCode) {
  return CdrService.isEnabled(projectCode)
    .then((enabled) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.gotCdrEnabled, enabled })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.getCdrEnabledFailed, error }) })
}

export function retrieveQonformEnabled(projectCode) {
  return QonformService.isEnabled(projectCode)
    .then((enabled) => {
      dispatcher.dispatch({ type: events.testDetailsSuiteView.gotQonformEnabled, enabled })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsSuiteView.getQonformEnabledFailed, error }) })
}

export function runCdrSearch(projectCode, executionId, verificationId) {
  ExecutionDetailsService.runCdrSearch(executionId, verificationId, projectCode)
    .catch((error) => { dispatcher.dispatch({ type: events.cdrSearch.failed, error }) })
}
