import PropTypes from 'prop-types'

import DataField from 'components/executionDetails/dataField'
import ErrorMessage from 'components/executionDetails/errorMessage'
import Link from 'components/common/link'


const Properties = ({ test, testSuiteLink, errorMessage }) => {
  const { testSuite, errorCategory, parameters } = test
  return (
    <>
      <DataField name="Test suite">
        <Link to={testSuiteLink}>{testSuite}</Link>
      </DataField>

      {errorCategory && (
        <DataField name="Error Category" value={errorCategory} />)}

      {errorMessage && (
        <DataField name="Error message">
          <ErrorMessage value={errorMessage} />
        </DataField>
      )}

      {parameters.length !== 0 && (
        <DataField name="Parameters">
          {parameters.map(item => (
            <div key={item.name}>{`${item.name}: ${item.value}`}</div>
          ))}
        </DataField>
      )}
    </>
  )
}

Properties.propTypes = {
  test: PropTypes.shape({
    testSuite: PropTypes.string,
    errorCategory: PropTypes.string,
    parameters: PropTypes.array,
  }).isRequired,
  testSuiteLink: PropTypes.string.isRequired,
  errorMessage: PropTypes.string,
}

Properties.defaultProps = { errorMessage: null }

export default Properties
