import { Component } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import FilterPanel from 'components/reports/common/filterPanel'

class TestPlanIdFilter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selection: props.initialValue || '',
    }
  }

  handleFilter = () => {
    this.props.onSubmit(this.state.selection)
    this.setState({ selection: '' })
  }

  render() {
    return (
      <FilterPanel title="Filter by Test Plan ID" onSubmit={this.handleFilter}>
        <FormControl fullWidth>
          <InputLabel>Select an option</InputLabel>
          <Select
            id="field"
            fullWidth
            value={this.state.selection}
            onChange={e => this.setState({ selection: e.target.value })}
          >
            <MenuItem key="EXISTING" value="EXISTING">Existing</MenuItem>
            <MenuItem key="MISSING" value="MISSING">Missing</MenuItem>
          </Select>
        </FormControl>
      </FilterPanel>
    )
  }
}

TestPlanIdFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

TestPlanIdFilter.defaultProps = {
  initialValue: '',
}

export default TestPlanIdFilter
