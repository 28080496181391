const reducer = (state, action) => {
  switch (action.type) {
  case 'email change':
    return { ...state, email: action.email, showSuccessMessage: false }
  case 'password change':
    return { ...state, password: action.password, showSuccessMessage: false }
  case 'load':
    return { loading: false, email: action.email || '', password: '', showSuccessMessage: false }
  case 'saved':
    return { ...state, showSuccessMessage: true }
  case 'close message':
    return { ...state, showSuccessMessage: false }
  default:
    throw new Error()
  }
}

export default reducer
