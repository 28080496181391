import { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

import DataField from 'components/executionDetails/dataField'
import { ExcelIcon } from 'components/icons'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import Store from './store'
import TemplateMenu from './templateMenu'
import * as Actions from './actions'
import style from './export.module.css'


class ExcelExport extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { projectCode } = this.context
    Store.on('change', this.updateState)
    Actions.getDefaults(projectCode)
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { projectCode } = this.context
    const { query, className } = this.props
    const {
      settings, showDialog, templates, templateIdGettingRenamed, templateName,
    } = this.state
    return (
      <div className={className}>
        <Tooltip title="Export to Excel">
          <IconButton id="openDialogBtn" onClick={() => Actions.toggleFieldSelection(true)}>
            <ExcelIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={showDialog}
          maxWidth="sm"
          fullWidth
          onClose={() => Actions.toggleFieldSelection(false)}
        >
          <DialogTitle>Excel Export</DialogTitle>
          <DialogContent>
            <div className="row">
              <div className="col-md-6">
                <TemplateMenu
                  onRename={() => Actions.renameTemplate()}
                  onDelete={() => Actions.removeTemplate()}
                />
                <DataField name="Test">
                  <TextField
                    fullWidth
                    id="remoteTestField"
                    type="remoteTest"
                    value={settings.remoteTest}
                    onChange={e => Actions.handleValueChange({ ...settings, remoteTest: e.target.value })}
                  />
                </DataField>
                <DataField name="Result Name">
                  <TextField
                    fullWidth
                    id="resultNameField"
                    value={settings.resultName}
                    onChange={e => Actions.handleValueChange({ ...settings, resultName: e.target.value })}
                  />
                </DataField>
                <DataField name="Default Search Template">
                  <TextField
                    fullWidth
                    id="templateField"
                    type="template"
                    value={settings.template}
                    onChange={e => Actions.handleValueChange({ ...settings, template: e.target.value })}
                  />
                </DataField>
                <DataField name="Order Name">
                  <TextField
                    fullWidth
                    id="orderNameField"
                    value={settings.orderName}
                    onChange={e => Actions.handleValueChange({ ...settings, orderName: e.target.value })}
                  />
                </DataField>
                <DataField name="Tolerance">
                  <TextField
                    fullWidth
                    id="toleranceField"
                    value={settings.tolerance}
                    onChange={e => Actions.handleValueChange({ ...settings, tolerance: e.target.value })}
                  />
                </DataField>
                <DataField name="User">
                  <TextField
                    fullWidth
                    id="userField"
                    value={settings.user}
                    onChange={e => Actions.handleValueChange({ ...settings, user: e.target.value })}
                  />
                </DataField>
                <DataField name="Files from">
                  <TextField
                    fullWidth
                    id="filesFromField"
                    value={settings.filesFrom}
                    onChange={e => Actions.handleValueChange({ ...settings, filesFrom: e.target.value })}
                  />
                </DataField>
                <DataField name="Steps">
                  <TextField
                    fullWidth
                    id="stepsField"
                    value={settings.steps}
                    onChange={e => Actions.handleValueChange({ ...settings, steps: e.target.value })}
                  />
                </DataField>
              </div>
              <div className={`col-md-6 ${style.templateColumn}`}>
                <a className={style.title}>Templates</a>
                {
                  templates.map((template) => {
                    if (template.id === templateIdGettingRenamed) {
                      return (
                        <div key={template.id} className={`${style.template} ${style.edit}`}>
                          <TextField
                            value={templateName}
                            fullWidth
                            onChange={event => Actions.updateTemplateName(event.target.value)}
                          />
                          <Button id="SaveBtn" color="primary" onClick={Actions.saveTemplateName}>Save</Button>
                          <Button id="CancelBtn" onClick={Actions.cancelRenameTemplate}>Cancel</Button>
                        </div>
                      )
                    }
                    return (
                      <div key={template.id} className={style.template}>
                        <a href="#" onClick={e => Actions.selectTemplate(e, template.id)}>{template.name}</a>
                        <TemplateMenu
                          onRename={() => Actions.renameTemplate(template.id)}
                          onDelete={() => Actions.removeTemplate(template.id)}
                          buttonClassName={style.hamburger}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              id="exportBtn"
              color="primary"
              onClick={() => Actions.handleDownload(projectCode, query, settings)}
            >
              Export
            </Button>
            <Button id="addBtn" onClick={Actions.addTemplate}>Add to Templates</Button>
            <Button id="closeBtn" onClick={() => Actions.toggleFieldSelection(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ExcelExport.propTypes = {
  query: PropTypes.objectOf(PropTypes.string).isRequired,
  className: PropTypes.string,
}

ExcelExport.defaultProps = {
  className: null,
}

ExcelExport.contextType = ProjectContext

export default memo(withRouter(withQuery(ExcelExport)))
