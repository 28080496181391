import { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'

import TemplateMenu from '../excelExport/templateMenu'

import style from './export.module.css'
import fields from './fields'

const ExportView = ({ templates, onExport, onCancel, onSaveTemplates }) => {
  const [selectedFields, setSelectedFields] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const handleExport = () => onExport(selectedFields)
  const handleAddToTemplates = () => {
    const id = moment().format('YYYYMMDD-HH:mm:ss.SSS')
    onSaveTemplates([...templates, { id, name: id, selectedFields }])
  }
  const handleFieldCheck = code => e => (e.target.checked
    ? setSelectedFields([...selectedFields, code])
    : setSelectedFields(selectedFields.filter(field => field !== code)))
  const handleRenameTemplate = () => {
    if (selectedTemplate == null) return
    const otherTemplates = templates.filter(it => it.id !== selectedTemplate.id)
    onSaveTemplates([...otherTemplates, selectedTemplate])
    setSelectedTemplate(null)
  }
  const handleDeleteTemplate = id => () => {
    onSaveTemplates(templates.filter(it => it.id !== id))
  }
  const handleSelectTemplate = id => (event) => {
    event.preventDefault()
    setSelectedFields(templates.find(it => it.id === id).selectedFields)
  }
  return (
    <Dialog
      open
      maxWidth="md"
      fullWidth
      onClose={onCancel}
    >
      <DialogTitle>CSV Export</DialogTitle>
      <DialogContent>
        <div className={`row ${style.fields}`}>
          <div className="col-md-4">
            { fields.column1.map(field => (
              <FormControlLabel
                key={field.code}
                label={field.text}
                control={(
                  <Checkbox
                    checked={selectedFields.includes(field.code)}
                    onChange={handleFieldCheck(field.code)}
                  />
                )}
              />
            ))}
          </div>
          <div className="col-md-4">
            { fields.column2.map(field => (
              <FormControlLabel
                key={field.code}
                label={field.text}
                control={(
                  <Checkbox
                    checked={selectedFields.includes(field.code)}
                    onChange={handleFieldCheck(field.code)}
                  />
                )}
              />
            ))}
          </div>
          <div className={`col-md-4 ${style.templateColumn}`}>
            <a className={style.title}>Templates</a>
            { templates.map((template) => {
              if (selectedTemplate && template.id === selectedTemplate.id) {
                return (
                  <div key={template.id} className={`${style.template} ${style.edit}`}>
                    <TextField
                      value={selectedTemplate.name}
                      fullWidth
                      onChange={event => setSelectedTemplate({ ...selectedTemplate, name: event.target.value })}
                    />
                    <Button id="SaveBtn" color="primary" onClick={handleRenameTemplate}>Save</Button>
                    <Button id="CancelBtn" onClick={() => setSelectedTemplate(null)}>Cancel</Button>
                  </div>
                )
              }
              return (
                <div key={template.id} className={style.template}>
                  <a href="#" onClick={handleSelectTemplate(template.id)}>{template.name}</a>
                  <TemplateMenu
                    onRename={() => setSelectedTemplate({ ...template })}
                    onDelete={handleDeleteTemplate(template.id)}
                    buttonClassName={style.hamburger}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button id="addBtn" onClick={handleAddToTemplates}>Add to Templates</Button>
        <Button id="exportBtn" color="primary" onClick={handleExport}>Export</Button>
        <Button id="closeBtn" onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

ExportView.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onSaveTemplates: PropTypes.func.isRequired,
}

export default ExportView
