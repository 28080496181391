import axios from 'axios'
import * as Common from 'services/common'

class ProjectConfigService {
  retriveConfig = (project) => {
    const url = `/api/project/${project}/configuration/`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  saveConfig = (project, settings, blacklist) => {
    const url = `/api/project/${project}/configuration/`
    const request = { ...settings, blacklist }
    return axios.post(url, request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  retrieveDataRetentionConfig = (project) => {
    const url = `/api/project/${project}/configuration/data-retention`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  saveDataRetentionConfig = (project, configuration) => {
    const url = `/api/project/${project}/configuration/data-retention`
    const request = {
      ...configuration,
      deletionSchedule: configuration.deletionSchedule === '' ? null : configuration.deletionSchedule,
      failedPolicyPeriod: configuration.failedPolicyPeriod === '' ? null : configuration.failedPolicyPeriod,
      passedPolicyPeriod: configuration.passedPolicyPeriod === '' ? null : configuration.passedPolicyPeriod,
    }
    return axios.post(url, request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new ProjectConfigService()
export default instance
