import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import StyledLink from '@mui/material/Link'

const CustomLink = ({ to, children, ...otherProps }) => (
  <StyledLink {...otherProps} color="textPrimary" underline="none" component={Link} to={to}>
    {children}
  </StyledLink>
)

CustomLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

CustomLink.defaultProps = {
  children: null,
}


export default CustomLink
