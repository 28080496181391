import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import AttachmentPreview from './attachmentPreview'
import style from './attachmentPreview.module.css'

const AttachmentPreviewDialog = ({ name, type, file, onClose }) => {
  if (name == null || file == null) {
    return null
  }
  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      className={style.attachmentDialog}
      onClose={onClose}
    >
      <DialogTitle>{name}</DialogTitle>
      <DialogContent className={style.attachmentDialogContent}>
        <AttachmentPreview name={name} type={type} file={file} onClose={onClose} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

AttachmentPreviewDialog.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func.isRequired,
}

AttachmentPreviewDialog.defaultProps = {
  name: null,
  type: null,
  file: null,
}

export default AttachmentPreviewDialog
