import axios from 'axios'
import * as Common from 'services/common'

class ImportService {
  import = ({ project, file }) => {
    const url = `/api/module/hpqc/${project}/import`
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      ...Common.requestOptions,
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return axios.post(url, formData, config)
      .catch(Common.catchError)
  }
}

const instance = new ImportService()
export default instance
