import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import TrimmedText from 'components/trimmedText'
import asLine from './line'
import style from './style.module.scss'


const MetadataLine = ({ name, value, comment }) => {
  const [open, setOpen] = useState(false)
  const [showIcon, setShowIcon] = useState(false)

  const splitComment = c => c.split(':', 2)

  const [firstPart, secondPart] = splitComment(comment)
  return (
    <>
      <div
        className={name == null ? null : style.metadata}
        role="button"
        tabIndex={0}
        onClick={() => setOpen(name != null)}
        onKeyPress={() => setOpen(name != null)}
        onMouseEnter={() => setShowIcon(name != null)}
        onMouseLeave={() => setShowIcon(false)}
      >
        <span className={style.metadataName}>
          {firstPart}
          :
          {' '}
        </span>
        <span className={style.comment}>
          <TrimmedText text={value} maxLength={50} />
        </span>
        { showIcon && <OpenInNewIcon fontSize="small" className={style.metadataIcon} /> }
      </div>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{name}</DialogTitle>
        <DialogContent className={style.metadataPreview}>
          <h3>Original:</h3>
          <div>{secondPart && secondPart.trim()}</div>
          <h3>Evaluated:</h3>
          <div>{value}</div>
        </DialogContent>
        <DialogActions>
          <Button id="closeMetadataPopupButton" color="primary" onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      )
    </>
  )
}


MetadataLine.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  comment: PropTypes.string.isRequired,
}

MetadataLine.defaultProps = { name: null, value: null }

export default asLine(MetadataLine)
