import { useEffect, useReducer, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { retrieve, updateCondition } from './actions'
import style from './style.module.scss'
import SuccessConditionTable from './successConditionTable'


const initialValue = { loading: true, list: [] }
const reducer = (state, action) => {
  switch (action.type) {
  case 'list received':
    return { loading: false, list: action.list }
  default:
    throw new Error('unknown event')
  }
}

const ConditionDetails = ({ executionId, onChanged }) => {
  const [state, dispatch] = useReducer(reducer, initialValue)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  const componentIsMounted = useRef(true)
  useEffect(() => () => {
    componentIsMounted.current = false
  }, [])

  const updateConditionState = useCallback((conditionId, updatedState, comment) => updateCondition(projectCode, executionId, conditionId, updatedState, comment)
    .then((list) => {
      if (componentIsMounted.current) {
        dispatch({ type: 'list received', list })
        onChanged()
      }
    })
    .catch(errorHandler), [errorHandler, projectCode, executionId, onChanged])

  const loadConditions = useCallback(id => retrieve(projectCode, id)
    .then((list) => {
      if (componentIsMounted.current) {
        dispatch({ type: 'list received', list })
      }
    })
    .catch(errorHandler), [errorHandler, projectCode])


  useEffect(() => { loadConditions(executionId) }, [executionId])
  useEffect(() => ReactTooltip.rebuild())
  return (
    <Card className={style.card}>
      <div className={style.overflowWrapper}>
        <CardHeader title="Success Conditions" />
        <Spinner spinning={state.loading}>
          <SuccessConditionTable list={state.list} updateCondition={updateConditionState} />
        </Spinner>
      </div>
    </Card>
  )
}

ConditionDetails.propTypes = {
  executionId: PropTypes.string.isRequired,
  onChanged: PropTypes.func.isRequired,
}

export default ConditionDetails
