import { memo } from 'react'
import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const Statement = ({ name, keyword }) => (
  <div>
    <a className={style.keywordWithColon}>{keyword}</a>
    {name && <a>{name}</a>}
  </div>
)
Statement.propTypes = {
  name: PropTypes.string,
  keyword: PropTypes.string.isRequired,
}
Statement.defaultProps = { name: null }


const titleStatementPropTypes = { keyword: PropTypes.string }
const titleStatementDefaultProps = { keyword: null }

const ExampleTitleStatement = ({ keyword, ...otherProps }) => <Statement keyword={keyword || 'Examples'} {...otherProps} />
ExampleTitleStatement.propTypes = titleStatementPropTypes
ExampleTitleStatement.defaultProps = titleStatementDefaultProps
ExampleTitleStatement.displayName = 'ExampleTitle'


const BackgroundTitleStatement = ({ keyword, ...otherProps }) => <Statement keyword={keyword || 'Background'} {...otherProps} />
BackgroundTitleStatement.propTypes = titleStatementPropTypes
BackgroundTitleStatement.defaultProps = titleStatementDefaultProps
BackgroundTitleStatement.displayName = 'BackgroundTitle'


const FeatureStatement = ({ keyword, ...otherProps }) => <Statement keyword={keyword || 'Feature'} {...otherProps} />
FeatureStatement.propTypes = titleStatementPropTypes
FeatureStatement.defaultProps = titleStatementDefaultProps
FeatureStatement.displayName = 'SuiteLine'

export const ExampleTitle = asLine(memo(ExampleTitleStatement), 'step')
export const BackgroundTitle = asLine(memo(BackgroundTitleStatement), 'test')
export const SuiteLine = asLine(memo(FeatureStatement), 'suite')
