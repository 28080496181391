import { dispatcher, events } from 'core/dispatcher'
import SuccessConditionService from 'services/successcondition/successConditionService'


function dispatchList(listLoading) {
  listLoading.then((list) => {
    dispatcher.dispatch({ type: events.configurationSuccessConditions.dataReceived, list })
  }).catch((message) => {
    dispatcher.dispatch({ type: events.configurationSuccessConditions.failed, message })
  })
}

export function retrieveData(project) {
  const listLoading = SuccessConditionService.findAllConfigs(project)
  dispatchList(listLoading)
}

export function handleAdd() {
  dispatcher.dispatch({ type: events.configurationSuccessConditions.addCondition })
}

export function handleCancel() {
  dispatcher.dispatch({ type: events.configurationSuccessConditions.cancelEdit })
}

export function handleEdit(id) {
  dispatcher.dispatch({ type: events.configurationSuccessConditions.editCondition, id })
}

export function handleDelete(project, id) {
  const listLoading = SuccessConditionService.delete(project, id)
  dispatchList(listLoading)
}

export function saveSuccessConditionConfig(successConditionConfig, project) {
  const call = successConditionConfig.id ? SuccessConditionService.updateConfig : SuccessConditionService.addConfig
  const listLoading = call(successConditionConfig, project)
  dispatchList(listLoading)
}
