import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import styles from './confirm.module.css'

const ConfirmTooltip = ({
  onCancel,
  onSubmit,
  children,
  visible,
  title,
}) => (
  <Tooltip
    title={(
      <div className={styles.confirmTooltip}>
        {title}
        <div className={styles.confirmTooltipButtons}>
          <Button
            color="primary"
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              onSubmit()
              onCancel()
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    )}
    arrow
    disableFocusListener
    disableHoverListener
    disableTouchListener
    open={visible}
    placement="top"
  >
    {children}
  </Tooltip>
)

ConfirmTooltip.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool,
  title: PropTypes.string,
}

ConfirmTooltip.defaultProps = {
  visible: false,
  title: 'Are you sure?',
}

export default ConfirmTooltip
