import {
  useCallback,
  useEffect,
  useRef,
  useReducer,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import UserService from 'services/userService'
import { dispatcher } from 'core/dispatcher'

import style from './header.module.scss'

const TFA_PENDING = 'TFA_PENDING'
const UPLOADED = 'UPLOADED'
const FAILED = 'FAILED'

const reducer = (state, action) => {
  switch (action.type) {
  case 'notification.tfa_confirmation_pending':
    return {
      ...state,
      visible: true,
      list: [{ type: TFA_PENDING, view: <TfaNotification key={TFA_PENDING} /> }].concat(state.list),
    }
  case 'notification.tfa_code_entry_pending':
    return {
      ...state,
      visible: true,
      list: [{ type: TFA_PENDING, view: <TfaCodeDisplayNotification key={TFA_PENDING} code={action.payload} /> }].concat(state.list),
    }
  case 'notification.tfa_confirmation_succeeded':
  case 'notification.tfa_confirmation_failed':
    return {
      ...state,
      visible: false,
      list: state.list.filter(it => it.type !== TFA_PENDING),
    }
  case 'notification.uploaded_to_hpqc':
    return {
      ...state,
      visible: true,
      list: [{ type: UPLOADED, view: <UploadedNotification key={UPLOADED} name={action.payload} /> }].concat(state.list),
    }
  case 'notification.hpqc_upload_failed':
    return {
      ...state,
      visible: true,
      list: [{ type: UPLOADED, view: <UploadFailedNotification key={FAILED} name={action.payload} /> }].concat(state.list),
    }
  case 'toggle visibility':
    return {
      ...state,
      visible: !state.visible,
    }
  default:
    return state
  }
}
const initialState = { visible: false, list: [] }

const anchorOrigin = { horizontal: 'center', vertical: 'bottom' }

const Notifications = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const buttonRef = useRef(null)
  const toggleVisible = useCallback(() => dispatch({ type: 'toggle visibility' }), [])
  useEffect(() => {
    const id = dispatcher.register(dispatch)
    return () => dispatcher.unregister(id)
  }, [])
  const icon = state.list.length > 0 ? 'notifications_active' : 'notifications'
  return (
    <div ref={buttonRef}>
      <IconButton color="inherit" disableTouchRipple onClick={toggleVisible} className={style.appBarBtn}>
        <Icon>{icon}</Icon>
      </IconButton>
      <Popover
        open={state.visible}
        onClose={toggleVisible}
        anchorEl={buttonRef.current}
        anchorOrigin={anchorOrigin}
      >
        <div className={style.notifications}>
          <div className={style.header}><a>Notifications</a></div>
          <div className={style.container}>
            { state.list.length === 0 && <NoNotifications /> }
            { state.list.map(notification => notification.view) }
          </div>
        </div>
      </Popover>
    </div>
  )
}

const TfaNotification = () => {
  const [error, setError] = useState()
  const [disabled, setDisabled] = useState(false)
  const inputRef = useRef()

  return (
    <div className={`${style.item} ${style.pendingNotification}`}>
      <div className={style.icon}>
        <img src="/images/tfa.png" alt="Two-factor-authentication" />
      </div>
      <div className={style.content}>
        <span>Two-factor authentication is required to continue. Please enter the code you received.</span>
        <div className={style.sendCodeContainer}>
          <TextField
            label="Code"
            fullWidth
            error={Boolean(error)}
            helperText={error}
            inputRef={inputRef}
            onChange={(e) => {
              if (e.target.value && error) {
                setError('')
              }
            }}
            disabled={disabled}
          />
          <Button
            color="primary"
            variant="contained"
            size="small"
            className={style.sendCodeBtn}
            onClick={() => {
              if (!inputRef.current.value) {
                setError('Required')
              } else {
                setDisabled(true)
                UserService.sendCode(inputRef.current.value)
              }
            }}
            disabled={disabled}
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  )
}

const TfaCodeDisplayNotification = ({ code }) => (
  <div className={`${style.item} ${style.pendingNotification}`}>
    <div className={style.icon}>
      <img src="/images/tfa.png" alt="Two-factor-authentication" />
    </div>
    <div className={style.content}>
      <span>Two-factor authentication is required to continue. Please enter this code in your authentication application.</span>
      <div className={style.sendCodeContainer}>{code}</div>
    </div>
  </div>
)
TfaCodeDisplayNotification.propTypes = { code: PropTypes.string.isRequired }

const UploadedNotification = ({ name }) => (
  <div className={style.item}>
    <div className={style.icon}>
      <UploadNotificationIcon successful />
    </div>
    <div className={style.content}>
      <a>
        Execution &apos;
        {name}
        &apos; is uploaded successfully.
      </a>
    </div>
  </div>
)
UploadedNotification.propTypes = { name: PropTypes.string.isRequired }


const UploadFailedNotification = ({ name }) => (
  <div className={style.item}>
    <div className={style.icon}>
      <UploadNotificationIcon successful={false} />
    </div>
    <div className={style.content}>
      <a>
        Uploading execution &apos;
        {name}
        &apos; to HPQC is failed.
      </a>
    </div>
  </div>
)
UploadFailedNotification.propTypes = { name: PropTypes.string.isRequired }

const UploadNotificationIcon = ({ successful }) => (
  <svg
    width="64"
    height="64"
    enableBackground="new 0 0 800 800"
    version="1.1"
    viewBox="0 0 800 800"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M771.9,400c0,205.3-166.6,371.9-371.9,371.9S28.1,605.3,28.1,400S194.7,28.1,400,28.1   S771.9,194.7,771.9,400z"
      fill={successful ? '#4DB6AC' : '#c62828'}
    />
    <g fill="none" stroke="#fff" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="16">
      <path
        d="m468.2 497.4h88.6c42.5 0 77-34.5 77-77s-34.5-77-77-77h-0.6c0.6-4.8 1-9.7 1-14.7
        0-65.4-53-118.4-118.4-118.4-50.9 0-94.2 32.2-110.9 77.4-10.6-10.6-25.2-17.2-41.4-17.2-32.3 0-58.5 26.2-58.5
        58.5 0 0.8 0.1 1.6 0.1 2.4-35.7 9.9-62 42.5-62 81.3 0 46.7 37.8 84.5 84.5 84.5h81.2"
        strokeLinejoin="round"
      />
      <polyline points="351.3 429.2 400 380.5 448.7 429.2" />
      <line x1="400" x2="400" y1="575.4" y2="383.8" />
    </g>
  </svg>
)
UploadNotificationIcon.propTypes = { successful: PropTypes.bool.isRequired }

const NoNotifications = () => (
  <div className={style.item}>
    <div className={style.content}>
      <div className={style.largeIcon}>
        <Icon>notifications</Icon>
        <a>You don&apos;t have any notifications.</a>
      </div>
    </div>
  </div>
)

export default Notifications
