import axios from 'axios'
import * as Common from 'services/common'

class GlobalSettingsService {
  loadSettings = () => {
    const url = '/api/global-settings'
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  save = (key, value) => {
    const url = `/api/global-settings/${encodeURIComponent(key)}`
    const config = { ...Common.requestOptions, headers: { 'content-type': 'application/json' } }
    return axios.post(url, value, config)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new GlobalSettingsService()
export default instance
