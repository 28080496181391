import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import { keys, load, write } from 'core/storage'
import ColumnNames from './columnNames'

const DEFAULT_PAGE_SIZE = 100
const IGNORE_CASE = (a, b) => a.toLowerCase().localeCompare(b.toLowerCase())

class TestRunStore extends ReportingEventEmitter {
  getInitialState(projectCode) {
    const storedFields = projectCode ? load(keys.TEST_RUN_REPORT_COLUMNS, projectCode) : null
    return {
      items: [],
      testers: [],
      loading: true,
      defaultPageSize: DEFAULT_PAGE_SIZE,
      errorCategories: [],
      labels: [],
      fields: storedFields || Object.keys(ColumnNames),
      openDrawer: true,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testRunReport.listReceived: {
      this.state.totalSize = action.list.totalSize
      this.state.passedCount = action.list.passedCount
      this.state.failedCount = action.list.failedCount
      this.state.items = action.list.items || []
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testRunReport.listInitialized: {
      this.state.totalSize = action.list.totalSize
      this.state.passedCount = action.list.passedCount
      this.state.failedCount = action.list.failedCount
      this.state.items = action.list.items || []
      this.state.testers = action.testers
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      this.state.errorCategories = action.errorCategories
        .map(category => category.name)
        .sort(IGNORE_CASE)
      this.state.labels = action.labels
        .sort(IGNORE_CASE)
      this.state.loading = false
      this.state.fields = load(keys.TEST_RUN_REPORT_COLUMNS, action.projectCode) || Object.keys(ColumnNames)
      this.emitChange()
      break
    }
    case events.testRunReport.listExpired: {
      this.state.loading = true
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testRunReport.failed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testRunReport.columnRemoved: {
      this.state.fields = this.state.fields.filter(it => it !== action.name)
      write(keys.TEST_RUN_REPORT_COLUMNS, this.state.fields, action.projectCode)
      this.emitChange()
      break
    }
    case events.testRunReport.columnAdded: {
      this.state.fields = [...this.state.fields, action.name]
      write(keys.TEST_RUN_REPORT_COLUMNS, this.state.fields, action.projectCode)
      this.emitChange()
      break
    }
    case events.testRunReport.openDrawer: {
      this.state.openDrawer = action.open || false
      this.emitChange()
      break
    }

    default: {
      // empty
    }
    }
  }
}

const testRunStore = new TestRunStore()
dispatcher.register(testRunStore.handleActions.bind(testRunStore))

export default testRunStore
