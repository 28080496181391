import axios from 'axios'
import * as Common from 'services/common'

class CheqService {
    retrieve = (project) => {
      const url = `/api/module/pms/${project}/settings`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    update = (project, settings) => {
      const url = `/api/module/pms/${project}/settings`
      return axios.post(url, settings, Common.requestOptions)
        .catch(Common.catchError)
    }

    updateSshKey = (project, sshKey) => {
      const url = `/api/module/pms/${project}/settings/key`
      const formData = new FormData()

      formData.append('file', sshKey)

      const config = {
        ...Common.requestOptions,
        headers: { 'content-type': 'multipart/form-data' },
      }
      return axios.post(url, formData, config)
        .catch(Common.catchError)
    }
}

const instance = new CheqService()
export default instance
