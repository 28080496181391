import { useCallback, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import JiraUploadsTable from 'components/executionDetails/jiraUploadsTable'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { retrieve, upload } from './actions'
import style from './style.module.scss'


const initialValue = { loading: true, list: [] }
const reducer = (state, action) => {
  switch (action.type) {
  case 'list received':
    return { loading: false, list: action.list }
  default:
    throw new Error('unknown event')
  }
}

const BasicJiraDetails = ({ executionId }) => {
  const [state, dispatch] = useReducer(reducer, initialValue)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  const loadUploadHistory = id => retrieve(projectCode, id)
    .then(list => dispatch({ type: 'list received', list }))
    .catch(errorHandler)

  useEffect(() => { loadUploadHistory(executionId) }, [executionId])
  useEffect(() => ReactTooltip.rebuild())
  const handleUpload = useCallback(() => {
    upload(projectCode, executionId)
      .then(() => loadUploadHistory(executionId))
      .catch(errorHandler)
  }, [executionId])
  return (
    <Card className={style.card}>
      <div className={style.overflowWrapper}>
        <CardHeader title="Jira Uploads" />
        <Spinner spinning={state.loading}>
          <JiraUploadsTable list={state.list} />
          <div className={style.buttons}>
            <Button variant="contained" id="upload" onClick={handleUpload}>Upload</Button>
          </div>
        </Spinner>
      </div>
    </Card>
  )
}

BasicJiraDetails.propTypes = {
  executionId: PropTypes.string.isRequired,
}

export default BasicJiraDetails
