export const keys = {
  LAST_PROJECT_MOVE: 'last_project_move',
  PROJECT: 'project',
  TEMPLATES: 'templates',
  TEST_RUN_REPORT_COLUMNS: 'test run report columns',
}

export const load = (key, projectCode) => {
  const storedText = localStorage.getItem(key)
  if (!storedText) {
    return null
  }
  if (projectCode) {
    const data = JSON.parse(storedText)
    return data[projectCode]
  }
  try {
    return JSON.parse(storedText)
  } catch {
    return storedText
  }
}

export const write = (key, value, projectCode) => {
  if (projectCode) {
    const storedText = localStorage.getItem(key)
    if (!storedText) {
      const data = { [projectCode]: value }
      localStorage.setItem(key, JSON.stringify(data))
      return
    }
    const data = JSON.parse(storedText)
    data[projectCode] = value
    localStorage.setItem(key, JSON.stringify(data))
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
}
