import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { withRouter } from 'react-router'

import HeaderButton from 'pages/headerButton'
import HpqcExcelImportSettings from 'components/configuration/hpqc/excelImportSettings'
import HpqcCredentials from 'components/configuration/hpqc/credentials'
import HpqcCustomFields from 'components/configuration/hpqc/customFields'
import HpqcTestSets from 'components/configuration/hpqc/testSets'
import HpqcSettings from 'components/configuration/hpqc/settings'
import TfaCredentials from 'components/configuration/tfaCredentials'


const JiraSettings = ({ match }) => {
  useEffect(() => {
    document.title = 'QiTASC ConQlude - Jira Settings'
  }, [])
  return (
    <Card>
      <CardHeader title="HPQC Settings" />
      <CardContent>
        <Box marginBottom={2}>
          <HeaderButton id="settingsBtn" label="Connection" url={`${match.url}/connection`} />
          <HeaderButton id="test-setsBtn" label="Test Sets" url={`${match.url}/test-sets`} />
          <HeaderButton id="credentialsBtn" label="User Credentials" url={`${match.url}/credentials`} />
          <HeaderButton id="tfaCredentialsBtn" label="TFA Credentials" url={`${match.url}/tfa-credentials`} />
          <HeaderButton id="customFieldsBtn" label="Custom Fields" url={`${match.url}/custom-fields`} />
          <HeaderButton id="hpqcExcelImportBtn" label="Excel Import" url={`${match.url}/hpqc-excel-import`} />
        </Box>
        <Switch>
          <Route path={`${match.url}/connection`} component={HpqcSettings} />
          <Route path={`${match.url}/test-sets`} component={HpqcTestSets} />
          <Route path={`${match.url}/credentials`} component={HpqcCredentials} />
          <Route path={`${match.url}/tfa-credentials`} component={TfaCredentials} />
          <Route path={`${match.url}/custom-fields`} component={HpqcCustomFields} />
          <Route path={`${match.url}/hpqc-excel-import`} component={HpqcExcelImportSettings} />
        </Switch>
      </CardContent>
    </Card>
  )
}

JiraSettings.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}


export default withRouter(JiraSettings)
