import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import union from 'lodash/union'
import without from 'lodash/without'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import Snackbar from '@mui/material/Snackbar'

import CreateTestInstanceButton from 'components/testSuite/testSuiteDetails/createTestInstanceButton'
import DateFilter from 'components/reports/common/widgets/dateFilter'
import Spinner from 'components/spinner'
import StringFilter from 'components/reports/common/widgets/stringFilter'
import TrimmedText from 'components/trimmedText'
import * as Utils from 'components/utils'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'
import calcTotalPages from 'utils/calcTotalPages'

import ImportExcel from './import'
import * as Actions from './actions'
import Store from './store'
import style from './style.module.css'
import TestPlanChips from './testPlanChips'
import TestPlanIdFilter from './testPlanIdFilter'

class TestPlanList extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount = () => {
    const { projectCode } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - HPQC Test Plans'
    Store.on('change', this.updateState)
    Actions.retrieveList(projectCode, query)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode } = this.context
      const { query } = this.props
      Actions.updateList(projectCode, query)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleFilterChange = filter => (value) => {
    const { redirect } = this.props
    const newFilter = {}
    newFilter[filter] = value
    newFilter.pageNumber = 1
    redirect(newFilter)
  }

  handleDateFilter = (dateFrom, dateUntil) => {
    const { redirect } = this.props
    redirect({ dateFrom, dateUntil, pageNumber: 1 })
  }

  handleRemoveChip = (filter) => {
    const { redirect } = this.props
    const newFilter = {}
    newFilter[filter] = null
    redirect(newFilter)
  }

  handleCreateTestPlans = () => {
    const { projectCode } = this.context
    const { query } = this.props
    const { selectedHidden, selectedItems } = this.state
    Actions.createTestPlans({
      project: projectCode, filter: query, selectedHidden, selectedItems,
    })
  }

  handleExportTestPlans = () => {
    const { projectCode } = this.context
    const { query } = this.props
    const { selectedHidden, selectedItems } = this.state
    Actions.exportTestPlans({
      project: projectCode, filter: query, selectedHidden, selectedItems,
    })
  }

  handleCreateTestInstances = (testSetId) => {
    const { projectCode } = this.context
    const { query } = this.props
    const { selectedHidden, selectedItems } = this.state
    Actions.createTestInstances({
      project: projectCode, filter: query, selectedHidden, selectedItems, testSetId,
    })
  }

  handleExportTestInstances = (testSetId) => {
    const { projectCode } = this.context
    const { query } = this.props
    const { selectedHidden, selectedItems } = this.state
    Actions.exportTestInstances({
      project: projectCode, filter: query, selectedHidden, selectedItems, testSetId,
    })
  }

  handleRowSelect = testId => (e) => {
    const { selectedItems } = this.state
    Actions.updateRowSelection(get(e, 'target.checked', false) ? union(selectedItems, [testId]) : without(selectedItems, testId))
  }

  handleShowHiddenItems = () => {
    Actions.selectAll({ includeHidden: true })
  }

  render() {
    const { projectCode } = this.context
    const { match, query } = this.props
    const {
      error, loading, confirmationMessage, isSelectedAll, successMessage, selectedHidden, selectedItems, defaultPageSize, totalSize, list,
    } = this.state

    if (error != null) {
      throw Error(error)
    }

    const { testSuiteName, testPlanId } = query
    const dateFrom = query.dateFrom ? moment(query.dateFrom) : null
    const dateUntil = query.dateUntil ? moment(query.dateUntil) : null

    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

    const columns = [
      {
        Header: (
          <Checkbox
            indeterminate={selectedItems.length > 0 && selectedItems.length < list.length}
            checked={isSelectedAll}
            disabled={list.length === 0}
            onChange={Actions.selectAll}
          />
        ),
        accessor: 'reference',
        Cell: item => <Checkbox checked={selectedItems.includes(item.value)} onChange={this.handleRowSelect(item.value)} />,
        padding: 'checkbox',
        width: 48,
        disableResizing: true,
      },
      {
        Header: 'Test Suite',
        accessor: 'testSuiteName',
        Cell: item => (
          <Link to={`/${match.params.projectCode}/test-suite/details/${item.row.original.testSuiteId}`}>
            <TrimmedText text={item.value} maxLength={30} />
          </Link>
        ),
        sortable: true,
      },
      {
        Header: 'Test Plan Id',
        accessor: 'testPlanId',
        sortable: true,
      },
      {
        Header: 'Test Plan Name',
        accessor: 'testPlanName',
        id: 'name',
        sortable: true,
      },
      {
        Header: 'Last Updated',
        accessor: 'time',
        Cell: item => Utils.formatDate(item.row.original.lastUpdated),
        sortable: true,
      },
    ]

    return (
      <div className={`row ${style.wrapper}`}>
        <div className="col-md-8">
          <Spinner spinning={loading}>
            <Card className={style.card}>
              <CardHeader
                id="cardHeader"
                title="HPQC Test Plans"
                action={
                  <ImportExcel onSelect={file => Actions.importExcel({ file, project: projectCode })} className={style.import} />
                }
              />
              <Snackbar open={confirmationMessage !== ''} message={confirmationMessage} onClose={Actions.confirmMessage} />
              <div className={style.buttonPanel}>
                <Button variant="contained" id="createBtn" onClick={this.handleCreateTestPlans} color="primary">Create / Update Test Plan</Button>
                <CreateTestInstanceButton onSubmit={this.handleCreateTestInstances} disabled={false} />
                <Button variant="contained" id="exportBtn" onClick={this.handleExportTestPlans}>Export Test Plan</Button>
                <CreateTestInstanceButton
                  label="Export Test Instances"
                  onSubmit={this.handleExportTestInstances}
                  disabled={false}
                />
                <Snackbar
                  open={successMessage != null}
                  message={successMessage || ''}
                  onClose={Actions.confirmSuccessMessage}
                />
              </div>
              { selectedHidden && (
                <div className={style.selectionMessage}>
                  <a>
                    You have selected
                    {totalSize}
                    {' '}
                    items (including now shown test plans).
                  </a>
                </div>
              )}
              { isSelectedAll && !selectedHidden && (totalSize !== selectedItems.length) && (
                <div className={style.selectionMessage}>
                  <a>
                    You have selected
                    {selectedItems.length}
                    {' '}
                    items.
                    {' '}
                  </a>
                  <u onClick={this.handleShowHiddenItems} onKeyPress={this.handleShowHiddenItems} role="link" tabIndex={0}>Click here</u>
                  <a> to select all hidden ones as well.</a>
                </div>
              )}
              <Table
                id="testPlan"
                columns={columns}
                data={list}
                totalPages={calcTotalPages({ totalSize, pageSize })}
              />
            </Card>
          </Spinner>
        </div>
        <div className="col-md-4">
          <TestPlanChips
            testSuiteName={testSuiteName}
            testPlanId={testPlanId}
            dateFrom={dateFrom ? Utils.formatDateNoSeconds(dateFrom) : null}
            dateUntil={dateUntil ? Utils.formatDateNoSeconds(dateUntil) : null}
            onRemove={this.handleRemoveChip}
          />
          <div>
            <StringFilter onSubmit={this.handleFilterChange('testSuiteName')} field="Test Suite Name" initialValue={testSuiteName} />
            <TestPlanIdFilter onSubmit={this.handleFilterChange('testPlanId')} field="Test Plan ID" initialValue={testPlanId} />
            <DateFilter onSubmit={this.handleDateFilter} initialDateFrom={dateFrom} initialDateUntil={dateUntil} />
          </div>
        </div>
      </div>
    )
  }
}

TestPlanList.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

TestPlanList.contextType = ProjectContext

export default withQuery(withRouter(TestPlanList))
