import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Snackbar from '@mui/material/Snackbar'

import Spinner from 'components/spinner'
import style from './project.module.css'

const Layout = ({ loading, showSuccessMessage, onSave, children }) => (
  <Card className={style.card}>
    <Spinner spinning={loading}>
      <div className="row">
        { children }
      </div>
      <div className={style.buttonRow}>
        <Button id="saveButton" variant="contained" color="primary" onClick={() => onSave()}>Save</Button>
      </div>
      <Snackbar
        id="snackbar"
        open={showSuccessMessage}
        message="Project configuration is saved successfully."
        autoHideDuration={10000}
      />
    </Spinner>
  </Card>
)


Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  showSuccessMessage: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout
