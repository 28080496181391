import PropTypes from 'prop-types'
import get from 'lodash/get'
import union from 'lodash/union'
import without from 'lodash/without'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'

import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import * as Utils from 'components/utils'
import Table from 'components/table'

import style from './style.module.css'

const HpqcReadyReportTable = ({ items, selectedExecutions, onSelect, projectCode }) => {
  const handleSelect = testId => (e) => {
    onSelect(get(e, 'target.checked', false) ? union(selectedExecutions, [testId]) : without(selectedExecutions, testId))
  }
  const handleSelectAll = () => {
    if (items.length > selectedExecutions.length) {
      onSelect(items.map(it => it.testId))
    } else {
      onSelect([])
    }
  }
  const columns = [
    {
      Header: (
        <Checkbox
          indeterminate={selectedExecutions.length > 0 && selectedExecutions.length < items.length}
          checked={items.length === selectedExecutions.length && items.length > 0}
          disabled={items.length === 0}
          onChange={handleSelectAll}
        />
      ),
      accessor: 'testId',
      Cell: item => <Checkbox onChange={handleSelect(item.value)} checked={selectedExecutions.includes(item.value)} />,
      width: 48,
      disableResizing: true,
      padding: 'checkbox',
    },
    {
      Header: '',
      accessor: 'result',
      Cell: item => <TestStatusIcon status={item.value} />,
      width: 48,
      disableResizing: true,
    },
    {
      Header: 'Test Name',
      accessor: 'testName',
      Cell: item => (
        <Link to={`/${projectCode}/detail/${item.row.original.testId}`}>
          <TrimmedText text={item.value} maxLength={30} />
        </Link>
      ),
    },
    {
      Header: 'Internal note',
      accessor: 'note',
    },
    {
      Header: 'Defects',
      accessor: 'defectsString',
    },
    {
      Header: 'Error category',
      accessor: 'errorCategories',
      Cell: item => (
        <ErrorCategoryColumn
          className={style.errorCategoryColumn}
          labels={item.row.original.labels}
          errorCategories={item.value}
          errorMessage={item.row.original.errorMessage}
        />
      ),
    },
    {
      Header: 'Tester',
      accessor: 'tester',
      Cell: item => <TrimmedText text={item.value} maxLength={30} />,
    },
    {
      Header: 'Start time',
      accessor: 'startTime',
      Cell: item => Utils.formatDate(item.value),
    },
    {
      Header: 'Duration',
      accessor: 'duration',
      Cell: item => Utils.formatDuration(item.value),
    },
  ]

  return (
    <Table
      id="phqcReadyReport"
      columns={columns}
      data={items}
    />
  )
}

HpqcReadyReportTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
  selectedExecutions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  projectCode: PropTypes.string.isRequired,
}

export default HpqcReadyReportTable
