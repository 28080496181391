import { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'

import ErrorPage from 'pages/error'
import LogoutPage from 'pages/logoutPage'
import MuiThemeProvider from 'core/themeProvider'
import PageOutline from 'core/pageOutline'
import ProjectRedirector from 'core/projectRedirector'
import Spinner from 'components/spinner'
import withErrorBoundary from 'core/errorBoundary'

import ProjectProviderStore from 'core/projectProvider/store'
import SessionStore from 'core/sessionStore'
import * as Actions from 'core/sessionActions'

const PageOutlineWithErrorBoundry = withErrorBoundary(PageOutline)
const ProjectRedirectorWithErrorBoundary = withErrorBoundary(ProjectRedirector)

class App extends Component {
  constructor(props) {
    super(props)
    Actions.setGlobalDefaults()
    this.state = SessionStore.getState()
  }

  componentDidMount() {
    const { loggedIn } = this.state
    SessionStore.on('change', this.updateState)
    if (!loggedIn) {
      Actions.authenticate(ProjectProviderStore.getState().projectCode)
    }
  }

  componentWillUnmount() {
    SessionStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    const state = SessionStore.getState()
    if (!state.loggedIn && !state.authenticationError) {
      Actions.authenticate(ProjectProviderStore.getState().projectCode)
    }
    this.setState(state)
  }

  render() {
    const { error, loggedIn } = this.state
    if (error) {
      return <div>{error}</div>
    }

    return (
      <MuiThemeProvider>
        <CssBaseline />
        <Spinner spinning={!loggedIn}>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact component={ProjectRedirectorWithErrorBoundary} />
              <Route path="/administration" component={PageOutlineWithErrorBoundry} />
              <Route path="/user" component={PageOutlineWithErrorBoundry} />
              <Route path="/error" component={ErrorPage} />
              <Route path="/logout" component={LogoutPage} />
              <Route path="/:projectCode" component={PageOutlineWithErrorBoundry} />
            </Switch>
          </BrowserRouter>
        </Spinner>
      </MuiThemeProvider>
    )
  }
}

export default App
