import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'
import PublishIcon from '@mui/icons-material/Publish'
import { Component } from 'react'
import Tooltip from '@mui/material/Tooltip'

class FileDialog extends Component {
  componentDidMount() {
    const inputElement = document.createElement('input')
    inputElement.setAttribute('type', 'file')
    inputElement.addEventListener('change', this.handleFileSelect)
    this.inputElement = inputElement
  }

  handleFileSelect = (e) => {
    this.props.onSelectFile(e)
  }

  handleIconClick = (e) => {
    e.preventDefault()
    this.inputElement.click()
  }

  render() {
    return (
      <Tooltip title={this.props.title}>
        <IconButton style={{ height: '20px', width: '30px', padding: '0' }} onMouseUp={this.handleIconClick}><PublishIcon /></IconButton>
      </Tooltip>
    )
  }
}

FileDialog.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  title: PropTypes.string,
}

FileDialog.defaultProps = {
  title: '',
}

export default FileDialog
