import { Component } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import Error from 'components/error'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import Store from './store'
import * as Actions from './actions'


class HpqcCredentials extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - HPQC User Credentials'
    Store.on('change', this.updateState)
    Actions.retrieveCredentials(projectCode)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { projectCode, user } = this.context
    const { error, loading, showSuccessMessage } = this.state
    if (error != null) {
      return <Error message={error} />
    }

    const { credentials } = this.state

    return (
      <div>
        <Typography variant="h6">{`HPQC User Credentials for ${user.name}`}</Typography>
        <Spinner spinning={loading}>
          <Box paddingTop={2} paddingBottom={2}>
            <Grid container>
              <Grid item md={3}>
                <TextField
                  fullWidth
                  id="usernameField"
                  label="Username"
                  value={credentials.username}
                  onChange={e => Actions.handleValueChange({ ...credentials, username: e.target.value })}
                />
                <TextField
                  fullWidth
                  id="passwordField"
                  label="Password"
                  type="password"
                  value={credentials.password}
                  onChange={e => Actions.handleValueChange({ ...credentials, password: e.target.value })}
                />
              </Grid>
            </Grid>
          </Box>
          <Button variant="contained" id="saveButton" color="primary" onClick={() => Actions.save(projectCode, credentials)}>Save</Button>
          <Snackbar id="snackbar" open={showSuccessMessage} message="Credentials were saved successfully." autoHideDuration={10000} />
        </Spinner>
      </div>
    )
  }
}

HpqcCredentials.contextType = ProjectContext

export default HpqcCredentials
