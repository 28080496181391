import axios from 'axios'
import { dispatcher, events } from 'core/dispatcher'
import * as Common from 'services/common'
import * as Utils from 'components/utils'
import UserService from 'services/userService'


export function setGlobalDefaults() {
  // Spring identifies requests with "X-Requested-With" header as api requests and responds with 401 in case of authentication
  // problems rather than 302.
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  // Axios uses defauls value "application/json, text/plain, */*" which confuses Spring AuthenticationEntryPoint and causes 302.
  // Setting accept header make sure default entry point with 302 is not applied in case of authentication problem.
  axios.defaults.headers.common.Accept = 'application/json'

  if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = ''
    axios.defaults.auth = {
      username: 'testmanager',
      password: 'test',
    }
  }
}

export function logout() {
  axios.delete('/api/login', Common.requestOptions)
}

export function authenticate(projectCode) {
  UserService.getCurrentUser(projectCode)
    .then((user) => {
      dispatcher.dispatch({ type: events.session.authenticated, user })
    }).catch((error) => {
      if (error.response != null && error.response.status === 401) {
        const currentPath = encodeURIComponent(window.location.pathname + window.location.search + window.location.hash)
        window.location = `/api/login?origin=${currentPath}`
      } else {
        dispatcher.dispatch({ type: events.session.authenticationError, message: Utils.extractMessage(error) })
      }
    })
}
