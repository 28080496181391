import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import style from './style.module.scss'

const ErrorIcon = ({ error }) => (
  <Icon
    data-tip={error}
    data-class={style.iconTooltip}
    className={style.icon}
  >
    error
  </Icon>
)

ErrorIcon.propTypes = { error: PropTypes.string.isRequired }

export default ErrorIcon
