import { useEffect, useReducer } from 'react'
import { VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryTooltip } from 'victory'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import useProject from 'hooks/useProject'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import style from './dailySuccessRate.module.css'

import retrieveInitialData from './actions'
import { initialState, reducer } from './reducer'

const inlineStyle = {
  legend: { parent: { width: '100%', height: '30px', display: 'inline-flex' } },
  chart: { parent: { maxWidth: '600px', margin: '0 auto' } },
}

const colorScale = ['#3c8863', '#c62828', '#e5cd38', '#f7941e']

const legend = [
  { name: 'Passed', symbol: { type: 'square', fill: colorScale[0] } },
  { name: 'Failed', symbol: { type: 'square', fill: colorScale[1] } },
  { name: 'Pending', symbol: { type: 'square', fill: colorScale[2] } },
  { name: 'Total', symbol: { type: 'square', fill: colorScale[3] } },
]

const DailySuccessRate = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const errorHandler = useErrorHandler()
  const { projectList, projectCode } = useProject()

  useEffect(() => {
    retrieveInitialData(projectCode)
      .then(data => dispatch({ type: 'received', data }))
      .catch(errorHandler)
  }, [errorHandler, projectCode])

  const getCurrentProjectName = () => projectList.find(p => p.code === projectCode)?.name

  const { loading, categories, noExecutionsLastWeek, bars } = state
  return (
    <Card className={style.paperChartContainer}>
      <CardHeader
        title="Daily Results from Past Week"
        subheader={getCurrentProjectName()}
      />
      {noExecutionsLastWeek
        && (
          <div className={style.noExecutionsLastWeek}>
            <a>No executions from last week!</a>
          </div>
        )}
      {!noExecutionsLastWeek
        && (
          <Spinner spinning={loading}>
            <CardContent>
              <div className={style.legend}>
                <VictoryChart style={inlineStyle.chart} width={600} height={400}>
                  <VictoryGroup offset={7} colorScale={colorScale} categories={categories}>
                    <VictoryBar labelComponent={<VictoryTooltip />} data={bars.passed} />
                    <VictoryBar labelComponent={<VictoryTooltip />} data={bars.failed} />
                    <VictoryBar labelComponent={<VictoryTooltip />} data={bars.pending} />
                    <VictoryBar labelComponent={<VictoryTooltip />} data={bars.total} />
                  </VictoryGroup>
                </VictoryChart>
                <VictoryLegend orientation="horizontal" data={legend} style={inlineStyle.legend} width={200} height={30} />
              </div>
            </CardContent>
          </Spinner>
        )}
    </Card>
  )
}

export default DailySuccessRate
