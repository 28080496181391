import UserService from 'services/userService'

export const retrieveData = async (email) => {
  const user = await UserService.findOne(email)
  return user
}

export const revoke = async (email, permission) => {
  await UserService.removePermission(email, permission)
  const user = await retrieveData(email)
  return user
}

export const add = async (email, role) => {
  await UserService.addPermission(email, role)
  const user = await retrieveData(email)
  return user
}

export const saveApiSecret = (email, apiSecret) => UserService.resetApiSecret(email, apiSecret)
