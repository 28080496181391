import { useState } from 'react'
import PropTypes from 'prop-types'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'

import style from './style.module.css'

const ExpansionPanel = ({ children, title, ...otherProps }) => {
  const [open, setOpen] = useState(false)
  return (
    <Card {...otherProps}>
      <CardHeader
        onClick={() => setOpen(!open)}
        className={style.extensionPanelTitle}
        action={(
          <IconButton onClick={() => setOpen(!open)}>
            <ExpandMoreIcon />
          </IconButton>
        )}
        title={title}
      />
      { open && (
        <CardContent>
          {children}
        </CardContent>
      )}
    </Card>
  )
}

ExpansionPanel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ExpansionPanel
