import { dispatcher, events } from 'core/dispatcher'
import UploadService from 'services/hpqc/uploadService'

export function retrieveReport(project, { pageNumber, pageSize }) {
  UploadService.findAll(project, pageNumber, pageSize)
    .then((response) => {
      dispatcher.dispatch({ type: events.hpqcUploadReport.listReceived, items: response.items, totalSize: response.totalItems })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.hpqcUploadReport.failed, error }) })
}

export function updateReport(project, filters) {
  setTimeout(() => dispatcher.dispatch({ type: events.hpqcUploadReport.listExpired }))
  retrieveReport(project, filters)
}
