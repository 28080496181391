import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import FieldWithTag from './fieldWithTag'
import style from './fields.module.scss'

const validateSettings = (settings) => {
  let errors = {}
  if (settings.enabled && (!settings.baseUrl || settings.baseUrl.length === 0)) {
    errors = { ...errors, baseUrl: 'Base URL cannot be empty' }
  }
  return errors
}

const Settings = ({
  settings, onSave, customFields, issueTypes, linkTypes,
}) => {
  const initialSettings = {
    ...settings,
    parentIssueFields: settings.parentIssueFields || {},
    testIssueFields: settings.testIssueFields || {},
    executionIssueFields: settings.executionIssueFields || {},
  }
  const [state, setState] = useState({ ...initialSettings, errors: {} })

  const updateSettings = (s) => {
    const mergedSettings = { ...state, ...s }
    const errors = validateSettings(mergedSettings)
    setState({ ...mergedSettings, errors })
  }
  const handleSave = () => onSave(state)
  return (
    <>
      <h4 className={style.title}>Parent Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Link with parent issue">
            <Select
              name="linkId"
              value={state.linkId}
              disabled={linkTypes.length === 0}
              onChange={e => updateSettings({ linkId: e.target.value })}
            >
              <MenuItem
                key="default_key"
                value=""
              >
                Not Selected
              </MenuItem>
              {linkTypes.map(link => <MenuItem key={link} value={link}>{link}</MenuItem>)}
            </Select>
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Best rate field">
            <Select
              name="bestRate"
              value={state.parentIssueFields.bestRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, bestRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
          <DataField name="Worst rate field">
            <Select
              name="worstRate"
              value={state.parentIssueFields.worstRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, worstRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
          <DataField name="Averate rate field">
            <Select
              name="averageRate"
              value={state.parentIssueFields.averageRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, averageRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Result field">
            <Select
              name="result"
              value={state.parentIssueFields.result}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, result: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
        </div>
      </div>
      <h4 className={style.title}>Test Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Issue type">
            <Select
              name="testIssue"
              value={state.testIssueType}
              disabled={issueTypes.length === 0}
              onChange={e => updateSettings({ testIssueType: e.target.value })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {issueTypes.map(issueType => <MenuItem key={issueType} value={issueType}>{issueType}</MenuItem>)}
            </Select>
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Best rate field">
            <Select
              name="bestRate"
              value={state.testIssueFields.bestRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, bestRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
          <DataField name="Worst rate field">
            <Select
              name="worstRate"
              value={state.testIssueFields.worstRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, worstRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
          <DataField name="Averate rate field">
            <Select
              name="averageRate"
              value={state.testIssueFields.averageRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, averageRate: e.target.value } })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {customFields.map(field => <MenuItem key={field.id} value={field.id}>{field.name}</MenuItem>)}
            </Select>
          </DataField>
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Test Case ID field"
            fields={customFields}
            field={state.testIssueFields.testCaseId}
            tag={state.testIssueFields.testCaseIdTag}
            onChange={(testCaseId, testCaseIdTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, testCaseId, testCaseIdTag } })}
          />
          <FieldWithTag
            name="App field"
            fields={customFields}
            field={state.testIssueFields.app}
            tag={state.testIssueFields.appTag}
            onChange={(app, appTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, app, appTag } })}
          />
          <FieldWithTag
            name="Platform field"
            fields={customFields}
            field={state.testIssueFields.platform}
            tag={state.testIssueFields.platformTag}
            onChange={(platform, platformTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, platform, platformTag } })}
          />
        </div>
      </div>
      <h4 className={style.title}>Execution Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Issue type">
            <Select
              name="executionIssue"
              value={state.executionIssueType}
              disabled={issueTypes.length === 0}
              onChange={e => updateSettings({ executionIssueType: e.target.value })}
            >
              <MenuItem key="default_key" value="">Not Selected</MenuItem>
              {issueTypes.map(issueType => <MenuItem key={issueType} value={issueType}>{issueType}</MenuItem>)}
            </Select>
          </DataField>
          <DataField name="PGW Version">
            <TextField
              id="pgwVersionTagField"
              value={state.executionIssueFields.pgwVersionTag}
              onChange={e => updateSettings({ executionIssueFields: { ...state.executionIssueFields, pgwVersionTag: e.target.value } })}
            />
          </DataField>
          <DataField name="Attachment Regex">
            <TextField
              id="attachmentRegexField"
              value={state.executionIssueFields.attachmentRegex}
              onChange={e => updateSettings({ executionIssueFields: { ...state.executionIssueFields, attachmentRegex: e.target.value } })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Recognition rate field"
            fields={customFields}
            field={state.executionIssueFields.recognitionRate}
            tag={state.executionIssueFields.recognitionRateTag}
            onChange={(recognitionRate, recognitionRateTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, recognitionRate, recognitionRateTag } })
            }}
          />
          <FieldWithTag
            name="Total bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesTotal}
            tag={state.executionIssueFields.bytesTotalTag}
            onChange={(bytesTotal, bytesTotalTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesTotal, bytesTotalTag } })
            }}
          />
          <FieldWithTag
            name="Matched bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesMatched}
            tag={state.executionIssueFields.bytesMatchedTag}
            onChange={(bytesMatched, bytesMatchedTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesMatched, bytesMatchedTag } })
            }}
          />
          <FieldWithTag
            name="Unmatched bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesUnmatched}
            tag={state.executionIssueFields.bytesUnmatchedTag}
            onChange={(bytesUnmatched, bytesUnmatchedTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesUnmatched, bytesUnmatchedTag } })
            }}
          />
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Cycle field"
            fields={customFields}
            field={state.executionIssueFields.cycle}
            tag={state.executionIssueFields.cycleTag}
            onChange={(cycle, cycleTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, cycle, cycleTag } })}
          />
          <FieldWithTag
            name="Tester field"
            fields={customFields}
            field={state.executionIssueFields.tester}
            tag={state.executionIssueFields.testerTag}
            onChange={(tester, testerTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, tester, testerTag } })}
          />
          <FieldWithTag
            name="App field"
            fields={customFields}
            field={state.executionIssueFields.app}
            tag={state.executionIssueFields.appTag}
            onChange={(app, appTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, app, appTag } })}
          />
          <FieldWithTag
            name="Platform field"
            fields={customFields}
            field={state.executionIssueFields.platform}
            tag={state.executionIssueFields.platformTag}
            onChange={(platform, platformTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, platform, platformTag } })}
          />
        </div>
      </div>
      <div className={style.buttonRow}>
        <Button
          variant="contained"
          id="saveButton"
          color="primary"
          onClick={handleSave}
          disabled={Object.keys(state.errors) > 0}
        >
          Save
        </Button>
      </div>
    </>
  )
}

Settings.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  issueTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  linkTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
}

export default Settings
