import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import FormattedDate from 'components/formattedDate'
import TestStatusIcon from 'components/testStatusIcon'
import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import CustomTable from 'components/table'

const JiraUploadList = ({ list, projectCode, totalPages }) => {
  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'result',
      Cell: item => <TestStatusIcon status={item.value} />,
      width: 48,
      disableResizing: true,
    },
    {
      Header: 'Test Suite',
      accessor: 'testSuiteName',
      Cell: item => <Link to={`/${projectCode}/detail/${item.row.original.executionId}`} title={item.value}>{item.value}</Link>,
    },
    {
      Header: 'Test',
      accessor: 'executionId',
      Cell: item => <Link to={`/${projectCode}/detail/${item.value}/${item.row.original.testIndex}`}>{item.row.original.testName}</Link>,
    },
    {
      Header: 'Cycle',
      accessor: 'cycle',
    },
    {
      Header: 'PGW Version',
      accessor: 'pgwVersionTag',
    },
    {
      Header: 'Application',
      accessor: 'app',
    },
    {
      Header: 'Platform',
      accessor: 'platform',
    },
    {
      Header: 'Recognition Rate',
      accessor: 'recognitionRate',
    },
    {
      Header: 'Jira Execution',
      accessor: 'executionIssueLink',
      Cell: item => (
        item.value
          ? <a href={item.value} target="_blank" rel="noopener noreferrer">{item.row.original.executionIssue}</a>
          : item.row.original.executionIssue || ''
      ),
    },
    {
      Header: 'User',
      accessor: 'user',
    },
    {
      Header: 'Upload Time',
      accessor: 'uploadTime',
      Cell: item => <FormattedDate value={item.value} />,
    },
    {
      Header: 'Upload Result',
      accessor: 'errorMessage',
      Cell: item => (item.value ? (<UploadFailedIcon message={item.value} />) : <UploadSuccessfulIcon />),
    },
  ], [])

  return (
    <CustomTable
      id="jiraBasicUploads"
      columns={columns}
      data={list}
      totalPages={totalPages}
    />
  )
}

JiraUploadList.propTypes = {
  projectCode: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  totalPages: PropTypes.number,
}

JiraUploadList.defaultProps = {
  totalPages: undefined,
  list: [],
}

export default JiraUploadList
