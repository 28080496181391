import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'


class HpqcUploadedStore extends ReportingEventEmitter {
  state = {
    items: [],
    loading: true,
    defaultPageSize: 20,
    totalSize: 0,
  }

  handleActions(action) {
    switch (action.type) {
    case events.jiraUploads.listReceived: {
      this.state.totalSize = action.totalSize
      this.state.items = action.items
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.jiraUploads.listExpired: {
      this.state.loading = true
      this.emitChange()
      break
    }
    case events.jiraUploads.failed: {
      this.state = { error: action.error }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcUploadedStore()
dispatcher.register(store.handleActions.bind(store))

export default store
