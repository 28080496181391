import { useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import style from './style.module.scss'

const SuccessConditionSelector = (
  {
    conditionId, requiresComment, state, operations, updateCondition,
  },
) => {
  const [commentOpen, setCommentOpen] = useState(false)
  const [newState, setNewState] = useState('PASSED')
  const [commentText, setCommentText] = useState('')

  const handleChange = (event) => {
    if (requiresComment) {
      setNewState(event.target.value)
      setCommentOpen(true)
    } else {
      updateCondition(conditionId, event.target.value, null)
    }
  }

  const updateCommentText = (event) => {
    setCommentText(event.target.value)
  }

  const handleSubmit = () => {
    setCommentOpen(false)
    updateCondition(conditionId, newState, commentText)
  }

  const handleCancel = () => {
    setCommentOpen(false)
  }

  if (commentOpen) {
    return (
      <Dialog open onClose={handleCancel} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Detail required</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="comment"
            label="Comment"
            type="text"
            fullWidth
            onChange={updateCommentText}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button type="submit" onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <FormControl className={style.formControl}>
      <Select
        value={state}
        onChange={handleChange}
      >
        {operations.map(op => <MenuItem key={op.name} value={op.name}>{op.description}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

SuccessConditionSelector.propTypes = {
  conditionId: PropTypes.string.isRequired,
  requiresComment: PropTypes.bool.isRequired,
  state: PropTypes.string.isRequired,
  operations: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  })).isRequired,
  updateCondition: PropTypes.func.isRequired,
}

export default SuccessConditionSelector
