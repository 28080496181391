import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import TestCategoryService from 'services/testCategoryService'
import useProject from 'hooks/useProject'

import style from './style.module.css'

const TestCategorySelector = ({ initialValue, onChange }) => {
  const [testCategories, setTestCategories] = useState([])
  const { projectCode } = useProject()

  useEffect(() => {
    let isMounted = true
    TestCategoryService.findAll(projectCode)
      .then((list) => {
        if (isMounted) setTestCategories(list)
      })
    return () => { isMounted = false }
  }, [projectCode])

  return (
    <FormControl className={style.testCategorySelector}>
      <Select
        fullWidth
        id="testCategoryList"
        value={(testCategories.length === 0 ? null : initialValue) || ''}
        onChange={e => onChange(e.target.value)}
      >
        {testCategories.map(category => (
          <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}


TestCategorySelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

TestCategorySelector.defaultProps = {
  initialValue: '',
}

export default TestCategorySelector
