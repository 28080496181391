import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import DataField from 'components/executionDetails/dataField'

import style from './settings.module.css'


const isUrlValid = settings => (!settings.enabled || settings.url.length > 0)

const isDomainValid = settings => (!settings.enabled || settings.domain.length > 0)

const isProjectValid = settings => (!settings.enabled || settings.project.length > 0)

const isScheduleValid = settings => (!settings.enabled || !settings.autoUpload || settings.schedule.length > 0)

const isDelayValid = settings => (!settings.enabled || !settings.autoUpload
    || settings.delayBeforeAutoSubmit == parseInt(settings.delayBeforeAutoSubmit, 10)) // eslint-disable-line eqeqeq

const validate = (settings, errors) => {
  const updatedErrors = { ...errors }
  if (isUrlValid(settings)) delete updatedErrors.url
  if (isDomainValid(settings)) delete updatedErrors.domain
  if (isProjectValid(settings)) delete updatedErrors.project
  if (isScheduleValid(settings)) delete updatedErrors.schedule
  if (isDelayValid(settings)) delete updatedErrors.delay
  return updatedErrors
}

const ConnectorSettings = ({ initialSettings, onSave }) => {
  const [state, setState] = useState({ settings: initialSettings, errors: {} })

  const { settings, errors } = state
  const onChange = s => setState({ settings: s, errors: validate(s, errors) })
  const handleSave = () => {
    const updatedErrors = {}
    if (!isUrlValid(settings)) updatedErrors.url = 'Url cannot be empty'
    if (!isDomainValid(settings)) updatedErrors.domain = 'Domain cannot be empty'
    if (!isProjectValid(settings)) updatedErrors.project = 'Project cannot be empty'
    if (!isScheduleValid(settings)) updatedErrors.schedule = 'Schedule cannot be empty'
    if (!isDelayValid(settings)) updatedErrors.delay = 'Delay must be a number.'
    if (Object.keys(updatedErrors).length === 0) {
      onSave(settings)
    } else {
      setState({ ...state, errors: updatedErrors })
    }
  }

  return (
    <>
      <Typography variant="h6">Connection Settings</Typography>
      <form onSubmit={handleSave} className={style.form}>
        <FormControlLabel
          label="Enabled"
          control={(
            <Checkbox
              id="enabledCheckBox"
              checked={settings.enabled}
              onChange={e => onChange({ ...settings, enabled: e.target.checked })}
            />
          )}
        />
        <DataField name="Url">
          <TextField
            id="urlField"
            fullWidth
            value={settings.url}
            error={errors.url != null}
            label={errors.url}
            onChange={e => onChange({ ...settings, url: e.target.value })}
          />
        </DataField>
        <DataField name="Domain">
          <TextField
            id="domainField"
            fullWidth
            value={settings.domain}
            error={errors.domain != null}
            label={errors.domain}
            onChange={e => onChange({ ...settings, domain: e.target.value })}
          />
        </DataField>
        <DataField name="Project">
          <TextField
            id="projectField"
            fullWidth
            value={settings.project}
            error={errors.project != null}
            label={errors.project}
            onChange={e => onChange({ ...settings, project: e.target.value })}
          />
        </DataField>
        <FormControlLabel
          label="Two-Factor-Authentication"
          control={(
            <Checkbox
              id="2faCheckbox"
              checked={settings.tfaEnabled}
              onChange={e => onChange({ ...settings, tfaEnabled: e.target.checked })}
            />
          )}
        />
        <FormControlLabel
          label="Upload steps"
          control={(
            <Checkbox
              id="uploadStepsCheckbox"
              checked={settings.uploadStepsEnabled}
              onChange={e => onChange({ ...settings, uploadStepsEnabled: e.target.checked })}
            />
          )}
        />
        <FormControlLabel
          label="Automatic Upload"
          control={(
            <Checkbox
              id="automaticUploadCheckbox"
              checked={settings.autoUpload}
              onChange={e => onChange({ ...settings, autoUpload: e.target.checked })}
            />
          )}
        />
        { settings.autoUpload && (
          <>
            <DataField name="Automatic Upload Schedule (cron expression)">
              <TextField
                id="scheduleField"
                fullWidth
                value={settings.schedule}
                error={errors.schedule != null}
                label={errors.schedule}
                onChange={e => onChange({ ...settings, schedule: e.target.value })}
              />
            </DataField>
            <DataField id="delayField" name="Delay Before Auto Submit (in seconds)">
              <TextField
                name="delayBeforeAutoSubmit"
                fullWidth
                value={settings.delayBeforeAutoSubmit}
                error={errors.delay != null}
                label={errors.delay}
                onChange={e => onChange({ ...settings, delayBeforeAutoSubmit: e.target.value })}
              />
            </DataField>
          </>
        )}
        <div className={style.buttonRow}>
          <Button variant="contained" id="saveButton" color="primary" onClick={handleSave}>Save</Button>
        </div>
      </form>
    </>
  )
}

ConnectorSettings.propTypes = {
  initialSettings: PropTypes.shape({
    enabled: PropTypes.bool,
    url: PropTypes.string,
    domain: PropTypes.string,
    project: PropTypes.string,
    uploadStepsEnabled: PropTypes.bool,
    autoUpload: PropTypes.bool,
    schedule: PropTypes.string,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ConnectorSettings
