import { useState } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import IconButton from 'components/iconButton'

import style from './style.module.css'

const EditRow = ({ projectList, roleList, onCancel, onSubmit }) => {
  const [permission, setPermission] = useState({ project: '', role: roleList[0].code })
  return (
    <form key="add" onSubmit={e => e.preventDefault()}>
      <div className={style.roleRow} id="editRow">
        <div className={style.projectColumn}>
          <Select
            label="Project"
            fullWidth
            id="projectField"
            value={permission.project}
            onChange={e => setPermission({ ...permission, project: e.target.value })}
          >
            {projectList.map(project => (
              <MenuItem key={project.code} value={project.code}>{project.name}</MenuItem>))}
          </Select>
        </div>
        <div className={style.roleColumn}>
          <Select
            label="Role"
            fullWidth
            id="roleField"
            value={permission.role}
            onChange={e => setPermission({ ...permission, role: e.target.value })}
          >
            {roleList.map(role => (
              <MenuItem key={role.code} value={role.code}>{role.name}</MenuItem>))}
          </Select>
        </div>
        <div className={style.buttonColumn}>
          <IconButton src="/images/accept.svg" onClick={() => onSubmit(permission)} id="acceptButton" />
          <IconButton src="/images/cancel.svg" onClick={onCancel} id="cancelButton" />
        </div>
      </div>
    </form>
  )
}

EditRow.propTypes = {
  projectList: PropTypes.arrayOf(PropTypes.object).isRequired,
  roleList: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default EditRow
