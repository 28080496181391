import ErrorCategoryService from 'services/errorCategoryService'
import downloadFile from 'utils/downloadFile'


const getList = async (project) => {
  const list = await ErrorCategoryService.getList(project)
  return list.slice().sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
}

export function retrieveData(project) {
  return Promise.all([
    getList(project),
    ErrorCategoryService.getLabels(project),
  ]).then(result => ({
    list: result[0],
    labels: result[1],
  }))
}

export async function create(project, category) {
  await ErrorCategoryService.create(project, category)
  return getList(project)
}

export async function update(project, category) {
  await ErrorCategoryService.update(project, category)
  return getList(project)
}

export async function remove(project, category) {
  await ErrorCategoryService.remove(project, category)
  return getList(project)
}

export function handleExport(project) {
  const url = ErrorCategoryService.createExportUrl(project)
  downloadFile(url)
}

export async function handleImport(project, file) {
  await ErrorCategoryService.importCategories(project, file)
  return getList(project)
}

export function addLabel(project, category, label) {
  if (category.labels.find(it => it === label)) {
    return Promise.reject(new Error(`Label "${label}" already exists`))
  }
  return ErrorCategoryService.update(project, { ...category, labels: [...category.labels, label] })
    .then(() => getList(project))
}

export function removeLabel(project, category, label) {
  return ErrorCategoryService.update(project, { ...category, labels: category.labels.filter(it => it !== label) })
    .then(() => getList(project))
}
