import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import PmsExportDetails from '../../components/pmsExportDetails/pmsExportDetails'
import ErrorPage from '../error/index'

const PmsExportDetailsPage = props => (
  <div style={{ padding: '0.5em' }}>
    <Switch>
      <Route path={`${props.match.url}/:pmsExport`} exact component={PmsExportDetails} />
      <Route component={ErrorPage} />
    </Switch>
  </div>
)

PmsExportDetailsPage.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
}

export default PmsExportDetailsPage
