import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'
import isEqual from 'lodash/isEqual'
import { DatePicker } from '@mui/lab'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import moment from 'moment'

import Pagination from 'components/pagination'
import Spinner from 'components/spinner'
import TrimmedText from 'components/trimmedText'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import calcTotalPages from 'utils/calcTotalPages'

import * as Actions from './actions'
import TestSuiteHistoryStore from './store'
import style from './style.module.css'

class TestSuiteHistory extends Component {
  state = TestSuiteHistoryStore.getState()

  componentDidMount() {
    const { projectCode } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Test Suite History'
    TestSuiteHistoryStore.on('change', this.updateState)
    Actions.retrieveInitialData(projectCode, query)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      Actions.retrieveInitialData(this.context.projectCode, this.props.query)
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    TestSuiteHistoryStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(TestSuiteHistoryStore.getState())
  }

  handleFilter = (event) => {
    const { redirect } = this.props
    const { name, date, defaultPageSize, pageSize } = this.state
    event.preventDefault()
    redirect({
      name,
      date: date.format('YYYY-MM-DD'),
      pageNumber: null,
      pageSize: pageSize === defaultPageSize ? null : pageSize,
    })
  }

  render() {
    const {
      error, loading, days, name, date, testSuitesHistory, pageSize, totalSize,
    } = this.state
    if (error != null) {
      return TestSuiteHistory.renderError(error.message)
    }

    return (
      <Spinner spinning={loading}>
        <form onSubmit={this.handleFilter} className={style.filterBlock}>
          <TextField
            id="nameFilter"
            placeholder="Filter by test suite name"
            value={name || ''}
            onChange={e => Actions.handleFilterChange({ name: e.target.value })}
            className={style.filterTextField}
          />
          <DatePicker
            id="dateFilter"
            maxDate={moment()}
            value={date.toDate()}
            inputFormat="DD.MM.YYYY"
            mask="__.__.____"
            onChange={d => Actions.handleFilterChange({ date: d })}
            renderInput={params => <TextField {...params} className={style.filterDateInput} />}
          />
          <Button id="searchButton" onClick={this.handleFilter} type="submit">Search</Button>
        </form>
        <div id="historyReport">
          <div className={style.firstline}>
            {days.map(item => (<div key={item} className={style.dates}>{item}</div>))}
          </div>
          <div>
            {testSuitesHistory.map(item => (
              <div key={item.name} className={style.line}>
                <TrimmedText text={item.name} maxLength={25} className={style.testSuite} />
                {item.history.map((result, pos) => (
                  <div key={days[pos]} className={TestSuiteHistory.mapStatusToClassName(result)} />
                ))}
              </div>
            ))}
          </div>
        </div>
        <Pagination
          totalPages={calcTotalPages({ totalSize, pageSize })}
        />
      </Spinner>
    )
  }
}

TestSuiteHistory.mapStatusToClassName = (result) => {
  if (result === 'PASSED') {
    return `${style.cell} ${style.passed}`
  } if (result === 'FAILED') {
    return `${style.cell} ${style.failed}`
  }
  return style.cell
}

TestSuiteHistory.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

TestSuiteHistory.propTypes = {
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

TestSuiteHistory.contextType = ProjectContext

export default withQuery(withRouter(TestSuiteHistory))
