const reducer = (state, action) => {
  switch (action.type) {
  case 'loaded':
    return {
      loading: false,
      list: action.list.sort((a, b) => a.code.localeCompare(b.code)),
      addProject: false,
    }
  case 'saving':
    return {
      ...state,
      loading: true,
    }
  case 'editing':
    return {
      ...state,
      selectedRow: action.code,
      selectedProject: state.list.find(it => it.code === action.code),
      addProject: false,
    }
  case 'editCanceled':
    return { ...state, selectedRow: null, selectedProject: null, addProject: false }
  case 'adding':
    return {
      ...state,
      addProject: true,
      selectedRow: null,
      selectedProject: { code: '', name: '', enabled: true },
    }
  default:
    throw new Error()
  }
}

export default reducer
