import YoutrackService from 'services/youtrackSettingsService'
import { dispatcher, events } from 'core/dispatcher'

export function retrieveInitialData() {
  YoutrackService.getList()
    .then((response) => {
      dispatcher.dispatch({ type: events.youtrackSettings.listReceived, list: response })
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.youtrackSettings.failed, error })
    })
}

function invalidAddSettings(settings) {
  return (!settings.instanceName
    || !settings.baseUrl
    || !settings.username
    || !settings.password
    || !settings.state
    || !settings.customerState
    || !settings.testCaseExecution
    || !settings.testCaseId)
}

function invalidEditSettings(settings) {
  return (settings.instanceName === ''
    || settings.baseUrl === ''
    || settings.username === ''
    || settings.password === ''
    || settings.state === ''
    || settings.customerState === ''
    || settings.testCaseExecution === ''
    || settings.testCaseId === '')
}

export function handleAddOrEdit(settings) {
  if (settings.addMode) {
    if (invalidAddSettings(settings)) {
      dispatcher.dispatch({ type: events.youtrackSettings.invalidInput })
      return
    }
    YoutrackService.add(settings)
      .then(() => {
        dispatcher.dispatch({ type: events.youtrackSettings.saved })
      })
      .catch((error) => {
        dispatcher.dispatch({ type: events.youtrackSettings.failed, error })
      })
  }

  if (settings.editMode) {
    if (invalidEditSettings(settings)) {
      dispatcher.dispatch({ type: events.youtrackSettings.invalidInput })
      return
    }
    YoutrackService.update(settings)
      .then((response) => {
        dispatcher.dispatch({ type: events.youtrackSettings.updated, youtrack: response })
      })
      .catch((error) => {
        dispatcher.dispatch({ type: events.youtrackSettings.failed, error })
      })
  }
}

export function addYouTrack() {
  dispatcher.dispatch({ type: events.youtrackSettings.startAdd })
}

export function editYouTrack(youtrack) {
  dispatcher.dispatch({ type: events.youtrackSettings.startEdit, youtrack })
}

export function closePanel() {
  dispatcher.dispatch({ type: events.youtrackSettings.closePanel })
}

export function deleteYouTrack(youtrack) {
  YoutrackService.delete(youtrack)
    .then(() => {
      dispatcher.dispatch({ type: events.youtrackSettings.saved })
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.youtrackSettings.failed, error })
    })
}
