import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'

const CreateTestInstanceButton = ({ label, onSubmit, disabled }) => {
  const [open, setOpen] = useState(false)
  const [testSet, setTestSet] = useState('')

  const handleSubmit = () => {
    onSubmit(testSet)
    setOpen(false)
    setTestSet('')
  }
  return [
    <Button variant="contained" disabled={disabled} key="button" onClick={() => setOpen(true)}>{label}</Button>,
    <Dialog open={open} key="dialog" onClose={() => setOpen(false)}>
      <DialogTitle>Create HPQC Test Instance</DialogTitle>
      <DialogContent>
        <p>Please enter id of the HPQC set in which test instance needs to be created.</p>
        <TextField value={testSet} name="testSet" onChange={e => setTestSet(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleSubmit} disabled={testSet.length === 0}>Create</Button>
        <Button color="primary" onClick={() => setOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>,
  ]
}

CreateTestInstanceButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  label: PropTypes.string,
}

CreateTestInstanceButton.defaultProps = {
  label: 'Create Test Instance',
}

export default CreateTestInstanceButton
