import { memo } from 'react'
import PropTypes from 'prop-types'

const inlineStyle = {
  wrapper: {
    display: 'inline-block',
    margin: '0 2px',
  },
  image: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
}

const IconButton = ({ src, onClick, hidden, id }) => (
  <div onClick={onClick} onKeyPress={onClick} style={inlineStyle.wrapper} role="button" tabIndex={0} id={id}>
    <img
      src={src}
      alt="Add new test category"
      style={hidden ? { ...inlineStyle.image, visibility: 'hidden' } : inlineStyle.image}
    />
  </div>
)

IconButton.propTypes = {
  src: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  id: PropTypes.string,
}

IconButton.defaultProps = {
  hidden: false,
  id: null,
}

export default memo(IconButton)
