import { dispatcher, events } from 'core/dispatcher'
import ReportingEventEmitter from 'core/eventEmitter'


const loadingLogs = {
  logs: [],
  status: 'loading',
}

const defaultTest = {
  name: '',
  result: '',
  index: 0,
  executionId: '',
  parameters: [],
  steps: [],
}

class TestStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      loadingJira: true,
      jiraUploads: [],
      protocol: { ...loadingLogs },
      trace: { ...loadingLogs },
      server: { ...loadingLogs },
      openErrorMessage: false,
      test: defaultTest,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testDetailsTestView.detailsReceived: {
      // sometimes error message of test is empty, so propogate the message from step level
      this.state.errorMessage = action.test.errorMessage
      if (action.test.result === 'FAILED' && !this.state.errorMessage) {
        this.state.errorMessage = action.test.steps
          .map(step => step.errorMessage)
          .find(err => err != null)
      }
      this.state.test = action.test
      this.state.loading = false
      this.state.error = null
      this.state.attachmentsHaveBeenUpdated = false
      if (this.state.test.deleted) {
        this.state.protocol.status = 'deleted'
        this.state.trace.status = 'deleted'
      }
      this.emitChange()
      break
    }
    case events.testDetailsTestView.logsReceived: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.logs = action.logs
        this.state.protocol.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'TRACE':
        this.state.trace.logs = action.logs
        this.state.trace.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'SERVER':
        this.state.server.logs = action.logs
        this.state.server.status = action.deleted ? 'deleted' : 'ready'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.testDetailsTestView.logsFailed: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.error = action.error
        this.state.protocol.status = 'failed'
        break
      case 'TRACE':
        this.state.trace.error = action.error
        this.state.trace.status = 'failed'
        break
      case 'SERVER':
        this.state.server.error = action.error
        this.state.server.status = 'failed'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.testDetailsTestView.failed: {
      this.state.loading = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsTestView.jiraDetailsReceived: {
      this.state.jiraUploads = action.details.uploads || []
      this.state.jiraApprovalDetails = action.details.approvalInfo
      this.state.loadingJira = false
      this.emitChange()
      break
    }
    case events.testDetailsTestView.jiraDetailsFailed: {
      this.state.error = action.error
      this.state.loadingJira = false
      this.emitChange()
      break
    }
    case events.testDetailsTestView.uploaded: {
      this.state.loadingJira = true
      this.emitChange()
      break
    }
    case events.attachmentList.removed:
    case events.attachmentList.removeUndone:
    case events.attachmentButton.removed: {
      this.state.test = defaultTest
      this.emitChange()
      break
    }
    case events.attachmentButton.attachmentRemoved: {
      this.state.attachmentsHaveBeenUpdated = true
      this.emitChange()
      break
    }
    case events.attachmentButton.failed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const testStore = new TestStore()
dispatcher.register(testStore.handleActions.bind(testStore))

export default testStore
