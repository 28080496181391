import PropTypes from 'prop-types'
import Snackbar from '@mui/material/Snackbar'

import Spinner from 'components/spinner'

const Layout = ({ loading, showSuccessMessage, children }) => (
  <Spinner spinning={loading}>
    { children }
    <Snackbar id="snackbar" open={showSuccessMessage} message="Settings were saved successfully." autoHideDuration={10000} />
  </Spinner>
)


Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  showSuccessMessage: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Layout
