import { memo } from 'react'
import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const CommentLine = ({ value }) => (
  <div className={style.comment}><a>{value.trim()}</a></div>
)

CommentLine.propTypes = {
  value: PropTypes.string.isRequired,
}

export default asLine(memo(CommentLine))
