import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const DescriptionLine = ({ value }) => (
  <div className={style.description}><a>{value}</a></div>
)

DescriptionLine.propTypes = {
  value: PropTypes.string.isRequired,
}

export default asLine(DescriptionLine)
