import AttachmentService from 'services/attachmentService'
import { dispatcher, events } from 'core/dispatcher'
import downloadFile from 'utils/downloadFile'


export function downloadAttachment(id) {
  const url = AttachmentService.createDownloadAttachmentLink(id)

  downloadFile(url)
}

export function getAttachmentUrl(id) {
  return AttachmentService.createDownloadAttachmentLink(id)
}

export function removeAttachment(attachmentId) {
  AttachmentService.removeAttachment(attachmentId)
    .then(() => dispatcher.dispatch({ type: events.attachmentButton.attachmentRemoved }))
    .catch((error) => { dispatcher.dispatch({ type: events.attachmentButton.failed, error }) })
}

export async function previewAttachment(id, name, type) {
  try {
    return await AttachmentService.downloadAttachment(id, name, type)
  } catch (error) {
    dispatcher.dispatch({ type: events.attachmentButton.failed, error })
    return null
  }
}
