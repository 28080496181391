import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionActions from '@mui/material/AccordionActions'
import Typography from '@mui/material/Typography'


const FilterPanel = ({ onSubmit, title, children, id }) => {
  const [expanded, setExpanded] = useState(false)
  const handleChange = (_, open) => {
    setExpanded(open)
  }
  const onFormSubmit = (e) => {
    e.preventDefault()
    return false
  }

  return (
    <form onSubmit={onFormSubmit} id={id}>
      <Accordion square expanded={expanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
        <AccordionActions>
          <Button onClick={e => handleChange(e, false)}>Cancel</Button>
          <Button color="primary" onClick={onSubmit}>
            Filter
            <input type="submit" style={{ display: 'none' }} />
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  )
}

FilterPanel.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

FilterPanel.defaultProps = { id: 'filterPanel' }

export default FilterPanel
