import { Dispatcher } from 'flux'

export const dispatcher = new Dispatcher()

export const events = {
  approval: {
    failed: 'APPROVAL_FAILED',
    listReceived: 'APPROVAL_LIST_RECEIVED',
    selected: 'APPROVAL_SELECTED',
    selectedAll: 'APPROVAL_SELECTED_ALL',
    approved: 'APPROVAL_VERIFIED',
    approving: 'APPROVAL_VERIFYING',
    openMoveDialog: 'APPROVAL_OPEN_MOVE_DIALOG',
    closeMoveDialog: 'APPROVAL_CLOSE_MOVE_DIALOG',
    performMove: 'APPROVAL_MOVE_REPORTS',
  },
  attachmentButton: {
    failed: 'ATTACHMENT_BUTTON_FAILED',
    attachmentRemoved: 'ATTACHMENT_BUTTON_REMOVED',
  },
  attachmentList: {
    closePreview: 'ATTACHMENT_CLOSE_PREVIEW',
    failed: 'ATTACHMENT_LIST_FAILED',
    preview: 'ATTACHMENT_LIST_PREVIEW',
    received: 'ATTACHMENT_LIST_RECEIVED',
    removed: 'ATTACHMENT_LIST_REMOVED',
    removeUndone: 'ATTACHMENT_LIST_REMOVE_UNDONE',
  },
  cdrSearch: {
    failed: 'CDR_SEARCH_FAILED',
  },
  csvExport: {
    templatesSaved: 'CSV_EXPORT_TEMPLATES_SAVED',
    toggleFieldSelection: 'CSV_EXPORT_TOGGLE',
  },
  errorCategoryList: {
    received: 'EC_LIST_RECEIVED',
    saved: 'EC_LIST_SAVED',
    failed: 'EC_LIST_FAILED',
    updated: 'EC_LIST_UPDATED',
  },
  errorCategoryHistory: {
    listReceived: 'ERROR_CATEGORIES_HISTORY_LIST_RECEIVED',
    listFailed: 'ERROR_CATEGORIES_HISTORY_LIST_FAILED',
  },
  excelExport: {
    dataReceived: 'EXCEL_EXPORT_DATA_RECEIVED',
    fieldChecked: 'EXCEL_EXPORT_FIELD',
    templateAdded: 'EXCEL_EXPORT_TEMPLATE_ADDED',
    templateNameChanged: 'EXCEL_EXPORT_TEMPLATE_NAME_CHANGED',
    templateRemoved: 'EXCEL_EXPORT_TEMPLATE_REMOVED',
    templateRenamed: 'EXCEL_EXPORT_TEMPLATE_RENAMED',
    templateRenameCanceled: 'EXCEL_EXPORT_TEMPLATE_REN_CANCELED',
    templateRenameStarted: 'EXCEL_EXPORT_TEMPLATE_REN_STARTED',
    templateSelected: 'EXCEL_EXPORT_TEMPLATE_SELECTED',
    toggleFieldSelection: 'EXCEL_EXPORT_TOGGLE',
  },
  messaging: {
    connected: 'MESSAGING_CONNECTED',
    disconnected: 'MESSAGING_DISCONNECTED',
  },
  pmsExportDetail: {
    detailsReceived: 'PMS_EXPORT_DETAILS_RECEIVED',
    exportDetailsFailed: 'GET_PMS_EXPORT_DETAILS_FAILED',
  },
  pmsExportReport: {
    listReceived: 'PMS_EXPORT_LIST_RECEIVED',
    exportListFailed: 'PMS_EXPORT_LIST_FAILED',
  },
  projectSelector: {
    failed: 'PROJECT_LIST_FAILED',
    listReceived: 'PROJECT_LIST_RECEIVED',
    selected: 'PROJECT_SELECTED',
    updateProject: 'PROJECT_UPDATE',
  },
  session: {
    authenticationFailed: 'AUTHENTICATION_FAILED',
    authenticated: 'AUTHENTICATED',
    authenticationError: 'AUTHENTICATION_ERROR',
    unauthorized: 'UNAUTHORIZED',
  },
  testDetailsStepView: {
    detailsReceived: 'STEP_DETAILS_RECEIVED',
    failed: 'STEP_DETAILS_FAILED',
    logsFailed: 'STEP_DETAILS_LOGS_FAILED',
    logsReceived: 'STEP_DETAILS_LOGS_RECEIVED',
  },
  testDetailsSuiteView: {
    approvalSucceeded: 'TEST_DETAILS_SUITE_APPROVAL_SUCCESS',
    approved: 'TEST_DETAILS_SUITE_APPROVED',
    openMoveDialog: 'TEST_DETAILS_SUITE_OPEN_MOVE_DIALOG',
    closeMoveDialog: 'TEST_DETAILS_SUITE_CLOSE_MOVE_DIALOG',
    performMove: 'TEST_DETAILS_SUITE_MOVE_REPORT',
    closePhoneInfo: 'TEST_DETAILS_SUITE_CLOSE_PHONE_INFO',
    defectSaved: 'TEST_DETAILS_SUITE_DEFECT_SAVED',
    deleted: 'TEST_DETAILS_SUITE_DELETED',
    deletionSucceeded: 'TEST_DETAILS_SUITE_DEL_SUCCESS',
    executionsReceived: 'TEST_DETAILS_SUITE_RECEIVED',
    failed: 'TEST_DETAILS_SUITE_FAILED',
    successConditionsReceived: 'TEST_DETAILS_SUITE_SC_RECEIVED',
    successConditionsFailed: 'TEST_DETAILS_SUITE_SC_FAILED',
    hpqcDetailsReceived: 'TEST_DETAILS_SUITE_HPQC_RECEIVED',
    hpqcDetailsUpdated: 'TEST_DETAILS_SUITE_HPQC_UPDATED',
    hpqcDetailsFailed: 'TEST_DETAILS_SUITE_HPQC_FAILED',
    hpqcNoteUpdated: 'TEST_DETAILS_SUITE_HPQC_NOTE_UPDATED',
    internalNoteUpdated: 'TEST_DETAILS_SUITE_INT_NOTE_UPDATED',
    jiraDetailsReceived: 'TEST_DETAILS_SUITE_JIRA_RECEIVED',
    jiraDetailsFailed: 'TEST_DETAILS_SUITE_JIRA_FAILED',
    logsFailed: 'TEST_DETAILS_SUITE_LOGS_FAILED',
    logsReceived: 'TEST_DETAILS_SUITE_LOGS_RECEIVED',
    newerExecutionsReceived: 'TEST_DETAILS_SUITE_NEWER_RECEIVED',
    newerExecutionsFailed: 'TEST_DETAILS_SUITE_NEWER_FAILED',
    retrieveStarted: 'TEST_DETAILS_SUITE_RETRIEVE',
    showPhoneInfo: 'TEST_DETAILS_SUITE_SHOW_PHONE_INFO',
    uploaded: 'TEST_DETAILS_SUITE_UPLOADED',
    uploadedToJira: 'TEST_DETAILS_SUITE_UPLOADED_JIRA',
    verificationSucceeded: 'TEST_DETAILS_SUITE_VER_SUCCESS',
    verified: 'TEST_DETAILS_SUITE_VERIFIED',
    cdrFileUploaded: 'TEST_DETAILS_SUITE_CDR_UPLOAD_SUCCESS',
    cdrUploadFailed: 'TEST_DETAILS_SUITE_CDR_UPLOAD_FAILED',
    retrievedVerifications: 'TEST_DETAILS_SUITE_RETRIEVED_VERIFICATIONS',
    retrieveVerificationsFailed: 'TEST_DETAILS_SUITE_RETRIEVE_VERIFICATIONS_FAILED',
    verificationStarted: 'TEST_DETAILS_SUITE_VERIFICATION_STARTED',
    verificationRunFailed: 'TEST_DETAILS_SUITE_VERIFICATION_RUN_FAILED',
    qonformVerificationStarted: 'TEST_DETAILS_SUITE_QONFORM_VERIFICATION_STARTED',
    qonformVerificationRunFailed: 'TEST_DETAILS_SUITE_QONFORM_VERIFICATION_RUN_FAILED',
    retrievedQonformVerifications: 'TEST_DETAILS_SUITE_RETRIEVED_QONFORM_VERIFICATIONS',
    retrieveQonformVerificationsFailed: 'TEST_DETAILS_SUITE_RETRIEVE_QONFORM_VERIFICATIONS_FAILED',
    overridesChanged: 'TEST_DETAILS_SUITE_OVERRIDES_CHANGED',
    qonformOverridesChanged: 'TEST_DETAILS_SUITE_QONFORM_OVERRIDES_CHANGED',
    gotContextPreview: 'TEST_DETAILS_SUITE_GOT_CONTEXT_PREVIEW',
    getContextPreviewFailed: 'TEST_DETAILS_SUITE_GET_CONTEXT_PREVIEW_FAILED',
    gotQonformContextPreview: 'TEST_DETAILS_SUITE_GOT_QONFORM_CONTEXT_PREVIEW',
    closeContextPreview: 'TEST_DETAILS_SUITE_CLOSE_QONFORM_CONTEXT_PREVIEW',
    getQonformContextPreviewFailed: 'TEST_DETAILS_SUITE_GET_QONFORM_CONTEXT_PREVIEW_FAILED',
    closeQonformContextPreview: 'TEST_DETAILS_SUITE_CLOSE_QONFORM_CONTEXT_PREVIEW',
    updateContext: 'TEST_DETAILS_SUITE_UPDATE_CONTEXT',
    updatedContext: 'TEST_DETAILS_SUITE_UPDATED_CONTEXT',
    updateContextFailed: 'TEST_DETAILS_SUITE_UPDATE_CONTEXT_FAILED',
    updateQonformContext: 'TEST_DETAILS_SUITE_UPDATE_QONFORM_CONTEXT',
    updatedQonformContext: 'TEST_DETAILS_SUITE_UPDATED_QONFORM_CONTEXT',
    updateQonformContextFailed: 'TEST_DETAILS_SUITE_UPDATE_QONFORM_CONTEXT_FAILED',
    gotCdrEnabled: 'TEST_DETAILS_SUITE_GOT_CDR_ENABLED',
    getCdrEnabledFailed: 'TEST_DETAILS_SUITE_GOT_CDR_ENABLED_FAILED',
    gotQonformEnabled: 'TEST_DETAILS_SUITE_GOT_QONFORM_ENABLED',
    getQonformEnabledFailed: 'TEST_DETAILS_SUITE_GOT_QONFORM_ENABLED_FAILED',
  },
  testDetailsTestView: {
    detailsReceived: 'TEST_DETAILS_RECEIVED',
    failed: 'TEST_DETAILS_FAILED',
    jiraDetailsReceived: 'TEST_DETAILS_JIRA_RECEIVED',
    jiraDetailsFailed: 'TEST_DETAILS_JIRA_FAILED',
    logsFailed: 'TEST_DETAILS_LOGS_FAILED',
    logsReceived: 'TEST_DETAILS_LOG_RECEIVED',
    uploaded: 'TEST_DETAILS_UPLOADED',
  },
  testPlanList: {
    confirmed: 'TEST_PLAN_LIST_CONFIRMED',
    failed: 'TEST_PLAN_LIST_FAILED',
    imported: 'TEST_PLAN_LIST_IMPORTED',
    importFailed: 'TEST_PLAN_LIST_IMPORT_FAILED',
    notificationConfirmed: 'TEST_PLAN_LIST_LIST_CONFIRMED',
    listExpired: 'TEST_PLAN_LIST_LIST_EXPIRED',
    listReceived: 'TEST_PLAN_LIST_LIST_RECEIVED',
    selected: 'TEST_PLAN_LIST_LIST_SELECTED',
    selectedAll: 'TEST_PLAN_LIST_LIST_SELECTED_ALL',
    testPlansCreated: 'TEST_PLAN_LIST_LIST_TC_CREATED',
    testPlansExported: 'TEST_PLAN_LIST_LIST_TC_EXPORTED',
    testInstancesCreated: 'TEST_PLAN_LIST_LIST_TI_CREATED',
    testInstancesExported: 'TEST_PLAN_LIST_LIST_TI_EXPORTED',
  },
  testRunReport: {
    columnAdded: 'TEST_RUN_COMPONENT_COLUMN_ADDED',
    columnRemoved: 'TEST_RUN_COMPONENT_COLUMN_REMOVED',
    failed: 'TEST_RUN_COMPONENT_FAILED',
    listExpired: 'TEST_RUN_COMPONENT_LIST_EXPIRED',
    listReceived: 'TEST_RUN_COMPONENT_LIST_RECEIVED',
    listInitialized: 'TEST_RUN_COMPONENT_LIST_INITIALIZED',
    openDrawer: 'TEST_RUN_COMPONENT_DRAWER_OPENED',
  },
  testSuiteList: {
    failed: 'TEST_SUITE_LIST_FAILED',
    listExpired: 'TEST_SUITE_LIST_LIST_EXPIRED',
    listReceived: 'TEST_SUITE_LIST_LIST_RECEIVED',
    openDrawer: 'TEST_SUITE_LIST_DRAWER_OPENED',
  },
  unassignedReport: {
    failed: 'UNASSIGNED_FAILED',
    listReceived: 'UNASSIGNED_LIST_RECEIVED',
    projectSelected: 'UNASSIGNED_PROJECT_SELECTED',
    reportSelected: 'UNASSIGNED_REPORT_SELECTED',
    reportsMoved: 'UNASSIGNED_REPORTS_MOVED',
  },
  hpqcReadyReport: {
    exported: 'HPQC_READY_REP_EXPORTED',
    exportStarted: 'HPQC_READY_REP_EXPORT_STARTED',
    uploadStarted: 'HPQC_READY_REP_UPLOAD_STARTED',
    listReceived: 'HPQC_READY_REP_RECEIVED',
    failed: 'HPQC_READY_REP_FAILED',
    updateSelected: 'HPQC_READY_REP_UPDATE_SELECTED',
  },
  hpqcExecutionReport: {
    listReceived: 'HPQC_EXEC_REP_RECEIVED',
    failed: 'HPQC_EXEC_REP_FAILED',
  },
  hpqcUploadReport: {
    listExpired: 'HPQC_UPLOAD_CHANGE_PAGE',
    listReceived: 'HPQC_UPLOAD_REP_RECEIVED',
    failed: 'HPQC_UPLOAD_REP_FAILED',
  },
  jiraUploads: {
    listExpired: 'HPQC_UPLOAD_CHANGE_PAGE',
    listReceived: 'HPQC_UPLOAD_REP_RECEIVED',
    failed: 'HPQC_UPLOAD_REP_FAILED',
  },
  testSuiteHistory: {
    filterChanged: 'TS_HISTORY_FILTER_CHANGED',
    listReceived: 'TS_HISTORY_LIST_RECEIVED',
    listFailed: 'TS_HISTORY_LIST_FAILED',
  },
  testSuiteDetails: {
    failedRetrievingTestPlan: 'TEST_SUITE_DETAILS_FAILED_GET_TP',
    failedRetrievingTestSuiteDetails: 'TEST_SUITE_DETAILS_FAILED_GET_DETAILS',
    failedRetrievingTicketData: 'TEST_SUITE_DETAILS_FAILED_GET_TICKET',
    failedUpdatingTestPlan: 'TEST_SUITE_DETAILS_FAILED_UPDATE_TP',
    failedUpdatingTicketId: 'TEST_SUITE_DETAILS_FAILED_UPDATE_TPID',
    invalidTicketId: 'TEST_SUITE_DETAILS_INVALID_TICKET_ID',
    modifyTicketId: 'TEST_SUITE_DETAILS_SET_TICKET_ID',
    resetTicketId: 'TEST_SUITE_DETAILS_RESET_TICKET_ID',
    retrieveDetails: 'TEST_SUITE_DETAILS_RETRIEVE_DETAILS',
    setOpenErrorMsg: 'TEST_SUITE_DETAILS_SET_ERROR_MSG',
    testInstanceCreationSuccess: 'TEST_SUITE_DETAILS_TI_CREATED',
    testPlanCreated: 'TEST_SUITE_DETAILS_TP_CREATED',
    testPlanReceived: 'TEST_SUITE_DETAILS_TP_RECEIVED',
    testPlanIdSubmitted: 'TEST_SUITE_DETAILS_TPID_SUBMITTED',
    testPlanIdUpdated: 'TEST_SUITE_DETAILS_TPID_UPDATED',
    testPlanUpdated: 'TEST_SUITE_DETAILS_TP_UPDATED',
    testSuiteReceived: 'TEST_SUITE_DETAILS_TEST_SUITE_RECEIVED',
    ticketDataReceived: 'TEST_SUITE_DETAILS_TICKET_DATA_RECEIVED',
    ticketIdSubmitted: 'TEST_SUITE_DETAILS_TICKET_ID_SUBMITTED',
    ticketIdUpdated: 'TEST_SUITE_DETAILS_TICKET_ID_UPDATED',
    toggleTestPlanDetails: 'TEST_SUITE_DETAILS_TOGGLE_TP',
    updateName: 'TEST_SUITE_DETAILS_UPDATE_NAME',
    updateNameSuccess: 'TEST_SUITE_DETAILS_UPDATE_NAME_SUCCESS',
  },
  verification: {
    failed: 'VERIFICATION_FAILED',
    listReceived: 'VERIFICATION_LIST_RECEIVED',
    selected: 'VERIFICATION_SELECTED',
    selectedAll: 'VERIFICATION_SELECTED_ALL',
    verified: 'VERIFICATION_VERIFIED',
    verifying: 'VERIFICATION_VERIFYING',
  },
  configurationExcelExport: {
    dataReceived: 'CONFIG_EXCEL_EXPORT_RECEIVED',
    failed: 'CONFIG_EXCEL_EXPORT_FAILED',
    valueChanged: 'CONFIG_EXCEL_EXPORT_VALUE_CHANGED',
  },
  configurationTestCategories: {
    cancelEdit: 'CONFIGURATION_TC_CANCEL_EDIT',
    categoryAlreadyExists: 'CONFIGURATION_TC_ALREADY_EXISTS',
    dataReceived: 'CONFIGURATION_TC_RECEIVED',
    editCategory: 'CONFIGURATION_TC_EDIT',
    failed: 'CONFIGURATION_TC_FAILED',
  },
  configurationSuccessConditions: {
    cancelEdit: 'CONFIGURATION_SC_CANCEL_EDIT',
    conditionAlreadyExists: 'CONFIGURATION_SC_ALREADY_EXISTS',
    dataReceived: 'CONFIGURATION_SC_RECEIVED',
    editCondition: 'CONFIGURATION_SC_EDIT',
    failed: 'CONFIGURATION_SC_FAILED',
  },
  configurationHpqcCredentials: {
    dataReceived: 'CONFIG_HPQC_CREDS_RECEIVED',
    failed: 'CONFIG_HPQC_CREDS_FAILED',
    saved: 'CONFIG_HPQC_CREDS_SAVED',
    valueChanged: 'CONFIG_HPQC_CREDS_VALUE_CHANGED',
  },
  configurationHpqcCustomFields: {
    addNew: 'CONFIGURATION_HPQC_CF_ADD',
    changed: 'CONFIGURATION_HPQC_CF_CHANGE',
    cancelEdit: 'CONFIGURATION_HPQC_CF_CANCEL_EDIT',
    dataReceived: 'CONFIGURATION_HPQC_CF_RECEIVED',
    deleted: 'CONFIGURATION_HPQC_CF_DELETED',
    failed: 'CONFIGURATION_HPQC_CF_FAILED',
    saved: 'CONFIGURATION_HPQC_CF_SAVED',
  },
  configurationHpqcTestSets: {
    addConfig: 'CONFIG_HPQC_TSETS_ADD',
    cancelEdit: 'CONFIG_HPQC_TSETS_CANCEL',
    editConfig: 'CONFIG_HPQC_TSETS_EDIT',
    dataReceived: 'CONFIG_HPQC_TSETS_DATA_RECEIVED',
    failed: 'CONFIG_HPQC_TSETS_FAILED',
    fieldCategoryChanged: 'CONFIG_HPQC_TSETS_CATEGORY_CHANGED',
    fieldDateFromChanged: 'CONFIG_HPQC_TSETS_DATE_FROM_CHANGED',
    fieldDateToChanged: 'CONFIG_HPQC_TSETS_DATE_TO_CHANGED',
    fieldEnabledChanged: 'CONFIG_HPQC_TSETS_ENABLED_CHANGED',
    fieldNameChanged: 'CONFIG_HPQC_TSETS_NAME_CHANGED',
    fieldTestSetIdChanged: 'CONFIG_HPQC_TSETS_TEST_SET_ID_CHANGED',
    saved: 'CONFIG_HPQC_TSETS_SAVED',
    validated: 'CONFIG_HPQC_TSETS_VALIDATED',
  },
  configurationHpqcExcelImport: {
    addRow: 'CONFIG_HPQC_XLS_ADD',
    addedPart: 'CONFIG_HPQC_XLS_ADDED_PART',
    cancelEdit: 'CONFIG_HPQC_XLS_CANCEL',
    cancelPartEdit: 'CONFIG_HPQC_XLS_PART_CANCEL',
    dataReceived: 'CONFIG_HPQC_XLS_DATA_RECEIVED',
    deleted: 'CONFIG_HPQC_XLS_DELETED',
    deletedPart: 'CONFIG_HPQC_XLS_DEL_PART',
    editRow: 'CONFIG_HPQC_XLS_EDIT',
    failed: 'CONFIG_HPQC_XLS_FAILED',
    movedPartUp: 'CONFIG_HPQC_XLS_MOVED_UP',
    movedPartDown: 'CONFIG_HPQC_XLS_MOVED_DOWN',
    saved: 'CONFIG_HPQC_XLS_SAVED',
    savedPart: 'CONFIG_HPQC_XLS_PART_SAVE',
    savedRow: 'CONFIG_HPQC_XLS_ADDED_ROW',
    updatedHeader: 'CONFIG_HPQC_XLS_UPDATED_HR',
  },
  configurationJiraSettings: {
    dataReceived: 'CONFIG_JIRA_SETTINGS_RECEIVED',
    failed: 'CONFIG_JIRA_SETTINGS_FAILED',
    newFieldAdded: 'CONFIG_JIRA_SETTINGS_FIELD_ADDED',
    newFieldCanceled: 'CONFIG_JIRA_SETTINGS_FIELD_CANCELED',
    newFieldChanged: 'CONFIG_JIRA_SETTINGS_FIELD_CHANGED',
    newFieldDeleted: 'CONFIG_JIRA_SETTINGS_FIELD_DELETED',
    newFieldSubmitted: 'CONFIG_JIRA_SETTINGS_FIELD_SUBMITTED',
    submitted: 'CONFIG_JIRA_SETTINGS_SUBMITTED',
    saved: 'CONFIG_JIRA_SETTINGS_SAVED',
    settingChanged: 'CONFIG_JIRA_SETTINGS_SETTING_CHANGED',
    submittedForTest: 'CONFIG_JIRA_SETTINGS_SUBMITTED_FOR_TEST',
    testResultsReceived: 'CONFIG_JIRA_SETTINGS_TEST_RESULTS_RECEIVED',
  },
  configurationProject: {
    blacklistChanged: 'CONFIG_PROJECT_BLACKLIST_CHANGED',
    dataReceived: 'CONFIG_PROJECT_RECEIVED',
    failed: 'CONFIG_PROJECT_FAILED',
    saved: 'CONFIG_PROJECT_SAVED',
    settingsChanged: 'CONFIG_PROJECT_SETTINGS_CHANGED',
  },
  projectAdministration: {
    saved: 'ADMIN_PROJECT_SAVED',
  },
  editYoutrackSettings: {
    nameChange: 'EDIT_YOUTRACK_NAME_CHANGE',
    baseUrlChange: 'EDIT_YOUTRACK_BASE_URL_CHANGE',
    usernameChange: 'EDIT_YOUTRACK_USERNAME_CHANGE',
    passwordChange: 'EDIT_YOUTRACK_PASSWORD_CHANGE',
    stateChange: 'EDIT_YOUTRACK_STATE_CHANGE',
    customerStateChange: 'EDIT_YOUTRACK_CUSTOMER_STATE_CHANGE',
    testCaseExecutionChange: 'EDIT_YOUTRACK_TEST_CASE_EXECUTION_CHANGE',
    testCaseIdChange: 'EDIT_YOUTRACK_TEST_CASE_ID_CHANGE',
  },
  addYoutrackSettings: {
    nameChange: 'ADD_YOUTRACK_NAME_CHANGE',
    baseUrlChange: 'ADD_YOUTRACK_BASE_URL_CHANGE',
    usernameChange: 'ADD_YOUTRACK_USERNAME_CHANGE',
    passwordChange: 'ADD_YOUTRACK_PASSWORD_CHANGE',
    stateChange: 'ADD_YOUTRACK_STATE_CHANGE',
    customerStateChange: 'ADD_YOUTRACK_CUSTOMER_STATE_CHANGE',
    testCaseExecutionChange: 'ADD_YOUTRACK_TEST_CASE_EXECUTION_CHANGE',
    testCaseIdChange: 'ADD_YOUTRACK_TEST_CASE_ID_CHANGE',
  },
  youtrackSettings: {
    listReceived: 'YOUTRACK_LIST_RECEIVED',
    invalidInput: 'YOUTRACK_INVALID_INPUT',
    saved: 'YOUTRACK_SAVED',
    failed: 'YOUTRACK_FAILED',
    updated: 'YOUTRACK_UPDATED',
    startEdit: 'YOUTRACK_EDIT_SETTINGS',
    startAdd: 'YOUTRACK_ADD_SETTINGS',
    closePanel: 'YOUTRACK_CLOSE_PANEL',
  },
}
