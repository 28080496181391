import ExecutionListService from 'services/executionListService'
import { dispatcher, events } from 'core/dispatcher'


export function retrieveData(filters) {
  ExecutionListService.findUnassigned(filters)
    .then((list) => {
      dispatcher.dispatch({ type: events.unassignedReport.listReceived, list })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.unassignedReport.failed, error }) })
}

export function updateReportsToMove(reports) {
  dispatcher.dispatch({ type: events.unassignedReport.reportSelected, reports })
}

export function setProjectToMoveReports(project) {
  dispatcher.dispatch({ type: events.unassignedReport.projectSelected, project })
}

export function moveReportsToProject(reports, project) {
  if (reports.length === 0 || project == null) {
    return
  }
  ExecutionListService.moveReportsToProject(project, reports, false)
    .then(() => {
      dispatcher.dispatch({ type: events.unassignedReport.reportsMoved })
      retrieveData({})
    })
    .catch((error) => { dispatcher.dispatch({ type: events.unassignedReport.failed, error }) })
}
