import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import AttachmentButton from 'components/executionDetails/attachmentButton/attachmentButton'
import Link from 'components/common/link'
import * as Utils from 'components/utils'
import asLine from './line'
import style from './style.module.scss'

const visibleAttachmentCount = attachments => (attachments || []).filter(a => !a.removed).length

const renderParameters = (param, inheritedKey) => {
  let prefix = inheritedKey
  if (param == null) {
    return null
  }
  if (prefix == null) {
    prefix = ''
  }
  return Object.entries(param).map(([key, value]) => {
    if (value instanceof Object) {
      return renderParameters(value, `${prefix}${key}.`)
    }
    return (
      <div key={key + value}>
        <span>{`${prefix}${key}`}</span>
        <div>{value}</div>
      </div>
    )
  })
}

const StepLine = ({
  executionId, testIndex, index, name, events, attachments, projectCode, keyword,
}) => {
  useEffect(() => ReactTooltip.rebuild())
  const [event, setEvent] = useState()
  const handleCloseDialog = () => setEvent(null)
  return useMemo(() => (
    <div>
      { keyword ? <a className={style.keyword}>{keyword.trim()}</a> : <a className={style.keywordWithColon}>Step</a> }
      { projectCode == null ? (<a>{name}</a>)
        : (<Link to={`/${projectCode}/detail/${executionId}/${testIndex}/${index}`}>{name}</Link>)}
      <span className={style.events}>
        {events.map(it => (
          <i
            className="material-icons"
            key={it.eventId}
            data-tip={it.eventName}
            id={it.eventName}
            role="button"
            tabIndex={0}
            onClick={() => setEvent(it)}
            onKeyPress={() => setEvent(it)}
          >
            {Utils.findImageForEvent(it.eventName)}
          </i>
        ))}
      </span>
      <span className={style.attachments}>
        {attachments.filter(a => !a.removed).map(a => (
          <AttachmentButton key={a.id} id={a.name} attachment={a} />
        ))}
      </span>
      { event != null && (
        <Dialog
          open
          maxWidth="md"
          fullWidth
          onClose={handleCloseDialog}
        >
          <DialogTitle>{event.eventName}</DialogTitle>
          <DialogContent className={style.params}>
            {renderParameters(event.parameters)}
          </DialogContent>
          <DialogActions>
            <Button id="closeEventPopupButton" color="primary" onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  ), [event, testIndex, visibleAttachmentCount(attachments)])
}

StepLine.propTypes = {
  name: PropTypes.string.isRequired,
  keyword: PropTypes.string,
  executionId: PropTypes.string,
  testIndex: PropTypes.number,
  index: PropTypes.number,
  events: PropTypes.arrayOf(PropTypes.object),
  attachments: PropTypes.arrayOf(PropTypes.object),
  projectCode: PropTypes.string,
}

StepLine.defaultProps = {
  keyword: null,
  executionId: null,
  testIndex: 0,
  index: 0,
  events: [],
  attachments: [],
  projectCode: null,
}

export default asLine(StepLine, 'step')
