import PropTypes from 'prop-types'
import startsWith from 'lodash/startsWith'

import * as Utils from 'components/utils'
import style from './attachmentPreview.module.css'

const AttachmentPreview = ({ name, type, file, onClose }) => {
  const escFunction = (event) => {
    if (event.keyCode === 27 && onClose) {
      onClose()
    }
  }

  const removeLinks = (event) => {
    const links = event.target.contentDocument.getElementsByTagName('a')
    for (let i = 0; i < links.length; i += 1) {
      links[i].removeAttribute('href')
    }
    event.target.contentDocument.addEventListener('keydown', escFunction)
  }

  if (name == null || file == null) {
    return null
  }
  if (Utils.isHtml(type)) {
    return (
      <iframe
        srcDoc={file}
        title="preview"
        className={style.iframe}
        ref={(component) => {
          const iframe = component
          if (iframe) {
            iframe.onload = removeLinks
          }
        }}
      />
    )
  } if (Utils.isText(type)) {
    return (
      <div className={style.previewBox}>
        <p>{file}</p>
      </div>
    )
  } if (startsWith(type, 'audio/')) {
    return (
      <audio controls src={file} autoPlay>
        <track kind="captions" />
        <a>Your browser does not support the </a>
        <code>audio</code>
        <a> element.</a>
      </audio>
    )
  }
  return (
    <div className={style.previewBox}>
      <img alt="imageAttachment" src={file} />
    </div>
  )
}

AttachmentPreview.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
}

AttachmentPreview.defaultProps = {
  name: null,
  type: null,
  file: null,
  onClose: null,
}

export default AttachmentPreview
