import { dispatcher, events } from 'core/dispatcher'
import UploadService from 'services/hpqc/uploadService'

export function retrieveReport(project) {
  UploadService.findReadyForUpload(project)
    .then((items) => { dispatcher.dispatch({ type: events.hpqcReadyReport.listReceived, items }) })
    .catch((error) => { dispatcher.dispatch({ type: events.hpqcReadyReport.failed, error }) })
}

export function updateSelectedExecutions(executions) {
  dispatcher.dispatch({ type: events.hpqcReadyReport.updateSelected, executions })
}


function download(request) {
  const blob = request.response
  let fileName = null
  const contentType = request.getResponseHeader('content-type')

  if (request.getResponseHeader('content-disposition')) {
    const contentDisposition = request.getResponseHeader('content-disposition')
    fileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1)
  } else {
    fileName = `unnamed.${contentType.substring(contentType.indexOf('/') + 1)}`
  }

  const el = document.createElement('a')
  document.body.appendChild(el)
  el.href = window.URL.createObjectURL(blob)
  el.download = fileName
  el.click()
  document.body.removeChild(el)
}

const handleDownloadResponse = request => () => {
  if (request.status >= 400) {
    dispatcher.dispatch({ type: events.hpqcReadyReport.failed, error: `Export request failed: ${request.statusText}` })
    return
  }
  if (request.status === 204) {
    dispatcher.dispatch({ type: events.hpqcReadyReport.exported })
    return
  }
  download(request)
}

const handleUploadResponse = request => () => {
  if (request.status >= 400) {
    dispatcher.dispatch({ type: events.hpqcReadyReport.failed, error: `Upload request failed: ${request.statusText}` })
    return
  }
  if (request.status === 204) {
    dispatcher.dispatch({ type: events.hpqcReadyReport.uploadStarted })
  }
}

export function downloadReports(project, rows) {
  const url = UploadService.createDownloadExecutionsLink(project, rows)
  const request = new XMLHttpRequest()
  request.open('GET', url, true)
  request.responseType = 'blob'
  request.onload = handleDownloadResponse(request)
  request.send()
}

export function uploadReports(project, rows) {
  const url = UploadService.createUploadExecutionsLink(project, rows)
  const request = new XMLHttpRequest()
  request.open('POST', url, true)
  request.responseType = 'blob'
  request.onload = handleUploadResponse(request)
  request.send()
}

export function handleSnackBarClose() {
  dispatcher.dispatch({ type: events.hpqcReadyReport.exportStarted })
}
