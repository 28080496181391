import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class TestCategoriesStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      list: [],
      error: null,
      selectedRow: null,
      addCategory: false,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationTestCategories.dataReceived: {
      this.state.list = action.list.sort((a, b) => a.id.localeCompare(b.id))
      this.state.selectedRow = null
      this.state.addCategory = false
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.configurationTestCategories.editCategory: {
      this.state.selectedRow = action.id
      this.state.newTestCategory = { ...this.state.list.find(it => it.id === action.id) }
      this.state.addCategory = false
      this.emitChange()
      break
    }
    case events.configurationTestCategories.addCategory: {
      this.state.selectedRow = null
      this.state.addCategory = true
      this.emitChange()
      break
    }
    case events.configurationTestCategories.cancelEdit: {
      this.state.selectedRow = null
      this.state.addCategory = false
      this.emitChange()
      break
    }
    case events.configurationTestCategories.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const testCategoriesStore = new TestCategoriesStore()
dispatcher.register(testCategoriesStore.handleActions.bind(testCategoriesStore))

export default testCategoriesStore
