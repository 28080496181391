import { forwardRef } from 'react'
import PropTypes from 'prop-types'

import * as Utils from 'components/utils'

const createQueryString = params => Object.keys(params)
  .map(k => ({ name: k, value: params[k] }))
  .filter(a => a.value)
  .map(a => (a.value instanceof Date ? { name: a.name, value: Utils.formatDateISOZ(a.value) } : a))
  .map(a => `${encodeURIComponent(a.name)}=${encodeURIComponent(a.value)}`)
  .join('&')

const withQuery = (Component) => {
  const C = forwardRef((props, ref) => {
    const { location, history, match } = props
    const query = Utils.parseQuery(location.search)
    const r = (queryParams) => {
      history.push(`${match.url}?${queryParams}`)
    }
    const redirect = (filter) => {
      r(createQueryString({ ...query, ...filter }))
    }
    return (
      <Component {...props} ref={ref} query={query} redirect={redirect} />
    )
  })
  C.displayName = `withQuery(${Component.displayName || Component.name})`
  C.WrappedComponent = Component
  C.propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      url: PropTypes.string,
      params: PropTypes.object,
    }).isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  }
  return C
}

export default withQuery
