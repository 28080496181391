import { dispatcher, events } from 'core/dispatcher'
import TestSuiteService from 'services/testSuiteService'

export function retrieveList(projectCode, filters) {
  TestSuiteService.findAll(projectCode, filters)
    .then((list) => { dispatcher.dispatch({ type: events.testSuiteList.listReceived, list }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteList.failed, error }) })
}

export function updateList(projectCode, filters) {
  setTimeout(() => dispatcher.dispatch({ type: events.testSuiteList.listExpired }))
  retrieveList(projectCode, filters)
}

export function setDrawerOpen(open) {
  dispatcher.dispatch({ type: events.testSuiteList.openDrawer, open })
}
