import ExecutionDetailsService from 'services/executionDetailsService'
import JiraUploadService from 'services/jira/uploadService'
import { dispatcher, events } from 'core/dispatcher'


function retrieveLogs(executionId, testId, logType) {
  ExecutionDetailsService.findTestLogs(executionId, testId, logType)
    .then((response) => { dispatcher.dispatch({ type: events.testDetailsTestView.logsReceived, ...response, logType }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsTestView.logsFailed, error, logType }) })
}

function retrieveJiraDetails(projectCode, executionId, testId) {
  JiraUploadService.getTestDetails(projectCode, executionId, testId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsFailed, error }) })
}

export function getTestDetails(executionId, testId) {
  return ExecutionDetailsService.findTest(executionId, testId)
    .then((test) => {
      dispatcher.dispatch({ type: events.testDetailsTestView.detailsReceived, test })
      return test
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsTestView.failed, error }) })
}

export function retrieveDetailsAndLogs(executionId, testId, jiraZephyrEnabled, projectCode) {
  getTestDetails(executionId, testId).then((test) => {
    if (!test.deleted) {
      retrieveLogs(executionId, testId, 'PROTOCOL')
      retrieveLogs(executionId, testId, 'TRACE')
    }
  })
  if (jiraZephyrEnabled) {
    retrieveJiraDetails(projectCode, executionId, testId)
  }
  retrieveLogs(executionId, testId, 'SERVER')
}

export function uploadToJira(projectCode, executionId, testId) {
  dispatcher.dispatch({ type: events.testDetailsTestView.uploaded })
  JiraUploadService.uploadTest(projectCode, executionId, testId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsFailed, error }) })
}

export function approveInJira(projectCode, executionId, testId) {
  dispatcher.dispatch({ type: events.testDetailsTestView.uploaded })
  JiraUploadService.approveTest(projectCode, executionId, testId)
    .then((details) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsReceived, details }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsTestView.jiraDetailsFailed, error }) })
}
