import ExecutionListService from 'services/executionListService'
import VerificationService from 'services/postprocess/verificationService'
import { dispatcher, events } from 'core/dispatcher'


export function retrieveList(project, user, page = 1) {
  ExecutionListService.findAllReadyForVerification(project, user, page, 50)
    .then((list) => { dispatcher.dispatch({ type: events.verification.listReceived, list }) })
    .catch((error) => { dispatcher.dispatch({ type: events.verification.failed, error }) })
}

export function updateRowSelection(ids) {
  dispatcher.dispatch({ type: events.verification.selected, ids })
}

export function selectAll() {
  dispatcher.dispatch({ type: events.verification.selectedAll })
}

function changeStatus(executions, status, project, user) {
  dispatcher.dispatch({ type: events.verification.verifying })
  VerificationService.changeMultipleVerificationStatus(executions, status)
    .then(() => {
      dispatcher.dispatch({ type: events.verification.verified })
      retrieveList(project, user)
    })
    .catch((message) => {
      dispatcher.dispatch({ type: events.verification.failed, error: `Verification failed: ${message}` })
    })
}

export function verify(executions, project, user) {
  if (executions.length > 0) {
    changeStatus(executions, 'VERIFIED', project, user)
  }
}

export function archive(executions, project, user) {
  if (executions.length > 0) {
    changeStatus(executions, 'ARCHIVED', project, user)
  }
}

export function changePage(page, redirect) {
  redirect(`&pageNumber=${page}`)
}
