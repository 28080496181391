import { dispatcher, events } from 'core/dispatcher'
import JiraService from 'services/jira/jiraService'

export function retrieveSettings(project) {
  JiraService.retrieveJiraSettings(project)
    .then(settings => dispatcher.dispatch({ type: events.configurationJiraSettings.dataReceived, settings }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationJiraSettings.failed, message }) })
}

export function handleSettingChange(settings) {
  dispatcher.dispatch({ type: events.configurationJiraSettings.settingChanged, settings })
}

const updateJiraSettings = project => (settings) => {
  JiraService.updateJiraSettings(project, settings)
    .then(() => dispatcher.dispatch({ type: events.configurationJiraSettings.saved }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationJiraSettings.failed, message }) })
}

const testJiraSettings = project => () => {
  JiraService.testJiraSettings(project)
    .then(testErrors => dispatcher.dispatch({ type: events.configurationJiraSettings.testResultsReceived, testErrors }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationJiraSettings.failed, message }) })
}

export function save(project) {
  dispatcher.dispatch({
    type: events.configurationJiraSettings.submitted,
    save: updateJiraSettings(project),
  })
}

export function test(project) {
  dispatcher.dispatch({
    type: events.configurationJiraSettings.submittedForTest,
    execute: testJiraSettings(project),
  })
}

export function handleNewFieldChange(newField) {
  dispatcher.dispatch({ type: events.configurationJiraSettings.newFieldChanged, newField })
}

export function addNewField() {
  dispatcher.dispatch({ type: events.configurationJiraSettings.newFieldAdded })
}

export function submitNewField() {
  dispatcher.dispatch({ type: events.configurationJiraSettings.newFieldSubmitted })
}

export function cancelNewField() {
  dispatcher.dispatch({ type: events.configurationJiraSettings.newFieldCanceled })
}

export const deleteField = project => (field) => {
  dispatcher.dispatch({
    type: events.configurationJiraSettings.newFieldDeleted,
    save: updateJiraSettings(project),
    field,
  })
}
