import { Component } from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Snackbar from '@mui/material/Snackbar'
import Box from '@mui/material/Box'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import CustomFields from './customFields'
import EditRow from './editRow'
import Settings from './settings'
import Store from './store'
import * as Actions from './actions'
import style from './settings.module.css'

class JiraSettings extends Component {
  state = Store.resetState()

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Jira Settings'
    Store.on('change', this.updateState)
    Actions.retrieveSettings(projectCode)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { projectCode } = this.context
    const {
      error, loading, toastMessage, settings, settingErrors, customFieldErrors, missingCustomFields, customFields, newField, statusList, unsavedChanges,
    } = this.state
    if (!this.handleDelete) {
      this.handleDelete = Actions.deleteField(projectCode)
    }
    if (error) {
      throw Error(error)
    }
    return (
      <div>
        <Card className={style.card}>
          <Spinner spinning={loading}>
            <CardContent>
              <div className="row">
                <Settings settings={settings} errors={settingErrors} statusList={statusList} onChange={Actions.handleSettingChange} />
                <CustomFields
                  list={customFields}
                  newField={newField}
                  onAdd={Actions.addNewField}
                  onDelete={this.handleDelete}
                  missingFields={missingCustomFields}
                >
                  { newField
                    && (
                      <EditRow
                        key="new"
                        newField={newField}
                        errors={customFieldErrors}
                        onCancel={Actions.cancelNewField}
                        onAdd={Actions.submitNewField}
                        onChange={Actions.handleNewFieldChange}
                      />
                    )}
                </CustomFields>
              </div>
              <div>
                <Button
                  variant="contained"
                  id="saveButton"
                  color="primary"
                  onClick={() => Actions.save(projectCode)}
                  disabled={settingErrors.size > 0 || customFieldErrors.size > 0}
                >Save{unsavedChanges ? '*' : ''}
                </Button>
                <Box component="span" mx={1}>
                  <Button
                    variant="contained"
                    id="testButton"
                    color="secondary"
                    onClick={() => Actions.test(projectCode)}
                    disabled={unsavedChanges}
                  >Test {unsavedChanges ? '(Save to test)' : ''}
                  </Button>
                </Box>
              </div>
              <Snackbar open={toastMessage} message={toastMessage} autoHideDuration={10000} />
            </CardContent>
          </Spinner>
        </Card>
      </div>
    )
  }
}

JiraSettings.contextType = ProjectContext

export default withRouter(JiraSettings)
