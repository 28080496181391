import { Component } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { withRouter } from 'react-router'
import { ProjectContext } from 'core/projectProvider/projectProvider'

class ProjectSelector extends Component {
  handleProjectSelection = (event) => {
    const { history } = this.props
    history.push(`/${event.target.value}`)
  }

  render() {
    const { projectCode, projectList } = this.context
    return (
      <Select
        id="projectSelector"
        value={projectCode}
        onChange={this.handleProjectSelection}
        sx={{
          color: 'inherit',
          '&:before, &:after': {
            borderColor: 'inherit',
          },
          '& .MuiSelect-icon': {
            color: 'inherit',
          },
        }}
      >
        {projectList
          .filter(project => project.enabled)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(project => (
            <MenuItem key={project.code} value={project.code}>
              {project.name}
            </MenuItem>
          ))}
      </Select>
    )
  }
}

ProjectSelector.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
}

ProjectSelector.contextType = ProjectContext

export default withRouter(ProjectSelector)
