import { useLayoutEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const WindowPortal = ({ children, onClose }) => {
  const [state] = useState({ containerEl: document.createElement('div') })
  useLayoutEffect(() => {
    const externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200')
    externalWindow.document.body.appendChild(state.containerEl)
    externalWindow.addEventListener('beforeunload', () => {
      onClose()
    })
    return () => { externalWindow.close() }
  }, [])

  return ReactDOM.createPortal(<> {children}</>, state.containerEl)
}

WindowPortal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default WindowPortal
