import { dispatcher, events } from 'core/dispatcher'
import ProjectService from 'services/projectService'
import UserService from 'services/userService'

// Apart from loading project list, also loads the user with the initially selected project
export function loadProjectList(projectCode) {
  Promise.all([
    ProjectService.findAll(),
    UserService.getCurrentUser(projectCode),
  ]).then(([list, user]) => {
    dispatcher.dispatch({ type: events.projectSelector.listReceived, list, projectCode, user })
  }).catch((message) => {
    dispatcher.dispatch({ type: events.projectSelector.failed, message })
  })
}

// User is loaded again with correct roles for the selected project
export function selectProject(projectCode) {
  UserService.getCurrentUser(projectCode)
    .then(user => dispatcher.dispatch({ type: events.projectSelector.selected, projectCode, user }))
    .catch((message) => { dispatcher.dispatch({ type: events.projectSelector.failed, message }) })
}

export function updateProject(project) {
  setTimeout(() => dispatcher.dispatch({ type: events.projectSelector.updateProject, project }))
}
