import axios from 'axios'
import * as Common from 'services/common'

class UploadService {
  findAll = (project, pageNumber, pageSize) => {
    const pageNumberQuery = pageNumber ? `&page=${pageNumber - 1}` : ''
    const pageSizeQuery = pageSize ? `&size=${pageSize}` : ''
    const url = `/api/module/hpqc/${project}/upload?project=${project}${pageNumberQuery}${pageSizeQuery}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findReadyForUpload = (project) => {
    const url = `/api/module/hpqc/${project}/upload/upload-status/ready`
    return axios.get(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  upload = (project, executionId) => {
    const url = `/api/module/hpqc/${project}/upload/${executionId}/upload-status`
    return axios.post(url, {}, Common.requestOptions)
      .catch(Common.catchError)
  }

  createDownloadExecutionsLink = (project, executions) => {
    const executionsParam = encodeURIComponent(executions.join())
    return `/api/module/hpqc/${project}/upload/package?executions=${executionsParam}`
  }

  createUploadExecutionsLink = (project, executions) => {
    const executionsParam = encodeURIComponent(executions.join())
    return `/api/module/hpqc/${project}/upload/package?executions=${executionsParam}`
  }
}

const instance = new UploadService()
export default instance
