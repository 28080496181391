import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class PmsExportDetailStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      pmsExportDetails: {
        records: [],
      },
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.pmsExportDetail.detailsReceived: {
      this.state.loading = false
      this.state.pmsExportDetails = action.pmsExportDetails
      this.state.error = null
      this.emitChange()
      break
    }
    case events.pmsExportDetail.exportDetailsFailed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new PmsExportDetailStore()
dispatcher.register(store.handleActions.bind(store))

export default store
