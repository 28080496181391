import axios from 'axios'
import * as Common from 'services/common'
import moment from 'moment'
import { formatDateISOZ } from 'components/utils'

class ErrorCategoryService {
  getList = async (project) => {
    if (!project) {
      throw Error('Invalid project code.')
    }
    const url = `/api/error-category/${project}`
    try {
      const response = await axios.get(url, Common.requestOptions)
      return response.data
    } catch (e) {
      throw await Common.catchError(e)
    }
  }

  getHistory = (project, date) => {
    const formattedDate = encodeURIComponent(formatDateISOZ(date ? moment(date) : moment()))
    const url = `/api/test-suite-execution/error-categories-history?project=${project}&date=${formattedDate}`
    return axios.get(url, Common.requestOptions)
      .then(response => response)
      .catch(Common.catchError)
  }

  update = (project, category) => {
    const url = `/api/error-category/${project}/${category.externalId}`
    return axios.post(url, category, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  create = (project, category) => {
    const url = `/api/error-category/${project}`
    return axios.post(url, category, Common.requestOptions)
      .catch(Common.catchError)
  }

  remove = (project, category) => {
    const url = `/api/error-category/${project}/${category.externalId}`
    return axios.delete(url, category, Common.requestOptions)
      .catch(Common.catchError)
  }

  createExportUrl = project => `/api/error-category/${project}/export`

  importCategories = (project, file) => {
    const url = `/api/error-category/${project}/import`
    const formData = new FormData()
    formData.append('file', file)
    const config = { ...Common.requestOptions, headers: { 'content-type': 'multipart/form-data' } }
    return axios.post(url, formData, config)
      .catch(Common.catchError)
  }

  getLabels = async (project) => {
    if (!project) {
      throw Error('Invalid project code.')
    }
    const url = `/api/error-category/${project}/labels`
    try {
      const response = await axios.get(url, Common.requestOptions)
      return response.data
    } catch (e) {
      throw await Common.catchError(e)
    }
  }
}

const instance = new ErrorCategoryService()
export default instance
