import { dispatcher, events } from 'core/dispatcher'
import YoutrackService from 'services/youtrack/youtrackService'
import TestSuiteService from 'services/testSuiteService'
import HpqcService from 'services/hpqc/hpqcService'

function getTestSuiteDetails(testSuiteId, projectCode) {
  TestSuiteService.getDetails(testSuiteId, projectCode)
    .then((testSuite) => { dispatcher.dispatch({ type: events.testSuiteDetails.testSuiteReceived, testSuite }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteDetails.failedRetrievingTestSuiteDetails, error }) })
}

export function setOpenErrorMessage(bool) {
  dispatcher.dispatch({ type: events.testSuiteDetails.setOpenErrorMsg, openErrorMessage: bool })
}

function getTicketStatus(testSuiteId) {
  YoutrackService.getTicketStatus(testSuiteId)
    .then((ticketData) => { dispatcher.dispatch({ type: events.testSuiteDetails.ticketDataReceived, ticketData }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteDetails.failedRetrievingTicketData, error }) })
}

export function getTestPlan({ project, testSuiteId }) {
  Promise.all([HpqcService.getTestPlan(project, testSuiteId), HpqcService.retriveHpqcSettings(project)])
    .then((result) => {
      dispatcher.dispatch({
        type: events.testSuiteDetails.testPlanReceived,
        testPlan: result[0],
        customFields: result[1].customFields,
      })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteDetails.failedRetrievingTestPlan, error }) })
}

export function retrieveTestSuiteDetails(testSuiteId, projectCode) {
  setTimeout(() => dispatcher.dispatch({ type: events.testSuiteDetails.retrieveDetails }))
  getTestSuiteDetails(testSuiteId, projectCode)
  getTicketStatus(testSuiteId)
}

export function saveTicketId(newValue, testSuiteId, projectCode) {
  dispatcher.dispatch({ type: events.testSuiteDetails.ticketIdSubmitted })
  return YoutrackService.saveTicketId(newValue, testSuiteId)
    .then(() => {
      dispatcher.dispatch({ type: events.testSuiteDetails.ticketIdUpdated })
      setTimeout(() => {
        retrieveTestSuiteDetails(testSuiteId, projectCode)
        getTestPlan({ testSuiteId, project: projectCode })
      })
    })
    .catch((error) => {
      if (error === YoutrackService.INVALID_TICKET_ID) {
        dispatcher.dispatch({ type: events.testSuiteDetails.invalidTicketId })
      } else {
        dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTicketId, error })
      }
    })
}

export function updateTestPlanId(testSuiteId, testPlanId, projectCode) {
  dispatcher.dispatch({ type: events.testSuiteDetails.testPlanIdSubmitted })
  return HpqcService.updateTestPlanId(projectCode, testSuiteId, testPlanId)
    .then(() => {
      dispatcher.dispatch({ type: events.testSuiteDetails.testPlanIdUpdated })
      setTimeout(() => getTestPlan({ testSuiteId, project: projectCode }))
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTestPlanId, error })
    })
}

export function modifyTicketId(value) {
  dispatcher.dispatch({ type: events.testSuiteDetails.modifyTicketId, value })
}

export function resetTicketId() {
  dispatcher.dispatch({ type: events.testSuiteDetails.resetTicketId })
}

export function handleEditTestPlanDetails() {
  dispatcher.dispatch({ type: events.testSuiteDetails.toggleTestPlanDetails, show: true })
}

export function handleCloseTestPlanDetails() {
  dispatcher.dispatch({ type: events.testSuiteDetails.toggleTestPlanDetails, show: false })
}

export function updateTestPlan({ testSuiteId, testPlan, project }) {
  HpqcService.updateTestPlan({ testSuiteId, testPlan, project })
    .then(() => {
      dispatcher.dispatch({ type: events.testSuiteDetails.testPlanUpdated })
      setTimeout(() => getTestPlan({ testSuiteId, project }))
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTestPlanId, error }) })
}

export function createTestPlan({ testSuiteId, project }) {
  dispatcher.dispatch({ type: events.testSuiteDetails.testPlanCreated })
  return HpqcService.createTestPlan(project, testSuiteId)
    .then(() => {
      dispatcher.dispatch({ type: events.testSuiteDetails.testPlanIdUpdated })
      setTimeout(() => getTestPlan({ testSuiteId, project }))
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTestPlanId, error })
    })
}

export function createTestInstance({ testSuiteId, testSetId, project }) {
  dispatcher.dispatch({ type: events.testSuiteDetails.testPlanCreated })
  return HpqcService.createTestInstance({ testSuiteId, testSetId, project })
    .then(() => { dispatcher.dispatch({ type: events.testSuiteDetails.testInstanceCreationSuccess }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTestPlanId, error }) })
}

export function updateName({ testSuiteId, name }) {
  dispatcher.dispatch({ type: events.testSuiteDetails.updateName })
  return HpqcService.updateName({ testSuiteId, name })
    .then((result) => { dispatcher.dispatch({ type: events.testSuiteDetails.updateNameSuccess, name: result.testSuite.name }) })
    .catch((error) => {
      dispatcher.dispatch({ type: events.testSuiteDetails.failedUpdatingTestPlanId, error })
    })
}
