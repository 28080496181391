import { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'

import IconButton from 'components/iconButton'

import style from './style.module.css'

const UserRow = ({ user, onEdit, onDelete }) => (
  <div className={style.userRow} id={user.email}>
    <div className={style.emailColumn}>{user.email}</div>
    <div className={style.nameColumn}>{user.name}</div>
    <div className={style.adminColumn}><Checkbox id="isAdminCheckBox" checked={user.admin} disabled /></div>
    <div className={style.buttonColumn}>
      <Link id="settingsButton" to={`/administration/users/${encodeURIComponent(user.email)}`}>
        <img alt="Open user settings" src="/images/settings.svg" />
      </Link>
      <IconButton id="editButton" onClick={() => onEdit(user.email)} src="/images/edit.svg" />
      <IconButton id="deleteButton" onClick={() => onDelete(user.email)} src="/images/delete.svg" />
    </div>
  </div>
)

UserRow.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    admin: PropTypes.bool,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default memo(UserRow, (prev, next) => prev.user === next.user)
