import { memo } from 'react'
import PropTypes from 'prop-types'

import Link from 'components/common/link'
import asLine from './line'
import style from './style.module.scss'

const TestLine = ({
  executionId, index, name, errorCategory, projectCode, isScenarioOutline,
}) => (
  <div>
    <a className={style.keywordWithColon}>{isScenarioOutline ? 'Scenario Outline' : 'Scenario'}</a>
    <Link to={`/${projectCode}/detail/${executionId}/${index}`}>{name}</Link>
    { errorCategory && <span className={style.testErrorCategory}>{errorCategory}</span>}
  </div>
)

TestLine.propTypes = {
  name: PropTypes.string.isRequired,
  executionId: PropTypes.string.isRequired,
  errorCategory: PropTypes.string,
  index: PropTypes.number.isRequired,
  projectCode: PropTypes.string.isRequired,
  isScenarioOutline: PropTypes.bool,
}

TestLine.defaultProps = { errorCategory: null, isScenarioOutline: false }

export default asLine(memo(TestLine), 'test')
