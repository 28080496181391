import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import sortBy from 'lodash/sortBy'

class HpqcCustomFieldsStore extends ReportingEventEmitter {
  state = {
    loading: true,
    list: [],
    error: null,
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationHpqcCustomFields.dataReceived: {
      this.state.list = sortBy(action.list, ['internalName'])
      this.state.newItem = null
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.addNew: {
      this.state.newItem = { internalName: '', hpqcField: '' }
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.cancelEdit: {
      this.state.newItem = null
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.saved: {
      if (!this.state.newItem.internalName) {
        this.state.error = 'Internal name cannot be empty.'
      } else if (!this.state.newItem.hpqcField) {
        this.state.error = 'HPQC field cannot be empty.'
      } else if (this.state.list.map(it => it.internalName).includes(this.state.newItem.internalName)) {
        this.state.error = `Field with internal name '${this.state.newItem.internalName}' already exists.`
      } else {
        this.state.list.push(this.state.newItem)
        action.save(this.state.list)
        this.state.newItem = null
      }
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.deleted: {
      this.state.list = this.state.list.filter(it => it.internalName !== action.internalName)
      action.save(this.state.list)
      this.state.newItem = null
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    case events.configurationHpqcCustomFields.changed: {
      this.state.newItem = action.newItem
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcCustomFieldsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
