import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import { withRouter } from 'react-router'

import HeaderButton from 'pages/headerButton'
import AllTestCases from 'components/configuration/successConditions/conditionTable/allTestCases'
import Tags from 'components/configuration/successConditions/conditionTable/tags'
import Metadata from 'components/configuration/successConditions/conditionTable/metadata'

const SuccessConditionSettings = ({ match }) => {
  useEffect(() => {
    document.title = 'QiTASC ConQlude - Success Conditions Settings'
  }, [])
  return (
    <Card>
      <CardHeader title="Success Conditions Settings" />
      <CardContent>
        <Box marginBottom={2}>
          <HeaderButton id="allTcsBtn" label="All Test cases" url={`${match.url}/all-test-cases`} />
          <HeaderButton id="tagsBtn" label="Tags" url={`${match.url}/tags`} />
          <HeaderButton id="metadataBtn" label="Metadata" url={`${match.url}/metadata`} />
        </Box>
        <Switch>
          <Route path={`${match.url}/all-test-cases`} component={AllTestCases} />
          <Route path={`${match.url}/tags`} component={Tags} />
          <Route path={`${match.url}/metadata`} component={Metadata} />
        </Switch>
      </CardContent>
    </Card>
  )
}

SuccessConditionSettings.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}


export default withRouter(SuccessConditionSettings)
