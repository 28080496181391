import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { Link } from 'react-router-dom'

import FormattedDate from 'components/formattedDate'
import Spinner from 'components/spinner'
import TrimmedText from 'components/trimmedText'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import CustomTable from 'components/table'

import Store from './store'
import retrieveInitialData from './actions'
import style from './style.module.css'

class PmsExportDetails extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { match } = this.props
    Store.on('change', this.updateState)
    const { pmsExport } = match.params
    const { projectCode } = this.context
    retrieveInitialData(projectCode, pmsExport)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { projectCode } = this.context
    const { error, loading, pmsExportDetails } = this.state
    const columns = [
      {
        Header: 'Test suite name',
        accessor: 'testSuiteExecutionId',
        Cell: item => (
          <Link to={`/${projectCode}/detail/${item.value}/${item.row.original.testIndex}`}>
            {item.row.original.index}
            {' '}
            {item.row.original.testSuiteName}
            {' '}
          </Link>
        ),
      },
      {
        Header: 'Date of creation',
        accessor: 'dateCreated',
        Cell: item => <FormattedDate value={item.value} />,
      },
      {
        Header: 'Error message',
        accessor: 'errorMessage',
        Cell: item => <TrimmedText style={{ whiteSpace: 'pre-wrap' }} text={item.value} maxLength={30} />,
      },
    ]
    if (error != null) {
      return PmsExportDetails.renderError(error)
    }
    const {
      file, fileName, lastModified, status, records, retryCount, nextRetry, dateSent,
    } = pmsExportDetails

    return (
      <Spinner spinning={loading}>
        <div>
          <Card className={style.card} zDepth={1}>
            <CardHeader title={fileName || 'Pending Export'} />
            <div className={style.headerData}>
              <div className={style.headerDataName}>Status</div>
              <div>{status}</div>
            </div>
            <div className={style.headerData}>
              <div className={style.headerDataName}>Retry count</div>
              <div>{retryCount}</div>
            </div>
            {lastModified != null && (
              <div className={style.headerData}>
                <div className={style.headerDataName}>Date of last modification</div>
                <div><FormattedDate value={lastModified} /></div>
              </div>
            )}
            {nextRetry != null && (
              <div className={style.headerData}>
                <div className={style.headerDataName}>Next retry</div>
                <div><FormattedDate value={nextRetry} /></div>
              </div>
            )}
            {dateSent != null && (
              <div className={style.headerData}>
                <div className={style.headerDataName}>Sending date</div>
                <div><FormattedDate value={dateSent} /></div>
              </div>
            )}
          </Card>
          {records.length > 0 && (
            <Card className={style.card} zDepth={1}>
              <CardHeader title="Records" />
              <CustomTable
                id="pmsExportDetails"
                columns={columns}
                data={records}
                plain
                baseId="errorCategoryBaseId"
                style={{ tableLayout: 'auto' }}
              />
            </Card>
          )}
          {file != null && (
            <Card className={style.card} zDepth={1}>
              <CardHeader title="File content" />
              <div className={style.content}>{file}</div>
            </Card>
          )}
        </div>
      </Spinner>
    )
  }
}

PmsExportDetails.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

PmsExportDetails.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

PmsExportDetails.contextType = ProjectContext


export default withRouter(PmsExportDetails)
