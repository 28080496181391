import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import withPaging from 'components/reports/withPaging'
import withQuery from 'components/withQuery'
import useProject from 'hooks/useProject'

import retrieve from './actions'
import List from './list'

const ListWithPaging = withPaging(List)

const defaultPageSize = 50

const JiraUploads = ({ query }) => {
  const [state, setState] = useState({ loading: true, items: [], totalSize: 0 })
  const handleError = useErrorHandler()
  const { projectCode } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Executions uploaded to Jira'
    retrieve(projectCode, query)
      .then(rsp => setState({ loading: false, items: rsp.items, totalSize: rsp.totalItems }))
      .catch(handleError)
  }, [query.pageNumber, query.pageSize])

  const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

  return (
    <Spinner spinning={state.loading}>
      <ListWithPaging
        projectCode={projectCode}
        pageSize={pageSize}
        totalSize={state.totalSize}
        items={state.items}
      />
    </Spinner>
  )
}

JiraUploads.propTypes = {
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withQuery(withRouter(JiraUploads))
