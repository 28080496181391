import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import DataField from 'components/executionDetails/dataField'

const ApprovalDetails = ({ details }) => {
  if (details == null) {
    return (
      <p>Upload first!</p>
    )
  }
  const {
    jiraIssue, jiraLink, jiraStatus, approvedBy, approvedAt,
  } = details
  return (
    <div className="row">
      <div className="col-md-6">
        <DataField name="Jira issue">
          <Link to={jiraLink || ''}>{jiraIssue}</Link>
        </DataField>
        <DataField name="Status in Jira" value={jiraStatus} />
      </div>
      <div className="col-md-6">
        <DataField name="Approved by" value={approvedBy || 'N/A'} />
        <DataField name="Approval time" value={approvedAt || 'N/A'} />
      </div>
    </div>
  )
}

ApprovalDetails.propTypes = {
  details: PropTypes.shape({
    jiraIssue: PropTypes.string,
    jiraLink: PropTypes.string.isRequired,
    jiraStatus: PropTypes.string.isRequired,
    approvedBy: PropTypes.string,
    approvedAt: PropTypes.string,
  }),
}
ApprovalDetails.defaultProps = { details: null }

export default ApprovalDetails
