import axios from 'axios'
import * as Common from 'services/common'

class UploadService {
  findAll = (project, pageNumber, pageSize) => {
    const pageNumberQuery = pageNumber ? `&page=${pageNumber - 1}` : ''
    const pageSizeQuery = pageSize ? `&size=${pageSize}` : ''
    const url = `/api/module/jira-zephyr/${project}/upload?project=${project}${pageNumberQuery}${pageSizeQuery}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getDetails = (project, executionId) => {
    const url = `/api/module/jira-zephyr/${project}/upload/${executionId}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getTestDetails = (project, executionId, testId) => {
    const url = `/api/module/jira-zephyr/${project}/upload/${executionId}/${testId}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  upload = (project, executionId) => {
    const url = `/api/module/jira-zephyr/${project}/upload/${executionId}/upload-status`
    return axios.post(url, {}, Common.requestOptions)
      .catch(Common.catchError)
  }

  uploadTest = (project, executionId, testId) => {
    const url = `/api/module/jira-zephyr/${project}/upload/${executionId}/${testId}/upload-status`
    return axios.post(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  approveTest = (project, executionId, testId) => {
    const url = `/api/module/jira-zephyr/${project}/upload/${executionId}/${testId}/approval-status`
    return axios.post(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new UploadService()
export default instance
