import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import style from './style.module.scss'

const validateApiSecret = (text) => {
  if (text.length === 0) return null
  if (text.length < 8) return 'Must be at least 8 characters'
  if (!/[0-9]/.test(text)) return 'Must contain a number'
  if (!/[A-Z]/.test(text)) return 'Must contain a capital letter'
  if (!/[a-z]/.test(text)) return 'Must contain a lowercase letter'
  return null
}

const ApiSecretForm = ({ onSubmit }) => {
  const [apiSecret, setApiSecret] = useState('')
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const apiSecretError = validateApiSecret(apiSecret)
  const handleSave = async () => {
    await onSubmit(apiSecret)
    setShowSuccessMessage(true)
    setApiSecret('')
  }
  return (
    <>
      <DataField name="Report API Secret">
        <TextField
          id="apiSecretField"
          type="password"
          value={apiSecret}
          error={apiSecretError != null}
          label={apiSecretError}
          onChange={e => setApiSecret(e.target.value)}
        />
      </DataField>
      <p className={style.textMuted}>Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</p>
      <div className={style.buttonRow}>
        <Button
          variant="contained"
          id="SaveBtn"
          color="primary"
          onClick={handleSave}
          disabled={apiSecretError != null}
        >
          Save
        </Button>
      </div>
      <Snackbar
        message="Settings were saved successfully."
        autoHideDuration={10000}
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      />
    </>
  )
}

ApiSecretForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default ApiSecretForm
