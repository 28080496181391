import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import FormattedDate from 'components/formattedDate'
import TestStatusIcon from 'components/testStatusIcon'
import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import Table from 'components/table'

const JiraUploadList = ({ list, projectCode, totalPages }) => {
  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'result',
      Cell: item => <TestStatusIcon status={item.value} />,
      width: 48,
      disableResizing: true,
    },
    {
      Header: 'Test Suite',
      accessor: 'testSuiteName',
      Cell: item => <Link to={`/${projectCode}/detail/${item.row.original.executionId}`} title={item.value}>{item.value}</Link>,
    },
    {
      Header: 'Test',
      accessor: 'executionId',
      Cell: item => <Link to={`/${projectCode}/detail/${item.value}/${item.row.original.testIndex}`}>{item.row.original.testName}</Link>,
    },
    {
      Header: 'Cycle',
      accessor: 'jiraCycle',
    },
    {
      Header: 'Folder',
      accessor: 'jiraFolder',
    },
    {
      Header: 'Jira Issue',
      accessor: 'jiraLink',
      Cell: item => (item.value ? <a href={item.value} target="_blank" rel="noopener noreferrer">{item.row.original.issue}</a> : item.row.original.issue || ''),
    },
    {
      Header: 'Jira Execution',
      accessor: 'jiraExecution',
    },
    {
      Header: 'User',
      accessor: 'user',
    },
    {
      Header: 'Upload Time',
      accessor: 'uploadTime',
      Cell: item => <FormattedDate value={item.value} />,
    },
    {
      Header: 'Upload Result',
      accessor: 'errorMessage',
      Cell: item => (item.value ? (<UploadFailedIcon message={item.value} />) : <UploadSuccessfulIcon />),
    },
  ], [])

  return (
    <Table
      id="jiraReportsUploads"
      columns={columns}
      data={list}
      totalPages={totalPages}
    />
  )
}

JiraUploadList.propTypes = {
  projectCode: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
  totalPages: PropTypes.number,
}

JiraUploadList.defaultProps = {
  list: [],
  totalPages: undefined,
}

export default JiraUploadList
