import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import FilterPanel from '../filterPanel'

const StringFilter = ({ onSubmit, field, initialValue, id }) => {
  const [text, setText] = useState('')
  useEffect(() => { setText(initialValue) }, [initialValue])

  const handleFilter = () => {
    onSubmit(text.trim())
    setText('')
  }

  const handleTextChange = (e) => {
    setText(e.target.value || '')
  }

  return (
    <FilterPanel
      id={id}
      title={`Filter by ${field}`}
      onSubmit={handleFilter}
    >
      <TextField
        label={field}
        fullWidth
        autoFocus
        onChange={handleTextChange}
        defaultValue={text}
      />
    </FilterPanel>
  )
}

StringFilter.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
}

StringFilter.defaultProps = {
  initialValue: '',
  id: undefined,
}

export default memo(StringFilter)
