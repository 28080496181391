import { dispatcher, events } from 'core/dispatcher'
import ProjectConfigService from 'services/projectConfigService'

const dispatchError = message => dispatcher.dispatch({ type: events.configurationProject.failed, message })

export const retrieveSettings = project => ProjectConfigService.retriveConfig(project)
  .then(config => dispatcher.dispatch({ type: events.configurationProject.dataReceived, config }))
  .catch(dispatchError)

export const save = ({ projectCode, settings, blacklist }) => ProjectConfigService.saveConfig(projectCode, settings, blacklist)
  .then(() => dispatcher.dispatch({ type: events.configurationProject.saved }))
  .catch(dispatchError)

export const handleValueChange = settings => dispatcher.dispatch({ type: events.configurationProject.settingsChanged, settings })

export const handleBlacklistChange = blacklist => dispatcher.dispatch({ type: events.configurationProject.blacklistChanged, blacklist })
