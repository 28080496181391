import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import { UploadIcon, ExcelIcon } from 'components/icons'
import Tooltip from '@mui/material/Tooltip'


const inlineStyle = {
  container: { textAlign: 'center' },
  uploadButton: { margin: 12 },
  uploadInput: {
    cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '100%', opacity: 0,
  },
}

class ImportExcel extends Component {
  state = { showDialog: false, files: [] }

  handleClose = () => {
    this.setState({ showDialog: false, files: [] })
  }

  handleSubmit = () => {
    this.props.onSelect(this.state.files[0])
    this.setState({ showDialog: false, files: [] })
  }

  render() {
    const label = this.state.files.length > 0 ? this.state.files[0].name : 'Select Excel file'
    return (
      <div className={this.props.className}>
        <Tooltip title="Import from Excel">
          <IconButton onClick={() => this.setState({ showDialog: true })}>
            <ExcelIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.showDialog}
          maxWidth="md"
          fullWidth
          onClose={this.handleClose}
        >
          <DialogTitle>Excel Import</DialogTitle>
          <DialogContent>
            <div style={inlineStyle.container}>
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                style={inlineStyle.uploadButton}
              >
                {label}
                <input
                  type="file"
                  style={inlineStyle.uploadInput}
                  onChange={e => this.setState({ files: e.target.files })}
                />
              </Button>
            </div>
          </DialogContent>
          <DialogActions>
            <Button id="importButton" color="primary" disabled={this.state.files.length === 0} onClick={this.handleSubmit}>Import</Button>
            <Button id="cancelButton" onClick={this.handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

ImportExcel.propTypes = {
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
}

ImportExcel.defaultProps = {
  className: null,
}

export default ImportExcel
