import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import VisibilityIcon from '@mui/icons-material/Visibility'
import WarningIcon from '@mui/icons-material/Warning'

import AttachmentButton from 'components/executionDetails/attachmentButton/attachmentButton'
import AttachmentPreview from 'components/executionDetails/attachmentPreviewDialog'
import ConfirmationButton from 'components/executionDetails/confirmationButton'
import DataField from 'components/executionDetails/dataField'
import DefectIcon from 'components/reports/testRunReport/widgets/defectIcon'
import Defects from 'components/executionDetails/defects'
import ErrorMessage from 'components/executionDetails/errorMessage'
import Link from 'components/common/link'
import LogLines from 'components/executionDetails/logLines'
import { SuiteOutline } from 'components/executionDetails/outline/outline'
import TestCategorySelector from 'components/executionDetails/testCategorySelector'
import TextBoxWithSubmit from 'components/textBoxWithSubmit'
import Title from 'components/title'
import Spinner from 'components/spinner'
import * as Utils from 'components/utils'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import ConfirmTooltip from 'components/confirmTooltip'


import BasicJiraDetails from './basicJiraDetails'
import SuccessConditions from './successConditionDetails'
import ExecutionList from './executions'
import ExpansionPanel from '../expansionPanel'
import FileDialog from './fileDialog'
import JiraUploadList from './uploadList'
import Result from './result'
import TestSuiteStore from './store'
import * as Actions from './actions'
import style from './style.module.css'
import MoveDialog from './moveDialog/moveDialog'
import CdrSearch from './cdrSearch'


class TestSuiteView extends Component {
  constructor(props) {
    super(props)
    this.state = TestSuiteStore.getState()
    if (!this.dataLoaded()) {
      this.state = TestSuiteStore.resetState()
    }
  }

  componentDidMount() {
    const {
      hpqcEnabled, jiraZephyrEnabled, cdrEnabled, qonformEnabled, projectCode,
    } = this.context
    const { match } = this.props
    const { testSuite } = this.state
    document.title = `QiTASC ConQlude - Execution Details - ${testSuite.testSuiteName || ''}`
    TestSuiteStore.on('change', this.updateState)
    if (!this.dataLoaded()) {
      Actions.retrieveDetailsAndLogs({ executionId: match.params.executionId, hpqcEnabled, jiraZephyrEnabled, projectCode })
      if (cdrEnabled) {
        Actions.retrieveEnabled(projectCode)
      }
      if (qonformEnabled) {
        Actions.retrieveQonformEnabled(projectCode)
      }
    }
    window.scrollTo(0, 0)
    ReactTooltip.hide()
    this.polling = setInterval(
      () => {
        if (cdrEnabled) {
          Actions.retrieveCdrVerifications(match.params.executionId, projectCode)
        }
        if (qonformEnabled) {
          Actions.retrieveQonformVerifications(match.params.executionId, projectCode)
        }
        if (cdrEnabled || qonformEnabled) {
          Actions.retrieveDetails(match.params.executionId)
        }
      }, 2000,
    )
  }

  componentDidUpdate() {
    const { hpqcEnabled, jiraZephyrEnabled } = this.context
    const { match } = this.props
    const { loading, testSuite } = this.state
    ReactTooltip.rebuild()
    if (!loading.execution && match.params.executionId !== testSuite.executionId) {
      Actions.reloadDetailsAndLogs({ executionId: match.params.executionId, hpqcEnabled, jiraZephyrEnabled })
    }
  }

  componentWillUnmount() {
    clearInterval(this.polling)
    this.polling = null
    TestSuiteStore.removeListener('change', this.updateState)
  }

  dataLoaded = () => {
    const { match } = this.props
    const { testSuite } = this.state
    return testSuite.executionId === match.params.executionId
  }

  updateState = () => {
    const state = TestSuiteStore.getState()
    document.title = `QiTASC ConQlude - Execution Details - ${state.testSuite.testSuiteName || ''}`
    this.setState(state)
  }

  renderParameters = (param, inheritedKey) => {
    let prefix = inheritedKey
    if (param == null) {
      return null
    }
    if (prefix == null) {
      prefix = ''
    }
    return Object.entries(param).map(([key, value]) => {
      if (value instanceof Object) {
        return this.renderParameters(value, `${prefix}${key}.`)
      }
      return (
        <div key={key + value}>
          <span>{`${prefix}${key}`}</span>
          <div>{value}</div>
        </div>
      )
    })
  }

  handleSelectedFile = (e, cdrVerificationId) => {
    const { match } = this.props
    const { projectCode } = this.context
    const file = e.target.files[0]
    Actions.uploadCdrFile(cdrVerificationId, match.params.executionId, file, projectCode)
  }

  handleRunVerificationButtonClick = (e, cdrVerificationId) => {
    const { match } = this.props
    const { projectCode } = this.context
    Actions.runVerification(match.params.executionId, cdrVerificationId, projectCode)
  }

  handleRunQonformVerificationButtonClick = (e, qonformVerificationId) => {
    const { match } = this.props
    const { projectCode } = this.context
    Actions.runQonformVerification(match.params.executionId, qonformVerificationId, projectCode)
  }

  handlePreview = (e, verificationExternalId) => {
    const { projectCode } = this.context
    const { contextOverrides } = this.state
    const { match } = this.props
    Actions.getContextPreview(match.params.executionId, verificationExternalId, projectCode, contextOverrides.get(verificationExternalId))
  }

  handleQonformPreview = (e, verificationExternalId) => {
    const { projectCode } = this.context
    const { qonformContextOverrides } = this.state
    const { match } = this.props
    Actions.getQonformContextPreview(match.params.executionId, verificationExternalId, projectCode, qonformContextOverrides.get(verificationExternalId))
  }

  handleUpdateContext = (e, verificationExternalId) => {
    const { projectCode } = this.context
    const { contextOverrides } = this.state
    const { match } = this.props
    Actions.updateContext(match.params.executionId, verificationExternalId, projectCode, contextOverrides.get(verificationExternalId))
  }

  handleUpdateQonformContext = (e, verificationExternalId) => {
    const { projectCode } = this.context
    const { qonformContextOverrides } = this.state
    const { match } = this.props
    Actions.updateQonformContext(match.params.executionId, verificationExternalId, projectCode, qonformContextOverrides.get(verificationExternalId))
  }

  render() {
    const { match } = this.props
    const {
      cdrEnabled, qonformEnabled, postProcessEnabled, hpqcEnabled, jiraBasicEnabled, jiraZephyrEnabled, projectCode, canMoveTests,
    } = this.context
    const {
      error, loading, testSuite, testSuiteStatus, conditionsState, attachmentsHaveBeenUpdated, hpqcDetails, showAttachmentsLink, reportId,
      showTicketWarning, defects, canChangeTestCategory, isTestManager, canDelete, approvalInProgress, verified, archived, canVerify, canArchive, canApprove,
      requireConfirmation, canDeny, canChangeHpqcDetails, hpqcStatuses, uploadStatus, canUpload, newerExecutions, showDataRetention,
      retention, canCancelDeletion, protocol, trace, server, open, parameters, errorMessage, errorCategory, jiraDetails, resultAttachments, moveDialogOpen,
      redirectToReportList, cdrVerificationsResponse, qonformVerificationsResponse, verificationHasBeenUpdated, qonformVerificationHasBeenUpdated,
      successConditionDetailsKey, successConditions, contextOverrides, contextPreviewFile, contextPreviewErrors, updateContextButtonStates,
      verificationServiceEnabled, qonformServiceEnabled, qonformContextOverrides, qonformContextPreviewErrors, updateQonformContextButtonStates,
      confirmTooltipVisible,
    } = this.state

    if (attachmentsHaveBeenUpdated) {
      Actions.retrieveDetails(match.params.executionId)
      Actions.retrieveCdrVerifications(match.params.executionId, projectCode)
    }
    if (verificationHasBeenUpdated) {
      Actions.retrieveCdrVerifications(match.params.executionId, projectCode)
    }
    if (qonformVerificationHasBeenUpdated) {
      Actions.retrieveQonformVerifications(match.params.executionId, projectCode)
    }
    if (error != null) {
      throw Error(error)
    }

    const hasPendingConditions = conditionsState === 'PENDING'

    const {
      executionId, testSuiteId, tester, approval, verification, intactServerVersion, note,
    } = testSuite
    const hpqcNote = hpqcDetails.note

    const shouldTriggerReload = () => {
      Actions.retrieveDetails(executionId)
    }

    function contextPreviewDescription(context, verifications) {
      const names = Object.values(verifications)
        .filter(v => v.context.id === context.id)
        .map(v => v.name)
      return `(Used in verifications: [${names.join()}])`
    }

    // eslint-disable-next-line no-unused-vars
    function renderQonformActions(qonformVerification, handleSelectedFile, handleRunQonformVerificationButtonClick) {
      if (qonformVerification.state === 'RUNNING') {
        return (
          <span className={style.actions}>
            <CircularProgress className={style.circularProgress} />
          </span>
        )
      }
      if (!qonformEnabled) {
        return ''
      }
      return (
        <span className={style.actions}>
          <Tooltip title="Run Verification">
            <IconButton aria-label="play" onClick={e => handleRunQonformVerificationButtonClick(e, qonformVerification.id)}>
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
        </span>
      )
    }

    function renderCdrVerificationActions(cdrVerification, handleSelectedFile, handleRunVerificationButtonClick) {
      if (cdrVerification.state === 'RUNNING') {
        return (
          <span className={style.actions}>
            <span style={{ marginRight: '12px' }}>
              <span className={style.attachments}>
                <AttachmentButton key={`${cdrVerification.id}_attachment_button_disabled`} disabled attachment={cdrVerification.attachment} />
              </span>
              <span className={style.cdrName}>{cdrVerification.attachment.name}</span>
            </span>
            <CircularProgress className={style.circularProgress} />
          </span>
        )
      }
      if (!verificationServiceEnabled) {
        return ''
      }
      if (cdrVerification.attachment && !cdrVerification.attachment.removed) {
        return (
          <span className={style.actions}>
            <span style={{ marginRight: '4px' }}>
              <span className={style.attachments}>
                <AttachmentButton key={`${cdrVerification.id}_attachment_button_enabled`} attachment={cdrVerification.attachment} />
              </span>
              <span className={style.cdrName}>{cdrVerification.attachment.name}</span>
            </span>
            <Tooltip title="Run Verification">
              <IconButton aria-label="play" onClick={e => handleRunVerificationButtonClick(e, cdrVerification.id)}>
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
          </span>
        )
      }
      return (
        <span>
          <span style={{ marginRight: '4px' }}>
            {cdrVerification.cdrSearch
              ? <CdrSearch executionId={executionId} verificationId={cdrVerification.id} project={projectCode} cdrSearch={cdrVerification.cdrSearch} />
              : ''}
            <span className={style.attachments}>
              <FileDialog title="Attach CDR File" onSelectFile={e => handleSelectedFile(e, cdrVerification.id)} />
            </span>
            <span className={style.attachFileHint}>Attach CDR File</span>
          </span>
          <IconButton disabled aria-label="play"><PlayArrowIcon /></IconButton>
        </span>
      )
    }

    // eslint-disable-next-line no-unused-vars
    function renderQonformVerificationResult(qonformVerification, qonformVerifications) {
      let result = ''
      if (qonformVerification.result !== undefined) {
        if (qonformVerification.result.result === 'PASSED') {
          result = 'Verification passed.'
        }
        if (qonformVerification.result.result === 'FAILED') {
          result = 'Verification failed.'
        }
        if (qonformVerification.result.result === 'ERROR' && qonformVerification.result.error !== null) {
          result = qonformVerification.result.error
        }
        return (
          <span>
            <span>{result}</span>
            <span className={style.attachments}>
              <AttachmentButton
                key={qonformVerification.context.id}
                id={qonformVerification.context.name}
                attachment={qonformVerification.context}
                description={contextPreviewDescription(qonformVerification.context, qonformVerifications)}
              />
              {qonformVerification.result.attachments.map(a => (
                <AttachmentButton key={a.id} id={a.name} attachment={a} />
              ))}
            </span>
          </span>
        )
      }
      return (
        <span>
          <span>Results will show up here when the Qonform verification is complete</span>
          <span className={style.attachments}>
            <AttachmentButton
              key={qonformVerification.context.id}
              id={qonformVerification.context.name}
              attachment={qonformVerification.context}
              description={contextPreviewDescription(qonformVerification.context, qonformVerifications)}
            />
          </span>
        </span>
      )
    }

    function renderCdrVerificationResult(cdrVerification, cdrVerifications) {
      let result = ''
      if (cdrVerification.result !== undefined) {
        if (cdrVerification.result.result === 'PASSED') {
          result = 'Verification passed.'
        }
        if (cdrVerification.result.result === 'FAILED') {
          result = 'Verification failed.'
        }
        if (cdrVerification.result.result === 'NO_COMPATIBLE_TICKETS') {
          result = 'No compatible tickets for testcase.'
        }
        if (cdrVerification.result.result === 'NO_RULES') {
          result = 'No rules are configured for testcase.'
        }
        if (cdrVerification.result.result === 'ERROR' && cdrVerification.result.error !== null) {
          result = cdrVerification.result.error
        }
        return (
          <span>
            <span>{result}</span>
            <span className={style.attachments}>
              <AttachmentButton
                key={cdrVerification.context.id}
                id={cdrVerification.context.name}
                attachment={cdrVerification.context}
                description={contextPreviewDescription(cdrVerification.context, cdrVerifications)}
              />
              {cdrVerification.result.attachments.map(a => (
                <AttachmentButton key={a.id} id={a.name} attachment={a} />
              ))}
            </span>
          </span>
        )
      }
      return (
        <span>
          <span>Results will show up here after running the verification</span>
          <span className={style.attachments}>
            <AttachmentButton
              key={cdrVerification.context.id}
              id={cdrVerification.context.name}
              attachment={cdrVerification.context}
              description={contextPreviewDescription(cdrVerification.context, cdrVerifications)}
            />
          </span>
        </span>
      )
    }

    function renderCdrVerificationCardTitle() {
      if (verificationServiceEnabled) {
        return 'CDR Verification'
      }
      return 'CDR Verification [disabled in configuration settings]'
    }

    function renderQonformVerificationCardTitle() {
      if (qonformServiceEnabled) {
        return 'Qonform'
      }
      return 'Qonform [disabled in configuration settings]'
    }

    return (
      <Spinner spinning={loading.execution}>
        <div className={style.titleHeader}>
          <Title
            status={testSuiteStatus}
            conditionsState={conditionsState}
            name={testSuite.testSuiteName || 'N/A'}
            link={`/${match.params.projectCode}/test-suite/details/${testSuiteId}`}
          />
          <div className={style.iconBar}>
            { showAttachmentsLink && (
              <Link to={`/${match.params.projectCode}/detail/${reportId}/attachments`} className={style.actionIcon}>
                <Tooltip title="Show Attachments">
                  <AttachFileIcon id="attachmentsPageButton" />
                </Tooltip>
              </Link>
            )}
            <Link to={`/${match.params.projectCode}/reports/test-run-report?testSuiteId=${testSuiteId}`} className={style.actionIcon}>
              <SearchIcon id="searchButton" />
            </Link>
            {showTicketWarning && (
              <img id="testplanWarningIcon" src="/images/testplanwarning.svg" alt="Test plan missing" className={style.testplanWarning} />)}
            <DefectIcon defects={defects} warnings={testSuite.warnings} className={style.defectIcon} />
          </div>
        </div>
        <div>

          <div className={`row ${style.testSuiteContainer}`}>
            <div className="col-md-4">
              <Card className={style.card}>
                <CardContent>
                  <DataField name="Start Time" value={Utils.formatDateTimeZone(testSuite.startTime)} />
                  <DataField name="Duration" value={Utils.formatDuration(testSuite.duration)} />
                  <DataField name="Tester" value={tester} />
                  { canChangeTestCategory ? (
                    <DataField name="Test Category" value="test">
                      <TestCategorySelector
                        initialValue={testSuite.testCategory}
                        onChange={value => Actions.handleTestCategoryChange(match.params.executionId, value)}
                      />
                    </DataField>
                  )
                    : <DataField name="Test Category" value={testSuite.testCategory} />}
                  <DataField name="intaQt Version" value={intactServerVersion || 'N/A'} />
                  <DataField name={`Internal note ${note && note.user ? `(${note.user})` : ''}`}>
                    <TextBoxWithSubmit
                      onClick={text => Actions.saveInternalNote(executionId, text)}
                      defaultText={note ? note.text : ''}
                    />
                  </DataField>
                  {errorCategory && (
                    <DataField name="Error Category" value={errorCategory} />)}
                  {errorMessage && (
                    <DataField name="Error message">
                      <ErrorMessage value={errorMessage} />
                    </DataField>
                  )}
                  { isTestManager && (
                    <div className={style.buttons}>
                      <ConfirmTooltip
                        visible={confirmTooltipVisible}
                        onCancel={() => this.setState(prevState => ({ ...prevState, confirmTooltipVisible: false }))}
                        onSubmit={() => Actions.scheduleForDeletion(executionId)}
                      >
                        <Button
                          variant="contained"
                          id="deleteButton"
                          disabled={!canDelete}
                          onClick={() => this.setState(prevState => ({ ...prevState, confirmTooltipVisible: true }))}
                        >
                          Delete
                        </Button>
                      </ConfirmTooltip>
                      {canMoveTests && (
                        <Button
                          variant="contained"
                          id="moveButton"
                          onClick={() => Actions.openMoveDialog()}
                        >
                          Move
                        </Button>
                      )}
                    </div>
                  )}
                </CardContent>
              </Card>
            </div>

            { postProcessEnabled && (
              <div className="col-md-4">
                <Card className={style.card}>
                  <CardHeader title="Execution Status" />
                  <CardContent>
                    <Spinner spinning={approvalInProgress}>
                      <div>
                        <DataField name="Verification status" value={testSuite.verificationStatus || 'New'} />
                        { verified && (
                          <div>
                            <DataField name="Verified by" value={verification.user} />
                            <DataField name="Verification time" value={Utils.formatDate(verification.timeStamp)} />
                          </div>
                        )}
                        { archived && (
                          <div>
                            <DataField name="Archived by" value={verification.user} />
                            <DataField name="Archive time" value={Utils.formatDate(verification.timeStamp)} />
                          </div>
                        )}
                        <DataField name="Approval status" value={testSuite.approvalStatus || 'Open'} />
                        { approval && (
                          <div>
                            <DataField name="Approval changed by" value={approval.user} />
                            <DataField name="Approval change reason" value={approval.action} />
                            <DataField name="Approval last change" value={Utils.formatDate(approval.timeStamp)} />
                          </div>
                        )}
                        <div className={style.buttons}>
                          <Button
                            variant="contained"
                            id="verifyButton"
                            disabled={!canVerify || hasPendingConditions}
                            onClick={() => Actions.verify(executionId)}
                          >
                            Verify
                          </Button>
                          <Button variant="contained" id="archiveButton" disabled={!canArchive} onClick={() => Actions.archive(executionId)}>Archive</Button>
                        </div>
                        { isTestManager && (
                          <div className={style.buttons}>
                            { postProcessEnabled && (
                              <ConfirmationButton
                                id="approveButton"
                                disabled={!canApprove || hasPendingConditions}
                                message="ALM Status was changed after previous upload. Do you really want to approve?"
                                requireConfiguration={requireConfirmation}
                                onClick={() => Actions.approve(executionId)}
                              >
                                Approve
                              </ConfirmationButton>
                            )}
                            { postProcessEnabled && (
                              <Button variant="contained" id="denyButton" disabled={!canDeny} onClick={() => Actions.deny(executionId)}>Deny</Button>)}
                          </div>
                        )}
                      </div>
                    </Spinner>
                  </CardContent>
                </Card>
              </div>
            )}

            {hpqcEnabled && (
              <div className="col-md-4">
                <Card className={style.card}>
                  <CardHeader title="HPQC Details" />
                  <CardContent>
                    <Defects defects={defects} onSave={list => Actions.saveDefects(executionId, list)} />
                    <Spinner spinning={loading.hpqc}>
                      <DataField name="ALM Status">
                        <Select
                          fullWidth
                          value={hpqcDetails.status || ''}
                          disabled={!canChangeHpqcDetails}
                          onChange={e => Actions.handleHpqcStatusChange(projectCode, executionId, e.target.value)}
                        >
                          {hpqcStatuses.map(status => (
                            <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>))}
                        </Select>
                      </DataField>
                      <DataField name={`ALM Comment ${hpqcNote && hpqcNote.user ? `(${hpqcNote.user})` : ''}`}>
                        <TextBoxWithSubmit
                          disabled={!canChangeHpqcDetails}
                          onClick={text => Actions.saveHpqcNote(projectCode, executionId, text)}
                          defaultText={hpqcNote ? hpqcNote.text : ''}
                        />
                      </DataField>
                      <DataField name="ALM Upload status" value={uploadStatus} />
                      <Button
                        variant="contained"
                        id="uploadButton"
                        disabled={!canUpload && postProcessEnabled}
                        onClick={() => Actions.upload(projectCode, executionId)}
                      >
                        Upload
                      </Button>
                    </Spinner>
                  </CardContent>
                </Card>
              </div>
            )}

            {jiraZephyrEnabled && (
              <div className="col-md-4">
                <Spinner spinning={loading.jira}>
                  <JiraUploadList list={jiraDetails} onUpload={() => Actions.uploadToJira(projectCode, executionId)} />
                </Spinner>
              </div>
            )}

            {jiraBasicEnabled && (
              <div className="col-md-4">
                <BasicJiraDetails executionId={match.params.executionId} />
              </div>
            )}

            {successConditions.length > 0 && (
              <div className="col-md-4">
                <SuccessConditions key={successConditionDetailsKey} executionId={match.params.executionId} onChanged={shouldTriggerReload} />
              </div>
            )}

            { newerExecutions.length > 0 && (
              <div className="col-md-8">
                <Card className={style.card}>
                  <CardHeader title="Newer Executions" />
                  <CardContent>
                    <Spinner spinning={loading.newerExecutions}>
                      <ExecutionList list={newerExecutions} projectCode={match.params.projectCode} />
                    </Spinner>
                  </CardContent>
                </Card>
              </div>
            )}

            { showDataRetention && (
              <div className="col-md-4">
                <Card className={style.card}>
                  <CardHeader title="Data Retention" />
                  <CardContent>
                    <div>
                      <DataField name="Retention status" value={retention.status} />
                      <DataField name="Time when scheduled for deletion" value={retention.scheduleTime} />
                      <DataField name="Scheduled by" value={retention.scheduledBy} />
                      <DataField name="Deletion reason" value={retention.reason} />
                      <DataField name="Deletion time" value={retention.deletionTime} />
                      <div className={style.buttons}>
                        { isTestManager && (
                          <Button
                            variant="contained"
                            id="cancelDeletionButton"
                            disabled={!canCancelDeletion}
                            onClick={() => Actions.cancelDeletion(executionId)}
                          >
                            Cancel Deletion
                          </Button>
                        )}
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            )}

          </div>

          <Card className={style.card}>
            <CardContent>
              <SuiteOutline {...testSuite} name={testSuite.testSuiteName} executionId={match.params.executionId} result={testSuiteStatus} />
            </CardContent>
          </Card>

          <Result attachments={resultAttachments} />

          <div style={{ marginTop: '1em' }}>
            {/* eslint-disable-next-line max-len */}
            { qonformEnabled && (qonformVerificationsResponse.qonformVerifications.length > 0 || qonformVerificationsResponse.qonformVerificationWarnings.length > 0) && (
              <Card className={style.card}>
                <CardHeader title={renderQonformVerificationCardTitle()} />
                <CardContent>
                  <List>
                    { qonformVerificationsResponse.qonformVerifications.map(qonformVerification => (
                      <div style={{ marginBottom: '48px' }} key={qonformVerification.name}>
                        <ListItem>
                          <ListItemText
                            primary={qonformVerification.name}
                            secondary={renderQonformVerificationResult(qonformVerification, qonformVerificationsResponse.qonformVerifications)}
                          />
                          <ListItemSecondaryAction>
                            {renderQonformActions(qonformVerification, this.handleSelectedFile, this.handleRunQonformVerificationButtonClick)}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ExpansionPanel title="Context Overrides">
                          <TextField
                            label="Enter Context Overrides"
                            error={qonformContextPreviewErrors.get(qonformVerification.id) !== undefined
                            && qonformContextPreviewErrors.get(qonformVerification.id) !== null}
                            helperText={qonformContextPreviewErrors.get(qonformVerification.id) || ''}
                            multiline
                            fullWidth
                            rows={16}
                            variant="filled"
                            value={qonformContextOverrides.get(qonformVerification.id) || ''}
                            onChange={e => Actions.changeQonformContextOverrides(qonformVerification.id, e.target.value)}
                          />
                          <div style={{ marginTop: '16px', marginBottom: '16px', float: 'right' }}>
                            <Button
                              startIcon={<VisibilityIcon />}
                              style={{ marginRight: '8px' }}
                              variant="contained"
                              disabled={qonformContextOverrides.get(qonformVerification.id) === undefined
                              || qonformContextOverrides.get(qonformVerification.id) === ''}
                              onClick={e => this.handleQonformPreview(e, qonformVerification.id)}
                            >
                              Preview
                            </Button>
                            <Button
                              variant="contained"
                              disabled={
                                qonformContextOverrides.get(qonformVerification.id) === undefined
                                      || qonformContextOverrides.get(qonformVerification.id) === ''
                                      || (updateQonformContextButtonStates.get(qonformVerification.id) !== undefined
                                          && updateQonformContextButtonStates.get(qonformVerification.id).disabled)
                              }
                              onClick={e => this.handleUpdateQonformContext(e, qonformVerification.id)}
                              color="primary"
                            >
                              Update
                            </Button>
                          </div>
                        </ExpansionPanel>
                      </div>
                    ))}
                  </List>
                  <List>
                    { qonformVerificationsResponse.qonformVerificationWarnings.map(qonformVerificationWarning => (
                      <ListItem key={qonformVerificationWarning.id}>
                        <ListItemIcon><WarningIcon /></ListItemIcon>
                        <ListItemText className={style.verificationWarning} primary={qonformVerificationWarning.warning} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            )}
          </div>

          <div style={{ marginTop: '1em' }}>
            { cdrEnabled && (cdrVerificationsResponse.cdrVerifications.length > 0 || cdrVerificationsResponse.cdrVerificationWarnings.length > 0) && (
              <Card className={style.card}>
                <CardHeader title={renderCdrVerificationCardTitle()} />
                <CardContent>
                  <List>
                    { cdrVerificationsResponse.cdrVerifications.map(cdrVerification => (
                      <div style={{ marginBottom: '48px' }} key={cdrVerification.name}>
                        <ListItem>
                          <ListItemText
                            primary={cdrVerification.name}
                            secondary={renderCdrVerificationResult(cdrVerification, cdrVerificationsResponse.cdrVerifications)}
                          />
                          <ListItemSecondaryAction>
                            {renderCdrVerificationActions(cdrVerification, this.handleSelectedFile, this.handleRunVerificationButtonClick)}
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ExpansionPanel title="Context Overrides">
                          <TextField
                            label="Enter Context Overrides"
                            error={contextPreviewErrors.get(cdrVerification.id) !== undefined && contextPreviewErrors.get(cdrVerification.id) !== null}
                            helperText={contextPreviewErrors.get(cdrVerification.id) || ''}
                            multiline
                            fullWidth
                            rows={8}
                            variant="filled"
                            value={contextOverrides.get(cdrVerification.id) || ''}
                            onChange={e => Actions.changeContextOverrides(cdrVerification.id, e.target.value)}
                          />
                          <div style={{ marginTop: '16px', marginBottom: '16px', float: 'right' }}>
                            <Button
                              startIcon={<VisibilityIcon />}
                              style={{ marginRight: '8px' }}
                              variant="contained"
                              disabled={contextOverrides.get(cdrVerification.id) === undefined || contextOverrides.get(cdrVerification.id) === ''}
                              onClick={e => this.handlePreview(e, cdrVerification.id)}
                            >
                              Preview
                            </Button>
                            <Button
                              variant="contained"
                              disabled={
                                contextOverrides.get(cdrVerification.id) === undefined
                                || contextOverrides.get(cdrVerification.id) === ''
                                || (updateContextButtonStates.get(cdrVerification.id) !== undefined
                                && updateContextButtonStates.get(cdrVerification.id).disabled)
                              }
                              onClick={e => this.handleUpdateContext(e, cdrVerification.id)}
                              color="primary"
                            >
                              Update
                            </Button>
                          </div>
                        </ExpansionPanel>
                      </div>
                    ))}
                  </List>
                  <List>
                    { cdrVerificationsResponse.cdrVerificationWarnings.map(cdrVerificationWarning => (
                      <ListItem key={cdrVerificationWarning.id}>
                        <ListItemIcon><WarningIcon /></ListItemIcon>
                        <ListItemText className={style.verificationWarning} primary={cdrVerificationWarning.warning} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            )}
          </div>

          <LogLines title="Protocol" logs={protocol.logs} status={protocol.status} error={protocol.error} />

          <LogLines title="Trace Log" logs={trace.logs} status={trace.status} error={trace.error} />

          <LogLines title="intaQt Log" logs={server.logs} status={server.status} error={server.error} />

          {
            moveDialogOpen
            && <MoveDialog onCancel={() => Actions.closeMoveDialog()} onMove={code => () => Actions.moveReportToOtherProject(match.params.executionId, code)} />
          }

          {
            redirectToReportList
            && <Redirect to="../reports/test-run-report" />
          }

          <Dialog
            title="Event"
            modal={false}
            autoScrollBodyContent
            open={open}
            actions={<Button id="closeButton" color="primary" onClick={Actions.closePhoneInfo}>Close</Button>}
            onClose={Actions.closePhoneInfo}
          >
            <Card>
              <CardContent className={style.params}>{this.renderParameters(parameters)}</CardContent>
            </Card>
          </Dialog>
          <AttachmentPreview
            name="Preview of updated context"
            type="application/json"
            file={contextPreviewFile}
            onClose={() => Actions.closeContextPreview()}
          />
        </div>
      </Spinner>
    )
  }
}

TestSuiteView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

TestSuiteView.contextType = ProjectContext

export default withRouter(TestSuiteView)
