import some from 'lodash/some'
import size from 'lodash/size'
import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import { keys, load, write } from 'core/storage'
import { requestOptions } from 'services/common'

class ProjectSelectorStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      projectCode: load(keys.PROJECT),
      projects: [],
      projectsLoaded: false,
      roles: [],
      projectList: [],
    }
  }

  updateTimeout() {
    const project = this.state.projects.find(it => it.code === this.state.projectCode)
    if (project && project.timeoutInSeconds) {
      requestOptions.timeout = project.timeoutInSeconds * 1000
    }
  }

  projectExists = projectCode => projectCode && some(this.state.projects, { code: projectCode, enabled: true })

  handleActions(action) {
    switch (action.type) {
    case events.projectSelector.listReceived: {
      this.state.projects = action.list
      this.state.user = action.user
      this.state.projectsLoaded = true
      if (!this.projectExists(this.state.projectCode)) {
        this.state.projectCode = null
        write(keys.PROJECT, null)
      }
      if (this.state.projectCode) {
        this.updateTimeout()
      }
      if (size(action.list) === 0) {
        this.state.error = new Error('Permission denied for all projects.')
      }
      if (action.projectCode) {
        if (this.projectExists(action.projectCode)) {
          this.state.projectCode = action.projectCode
          write(keys.PROJECT, action.projectCode)
        } else {
          this.state.projectCode = null
        }
      }
      this.emitChange()
      break
    }
    case events.projectSelector.selected: {
      if (this.projectExists(action.projectCode)) {
        this.state.projectCode = action.projectCode
        this.updateTimeout()
        write(keys.PROJECT, action.projectCode)
        this.state.user = action.user
      } else {
        this.state.projectCode = null
      }
      this.emitChange()
      break
    }
    case events.projectSelector.failed: {
      this.state.error = new Error(`Authentication failed: ${action.message}`)
      this.emitChange()
      break
    }
    case events.projectAdministration.saved: {
      this.state.projects = []
      this.state.projectsLoaded = false
      this.emitChange()
      break
    }
    case events.projectSelector.updateProject: {
      this.state.projects = this.state.projects.map(i => (i.code === action.project.code ? action.project : i))
      this.emitChange()
      break
    }

    default: {
      // empty
    }
    }
  }
}

const projectSelectorStore = new ProjectSelectorStore()
dispatcher.register(projectSelectorStore.handleActions.bind(projectSelectorStore))

export default projectSelectorStore
