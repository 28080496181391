import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import FilterPanel from 'components/reports/common/filterPanel'

const StatusFilter = ({ onSubmit, initialValue, id }) => {
  const [status, setStatus] = useState('')

  useEffect(() => { setStatus(initialValue) }, [initialValue])

  const handleFilter = () => {
    if (!isEmpty(status)) {
      onSubmit(status)
      setStatus('')
    }
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  return (
    <FilterPanel title="Filter by intaQt Result" onSubmit={handleFilter} id={id}>
      <FormControl fullWidth>
        <InputLabel>Select a result</InputLabel>
        <Select
          id="statusFilterSelect"
          autoFocus
          value={status}
          onChange={handleStatusChange}
        >
          <MenuItem key="PASSED" value="PASSED">Passed </MenuItem>
          <MenuItem key="FAILED" value="FAILED">Failed </MenuItem>
        </Select>
      </FormControl>
    </FilterPanel>
  )
}

StatusFilter.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

StatusFilter.defaultProps = {
  id: 'statusFilter',
  initialValue: '',
}

export default memo(StatusFilter)
