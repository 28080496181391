import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'

import { passedResultColor, failedResultColor } from 'core/themeProvider'

const style = { fill: 'currentColor' }

export const UploadIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" style={style}>
    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4
      2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7
      1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
    />
  </svg>
)

export const ExcelIcon = () => (
  <svg viewBox="0 0 24 24" width="22" height="22" xmlns="http://www.w3.org/2000/svg" style={style}>
    <g>
      <path d="M23.5 3H14V.5a.5.5 0 0 0-.59-.5l-13 2.5A.5.5 0 0 0 0 3v18a.5.5 0 0 0 .41.49l13 2.5h.09a.5.5 0 0 0 .5-.5V21h9.5a.5.5 0 0 0
        .5-.5v-17a.5.5 0 0 0-.5-.5zM8.95 16.28a.5.5 0 0 1-.89.45L6.5 13.62l-1.55 3.1a.5.5 0 0 1-.89-.45l1.88-3.77-1.89-3.78a.5.5 0 0 1
        .89-.45l1.56 3.11 1.55-3.1a.5.5 0 0 1 .89.45L7.06 12.5zM23 20h-9v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0
        .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14v-1h2.5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V7h2.5a.5.5
        0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H14V4h9z"
      /><rect height="2" rx=".5" ry=".5" width="4" x="18" y="5" />
      <rect height="2" rx=".5" ry=".5" width="4" x="18" y="8" /><rect height="2" rx=".5" ry=".5" width="4" x="18" y="11" />
      <rect height="2" rx=".5" ry=".5" width="4" x="18" y="14" /><rect height="2" rx=".5" ry=".5" width="4" x="18" y="17" />
    </g>
  </svg>
)

export const ExportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" style={style}>
    <path
      d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4
      2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7
      1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
      fill="#231F20"
      opacity="0.5"
    />
  </svg>
)

export const ExportErrorCategoriesIcon = () => (
  <svg viewBox="0 0 512 512" width="18" height="18" version="1.2" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M507.296,4.704c-3.36-3.36-8.032-5.056-12.768-4.64L370.08,11.392c-6.176,0.576-11.488,4.672-13.6,10.496
    s-0.672,12.384,3.712,16.768l33.952,33.952L224.448,242.272c-6.24,6.24-6.24,16.384,0,22.624l22.624,22.624
    c6.272,6.272,16.384,6.272,22.656,0.032l169.696-169.696l33.952,33.952c4.384,4.384,10.912,5.824,16.768,3.744
    c2.24-0.832,4.224-2.112,5.856-3.744c2.592-2.592,4.288-6.048,4.608-9.888l11.328-124.448
    C512.352,12.736,510.656,8.064,507.296,4.704z"
    />
    <path d="M448,192v256H64V64h256V0H32C14.304,0,0,14.304,0,32v448c0,17.664,14.304,32,32,32h448c17.664,0,32-14.336,32-32V192H448z" />
  </svg>
)

export const ImportErrorCategoriesIcon = () => (
  <svg viewBox="0 0 512 512" width="18" height="18" version="1.2" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M287.52,224.48c-3.36-3.36-8-5.088-12.736-4.64l-124.448,11.296c-6.176,0.576-11.52,4.672-13.6,10.496
      c-2.112,5.856-0.672,12.384,3.712,16.768l33.952,33.952L4.704,462.048c-6.24,6.24-6.24,16.384,0,22.624l22.624,22.624
      c6.24,6.272,16.352,6.272,22.624,0L219.648,337.6l33.952,33.952c4.384,4.384,10.912,5.824,16.768,3.744
      c2.24-0.832,4.224-2.112,5.856-3.744c2.592-2.592,4.288-6.048,4.608-9.888l11.328-124.448
      C292.608,232.48,290.88,227.84,287.52,224.48z"
    />
    <path d="M480,0H32C14.336,0,0,14.336,0,32v320h64V64h384v384H160v64h320c17.696,0,32-14.304,32-32V32C512,14.336,497.696,0,480,0z" />
  </svg>
)

export const UploadFailedIcon = ({ message }) => (
  <Icon fontSize="small" style={{ color: failedResultColor }} data-tip={message}>clear</Icon>
)
UploadFailedIcon.propTypes = { message: PropTypes.string }
UploadFailedIcon.defaultProps = { message: undefined }

export const UploadSuccessfulIcon = () => (
  <Icon fontSize="small" style={{ color: passedResultColor }}>done</Icon>
)
