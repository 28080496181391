import PropTypes from 'prop-types'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'

export const conqludeColor = '#c5eefc'

export const qitascOrange = {
  50: '#fef2e4',
  100: '#fddfbc',
  200: '#fbca8f',
  300: '#f9b462',
  400: '#f8a440',
  500: '#f7941e',
  600: '#f68c1a',
  700: '#f58116',
  800: '#f37712',
  900: '#f1650a',
  A100: '#ffffff',
  A200: '#ffefe7',
  A400: '#ffceb4',
  A700: '#ffbe9b',
}

export const qitascBlue = {
  50: '#e0efff',
  100: '#b3d7ff',
  200: '#80bdff',
  300: '#4da3ff',
  400: '#268fff',
  500: '#007bff',
  600: '#0073ff',
  700: '#0068ff',
  800: '#005eff',
  900: '#004bff',
  A100: '#ffffff',
  A200: '#f2f5ff',
  A400: '#bfceff',
  A700: '#a6baff',
}

export const qitascGray100 = '#ebeceb'
export const qitascGray200 = '#bfc2c9'
export const qitascGray300 = '#8c8c8e'
export const qitascGray400 = '#64646a'
export const qitascGray500 = '#32323c'

export const backgroundColor = '#fafafa'

export const passedResultColor = '#2e7d32'
export const failedResultColor = '#c62828'
export const pendingResultColor = '#c66c28'
export const skippedResultColor = '#ffbb00'
export const unknownResultColor = '#8c8c8e'

const theme = createTheme({
  palette: {
    primary: qitascOrange,
    secondary: {
      light: qitascBlue[300],
      main: qitascBlue[500],
      dark: qitascBlue[700],
    },
  },
  typography: {
    fontFamily: ['AvenirLT', 'Roboto', 'sans-serif'].join(','),
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          '&:hover': {
            color: qitascOrange[500],
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor,
          fontSize: '0.875rem',
          lineHeight: 1.43,
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'inherit',
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'large',
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: qitascOrange[500],
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(247, 148, 30, 0.04)',
          },
        },
      },
    },
  },
})

const ThemeProviderWrapper = ({ children }) => (
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={DateAdapter}>
      {children}
    </LocalizationProvider>
  </ThemeProvider>
)

ThemeProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProviderWrapper
