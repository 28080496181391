import axios from 'axios'
import * as Common from 'services/common'
import * as Utils from 'components/utils'

class TestPlanService {
  findAll = (project, filters) => {
    const sortParams = filters.sortField ? `&sort=${filters.sortField}${filters.sortDirection ? (`,${filters.sortDirection}`) : ''}` : ''
    const testSuiteName = filters.testSuiteName ? `&testSuiteName=${encodeURIComponent(filters.testSuiteName)}` : ''
    const testPlanId = filters.testPlanId ? `&testPlanId=${filters.testPlanId}` : ''
    const dateFrom = filters.dateFrom ? `&dateFrom=${encodeURIComponent(Utils.formatDateISOZ(filters.dateFrom))}` : ''
    const dateUntil = filters.dateUntil ? `&dateUntil=${encodeURIComponent(Utils.formatDateISOZ(filters.dateUntil))}` : ''
    const pageNumber = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
    const pageSize = filters.pageSize ? `&size=${filters.pageSize}` : ''
    const url = `/api/module/hpqc/${project}/test-plan?${sortParams}${pageNumber}${pageSize}${testSuiteName}${testPlanId}${dateFrom}${dateUntil}`

    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  createTestPlansByFilter = ({ project, filter }) => {
    const url = `/api/module/hpqc/${project}/test-plan`
    return axios.post(url, { filter }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  createTestPlansById = ({ project, ids }) => {
    const url = `/api/module/hpqc/${project}/test-plan`
    return axios.post(url, { ids }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  createTestInstancesByFilter = ({ project, filter, testSetId }) => {
    const url = `/api/module/hpqc/${project}/test-plan/test-instance`
    return axios.post(url, { filter, testSetId }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  createTestInstancesById = ({ project, ids, testSetId }) => {
    const url = `/api/module/hpqc/${project}/test-plan/test-instance`
    return axios.post(url, { ids, testSetId }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  exportTestPlans = ({ project, filter, ids }) => this.makeRequest({
    url: `/api/module/hpqc/${project}/test-plan/export`,
    requestBody: { ids, filter },
  })

  exportTestInstances = ({ project, filter, ids, testSetId }) => this.makeRequest({
    url: `/api/module/hpqc/${project}/test-plan/test-instance/export`,
    requestBody: { ids, filter, testSetId },
  })

  makeRequest = ({ url, requestBody }) => new Promise((resolve, reject) => {
    const request = new XMLHttpRequest()
    request.open('POST', url, true)
    request.setRequestHeader('Content-Type', 'application/json')
    request.responseType = 'blob'
    request.onload = () => {
      if (request.status === 400) {
        const fr = new FileReader()
        fr.onload = () => reject(JSON.parse(fr.result).message)
        fr.readAsText(request.response)
        return
      }
      if (request.status > 400) {
        reject(new Error(`Export request failed: ${request.statusText}`))
        return
      }
      if (request.status === 204) {
        resolve()
        return
      }
      this.download(request)
    }
    request.send(JSON.stringify(requestBody))
  })

  download = (request) => {
    const blob = request.response
    let fileName = null
    const contentType = request.getResponseHeader('content-type')

    if (request.getResponseHeader('content-disposition')) {
      const contentDisposition = request.getResponseHeader('content-disposition')
      fileName = contentDisposition.substring(contentDisposition.indexOf('=') + 1)
    } else {
      fileName = `unnamed.${contentType.substring(contentType.indexOf('/') + 1)}`
    }

    const el = document.createElement('a')
    document.body.appendChild(el)
    el.href = window.URL.createObjectURL(blob)
    el.download = fileName
    el.click()
    document.body.removeChild(el)
  }
}

const instance = new TestPlanService()
export default instance
