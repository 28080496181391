import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Paper from '@mui/material/Paper'

import JiraUploadList from 'components/reports/jiraUploads/list'
import LogLines from 'components/executionDetails/logLines'
import Spinner from 'components/spinner'
import { TestOutline } from 'components/executionDetails/outline/outline'
import Title from 'components/title'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import ApprovalDetails from './approvalDetails'
import Properties from './properties'
import TestStore from './store'
import * as Actions from './actions'
import style from './style.module.scss'
import BasicJiraDetails from './basicJiraDetails/index'

class TestView extends Component {
  constructor(props) {
    super(props)
    this.state = TestStore.getState()
    if (!this.dataLoaded()) {
      this.state = TestStore.resetState()
    }
  }

  componentDidMount() {
    const { jiraZephyrEnabled, projectCode } = this.context
    const { match } = this.props
    TestStore.on('change', this.updateState)
    if (!this.dataLoaded()) {
      const { executionId, test } = match.params
      Actions.retrieveDetailsAndLogs(executionId, test, jiraZephyrEnabled, projectCode)
    }
    ReactTooltip.hide()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
    if (!this.dataLoaded()) {
      const { match } = this.props
      const { executionId, test } = match.params
      Actions.getTestDetails(executionId, test)
    }
  }

  componentWillUnmount() {
    TestStore.removeListener('change', this.updateState)
  }

  isTestManager = () => {
    const { roles } = this.context
    return roles.includes('ROLE_TEST_MANAGER')
  }

  dataLoaded = () => {
    const { match } = this.props
    const { test } = this.state
    return test.executionId === match.params.executionId
      && String(test.index) === match.params.test
  }

  updateState = () => {
    const state = TestStore.getState()
    this.setState(state)
  }

  render() {
    const { jiraZephyrEnabled, jiraBasicEnabled, projectCode } = this.context
    const { match } = this.props
    const {
      error, loading, loadingJira, test, errorMessage, protocol, trace, server, jiraUploads, jiraApprovalDetails,
    } = this.state
    if (error != null) {
      throw Error(error)
    }

    return (
      <Spinner spinning={loading}>
        <div>
          <Paper className={style.paper} zdepth={1}>
            <Title status={test.result} name={test.name} />
            <Properties
              test={test}
              testSuiteLink={`/${projectCode}/detail/${match.params.executionId}`}
              errorMessage={errorMessage}
            />
          </Paper>

          { jiraZephyrEnabled && (
            <Card className={style.card}>
              <CardHeader title="Jira uploads" />
              <CardContent>
                <Spinner spinning={loadingJira}>
                  <JiraUploadList list={jiraUploads} projectCode={projectCode} />
                  <div className={style.buttons}>
                    <Button variant="contained" onClick={() => Actions.uploadToJira(projectCode, match.params.executionId, test.index)}>Upload</Button>
                  </div>
                </Spinner>
              </CardContent>
            </Card>
          )}

          { jiraBasicEnabled && (
            <BasicJiraDetails executionId={match.params.executionId} testId={test.index} />)}

          { jiraZephyrEnabled && (
            <Card className={style.card}>
              <CardHeader title="Jira approval details" />
              <CardContent>
                <Spinner spinning={loadingJira}>
                  <ApprovalDetails details={jiraApprovalDetails} />
                  { this.isTestManager() && jiraApprovalDetails
                  && (
                    <div className={style.buttons}>
                      <Button variant="contained" onClick={() => Actions.approveInJira(projectCode, match.params.executionId, test.index)}>Approve</Button>
                    </div>
                  )}
                </Spinner>
              </CardContent>
            </Card>
          )}

          <Card className={style.card}>
            <CardContent>
              <TestOutline {...test} />
            </CardContent>
          </Card>
          <LogLines title="Protocol" logs={protocol.logs} status={protocol.status} error={protocol.error} />
          <LogLines title="Trace Log" logs={trace.logs} status={trace.status} error={trace.error} />
          <LogLines title="intaQt Log" logs={server.logs} status={server.status} error={server.error} />
        </div>
      </Spinner>
    )
  }
}

TestView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

TestView.contextType = ProjectContext

export default withRouter(TestView)
