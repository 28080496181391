import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'


const loadingLogs = {
  logs: [],
  status: 'loading',
}

class StepStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      protocol: { ...loadingLogs },
      trace: { ...loadingLogs },
      server: { ...loadingLogs },
      attachments: [],
      openErrorMessage: false,
      step: {
        name: '',
        result: '',
      },
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testDetailsStepView.detailsReceived: {
      this.state.step = action.step
      this.state.attachmentsHaveBeenUpdated = false
      this.state.loading = false
      this.state.openErrorMessage = false
      this.state.attachments = action.step.attachments ? action.step.attachments.filter(it => !it.removed) : []
      if (this.state.step.deleted) {
        this.state.protocol.status = 'deleted'
        this.state.trace.status = 'deleted'
      }
      this.emitChange()
      break
    }
    case events.testDetailsStepView.logsReceived: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.logs = action.logs
        this.state.protocol.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'TRACE':
        this.state.trace.logs = action.logs
        this.state.trace.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'SERVER':
        this.state.server.logs = action.logs
        this.state.server.status = action.deleted ? 'deleted' : 'ready'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.testDetailsStepView.logsFailed: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.error = action.error
        this.state.protocol.status = 'failed'
        break
      case 'TRACE':
        this.state.trace.error = action.error
        this.state.trace.status = 'failed'
        break
      case 'SERVER':
        this.state.server.error = action.error
        this.state.server.status = 'failed'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.testDetailsStepView.failed: {
      this.state.loading = false
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.attachmentButton.attachmentRemoved: {
      this.state.attachmentsHaveBeenUpdated = true
      this.state.loading = true
      this.emitChange()
      break
    }
    case events.attachmentButton.failed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.cdrSearch.failed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const stepStore = new StepStore()
dispatcher.register(stepStore.handleActions.bind(stepStore))

export default stepStore
