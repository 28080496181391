import * as React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import TestStatusIcon from 'components/testStatusIcon'
import style from './style.module.scss'

const formatTime = time => (time ? moment(time).format('HH:mm:ss') : '')

const visibleAttachmentCount = attachments => (attachments || []).filter(a => !a.removed).length

const asLine = (Component, componentLevel) => {
  class Line extends React.Component {
    shouldComponentUpdate(newProps) {
      const {
        selected, startTime, result, attachments, value,
      } = this.props
      return selected !== newProps.selected
        || startTime !== newProps.startTime
        || result !== newProps.result
        || value !== newProps.value
        || visibleAttachmentCount(attachments) !== visibleAttachmentCount(newProps.attachments)
    }

    render() {
      const {
        lineNumber, result, startTime, onClick, level, selected, isBackground, ...otherProps
      } = this.props
      return (
        <div className={selected ? style.selected : ''}>
          <div onClick={onClick} role="presentation">
            <span style={style.lineNumber}>{isBackground ? 'bg' : lineNumber}</span>
            {result && <div className={style.result}><TestStatusIcon status={result} /></div> }
            <span style={style.startTime}>{formatTime(startTime) }</span>
          </div>
          <div className={style[componentLevel || level]}>
            <Component {...otherProps} selected={selected} />
          </div>
        </div>
      )
    }
  }

  Line.propTypes = {
    lineNumber: PropTypes.number,
    isBackground: PropTypes.bool,
    result: PropTypes.string,
    startTime: PropTypes.string,
    level: PropTypes.oneOf(['suite', 'test', 'step', 'detail']),
    selected: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.string,
    attachments: PropTypes.arrayOf(PropTypes.object),
  }

  Line.defaultProps = {
    lineNumber: null,
    isBackground: false,
    result: null,
    startTime: null,
    level: 'suite',
    selected: false,
    attachments: [],
    value: null,
    onClick: () => {},
  }
  return Line
}
export default asLine
