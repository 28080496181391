import { useMemo } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Table from 'components/table'
import style from './style.module.scss'
import SuccessConditionDetail from './successConditionDetail'
import SuccessConditionSelector from './successConditionSelector'


const SuccessConditionTable = ({ list, updateCondition }) => {
  const columns = useMemo(() => [
    {
      Header: 'Source',
      accessor: 'type',
      width: 106,
    },
    {
      Header: 'Details',
      accessor: 'details',
      Cell: item => <SuccessConditionDetail item={item.row.original} />,
    },
    {
      Header: 'State',
      accessor: 'id',
      Cell: item => (
        <SuccessConditionSelector
          conditionId={item.value}
          state={item.row.original.state}
          operations={item.row.original.operations}
          updateCondition={updateCondition}
          requiresComment={item.row.original.requiresCommentOnChange}
        />
      ),
      width: 106,
    },
  ], [])

  return (
    <Box className={style.tableContainer}>
      <Table
        id="successCondition"
        columns={columns}
        data={list}
      />
    </Box>
  )
}

SuccessConditionTable.propTypes = { list: PropTypes.arrayOf(PropTypes.object), updateCondition: PropTypes.func }
SuccessConditionTable.defaultProps = { list: [], updateCondition: null }

export default SuccessConditionTable
