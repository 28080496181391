import { useState } from 'react'
import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'
import { Link as RouterLink } from 'react-router-dom'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'

import useProject from 'hooks/useProject'

import { getLinks } from './tabBar'

import style from './header.module.scss'

const MobileMenu = () => {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const { roles, postProcessEnabled, projectCode } = useProject()
  const { links, currentLink } = getLinks({ projectCode, postProcessEnabled, roles })

  return (
    <div className={style.mobileMenuContainer}>
      <IconButton onClick={() => setDrawerVisible(true)} color="inherit" size="small">
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={drawerVisible}
        onClose={() => setDrawerVisible(false)}
      >
        <div className={style.mobileDrawerContent}>
          <List>
            {links.map(({
              value,
              icon,
              label,
              to,
            }) => (
              <ListItemButton key={value} selected={value === currentLink} onClick={() => setDrawerVisible(false)} className={style.menuBtn}>
                <Link
                  component={RouterLink}
                  underline="none"
                  to={to}
                >
                  <span className={style.menuItemIcon}>{icon}</span>
                  {label}
                </Link>
              </ListItemButton>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  )
}

export default MobileMenu
