import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const defaultSettings = {
  enabled: false,
  baseUrl: '',
  authToken: '',
  socketTimeoutMillis: '',
  jiraProjectKey: '',
  jiraVersionName: '',
  cycleField: '',
  productField: '',
  automatedTestField: '',
  approvedStatus: '',
}

const emptyField = {
  name: '',
  externalCode: '',
  tag: '',
  defaultValue: '',
  type: 'TEXT',
}

const reservedTags = ['reporter', 'description', 'fix version/s', 'component/s']

class JiraSettingsStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      settings: defaultSettings,
      customFields: [],
      statusList: [],
      newField: null,
      settingErrors: new Map(),
      customFieldErrors: new Map(),
      missingCustomFields: [],
      toastMessage: '',
      unsavedChanges: false,
    }
  }

  isFieldNameEntered = () => (this.state.newField && this.state.newField.name && this.state.newField.name.length > 0)

  isFieldNameUnique = () => (!this.isFieldNameEntered()
    || !this.state.customFields.map(it => it.name.toLowerCase()).includes(this.state.newField.name.toLowerCase()))

  isFieldTagEntered = () => (this.state.newField && this.state.newField.tag && this.state.newField.tag.length > 0)

  isFieldTagUnique = () => (!this.isFieldTagEntered()
    || !this.state.customFields.map(it => it.tag.toLowerCase()).includes(this.state.newField.tag.toLowerCase()))

  isFieldTagAvailable = () => (!this.isFieldTagEntered() || !reservedTags.includes(this.state.newField.tag.toLowerCase()))

  isFieldCodeEntered = () => (this.state.newField && this.state.newField.externalCode && this.state.newField.externalCode.length > 0)

  isFieldCodeUnique = () => (!this.isFieldCodeEntered()
    || !this.state.customFields.map(it => it.externalCode.toLowerCase()).includes(this.state.newField.externalCode.toLowerCase()))

  isUrlValid = () => (!this.state.settings.enabled || this.state.settings.baseUrl.length > 0)

  isAuthTokenValid = () => (!this.state.settings.enabled || this.state.settings.authToken.length > 0)

  isTimeoutValid = () => (!this.state.settings.enabled
    || this.state.settings.socketTimeoutMillis.length === 0
    || Number.isInteger(Number(this.state.settings.socketTimeoutMillis)))

  isJiraProjectValid = () => (!this.state.settings.enabled || this.state.settings.jiraProjectKey.length > 0)

  isJiraVersionValid = () => (!this.state.settings.enabled || this.state.settings.jiraVersionName.length > 0)

  isCycleValid = () => (!this.state.settings.cycleField
    || this.state.customFields.map(it => it.name).includes(this.state.settings.cycleField))

  isProductValid = () => (!this.state.settings.productField
    || this.state.customFields.map(it => it.name).includes(this.state.settings.productField))

  isAutomatedTestValid = () => (!this.state.settings.automatedTestField
    || this.state.customFields.map(it => it.name).includes(this.state.settings.automatedTestField))

  validate = () => {
    if (this.isUrlValid()) this.state.settingErrors.delete('baseUrl')
    if (this.isAuthTokenValid()) this.state.settingErrors.delete('authToken')
    if (this.isTimeoutValid()) this.state.settingErrors.delete('socketTimeoutMillis')
    if (this.isJiraProjectValid()) this.state.settingErrors.delete('jiraProjectKey')
    if (this.isJiraVersionValid()) this.state.settingErrors.delete('jiraVersionName')
    if (this.isFieldNameEntered() && this.isFieldNameUnique()) this.state.customFieldErrors.delete('fieldName')
    if (this.isFieldTagEntered() && this.isFieldTagUnique() && this.isFieldTagAvailable()) this.state.customFieldErrors.delete('fieldTag')
    if (this.isFieldCodeEntered() && this.isFieldCodeUnique()) this.state.customFieldErrors.delete('fieldExternalCode')
    if (this.isCycleValid()) this.state.settingErrors.delete('cycleField')
    if (this.isProductValid()) this.state.settingErrors.delete('productField')
    if (this.isAutomatedTestValid()) this.state.settingErrors.delete('automatedTestField')
  }

  checkFieldSettings = () => {
    if (!this.isCycleValid()) this.state.settingErrors.set('cycleField', 'No custom field with this name is configured')
    if (!this.isProductValid()) this.state.settingErrors.set('productField', 'No custom field with this name is configured')
    if (!this.isAutomatedTestValid()) this.state.settingErrors.set('automatedTestField', 'No custom field with this name is configured')
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationJiraSettings.dataReceived: {
      const { customFields, statusList, ...settings } = action.settings || defaultSettings
      this.state.settings = { ...defaultSettings, ...settings }
      this.state.customFields = customFields || []
      this.state.statusList = statusList || []
      this.state.newField = null
      this.state.loading = false
      this.state.settingErrors.clear()
      this.state.customFieldErrors.clear()
      this.state.error = null
      this.state.toastMessage = ''
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.settingChanged: {
      this.state.toastMessage = ''
      this.state.unsavedChanges = true
      this.state.settings = action.settings
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.newFieldChanged: {
      this.state.toastMessage = ''
      this.state.newField = action.newField
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.newFieldAdded: {
      this.state.newField = emptyField
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.newFieldCanceled: {
      this.state.newField = null
      this.state.customFieldErrors.delete('fieldName')
      this.state.customFieldErrors.delete('fieldTag')
      this.state.customFieldErrors.delete('fieldExternalCode')
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.newFieldSubmitted: {
      this.state.toastMessage = ''
      if (!this.isFieldNameEntered()) this.state.customFieldErrors.set('fieldName', 'Name is required')
      if (!this.isFieldNameUnique()) this.state.customFieldErrors.set('fieldName', 'Name already exists')
      if (!this.isFieldTagEntered()) this.state.customFieldErrors.set('fieldTag', 'Tag is required')
      if (!this.isFieldTagUnique()) this.state.customFieldErrors.set('fieldTag', 'Tag already exists')
      if (!this.isFieldTagAvailable()) this.state.customFieldErrors.set('fieldTag', 'Tag is reserved')
      if (!this.isFieldCodeEntered()) this.state.customFieldErrors.set('fieldExternalCode', 'Jira code is required')
      if (!this.isFieldCodeUnique()) this.state.customFieldErrors.set('fieldExternalCode', 'Jira code already exists')
      if (this.state.customFieldErrors.size === 0) {
        this.state.customFields.push(this.state.newField)
        this.state.newField = null
        this.validate()
        this.state.unsavedChanges = true
      }
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.newFieldDeleted: {
      this.state.customFields = this.state.customFields.filter(it => it.name !== action.field)
      this.checkFieldSettings()
      this.state.unsavedChanges = true
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.submitted: {
      this.state.toastMessage = ''
      if (!this.isUrlValid()) this.state.settingErrors.set('baseUrl', 'Url cannot be empty')
      if (!this.isAuthTokenValid()) this.state.settingErrors.set('authToken', 'Authentication token cannot be empty')
      if (!this.isTimeoutValid()) this.state.settingErrors.set('socketTimeoutMillis', 'Timeout must be an number')
      if (!this.isJiraProjectValid()) this.state.settingErrors.set('jiraProjectKey', 'Jira project cannot be empty')
      if (!this.isJiraVersionValid()) this.state.settingErrors.set('jiraVersionName', 'Product version cannot be empty')
      this.checkFieldSettings()
      if (this.state.settingErrors.size > 0 || this.state.customFieldErrors.size > 0) {
        this.emitChange()
      } else {
        this.state.newField = null
        action.save({ ...this.state.settings, customFields: this.state.customFields })
      }
      break
    }
    case events.configurationJiraSettings.saved: {
      this.state.toastMessage = 'Settings were saved successfully.'
      this.state.unsavedChanges = false
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    case events.configurationJiraSettings.submittedForTest: {
      this.state.toastMessage = ''
      action.execute()
      break
    }
    case events.configurationJiraSettings.testResultsReceived: {
      this.state.settingErrors.clear()
      this.state.missingCustomFields = action.testErrors.missingCustomFields
      if (action.testErrors.message) {
        this.state.toastMessage = action.testErrors.message
      } else if (action.testErrors.authenticationFailed) {
        this.state.settingErrors.set('authToken', 'Authentication failed')
        this.state.toastMessage = 'Please make sure authentication token is correct.'
      } else if (action.testErrors.projectIsMissing) {
        this.state.settingErrors.set('jiraProjectKey', 'Project not found on Jira')
        this.state.toastMessage = 'Please make sure jira project key and authentication token are correct.'
      } else if (action.testErrors.versionIsMissing) {
        this.state.settingErrors.set('jiraVersionName', 'Version not found on Jira')
        this.state.toastMessage = 'Please make sure jira product version is correct.'
      } else if (action.testErrors.missingCustomFields.length > 0) {
        this.state.toastMessage = 'Please add required custom fields.'
      } else {
        this.state.toastMessage = 'Connection and configuration tests were successful.'
      }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new JiraSettingsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
