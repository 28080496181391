import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const emptyYoutrack = {
  instanceName: '',
  baseUrl: '',
  username: '',
  password: '',
  state: '',
  customerState: '',
  testCaseExecution: '',
  testCaseId: '',
}
class YoutrackSettingsStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      settings: [],
      addMode: false,
      editMode: false,
      selectedYoutrack: emptyYoutrack,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.youtrackSettings.listReceived: {
      this.state.settings = action.list.sort((a, b) => a.instanceName.toLowerCase().localeCompare(b.instanceName.toLowerCase()))
      this.state.loading = false
      this.state.addMode = false
      this.state.editMode = false
      this.state.updated = false
      this.emitChange()
      break
    }
    case events.youtrackSettings.failed: {
      this.state.error = action.error
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.youtrackSettings.saved:
    case events.youtrackSettings.updated: {
      this.state.updated = true
      this.state.loading = true
      this.state.selectedYoutrack = emptyYoutrack
      this.emitChange()
      break
    }
    case events.youtrackSettings.closePanel: {
      this.state.selectedYoutrack = emptyYoutrack
      this.state.addMode = false
      this.state.editMode = false
      this.emitChange()
      break
    }
    case events.youtrackSettings.startAdd: {
      this.state.selectedYoutrack = emptyYoutrack
      this.state.addMode = true
      this.state.invalidInput = false
      this.emitChange()
      break
    }
    case events.youtrackSettings.startEdit: {
      this.state.selectedYoutrack = action.youtrack
      this.state.editMode = true
      this.state.invalidInput = false
      this.emitChange()
      break
    }
    case events.youtrackSettings.invalidInput: {
      this.state.invalidInput = true
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const youtrackSettingsStore = new YoutrackSettingsStore()
dispatcher.register(youtrackSettingsStore.handleActions.bind(youtrackSettingsStore))

export default youtrackSettingsStore
