import { EventEmitter } from 'events'


class ReportingEventEmitter extends EventEmitter {
  constructor() {
    super()
    this.state = this.getInitialState()
  }

  emitChange = () => {
    this.emit('change')
  }

  getState() {
    return this.state
  }

  getInitialState() {
    return {}
  }

  resetState = (project) => {
    this.state = this.getInitialState(project)
    return this.state
  }
}

export default ReportingEventEmitter
