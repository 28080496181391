import { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { VictoryBar, VictoryChart, VictoryStack, VictoryTooltip } from 'victory'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { getData, getCount } from './actions'
import { initialState, reducer } from './reducer'
import style from './approvalOverview.module.css'


const inlineStyle = {
  periodSelection: { float: 'right', width: '140px', marginTop: '10px' },
  rightAlign: { textAlign: 'right' },
  chart: { parent: { maxWidth: '600px', margin: '0 auto' } },
}

const colorScale = ['#f7941e', '#3c8863', '#8c8c8e']

const ApprovalOverview = ({ match }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  useEffect(() => {
    getData(projectCode)
      .then(data => dispatch({ type: 'received', data }))
      .catch(errorHandler)
    getCount(projectCode)
      .then(({ totalSize }) => dispatch({ type: 'countReceived', count: totalSize }))
      .catch(errorHandler)
  }, [errorHandler, projectCode])
  const {
    loading, noApprovals, chart, categories, newCount,
  } = state
  return (
    <Card className={style.paperChartContainer}>
      <CardHeader title="Approvals overview" subheader="Last week" />
      <Spinner spinning={loading}>
        <div>
          {noApprovals && (
            <div className={style.noApprovals}>
              <a>No approvals from last week!</a>
            </div>
          )}
          {!noApprovals && (
            <VictoryChart domainPadding={{ x: 50 }} width={800} style={inlineStyle.chart}>
              <VictoryStack categories={{ x: categories }} colorScale={colorScale}>
                {chart.map(it => (
                  <VictoryBar key={it.key} data={it.data} labelComponent={<VictoryTooltip />} />))}
              </VictoryStack>
            </VictoryChart>
          )}
          <div>
            {newCount === 0 && <p>No executions ready for approval.</p> }
            {newCount === 1 && <p>You have <Link to={`/${match.params.projectCode}/approval`}>an execution</Link> to approve.</p> }
            {newCount > 1 && <p>You have <Link to={`/${match.params.projectCode}/approval`}>{newCount} executions</Link> to approve.</p> }
          </div>
        </div>
      </Spinner>
    </Card>
  )
}

ApprovalOverview.propTypes = { match: PropTypes.shape({ params: PropTypes.object }).isRequired }

export default withRouter(ApprovalOverview)
