import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'

import style from './settings.module.css'


const Settings = ({ settings, errors, statusList, onChange }) => (
  <div className="col-md-6">
    <CardHeader title="Jira Settings" />
    <CardContent className={style.settingsContent}>
      <FormControlLabel
        label="Enabled"
        control={<Checkbox checked={settings.enabled} onChange={e => onChange({ ...settings, enabled: e.target.checked })} />}
      />
      <FormControlLabel
        label="Auto upload"
        control={<Checkbox checked={settings.autoUpload} onChange={e => onChange({ ...settings, autoUpload: e.target.checked })} />}
      />
      <DataField name="Url">
        <TextField
          id="urlField"
          fullWidth
          value={settings.baseUrl}
          label={errors.get('baseUrl')}
          error={errors.has('baseUrl')}
          onChange={e => onChange({ ...settings, baseUrl: e.target.value })}
        />
      </DataField>
      <DataField name="Authentication Token">
        <TextField
          id="authTokenField"
          fullWidth
          value={settings.authToken}
          label={errors.get('authToken')}
          error={errors.has('authToken')}
          placeholder="e.g. Basic cfF1bG1hcmtlfwerGge2ce=="
          onChange={e => onChange({ ...settings, authToken: e.target.value })}
        />
      </DataField>
      <DataField name="Jira Project">
        <TextField
          id="jiraProjectField"
          fullWidth
          value={settings.jiraProjectKey}
          label={errors.get('jiraProjectKey')}
          error={errors.has('jiraProjectKey')}
          onChange={e => onChange({ ...settings, jiraProjectKey: e.target.value })}
        />
      </DataField>
      <DataField name="Product Version">
        <TextField
          id="jiraVersionField"
          fullWidth
          value={settings.jiraVersionName}
          label={errors.get('jiraVersionName')}
          error={errors.has('jiraVersionName')}
          onChange={e => onChange({ ...settings, jiraVersionName: e.target.value })}
        />
      </DataField>
      <DataField name="Custom Field: Cycle">
        <TextField
          id="cycleFieldField"
          fullWidth
          value={settings.cycleField}
          label={errors.get('cycleField')}
          error={errors.has('cycleField')}
          onChange={e => onChange({ ...settings, cycleField: e.target.value })}
        />
      </DataField>
      <DataField name="Custom Field: Product">
        <TextField
          id="productFieldField"
          fullWidth
          value={settings.productField}
          label={errors.get('productField')}
          error={errors.has('productField')}
          onChange={e => onChange({ ...settings, productField: e.target.value })}
        />
      </DataField>
      <DataField name="Approved Status">
        <Select
          id="approvedStatusField"
          fullWidth
          value={statusList.length === 0 ? '' : settings.approvedStatus}
          disabled={statusList.length === 0}
          onChange={e => onChange({ ...settings, approvedStatus: e.target.value })}
        >
          <MenuItem key="default_key" value="">Not Selected</MenuItem>
          {statusList.map(status => <MenuItem key={status} value={status}>{status}</MenuItem>)}
        </Select>
      </DataField>
      <DataField name="Socket Timeout (milliseconds)">
        <TextField
          id="socketTimeoutMillisField"
          fullWidth
          value={settings.socketTimeoutMillis}
          label={errors.get('socketTimeoutMillis')}
          error={errors.has('socketTimeoutMillis')}
          onChange={e => onChange({ ...settings, socketTimeoutMillis: e.target.value })}
        />
      </DataField>
    </CardContent>
  </div>
)

Settings.propTypes = {
  errors: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
  statusList: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Settings
