import { Component } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import EditRow from './editRow'
import TestCategoriesStore from './store'
import * as Actions from './actions'
import style from './testCategories.module.css'


class TestCategories extends Component {
  state = TestCategoriesStore.getState()

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Test Category Configuration'
    TestCategoriesStore.on('change', this.updateState)
    Actions.retrieveData(projectCode)
  }

  componentWillUnmount = () => {
    TestCategoriesStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(TestCategoriesStore.getState())
  }

  handleSave = (category) => {
    const { projectCode } = this.context
    Actions.saveTestCategory(category, projectCode)
  }

  render() {
    const { projectCode } = this.context
    const {
      error, loading, list, selectedRow, addCategory,
    } = this.state
    if (error) {
      throw Error(error)
    }

    return (
      <Spinner spinning={loading}>
        <Card>
          <CardHeader title="Test Categories" />
          <div className={style.wrapper}>
            <div key="header" className={style.headerRow}>
              <div className={style.nameColumn}>Name</div>
              <div className={style.tagColumn}>Tag</div>
              <div className={style.buttonColumn} />
            </div>
            {list.map(item => (item.id === selectedRow ? (
              <EditRow
                key={item.id}
                testCategory={item}
                onSubmit={this.handleSubmit}
                onSave={this.handleSave}
                onCancel={Actions.handleCancel}
              />
            )
              : (
                <div key={item.id} className={style.categoryRow}>
                  <div className={style.nameColumn}>{item.name}</div>
                  <div className={style.tagColumn}>{item.tag}</div>
                  <div className={style.buttonColumn}>
                    <IconButton onClick={() => Actions.handleDelete(projectCode, item.id)} src="/images/delete.svg" />
                    <IconButton onClick={() => Actions.handleEdit(item.id)} src="/images/edit.svg" />
                  </div>
                </div>
              )))}
            { addCategory && (
              <EditRow
                key="new"
                onSubmit={this.handleSubmit}
                onSave={this.handleSave}
                onCancel={Actions.handleCancel}
              />
            )}
          </div>
          { !addCategory && (
            <div className={style.wrapper}>
              <IconButton onClick={Actions.handleAdd} src="/images/add.svg" />
            </div>
          )}
        </Card>
      </Spinner>
    )
  }
}

TestCategories.contextType = ProjectContext

export default TestCategories
