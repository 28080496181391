import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import DataField from 'components/executionDetails/dataField'


const ProjectSettings = ({ settings, onChange }) => (
  <div className="col-md-6">
    <CardHeader title="Project Settings" />
    <CardContent>
      <DataField name="CSV Separator">
        <Select
          id="projectSettingsCsvSeparator"
          value={settings.csvSeparator}
          onChange={e => onChange({ ...settings, csvSeparator: e.target.value === 't' ? '\t' : e.target.value })}
        >
          <MenuItem value=";">Semicolon</MenuItem>
          <MenuItem value=",">Comma</MenuItem>
          <MenuItem value="t">Tab</MenuItem>
        </Select>
      </DataField>
    </CardContent>
  </div>
)

ProjectSettings.propTypes = {
  settings: PropTypes.shape({ csvSeparator: PropTypes.string }).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ProjectSettings
