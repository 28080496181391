import { useState } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.css'

const inlineStyle = {
  visible: {
    visibility: 'visible',
    opacity: '0.3',
  },
  hidden: {
    visibility: 'hidden',
  },
}

const Pin = ({ onToggle, checked }) => {
  const [hovered, setHovered] = useState(false)
  const renderChecked = () => (
    <span
      role="switch"
      aria-checked="true"
      tabIndex={0}
      className={style.buttonRole}
      onClick={() => onToggle(false)}
      onKeyPress={() => onToggle(false)}
    >
      <img src="/images/pin.svg" alt="pinned" className={style.icon} />
    </span>
  )

  const renderUnchecked = () => (
    <span
      role="switch"
      aria-checked="false"
      tabIndex={0}
      className={style.buttonRole}
      onClick={() => onToggle(true)}
      onKeyPress={() => onToggle(true)}
      onMouseOver={() => setHovered(true)}
      onFocus={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onBlur={() => setHovered(false)}
    >
      <img
        src="/images/pin.svg"
        alt="pinned"
        className={style.icon}
        style={hovered ? inlineStyle.visible : inlineStyle.hidden}
      />
    </span>
  )

  return checked ? renderChecked() : renderUnchecked()
}

Pin.propTypes = {
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default Pin
