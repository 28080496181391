import { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import IconButton from 'components/iconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import style from './excelImportSettings.module.css'

const emptyRow = { field: '', column: '', enableAggregates: true }

class SimpleRow extends Component {
  state = {
    newRow: { ...emptyRow },
  }

  handleAggregateCheck = (e) => {
    if (e.target.checked) {
      this.setState(prevState => ({ newRow: { field: prevState.newRow.field, aggregates: (this.props.row || {}).aggregates || [] } }))
    } else {
      this.setState(prevState => ({ newRow: { field: prevState.newRow.field, column: '' } }))
    }
  }

  handleEdit = () => {
    this.setState({ newRow: { ...this.props.row } })
    this.props.onEdit(this.props.row.field)
  }

  handleSave = () => {
    this.props.onSave(this.state.newRow)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  handleChange = (field, value) => {
    this.setState(prevState => ({ newRow: { ...prevState.newRow, [field]: value } }))
  }

  render() {
    if (this.props.edit) {
      return (
        <form onSubmit={this.handleSubmit}>
          <div className={style.configRow}>
            <div className={style.nameColumn}>
              { this.props.row ? this.props.row.field : (
                <FormControl fullWidth>
                  <InputLabel>Field name</InputLabel>
                  <Select
                    id="fieldField"
                    fullWidth
                    value={this.state.newRow.field}
                    onChange={e => this.handleChange('field', e.target.value)}
                  >
                    {this.props.fields.map(field => (
                      <MenuItem key={field} value={field}>{field}</MenuItem>))}
                  </Select>
                </FormControl>
              )}
            </div>
            <div className={style.fieldColumn}>
              { this.state.newRow.aggregates == null && (
                <TextField
                  id="columnField"
                  fullWidth
                  label="Excel column header"
                  value={this.state.newRow.column}
                  onChange={e => this.handleChange('column', e.target.value)}
                />
              )}
            </div>
            <div className={style.aggregateColumn}>
              <Checkbox
                id="aggregateField"
                checked={this.state.newRow.aggregates != null}
                onChange={this.handleAggregateCheck}
              />
            </div>
            <div className={style.buttonColumn}>
              <IconButton id="acceptBtn" src="/images/accept.svg" onClick={this.handleSave} />
              <IconButton id="cancelBtn" src="/images/cancel.svg" onClick={this.props.onCancel} />
            </div>
          </div>
        </form>
      )
    }
    return (
      <div className={style.configRow}>
        <div className={style.nameColumn}>{this.props.row.field}</div>
        <div className={style.fieldColumn}>{this.props.row.column}</div>
        <div className={style.aggregateColumn}>
          <Checkbox checked={this.props.row.aggregates != null} disabled />
        </div>
        <div className={style.buttonColumn}>
          {this.props.row.aggregates && (
            <IconButton onClick={() => this.props.onAddPart(this.props.row.field)} src="/images/add.svg" />)}
          <IconButton onClick={() => this.props.onDelete(this.props.row.field)} src="/images/delete.svg" />
          <IconButton onClick={this.handleEdit} src="/images/edit.svg" />
        </div>
      </div>
    )
  }
}

SimpleRow.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string),
  row: PropTypes.shape({ field: PropTypes.string, column: PropTypes.string, aggregates: PropTypes.array }),
  edit: PropTypes.bool.isRequired,
  onAddPart: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

SimpleRow.defaultProps = {
  row: null,
  fields: [],
  onAddPart: () => {},
  onEdit: () => {},
  onDelete: () => {},
}

export default SimpleRow
