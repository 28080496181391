import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import FormattedDate from 'components/formattedDate'
import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import Table from 'components/table'

const JiraUploadsTable = ({ list }) => {
  const columns = useMemo(() => [
    {
      Header: 'Test',
      accessor: 'testName',
      Cell: item => (
        <Link to={`${item.row.original.executionId}/${item.row.original.testIndex}`} title={item.value}>
          {item.value}
        </Link>
      ),
    },
    {
      Header: 'Upload time',
      accessor: 'uploadTime',
      Cell: item => <FormattedDate value={item.value} />,
      width: 140,
    },
    {
      Header: 'Issue',
      accessor: 'executionIssueLink',
      Cell: item => (
        item.value
          ? <a href={item.value} target="_blank" rel="noopener noreferrer">{item.row.original.executionIssue}</a>
          : item.row.original.executionIssue || ''
      ),
      width: 64,
    },
    {
      Header: 'Result',
      accessor: 'uploadIcon',
      width: 70,
    },
  ], [])
  const data = useMemo(() => (
    list.map((item) => {
      const uploadIcon = item.errorMessage ? (<UploadFailedIcon message={item.errorMessage} />) : <UploadSuccessfulIcon />
      return { ...item, uploadIcon }
    })
  ), [list])

  return (
    <Table
      id="jiraUploads"
      columns={columns}
      data={data}
    />
  )
}

JiraUploadsTable.propTypes = { list: PropTypes.arrayOf(PropTypes.object) }
JiraUploadsTable.defaultProps = { list: [] }

export default JiraUploadsTable
