import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import style from './fields.module.scss'

const FieldWithTag = ({
  fields, field, name, tag, onChange,
}) => (
  <DataField name={name}>
    <Select
      id="fieldField"
      name="field"
      value={field}
      className={style.field}
      disabled={fields.length === 0}
      onChange={e => onChange(e.target.value, tag)}
    >
      <MenuItem key="default_key" value="">Not Selected</MenuItem>
      {fields.map(f => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
    </Select>
    <TextField
      id="nameField"
      name="tag"
      value={tag}
      label="Metadata tag"
      className={style.tag}
      onChange={e => onChange(field, e.target.value)}
    />
  </DataField>
)

FieldWithTag.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  field: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tag: PropTypes.string,
  name: PropTypes.string.isRequired,
}

FieldWithTag.defaultProps = {
  tag: '',
  field: '',
}

export default FieldWithTag
