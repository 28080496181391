import startsWith from 'lodash/startsWith'
import moment from 'moment'

const eventmap = {
  PhoneAcquired: 'phone',
  PhoneConnected: 'phone',
  PhoneDisconnected: 'phone',
  CallInitiated: 'phone',
  SmsReceived: 'email',
  SmsSent: 'email',
  UrlOpened: 'computer',
}

function formatDatePattern(date, pattern) {
  if (!date) {
    return ''
  }
  return moment(date).format(pattern)
}

export function formatDate(dateString) {
  return formatDatePattern(dateString, 'DD.MM.YYYY, HH:mm:ss')
}

export function formatDateTimeZone(dateString) {
  return moment.parseZone(dateString).format('DD.MM.YYYY, HH:mm:ss Z')
}

export function formatDateNoSeconds(dateString) {
  return formatDatePattern(dateString, 'DD.MM.YYYY, HH:mm')
}

export function formatDateISO(date) {
  return formatDatePattern(date, 'YYYY-MM-DDTHH:mm:ss')
}

export function formatDateISOZ(date) {
  return formatDatePattern(date, 'YYYY-MM-DDTHH:mm:ssZ')
}

export function formatDuration(seconds) {
  return moment.duration(seconds, 'seconds').humanize()
}

export function findImageForEvent(type) {
  const event = eventmap[type]
  if (event == null) {
    return 'assignment'
  }
  return event
}

export function parseQuery(qstr) {
  const query = {}
  const a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&').filter(it => it.length >= 2)
  for (let i = 0; i < a.length; i += 1) {
    const b = a[i].split('=')
    query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '')
  }
  return query
}

export function isRegexValid(regex) {
  try {
    RegExp(regex)
    return true
  } catch (e) {
    return false
  }
}

export function downloadFile(filename, data, element) {
  const url = window.URL.createObjectURL(data)
  const tempLink = document.createElement('a')
  tempLink.href = url
  tempLink.setAttribute('download', filename)
  tempLink.setAttribute('target', '_blank')

  const container = element || document.body
  container.appendChild(tempLink)
  tempLink.click()
  container.removeChild(tempLink)
  window.URL.revokeObjectURL(url)
}

export function extractMessage(error) {
  if (error.response && error.response.status === 404) {
    return 'Request failed with status code 404'
  }
  if (error.response && error.response.data && error.response.data.message) {
    return error.response.data.message
  }
  if (error.response && error.response.data) {
    try {
      const errorJson = JSON.parse(error.response.data)
      if (errorJson.message) {
        return errorJson.message
      }
    } catch (e) {
      return error.message
    }
  }
  return error.message
}

export function isHtml(mimeType) {
  return mimeType === 'text/html'
}

export function isText(mimeType) {
  const textTypes = ['application/json', 'application/report']
  return (startsWith(mimeType, 'text') || textTypes.includes(mimeType)) && !isHtml(mimeType)
}

export function canPreview(mimeType) {
  return startsWith(mimeType, 'image/') || isText(mimeType) || isHtml(mimeType) || startsWith(mimeType, 'audio/')
}

export function createQueryString(params) {
  return Object.keys(params)
    .map(k => ({ name: k, value: params[k] }))
    .filter(a => a.value)
    .map(a => `${encodeURIComponent(a.name)}=${encodeURIComponent(a.value)}`)
    .join('&')
}

export const hpqcStatuses = [
  { id: null, name: 'Default' },
  { id: 'FAILED', name: 'Failed' },
  { id: 'PASSED', name: 'Passed' },
  { id: 'NOT_APPLICABLE', name: 'N/A' },
  { id: 'NOT_COMPLETED', name: 'Not Completed' },
  { id: 'BLOCKED', name: 'Blocked' },
  { id: 'BLOCKED_EXECUTED', name: 'Blocked - Executed' },
  { id: 'EXEMPTED', name: 'Exempted' },
  { id: 'RESTRICTED', name: 'Restricted' },
]
