import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import find from 'lodash/find'
import some from 'lodash/some'
import Icon from '@mui/material/Icon'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import useProject from 'hooks/useProject'

import style from './header.module.scss'


export const getLinks = ({ projectCode, postProcessEnabled, roles }) => {
  const isAdmin = roles.includes('ROLE_ADMIN')
  const isTestManager = roles.includes('ROLE_TEST_MANAGER')
  const defaultLinks = [
    {
      label: 'Dashboard',
      icon: <Icon>dashboard</Icon>,
      to: `/${projectCode}/`,
      value: 'DASHBOARD',
      patterns: [],
    },
    {
      label: 'Reports',
      icon: <Icon>insert_chart</Icon>,
      to: `/${projectCode}/reports/test-run-report`,
      value: 'REPORTS',
      patterns: [`/${projectCode}/reports`, `/${projectCode}/detail`, `/${projectCode}/pmsExportDetail`],
    },
    {
      label: 'Test Suites',
      icon: <Icon>insert_chart</Icon>,
      to: `/${projectCode}/test-suite/list`,
      value: 'TEST_SUITE',
      patterns: [`/${projectCode}/test-suite`],
    },
  ]

  if (isTestManager) {
    defaultLinks.push({
      label: 'Configuration',
      icon: <Icon>settings_applications</Icon>,
      to: `/${projectCode}/configuration/project`,
      value: 'CONFIG',
      patterns: [`/${projectCode}/configuration`],
    })
  }

  if (postProcessEnabled && isTestManager) {
    defaultLinks.push({
      label: 'Approval',
      icon: <Icon>settings_applications</Icon>,
      to: `/${projectCode}/approval`,
      value: 'APPROVAL',
      patterns: [`/${projectCode}/approval`],
    })
  }

  if (postProcessEnabled) {
    defaultLinks.push({
      label: 'Verification',
      icon: <Icon>insert_chart</Icon>,
      to: `/${projectCode}/verification`,
      value: 'VERIFICATION',
      patterns: [`/${projectCode}/verification`],
    })
  }

  if (isAdmin) {
    defaultLinks.push({
      label: 'Administration',
      icon: <Icon>settings_applications</Icon>,
      to: '/administration/projects',
      value: 'ADMIN',
      patterns: ['/administration'],
    })
  }

  const currentLink = find(defaultLinks, it => some(it.patterns, p => window.location.pathname.startsWith(p)))?.value || 'DASHBOARD'

  return { links: defaultLinks, currentLink }
}

const LinkTab = props => (
  <Tab
    component={Link}
    {...props}
  />
)

const TabBar = () => {
  const { roles, postProcessEnabled, projectCode } = useProject()
  const { links: tabs, currentLink } = getLinks({ projectCode, postProcessEnabled, roles })

  return (
    <div className={style.tabBar}>
      <Tabs
        scrollButtons="auto"
        variant="scrollable"
        value={currentLink}
      >
        {tabs.map(i => <LinkTab {...i} key={i.value} />)}
      </Tabs>
    </div>
  )
}

TabBar.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
}

export default withRouter(TabBar)
