import { memo } from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'

import styles from './style.module.css'


const DefectsColumn = ({ defects, className, style }) => (
  <div
    className={className}
    style={style}
    data-class={styles.errorCategoryTooltip}
  >
    { defects.map(defect => (
      <Chip key={defect.id} label={defect.id} size="small" />
    ))}
  </div>
)

DefectsColumn.propTypes = {
  defects: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  style: PropTypes.shape({ backgroundColor: PropTypes.string }),
}

DefectsColumn.defaultProps = {
  defects: [],
  className: null,
  style: {},
}

export default memo(DefectsColumn)
