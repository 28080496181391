import { dispatcher, events } from 'core/dispatcher'
import * as Utils from 'components/utils'


export function handleAuthenticationFailure(error) {
  if (error.response && error.response.status === 401) {
    dispatcher.dispatch({ type: events.session.authenticationFailed })
    return Promise.reject(new Error('Authentication failed.'))
  }
  return null
}

export function catchError(error) {
  const authFailure = handleAuthenticationFailure(error)
  if (authFailure) return authFailure
  return Promise.reject(Utils.extractMessage(error))
}

export function getErrorCode(error) {
  if (error.response && error.response.data) {
    return error.response.data.errorCode
  }
  return null
}

export const DEFAULT_TIMEOUT = 60000

export const requestOptions = {
  timeout: DEFAULT_TIMEOUT,
}
