import axios from 'axios'
import * as Common from 'services/common'

class QonformService {
    isEnabled = (project) => {
      const url = `/api/module/qonform/${project}/settings/enabled`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    retrieveSettings = (project) => {
      const url = `/api/module/qonform/${project}/settings`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    updateSettings = (project, settings) => {
      const request = {
        ...settings,
        qonformServiceEnabled: settings.qonformServiceEnabled || false,
        allowSelfSignedCertificates: settings.allowSelfSignedCertificates || false,
      }
      const url = `/api/module/qonform/${project}/settings`
      return axios.post(url, request, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }
}

const instance = new QonformService()
export default instance
