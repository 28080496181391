import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import get from 'lodash/get'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import Spinner from 'components/spinner'
import * as Utils from 'components/utils'
import TestStatusIcon from 'components/testStatusIcon'
import WarningIcons from 'components/reports/testRunReport/widgets/defectIcon'
import Title from 'components/title'
import TrimmedText from 'components/trimmedText'
import TextBoxWithSubmit from 'components/textBoxWithSubmit'
import { UploadSuccessfulIcon, UploadFailedIcon } from 'components/icons'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'

import CreateTestInstanceButton from './createTestInstanceButton'
import TestPlanEditor from './testPlanEditor'
import * as Actions from './actions'
import style from './style.module.css'
import TestSuiteDetailsStore from './store'

const inlineStyle = {
  icon: { marginRight: '12px' },
  warningIcon: { width: '24px', height: '24px' },
}

class TestSuiteDetails extends Component {
  constructor(props) {
    super(props)
    this.state = TestSuiteDetailsStore.getState()
  }

  componentDidMount = () => {
    const { projectCode, hpqcEnabled } = this.context
    const { match } = this.props
    document.title = 'QiTASC ConQlude - Test Suite Details'
    TestSuiteDetailsStore.on('change', this.updateState)
    Actions.retrieveTestSuiteDetails(match.params.testSuiteId, projectCode)
    if (hpqcEnabled) {
      Actions.getTestPlan({ testSuiteId: match.params.testSuiteId, project: projectCode })
    }
    ReactTooltip.hide()
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    TestSuiteDetailsStore.removeListener('change', this.updateState)
  }

  onTicketIdSubmit = (e) => {
    e.preventDefault()
    this.handleSaveTicketId()
  }

  updateState = () => {
    this.setState(TestSuiteDetailsStore.getState())
  }

  handleOpen = () => {
    Actions.setOpenErrorMessage(true)
  }

  handleClose = () => {
    Actions.setOpenErrorMessage(false)
  }

  handleChangeTicketId = (e) => {
    Actions.modifyTicketId(e.target.value)
  }

  handleSaveTicketId = () => {
    const { projectCode } = this.context
    const { match } = this.props
    const { ticketId } = this.state
    Actions.saveTicketId(ticketId, match.params.testSuiteId, projectCode)
  }

  render() {
    const { projectCode, roles, hpqcEnabled } = this.context
    const { match } = this.props
    const {
      error, loading, ticketId, showTicketWarning, loadingTestPlan, hpqcError, loadingTicket, ticketData,
      testPlan, testSuite, showEditButtons, showYouTrackButton, executions, openErrorMessage, showTestPlanDialog, customFields,
    } = this.state

    if (error != null) {
      return TestSuiteDetails.renderError(error)
    }

    const { testSuiteId } = match.params
    const {
      lastStatus, name, lastPassedTime, lastFailureTime, lastExecutionTime, lastErrorCategory, lastErrorMessage,
    } = testSuite
    const columns = [
      {
        Header: 'Status',
        accessor: 'result',
        Cell: item => <TestStatusIcon status={item.value} />,
      },
      {
        Header: 'Name',
        accessor: 'testId',
        Cell: item => (
          <>
            <Link to={`/${match.params.projectCode}/detail/${item.value}`}>
              {item.row.original.testName}
            </Link>
            <WarningIcons defects={item.row.original.defects} warnings={item.row.original.warnings} />
          </>
        ),
      },
      {
        Header: 'Tester',
        accessor: 'tester',
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        Cell: item => Utils.formatDate(item.value),

      },
      {
        Header: 'Duration',
        accessor: 'duration',
        Cell: item => Utils.formatDuration(item.value),
      },
    ]
    const isTestManager = roles.includes('ROLE_TEST_MANAGER')

    return (
      <Spinner spinning={loading}>
        <Paper className={style.paper} zdepth={1} sx={{ overflow: 'auto' }}>
          <div className={style.titleContainer}>
            <Title
              status={lastStatus}
              name={name}
              onEditingSubmit={value => Actions.updateName({ testSuiteId, name: value })}
            />
            <div className={style.iconBar}>
              <Link to={`/${match.params.projectCode}/reports/test-run-report?testSuiteId=${testSuiteId}`}>
                <i className={`material-icons ${style.actionIcon}`}>search</i>
              </Link>
              {showTicketWarning && (
                <img src="/images/testplanwarning.svg" style={inlineStyle.warningIcon} alt="Test plan missing" />)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <DataField name="Last Execution Time" value={lastExecutionTime ? Utils.formatDate(lastExecutionTime) : 'N/A'} />
              <DataField name="Last Passed Time" value={lastPassedTime ? Utils.formatDate(lastPassedTime) : 'N/A'} />
              <DataField name="Last Failure Time" value={lastFailureTime ? Utils.formatDate(lastFailureTime) : 'N/A'} />
              <DataField name="Last Error Message">
                {lastErrorMessage && (
                  <span className="link" onClick={this.handleOpen} onKeyPress={this.handleOpen} role="link" tabIndex={0}>
                    <TrimmedText maxLength={50} text={lastErrorMessage} showTooltip={false} />
                  </span>
                )}
                {!lastErrorMessage && (
                  <div>N/A</div>)}
              </DataField>
              {lastErrorCategory && (
                <DataField name="Last Error Category" value={lastErrorCategory} />) }
            </div>
            <div className="col-md-4">
              { hpqcEnabled && (
                <Spinner spinning={loadingTestPlan}>
                  <DataField name="HPQC Test Plan Id">
                    <TextBoxWithSubmit
                      defaultText={get(testPlan, 'testPlanId') || ''}
                      minRows={1}
                      maxRows={1}
                      onClick={testPlanId => Actions.updateTestPlanId(testSuiteId, testPlanId, projectCode)}
                      errorText={hpqcError}
                    />
                  </DataField>
                  <DataField name="Test Plan Description" id="planDescription">
                    <TextField
                      id="textBoxWithSubmit"
                      multiline
                      rows={3}
                      disabled
                      value={get(testPlan, 'description') || ''}
                    />
                  </DataField>
                  { isTestManager && (
                    <div className={style.hpqcButtons}>
                      <Button variant="contained" onClick={Actions.handleEditTestPlanDetails}>Edit Test Plan Details</Button>
                      <Button variant="contained" onClick={() => Actions.createTestPlan({ testSuiteId, project: projectCode })}>
                        {(testPlan || {}).testPlanId != null ? 'Update Test Plan' : 'Create Test Plan'}
                      </Button>
                      <CreateTestInstanceButton
                        disabled={(testPlan || {}).testPlanId == null}
                        onSubmit={testSetId => Actions.createTestInstance({ testSuiteId, testSetId, project: projectCode })}
                      />
                    </div>
                  )}
                </Spinner>
              )}
            </div>
            <div className="col-md-4">
              <Spinner spinning={loadingTicket}>
                <div>
                  <DataField name="YouTrack Ticket Number">
                    <form onSubmit={this.onTicketIdSubmit} className={style.ticketIdForm}>
                      <TextField
                        value={ticketId}
                        id="TicketIdField"
                        className={style.ticketIdField}
                        label={ticketData.error}
                        error={ticketData.error != null}
                        onChange={this.handleChangeTicketId}
                      />
                    </form>
                    {showEditButtons && (
                      <span>
                        <IconButton onClick={this.handleSaveTicketId}>
                          <UploadSuccessfulIcon />
                        </IconButton>
                        <IconButton onClick={Actions.resetTicketId}>
                          <UploadFailedIcon />
                        </IconButton>
                      </span>
                    )}
                    {showYouTrackButton && (
                      <a href={`https://youtrack.qvie.qitasc.com/issue/${ticketId}`} rel="noopener noreferrer" target="_blank">
                        <i className={`material-icons ${style.openYoutrackButton}`}>open_in_new</i>
                      </a>
                    )}
                  </DataField>
                  <DataField name="YouTrack Ticket State" value={ticketData.state} />
                  <DataField name="YouTrack TC Execution State" value={ticketData.tcExecutionState} />
                </div>
              </Spinner>
            </div>
          </div>

          {executions && (
            <h4 className={style.executionsTitle}>Last Executions</h4>)}
          {executions && (
            <Table
              id="testSuiteDetails"
              columns={columns}
              data={executions}
              className={style.executionsTable}
            />
          )}

          <Dialog open={openErrorMessage} maxWidth="lg" onClose={this.handleClose}>
            <DialogTitle>Error Message</DialogTitle>
            <DialogContent className={style.whiteSpace}>{lastErrorMessage}</DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose}>Ok</Button>
            </DialogActions>
          </Dialog>
          { showTestPlanDialog && (
            <TestPlanEditor
              testPlan={testPlan}
              customFields={customFields}
              onSubmit={plan => Actions.updateTestPlan({ testSuiteId, testPlan: plan, project: projectCode })}
              onCancel={Actions.handleCloseTestPlanDetails}
            />
          )}
        </Paper>
      </Spinner>
    )
  }
}

TestSuiteDetails.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

TestSuiteDetails.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

TestSuiteDetails.contextType = ProjectContext

export default withRouter(TestSuiteDetails)
