import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const DEFAULT_PAGE_SIZE = 100

class UnassignedReportsStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      items: [],
      reportsToMove: [],
      loading: true,
      projectToMove: null,
      openMoveToProjectDialog: false,
      defaultPageSize: DEFAULT_PAGE_SIZE,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.unassignedReport.listReceived: {
      this.state.totalSize = action.list.totalSize
      this.state.items = action.list.items || []
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.unassignedReport.failed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.unassignedReport.reportSelected: {
      this.state.reportsToMove = action.reports || []
      this.emitChange()
      break
    }
    case events.unassignedReport.projectSelected: {
      this.state.projectToMove = action.project
      this.emitChange()
      break
    }
    case events.unassignedReport.reportsMoved: {
      this.state.projectToMove = null
      this.state.reportsToMove = []
      this.state.loading = true
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new UnassignedReportsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
