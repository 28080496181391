import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import get from 'lodash/get'
import find from 'lodash/find'
import some from 'lodash/some'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { withRouter } from 'react-router'

import GlobalSettings from 'components/administration/globalSettings'
import ProjectList from 'components/administration/projects/list/index'
import ProjectSettings from 'components/administration/projects/settings/settings'
import UserList from 'components/administration/users/list'
import UserSettings from 'components/administration/users/settings'
import YoutrackSettings from 'components/administration/youtrack/list/youtrackList'
import PageNotFound from 'pages/pageNotFound'
import withErrorBoundary from 'core/errorBoundary'

const DEFAULT_TAB = 'projects'

const AdministrationPage = ({ history, match }) => {
  const tabs = [
    { name: 'projects', label: 'Projects' },
    { name: 'users', label: 'Users' },
    { name: 'youtrack', label: 'YouTrack' },
    { name: 'global-settings', label: 'Global Settings' },
  ]
  const handleChange = useCallback((_, val) => {
    const selectedTab = find(tabs, { name: val })
    const link = get(selectedTab, 'link', val)
    history.push(`${match.url}/${link}`)
  }, [])
  const matchingTab = find(tabs, it => some(get(it, 'pattern', [it.name]), p => window.location.pathname.startsWith(`${match.url}/${p}`)))
  return (
    <>
      <Tabs
        onChange={handleChange}
        value={matchingTab ? matchingTab.name : DEFAULT_TAB}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs.map(tab => <Tab id={tab.name} key={tab.name} value={tab.name} label={tab.label} />)}
      </Tabs>
      <div>
        <Switch>
          <Route path={`${match.url}/global-settings`} component={withErrorBoundary(GlobalSettings)} />
          <Route path={`${match.url}/projects/:project`} component={withErrorBoundary(ProjectSettings)} />
          <Route path={`${match.url}/projects`} component={withErrorBoundary(ProjectList)} />
          <Route path={`${match.url}/users/:email`} component={withErrorBoundary(UserSettings)} />
          <Route path={`${match.url}/users`} component={withErrorBoundary(UserList)} />
          <Route path={`${match.url}/youtrack`} component={withErrorBoundary(YoutrackSettings)} />
          <Route component={PageNotFound} />
        </Switch>
      </div>
    </>
  )
}

AdministrationPage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}

export default withRouter(AdministrationPage)
