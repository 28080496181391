import PropTypes from 'prop-types'

import style from './style.module.scss'

const ItemCount = (props) => {
  const { total, passed, failed } = props
  if (total === 0) {
    return <span>No reports found</span>
  }
  if (total === 1) {
    return <span>Found 1 report</span>
  }
  return (
    <span>
      <a>Found {total} (</a><a className={style.passedCount}>{passed}</a><a>/</a><a className={style.failedCount}>{failed}</a><a>) reports</a>
    </span>
  )
}

ItemCount.propTypes = {
  total: PropTypes.number,
  failed: PropTypes.number,
  passed: PropTypes.number,
}

ItemCount.defaultProps = {
  total: 0,
  failed: 0,
  passed: 0,
}

export default ItemCount
