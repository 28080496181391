import { useEffect } from 'react'

import SuccessRate from 'components/dashboard/successRate/successRate'
import DailySuccessRate from 'components/dashboard/dailySuccessRate/dailySuccessRate'
import Verification from 'components/dashboard/verification/verification'
import ApprovalOverview from 'components/dashboard/approvalOverview/approvalOverview'

import useProject from 'hooks/useProject'

import style from './home.module.css'

const HomePage = () => {
  const { roles, postProcessEnabled } = useProject()
  const isTestManager = roles.includes('ROLE_TEST_MANAGER')

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Dashboard'
  }, [])

  return (
    <div>
      <div className={`row ${style.chartContainer}`}>
        { postProcessEnabled && isTestManager && (
          <div className={`col-md-6 ${style.dashboardCol}`}>
            <ApprovalOverview />
          </div>
        )}
        { postProcessEnabled && (
          <div className={`col-md-6 ${style.dashboardCol}`}>
            <Verification />
          </div>
        )}
        <div className={`col-md-6 ${style.dashboardCol}`}>
          <DailySuccessRate />
        </div>
        <div className={`col-md-6 ${style.dashboardCol}`}>
          <SuccessRate />
        </div>
      </div>
    </div>
  )
}

export default HomePage
