const columnNames = {
  errorCategory: 'Error Category',
  tester: 'Tester',
  startTime: 'Start Time',
  duration: 'Duration',
  note: 'Internal Note',
  verificationState: 'Verification State',
  verifiedBy: 'Verified by',
  verificationTime: 'Verification Time',
  approvalState: 'Approval State',
  approvalAction: 'Approval Change Reason',
  approvalChangedBy: 'Approval Change By',
  approvalLastChanged: 'Approval Change At',
  defects: 'Defects',
  almState: 'ALM State',
  almComment: 'ALM Comment',
  almUploadState: 'ALM Upload State',
}

export default columnNames
