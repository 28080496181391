import { Component } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import Store from './store'
import * as Actions from './actions'
import style from './customFields.module.css'


class HpqcCustomFields extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - HPQC Custom Fields'
    Store.on('change', this.updateState)
    Actions.retrieveData(projectCode)
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleSave = () => {
    const { projectCode } = this.context
    Actions.save(projectCode)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  render() {
    const { projectCode } = this.context
    const { error, loading, list, newItem } = this.state
    if (error != null) {
      return HpqcCustomFields.renderError(error)
    }
    return (
      <div>
        <Typography variant="h6">Custom Fields</Typography>
        <Spinner spinning={loading}>
          <Box paddingTop={2} paddingBottom={2}>
            <Grid container>
              <Grid item md={4}>
                <div className={style.wrapper}>
                  <div key="header" className={style.headerRow}>
                    <div className={style.nameColumn}>Name</div>
                    <div className={style.fieldColumn}>HPQC Field</div>
                    <div className={style.buttonColumn} />
                  </div>
                  {list.map(item => (
                    <div key={item.internalName} className={style.itemRow}>
                      <div className={style.nameColumn}>{item.internalName}</div>
                      <div className={style.fieldColumn}>{item.hpqcField}</div>
                      <div className={style.buttonColumn}>
                        <IconButton
                          src="/images/delete.svg"
                          onClick={() => Actions.handleDelete({ project: projectCode, internalName: item.internalName })}
                        />
                      </div>
                    </div>
                  ))}
                  { newItem && (
                    <form key="new" onSubmit={this.handleSubmit}>
                      <div className={style.itemRow}>
                        <div className={style.nameColumn}>
                          <TextField
                            name="name"
                            autoFocus
                            label="Internal Name"
                            fullWidth
                            value={newItem.internalName}
                            onChange={e => Actions.handleChange({ internalName: e.target.value, hpqcField: newItem.hpqcField })}
                          />
                        </div>
                        <div className={style.fieldColumn}>
                          <TextField
                            name="field"
                            label="HPQC Field Name"
                            fullWidth
                            value={newItem.hpqcField}
                            onChange={e => Actions.handleChange({ internalName: newItem.internalName, hpqcField: e.target.value })}
                          />
                        </div>
                        <div className={style.buttonColumn}>
                          <IconButton src="/images/accept.svg" onClick={this.handleSave} />
                          <IconButton src="/images/cancel.svg" onClick={Actions.handleCancel} />
                        </div>
                      </div>
                    </form>
                  )}
                </div>
                { !newItem && (
                  <Box marginTop={2}>
                    <IconButton onClick={Actions.handleAdd} src="/images/add.svg" />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Spinner>
      </div>
    )
  }
}

HpqcCustomFields.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

HpqcCustomFields.contextType = ProjectContext

export default HpqcCustomFields
