import { Component } from 'react'
import { withRouter } from 'react-router'

import { ProjectContext } from 'core/projectProvider/projectProvider'

import BlacklistSettings from './blacklistSettings'
import Layout from './layout'
import ProjectSettings from './settings'
import Store from './store'
import * as Actions from './actions'

class ProjectConfig extends Component {
  state = Store.resetState()

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Project Configuration'
    Store.on('change', this.updateState)
    Actions.retrieveSettings(projectCode)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleSave = () => {
    const { projectCode } = this.context
    const { blacklist, settings } = this.state
    Actions.save({ projectCode, blacklist, settings })
  }

  render() {
    const {
      error, loading, showSuccessMessage, blacklist, settings,
    } = this.state
    if (error) {
      throw Error(error)
    }
    return (
      <Layout loading={loading} showSuccessMessage={showSuccessMessage} onSave={this.handleSave}>
        <ProjectSettings
          settings={settings}
          onChange={Actions.handleValueChange}
        />
        <BlacklistSettings blacklist={blacklist} onChange={Actions.handleBlacklistChange} />
      </Layout>
    )
  }
}

ProjectConfig.contextType = ProjectContext

export default withRouter(ProjectConfig)
