import { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'

import style from './testCategories.module.css'

class EditTagsRow extends Component {
  constructor(props) {
    super(props)
    this.state = props.condition
  }

  handleSave = () => {
    const { value, tag } = this.state
    let isValid = true
    if (!value) {
      this.setState({ valueError: 'Value cannot be empty' })
      isValid = false
    }
    if (!tag) {
      this.setState({ tagError: 'Tag cannot be empty' })
      isValid = false
    } else if (/\s/g.test(tag)) {
      this.setState({ tagError: 'Tag cannot contain spaces' })
      isValid = false
    }
    if (isValid) {
      const { onSave } = this.props
      onSave(this.state)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  render() {
    const { onCancel } = this.props
    const { tagError, tag, valueError, value } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={style.conditionRow}>
          <div className={style.tagColumn}>
            <TextField
              name="addTag"
              fullWidth
              value={tag}
              label={tagError || 'Tag'}
              error={tagError != null}
              onChange={e => this.setState({ tag: e.target.value, tagError: null })}
            />
          </div>
          <div className={style.valueColumn}>
            <TextField
              name="addCondition"
              fullWidth
              value={value}
              label={valueError || 'Value'}
              error={valueError != null}
              onChange={e => this.setState({ value: e.target.value, valueError: null })}
            />
          </div>
          <div className={style.buttonColumn}>
            <IconButton src="/images/accept.svg" onClick={this.handleSave} />
            <IconButton src="/images/cancel.svg" onClick={onCancel} />
          </div>
        </div>
      </form>
    )
  }
}

EditTagsRow.propTypes = {
  condition: PropTypes.shape({ id: PropTypes.string, value: PropTypes.string }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

EditTagsRow.defaultProps = { condition: { id: '', value: '', type: 'TAG', tagError: null, valueError: null } }

export default EditTagsRow
