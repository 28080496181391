import get from 'lodash/get'

export const initialState = {
  loading: false,
  timePeriod: 'Last week',
  data: [
    { x: 'Passed', y: 1, label: 'Passed: N/A' },
    { x: 'Failed', y: 1, label: 'Failed: N/A' },
    { x: 'Pending', y: 1, label: 'Pending: N/A' },
  ],
}


export const reducer = (state, action) => {
  switch (action.type) {
  case 'received': {
    const passedNo = get(action, 'data.passedNo', 0)
    const failedNo = get(action, 'data.failedNo', 0)
    const pendingNo = get(action, 'data.pendingNo', 0)
    const data = [
      { x: 'Passed', y: passedNo, label: `Passed: ${passedNo}` },
      { x: 'Failed', y: failedNo, label: `Failed: ${failedNo}` },
      { x: 'Pending', y: pendingNo, label: `Pending: ${pendingNo}` },
    ]
    const noExecutions = action.data.passedNo + action.data.failedNo + action.data.pendingNo === 0
    return { ...state, data, noExecutions, loading: false }
  }
  case 'periodUpdated': {
    return { ...state, timePeriod: action.period, loading: true }
  }
  default: {
    throw new Error()
  }
  }
}
