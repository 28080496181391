import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import _ from 'lodash'
import SessionStore from 'core/sessionStore'
import * as Utils from 'components/utils'

const status = {
  passed: 'PASSED',
  failed: 'FAILED',
}

const uploadStatus = {
  UPLOADED: 'UPLOADED',
  FAILED: 'FAILED',
  READY: 'READY',
}

const approvalStatus = {
  APPROVED: 'APPROVED',
  DENIED: 'DENIED',
}

const verificationStatus = {
  VERIFIED: 'VERIFIED',
  ARCHIVED: 'ARCHIVED',
}

const retentionStatus = {
  RETAINED: 'RETAINED',
  SCHEDULED: 'SCHEDULED',
  DELETED: 'DELETED',
}

const loadingLogs = {
  logs: [],
  status: 'loading',
}

const mapUploadStatus = (remoteUploadStatus) => {
  if (remoteUploadStatus === uploadStatus.UPLOADED) {
    return 'Uploaded'
  }
  if (remoteUploadStatus === uploadStatus.FAILED) {
    return 'Upload failed'
  }
  if (remoteUploadStatus === uploadStatus.READY) {
    return 'Not uploaded'
  }
  return 'Unknown status'
}

class TestSuiteStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: {
        execution: true, hpqc: true, jira: true, newerExecutions: true,
      },
      protocol: { ...loadingLogs },
      trace: { ...loadingLogs },
      server: { ...loadingLogs },
      testSuite: { tests: [], lineNumber: 0 },
      testSuiteStatus: status.passed,
      open: false,
      isTestManager: false,
      retention: {},
      defects: [],
      hpqcDetails: { uploaded: false },
      hpqcStatuses: Utils.hpqcStatuses,
      newerExecutions: [],
      moveDialogOpen: false,
      redirectToReportList: false,
      cdrVerificationsResponse: { cdrVerifications: [], cdrVerificationWarnings: [] },
      qonformVerificationsResponse: { qonformVerifications: [], qonformVerificationWarnings: [] },
      successConditionDetailsKey: '',
      successConditions: [],
      contextOverrides: new Map(),
      updateContextButtonStates: new Map(),
      contextPreviewErrors: new Map(),
      contextPreviewFile: null,
      qonformContextOverrides: new Map(),
      qonformContextPreviewErrors: new Map(),
      updateQonformContextButtonStates: new Map(),
      verificationServiceEnabled: false,
      qonformServiceEnabled: false,
    }
  }

  hasWarning = () => this.state.testSuite.warnings
    && (this.state.testSuite.warnings.includes('MISSING_TEST_PLAN')
      || this.state.testSuite.warnings.includes('MISSING_CATEGORY'))

  isTestManager = () => {
    const session = SessionStore.getState()
    return session && session.roles && session.roles.includes('ROLE_TEST_MANAGER')
  }

  getEmail = () => {
    const session = SessionStore.getState()
    return session == null ? null : session.email
  }

  mapDataRetentionDetails = (testSuite) => {
    this.state.showDataRetention = testSuite.retentionStatus !== retentionStatus.RETAINED
    this.state.retention.status = testSuite.retentionStatus
    this.state.retention.scheduleTime = Utils.formatDate(testSuite.retentionScheduleTime)
    this.state.retention.scheduledBy = testSuite.retentionScheduledBy
    this.state.retention.deletionTime = Utils.formatDate(testSuite.retentionDeletionTime) || 'N/A'
    this.state.retention.reason = testSuite.retentionReason
  }

  handleActions(action) {
    switch (action.type) {
    case events.testDetailsSuiteView.executionsReceived: {
      const isVerified = (action.testSuite.verificationStatus === verificationStatus.VERIFIED)
      const isArchived = (action.testSuite.verificationStatus === verificationStatus.ARCHIVED)
      const isApproved = (action.testSuite.approvalStatus === approvalStatus.APPROVED)
      const isDenied = (action.testSuite.approvalStatus === approvalStatus.DENIED)
      const isUploaded = (action.testSuite.uploadStatus === uploadStatus.UPLOADED)
      const isRetained = (action.testSuite.retentionStatus === retentionStatus.RETAINED)
      const isDeleted = (action.testSuite.retentionStatus === retentionStatus.DELETED)
      const isTestManager = this.isTestManager()
      this.state.testSuite = action.testSuite
      this.state.isTestManager = isTestManager
      this.state.verified = isVerified && action.testSuite.verification
      this.state.archived = isArchived && action.testSuite.verification
      this.state.canVerify = !isVerified && isRetained
      this.state.canArchive = !isArchived && isRetained && !isApproved && !isUploaded
      this.state.approvalStatus = action.testSuite.approvalStatus
      this.state.approved = isApproved
      this.state.canApprove = !isApproved && isRetained && isTestManager && !this.hasWarning()
      this.state.canDeny = !isDenied && !isUploaded && isRetained && isTestManager
      this.state.uploadStatus = mapUploadStatus(action.testSuite.uploadStatus)
      this.state.canUpload = isApproved && isRetained && action.testSuite.testCategory
      this.state.canDelete = isTestManager && isRetained && !isApproved
      this.state.canCancelDeletion = isTestManager && action.testSuite.retentionStatus === retentionStatus.SCHEDULED
      this.state.canChangeTestCategory = isTestManager
      this.state.canChangeHpqcDetails = isTestManager
      this.state.loading.execution = false
      this.state.testSuiteStatus = action.testSuite.tests.every(test => test.result === status.passed) ? status.passed : status.failed
      this.state.conditionsState = action.testSuite.conditionsState
      this.state.reportId = this.state.testSuite.reportId
      this.state.defects = action.testSuite.defects.sort((a, b) => a.id.localeCompare(b.id))
      this.state.showEditButtons = false
      this.state.error = null
      this.state.showTicketWarning = action.testSuite.warnings.includes('MISSING_TEST_PLAN')
      this.state.showAttachmentsLink = !isDeleted
      this.state.attachmentsHaveBeenUpdated = false
      this.state.requireConfirmation = action.testSuite.warnings.includes('HPQC_STATUS')
      this.state.errorMessage = action.testSuite.errorMessage;
      [this.state.errorCategory] = action.testSuite.tests
        .map(test => test.errorCategory)
        .filter(it => it != null)

      if (isDeleted) {
        this.state.protocol.status = 'deleted'
        this.state.trace.status = 'deleted'
      }
      this.state.resultAttachments = _.chain(action)
        .get('testSuite.tests')
        .flatMap(test => test.steps)
        .flatMap(step => step.attachments)
        .filter(attachment => !attachment.removed)
        .filter(attachment => attachment.result)
        .value()
      this.mapDataRetentionDetails(action.testSuite)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.logsReceived: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.logs = action.logs
        this.state.protocol.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'TRACE':
        this.state.trace.logs = action.logs
        this.state.trace.status = action.deleted ? 'deleted' : 'ready'
        break
      case 'SERVER':
        this.state.server.logs = action.logs
        this.state.server.status = action.deleted ? 'deleted' : 'ready'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.logsFailed: {
      switch (action.logType) {
      case 'PROTOCOL':
        this.state.protocol.error = action.error
        this.state.protocol.status = 'failed'
        break
      case 'TRACE':
        this.state.trace.error = action.error
        this.state.trace.status = 'failed'
        break
      case 'SERVER':
        this.state.server.error = action.error
        this.state.server.status = 'failed'
        break
      default:
      }
      this.emitChange()
      break
    }
    case events.attachmentButton.failed:
    case events.testDetailsSuiteView.failed: {
      this.state.approvalInProgress = false
      this.state.loading.execution = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.uploaded:
      this.state.loading.hpqc = true
      this.state.showDataRetention = false
      this.emitChange()
      break
    case events.testDetailsSuiteView.deleted:
    case events.testDetailsSuiteView.verified:
    case events.testDetailsSuiteView.approved: {
      this.state.approvalInProgress = true
      this.state.loading.hpqc = true
      this.state.showDataRetention = false
      this.emitChange()
      break
    }
    case 'notification.uploaded_to_hpqc': {
      this.state.uploadStatus = uploadStatus.UPLOADED
      this.state.loading.hpqc = false
      this.emitChange()
      break
    }
    case 'notification.hpqc_upload_failed': {
      this.state.uploadStatus = uploadStatus.FAILED
      this.state.loading.hpqc = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.deletionSucceeded:
    case events.testDetailsSuiteView.verificationSucceeded:
    case events.testDetailsSuiteView.approvalSucceeded: {
      this.state.approvalInProgress = false
      this.state.loading.hpqc = false
      this.state.loading.execution = true
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.showPhoneInfo: {
      this.state.open = true
      this.state.parameters = action.parameters
      this.state.phoneEventName = action.eventName
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.closePhoneInfo: {
      this.state.open = false
      this.state.parameters = null
      this.state.phoneEventName = null
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.openMoveDialog: {
      this.state.moveDialogOpen = true
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.closeMoveDialog: {
      this.state.moveDialogOpen = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.performMove: {
      this.state.moveDialogOpen = false
      this.state.redirectToReportList = true
      this.emitChange()
      break
    }
    case events.attachmentList.removed:
    case events.attachmentList.removeUndone:
    case events.attachmentButton.removed: {
      this.state.attachmentsHaveBeenUpdated = true
      this.emitChange()
      break
    }
    case events.attachmentButton.attachmentRemoved: {
      this.state.attachmentsHaveBeenUpdated = true
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.defectSaved: {
      this.state.defects = action.defects.sort((a, b) => a.id.localeCompare(b.id))
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.internalNoteUpdated: {
      this.state.testSuite.note = { text: action.note }
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.successConditionsReceived: {
      this.state.successConditions = action.details
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.successConditionsFailed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.hpqcDetailsReceived: {
      this.state.hpqcDetails = action.details
      this.state.loading.hpqc = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.hpqcDetailsUpdated: {
      this.state.hpqcDetails.status = action.status
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.hpqcNoteUpdated: {
      this.state.hpqcDetails.note = { text: action.note }
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.newerExecutionsReceived: {
      this.state.newerExecutions = action.list
        .filter(it => it.testId !== this.state.testSuite.executionId)
        .slice(0, 10)
      this.state.loading.newerExecutions = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.newerExecutionsFailed: {
      this.state.newerExecutions = []
      this.state.loading.newerExecutions = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.retrieveStarted: {
      this.state = this.getInitialState()
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.uploadedToJira: {
      this.state.loading.jira = true
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.jiraDetailsReceived: {
      this.state.jiraDetails = action.details
      this.state.loading.jira = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.jiraDetailsFailed: {
      this.state.error = action.error
      this.state.loading.jira = false
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.cdrFileUploaded: {
      this.state.attachmentsHaveBeenUpdated = true
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.cdrUploadFailed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.retrievedVerifications: {
      this.state.verificationHasBeenUpdated = false
      for (let i = 0; i < this.state.cdrVerificationsResponse.cdrVerifications.length && action.cdrVerificationsResponse.cdrVerifications.length; i += 1) {
        // if at least one verification is not running anymore i.e. it is changed, reload success conditions component
        if (this.state.cdrVerificationsResponse.cdrVerifications[i].state === 'RUNNING'
        && action.cdrVerificationsResponse.cdrVerifications[i].state === 'NOT_RUNNING') {
          const randomString = Math.random().toString(36).substr(2, 5)
          this.state.successConditionDetailsKey = randomString
          break
        }
      }
      this.state.cdrVerificationsResponse = action.cdrVerificationsResponse
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.retrieveVerificationsFailed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.retrievedQonformVerifications: {
      this.state.qonformVerificationHasBeenUpdated = false
      // eslint-disable-next-line max-len
      for (let i = 0; i < this.state.qonformVerificationsResponse.qonformVerifications.length && action.qonformVerificationsResponse.qonformVerifications.length; i += 1) {
        // if at least one verification is not running anymore i.e. it is changed, reload success conditions component
        if (this.state.qonformVerificationsResponse.qonformVerifications[i].state === 'RUNNING'
              && action.qonformVerificationsResponse.qonformVerifications[i].state === 'NOT_RUNNING') {
          this.state.successConditionDetailsKey = Math.random().toString(36).substr(2, 5)
          break
        }
      }
      this.state.qonformVerificationsResponse = action.qonformVerificationsResponse
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.retrieveQonformVerificationsFailed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.verificationStarted: {
      this.state.verificationHasBeenUpdated = true
      for (let i = 0; i < this.state.cdrVerificationsResponse.cdrVerifications.length; i += 1) {
        if (this.state.cdrVerificationsResponse.cdrVerifications[i].id === action.verificationExternalId) {
          this.state.cdrVerificationsResponse.cdrVerifications[i].state = 'RUNNING'
          break
        }
      }
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.verificationRunFailed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.qonformVerificationStarted: {
      this.state.qonformVerificationHasBeenUpdated = true
      for (let i = 0; i < this.state.qonformVerificationsResponse.qonformVerifications.length; i += 1) {
        if (this.state.qonformVerificationsResponse.qonformVerifications[i].id === action.verificationExternalId) {
          this.state.qonformVerificationsResponse.qonformVerifications[i].state = 'RUNNING'
          break
        }
      }
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.qonformVerificationRunFailed: {
      this.state.openErrorMessage = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.overridesChanged: {
      this.state.contextOverrides.set(action.cdrVerificationId, action.overrides)
      this.state.contextPreviewErrors.set(action.cdrVerificationId, null)
      this.state.updateContextButtonStates.set(action.cdrVerificationId, { disabled: false })
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.qonformOverridesChanged: {
      this.state.qonformContextOverrides.set(action.qonformVerificationId, action.overrides)
      this.state.qonformContextPreviewErrors.set(action.qonformVerificationId, null)
      this.state.updateQonformContextButtonStates.set(action.qonformVerificationId, { disabled: false })
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.gotContextPreview: {
      this.state.contextPreviewFile = action.contextPreviewFile
      this.state.contextPreviewErrors.set(action.verificationExternalId, null)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.gotQonformContextPreview: {
      this.state.contextPreviewFile = action.contextPreviewFile
      this.state.contextPreviewErrors.set(action.verificationExternalId, null)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.getContextPreviewFailed: {
      this.state.contextPreviewErrors.set(action.verificationExternalId, action.error)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.getQonformContextPreviewFailed: {
      this.state.qonformContextPreviewErrors.set(action.verificationExternalId, action.error)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.updateContext: {
      this.state.updateContextButtonStates.set(action.verificationExternalId, { disabled: true })
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.updateQonformContext: {
      this.state.updateQonformContextButtonStates.set(action.verificationExternalId, { disabled: true })
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.updatedContext: {
      this.state.cdrVerificationsResponse.cdrVerifications = action.cdrVerifications
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.updatedQonformContext: {
      this.state.qonformVerificationsResponse.qonformVerifications = action.qonformVerifications
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.updateContextFailed: {
      this.state.updateContextButtonStates.set(action.verificationExternalId, { disabled: false })
      this.state.contextPreviewErrors.set(action.verificationExternalId, action.error)
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.closeContextPreview: {
      this.state.contextPreviewFile = null
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.gotCdrEnabled: {
      this.state.verificationServiceEnabled = action.enabled
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.getCdrEnabledFailed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.gotQonformEnabled: {
      this.state.qonformServiceEnabled = action.enabled
      this.emitChange()
      break
    }
    case events.testDetailsSuiteView.getQonformEnabledFailed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const testSuiteStore = new TestSuiteStore()
dispatcher.register(testSuiteStore.handleActions.bind(testSuiteStore))

export default testSuiteStore
