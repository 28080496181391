import axios from 'axios'
import moment from 'moment'
import * as Common from 'services/common'
import { formatDateISOZ } from 'components/utils'

class ExecutionListService {
  createFilterQuery = (project, filters, metadata) => {
    const projectParam = `project=${project}`
    const sortParams = filters.sortField ? `&sort=${filters.sortField}${filters.sortDirection ? (`,${filters.sortDirection}`) : ''}` : ''
    const pageNumber = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
    const pageSize = filters.pageSize ? `&size=${filters.pageSize}` : ''
    const testNameFilter = filters.testName ? `&testSuite=${encodeURIComponent(filters.testName)}` : ''
    const testerFilter = filters.tester ? `&tester=${filters.tester}` : ''
    const dateFromFilter = filters.dateFrom ? `&startedDateFrom=${encodeURIComponent(formatDateISOZ(filters.dateFrom))}` : ''
    const dateUntilFilter = filters.dateUntil ? `&startedDateUntil=${encodeURIComponent(formatDateISOZ(filters.dateUntil))}` : ''
    const statusFilter = filters.status ? `&status=${filters.status}` : ''
    const conditionsStateFilter = filters.conditionsState ? `&conditionsState=${filters.conditionsState}` : ''
    const uploadStatusFilter = filters.uploadStatus ? `&uploadStatus=${filters.uploadStatus}` : ''
    const testSuiteId = filters.testSuiteId ? `&testSuiteId=${filters.testSuiteId}` : ''
    const defectId = filters.defect ? `&defect=${filters.defect}` : ''
    const errorCategoryFilter = filters.errorCategory ? `&errorCategory=${filters.errorCategory}` : ''
    const labelFilter = filters.label ? `&label=${filters.label}` : ''
    const intactVersionFilter = filters.intactVersion ? `&intactVersion=${encodeURIComponent(filters.intactVersion)}` : ''
    const tagFilter = filters.tag ? `&tag=${encodeURIComponent(filters.tag)}` : ''
    const metadataFilterName = filters.metadataName ? `&metadataName=${encodeURIComponent(filters.metadataName)}` : ''
    const metadataFilterValue = filters.metadataValue ? `&metadataValue=${encodeURIComponent(filters.metadataValue)}` : ''
    const metadataFields = metadata && metadata.length > 0 ? `&metadataFields=${encodeURIComponent([...metadata].join())}` : ''
    return `${projectParam}${sortParams}${pageNumber}${pageSize}${testNameFilter}${testerFilter}${uploadStatusFilter}${dateFromFilter}`
      + `${dateUntilFilter}${statusFilter}${conditionsStateFilter}${testSuiteId}${defectId}${errorCategoryFilter}${labelFilter}${intactVersionFilter}`
      + `${tagFilter}${metadataFilterName}${metadataFilterValue}${metadataFields}`
  }

  findAll = (project, filters, metadataFields) => {
    const query = this.createFilterQuery(project, filters, metadataFields)
    const url = `/api/test-suite-execution?${query}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findUnassigned = (filters) => {
    const sortParams = filters.sortField ? `&sort=${filters.sortField}${filters.sortDirection ? (`,${filters.sortDirection}`) : ''}` : ''
    const pageNumber = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
    const pageSize = filters.pageSize ? `&size=${filters.pageSize}` : ''
    const url = `/api/test-suite-execution/unassigned?${sortParams}${pageNumber}${pageSize}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findAllReadyForApproval = (project, page, pageSize) => {
    const projectParam = `project=${project}`
    const otherParams = `page=${page - 1}&size=${pageSize}&sort=startTime,desc&approval=READY`
    const url = `/api/test-suite-execution?${projectParam}&${otherParams}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findAllUploaded = (project, page, pageSize) => {
    const projectParam = `project=${project}`
    const otherParams = `page=${page - 1}&size=${pageSize}&sort=startTime,desc&uploadStatus=UPLOADED,FAILED`
    const url = `/api/test-suite-execution?${projectParam}&${otherParams}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findAllReadyForVerification = (project, user, page, pageSize) => {
    const projectParam = `project=${project}`
    const testerFilter = `tester=${user}`
    const otherParams = `page=${page - 1}&size=${pageSize}&sort=startTime,desc&verification=NEW`
    const url = `/api/test-suite-execution?${projectParam}&${testerFilter}&${otherParams}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  createCsvUrl = (project, filters, fields, metadata) => {
    const query = this.createFilterQuery(project, filters, metadata)
    const fieldsStr = [...fields].join()
    return `/api/test-suite-execution/export?${query}&fields=${fieldsStr}`
  }

  getDailyCountStats = (project) => {
    const url = `/api/test-suite-execution/daily-success-rate?project=${project}&time=${encodeURIComponent(formatDateISOZ(moment()))}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getSuccessRateStats = (project, from, to) => {
    const dateFrom = encodeURIComponent(formatDateISOZ(from))
    const dateTo = encodeURIComponent(formatDateISOZ(to))
    const url = `/api/test-suite-execution/success-rate?project=${project}&from=${dateFrom}&to=${dateTo}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  moveReportsToProject = (project, reports, isMove) => {
    const request = { reports, project, isMove }
    return axios.post('/api/test-suite-execution/reports-assignment', request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new ExecutionListService()
export default instance
