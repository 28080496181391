import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import FilterPanel from 'components/reports/common/filterPanel'

const UploadStatusFilter = ({ initialValue, onSubmit, id }) => {
  const [status, setStatus] = useState(initialValue)

  const handleFilter = () => {
    onSubmit(status)
    setStatus('')
  }
  return (
    <FilterPanel title="Filter by Upload Status" onSubmit={handleFilter} id={id}>
      <FormControl fullWidth>
        <InputLabel>Select an upload status</InputLabel>
        <Select
          id="statusFilterSelect"
          fullWidth
          autoFocus
          value={status}
          onChange={e => setStatus(e.target.value)}
        >
          <MenuItem key="UPLOADED" value="UPLOADED">Uploaded</MenuItem>
          <MenuItem key="FAILED" value="FAILED">Failed</MenuItem>
          <MenuItem key="NOT_UPLOADED" value="READY,FAILED">Not Uploaded</MenuItem>
        </Select>
      </FormControl>
    </FilterPanel>
  )
}

UploadStatusFilter.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

UploadStatusFilter.defaultProps = {
  id: 'uploadStatusFilter',
  initialValue: '',
}

export default memo(UploadStatusFilter)
