import { dispatcher, events } from 'core/dispatcher'
import TestCategoryService from 'services/testCategoryService'


function dispatchList(listLoading) {
  listLoading.then((list) => {
    dispatcher.dispatch({ type: events.configurationTestCategories.dataReceived, list })
  }).catch((message) => {
    dispatcher.dispatch({ type: events.configurationTestCategories.failed, message })
  })
}

export function retrieveData(project) {
  const listLoading = TestCategoryService.findAll(project)
  dispatchList(listLoading)
}

export function handleAdd() {
  dispatcher.dispatch({ type: events.configurationTestCategories.addCategory })
}

export function handleCancel() {
  dispatcher.dispatch({ type: events.configurationTestCategories.cancelEdit })
}

export function handleEdit(id) {
  dispatcher.dispatch({ type: events.configurationTestCategories.editCategory, id })
}

export function handleDelete(project, id) {
  const listLoading = TestCategoryService.delete(project, id)
  dispatchList(listLoading)
}

export function saveTestCategory(testCategory, project) {
  const call = testCategory.id ? TestCategoryService.update : TestCategoryService.add
  const listLoading = call(testCategory, project)
  dispatchList(listLoading)
}
