import { useEffect, useReducer } from 'react'
import Snackbar from '@mui/material/Snackbar'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import FieldsSettings from './settings'
import FieldsSettingsFallback from './settingsFallback'
import { loadConfig, loadSettings, saveSettings } from './actions'

const initialState = {
  loading: true,
  showSuccessMessage: false,
  settings: { parentIssueFields: {}, executionIssueFields: {}, testIssueFields: {} },
  issueTypes: [],
  customFields: [],
  linkTypes: [],
}

const reducer = (state, action) => {
  switch (action.type) {
  case 'loaded':
    return {
      loading: false,
      showSuccessMessage: false,
      useFallback: action.useFallback,
      settings: action.settings || initialState.settings,
      issueTypes: action.config.issueTypes.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
      customFields: action.config.customFields.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      linkTypes: action.config.issueLinkTypes.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
    }
  case 'saving':
    return { ...state, loading: true }
  case 'saved':
    return { ...state, showSuccessMessage: true, loading: false, settings: action.settings }
  default:
    throw new Error()
  }
}

const JiraSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    const fetchData = async () => {
      const { config, useFallback } = await loadConfig(projectCode).catch(handleError)
      const settings = await loadSettings(projectCode).catch(handleError)
      dispatch({ type: 'loaded', config, settings, useFallback })
    }
    fetchData()
  }, [handleError, projectCode])
  const handleSave = async (settings) => {
    dispatch({ type: 'saving' })
    await saveSettings(projectCode, settings).catch(handleError)
    const updatedSettings = await loadSettings(projectCode).catch(handleError)
    dispatch({ type: 'saved', settings: updatedSettings })
  }
  return (
    <div>
      <Spinner spinning={state.loading}>
        { state.useFallback
          ? (
            <FieldsSettingsFallback
              settings={state.settings}
              customFields={state.customFields}
              issueTypes={state.issueTypes}
              linkTypes={state.linkTypes}
              onSave={handleSave}
            />
          )
          : (
            <FieldsSettings
              settings={state.settings}
              customFields={state.customFields}
              issueTypes={state.issueTypes}
              linkTypes={state.linkTypes}
              onSave={handleSave}
            />
          )}
        <Snackbar open={state.showSuccessMessage} message="Settings were saved successfully." autoHideDuration={10000} />
      </Spinner>
    </div>
  )
}

export default withRouter(JiraSettings)
