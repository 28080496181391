import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { ImportErrorCategoriesIcon, UploadIcon } from 'components/icons'
import style from '../pms/sshKey/style.module.scss'

const inlineStyle = {
  exportButton: { minWidth: '30px', width: '30px', height: '30px' },
}


class Import extends Component {
  state = { showDialog: false, files: [] }

  handleClose = () => {
    this.setState({ showDialog: false, files: [] })
  }

  handleSubmit = () => {
    this.props.onSelect(this.state.files[0])
    this.setState({ showDialog: false, files: [] })
  }

  render() {
    const label = this.state.files.length > 0 ? this.state.files[0].name : 'Select file'
    return (
      <>
        <Button style={inlineStyle.exportButton} id="importButton" onClick={() => this.setState({ showDialog: true })}>
          <ImportErrorCategoriesIcon />
        </Button>
        <Dialog
          open={this.state.showDialog}
          maxWidth="lg"
          fullWidth
          onClose={this.handleClose}
        >
          <DialogTitle>Import Error Categories</DialogTitle>
          <DialogContent>
            <input
              id="raised-button-file"
              className={style.uploadInput}
              type="file"
              onChange={e => this.setState({ files: e.target.files })}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" id="fileSelectBtn" component="span" className={style.uploadButton} endIcon={<UploadIcon />}>
                {label}
              </Button>
            </label>
          </DialogContent>
          <DialogActions>
            <Button id="importButton" color="primary" disabled={this.state.files.length === 0} onClick={this.handleSubmit}>Import</Button>
            <Button id="closeButton" onClick={this.handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

Import.propTypes = {
  onSelect: PropTypes.func.isRequired,
}

export default Import
