import ApprovalService from 'services/postprocess/approvalService'
import ExecutionListService from 'services/executionListService'
import { dispatcher, events } from 'core/dispatcher'


export function retrieveList(project, page = 1) {
  ExecutionListService.findAllReadyForApproval(project, page, 50)
    .then((list) => { dispatcher.dispatch({ type: events.approval.listReceived, list }) })
    .catch((error) => { dispatcher.dispatch({ type: events.approval.failed, error }) })
}

export function updateRowSelection(ids) {
  dispatcher.dispatch({ type: events.approval.selected, ids })
}

export function selectAll() {
  dispatcher.dispatch({ type: events.approval.selectedAll })
}

function changeStatus(executions, status, projectCode) {
  dispatcher.dispatch({ type: events.approval.approving })
  ApprovalService.changeMultipleApprovalStatus(executions, status)
    .then(() => {
      dispatcher.dispatch({ type: events.approval.approved })
      retrieveList(projectCode)
    })
    .catch((message) => {
      dispatcher.dispatch({ type: events.approval.failed, error: `Approval failed: ${message}` })
    })
}

export function approve(executions, project) {
  if (executions.length > 0) {
    changeStatus(executions, 'APPROVED', project)
  }
}

export function deny(executions, project) {
  if (executions.length > 0) {
    changeStatus(executions, 'DENIED', project)
  }
}

export function moveReportsToOtherProject(executionIds, targetProjectCode, currentProjectCode) {
  ExecutionListService.moveReportsToProject(targetProjectCode, executionIds, true)
    .then(() => {
      dispatcher.dispatch({ type: events.approval.performMove })
      retrieveList(currentProjectCode)
    })
    .catch((error) => { dispatcher.dispatch({ type: events.approval.failed, error: `Project move failed: ${error}` }) })
}

export function openMoveDialog() {
  dispatcher.dispatch({ type: events.approval.openMoveDialog })
}

export function closeMoveDialog() {
  dispatcher.dispatch({ type: events.approval.closeMoveDialog })
}
