import { dispatcher, events } from 'core/dispatcher'
import PmsExportService from 'services/pms/exportService'

export default function retrieveList(filters, projectCode) {
  PmsExportService.findAll(projectCode, filters)
    .then((data) => {
      dispatcher.dispatch({ type: events.pmsExportReport.listReceived, data })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.pmsExportReport.exportListFailed, error }) })
}
