import axios from 'axios'
import * as Common from 'services/common'

class CheqService {
    retrieveSettings = (project) => {
      const url = `/api/module/cheq/${project}/settings`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    updateSettings = (project, settings) => {
      const request = {
        ...settings,
        socketTimeoutMillis: settings.socketTimeoutMillis || 0,
        allowSelfSignedCertificates: settings.allowSelfSignedCertificates || false,
      }
      const url = `/api/module/cheq/${project}/settings`
      return axios.post(url, request, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }
}

const instance = new CheqService()
export default instance
