import PropTypes from 'prop-types'

const TrimmedText = (props) => {
  const { text, maxLength, showTooltip, ...otherProps } = props
  if (text == null) {
    return <span {...otherProps} />
  }

  if (text.length <= maxLength && !text.includes('\n')) {
    return <span {...otherProps}>{text}</span>
  }

  const trimmedText = props.text.includes('\n')
    ? props.text.substring(0, Math.min(maxLength - 3, props.text.indexOf('\n'))).trim().concat('...')
    : props.text.substring(0, maxLength - 3).trim().concat('...')

  const content = showTooltip
    ? (
      <div data-tip={props.text} {...otherProps} data-multiline data-html>
        {trimmedText}
      </div>
    )
    : (
      <div {...otherProps}>
        {trimmedText}
      </div>
    )

  return content
}

TrimmedText.propTypes = {
  text: PropTypes.string,
  maxLength: PropTypes.number.isRequired,
  showTooltip: PropTypes.bool,
}

TrimmedText.defaultProps = {
  text: null,
  showTooltip: true,
}

export default TrimmedText
