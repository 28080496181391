import { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import has from 'lodash/has'
import Button from '@mui/material/Button'

import { UploadIcon } from 'components/icons'

import style from './style.module.scss'


const Settings = ({ onSave }) => {
  const [state, setState] = useState()
  const handleSave = () => {
    onSave(state.file)
  }
  return (
    <>
      <input
        id="raised-button-file"
        className={style.fileInput}
        type="file"
        onChange={e => setState({ file: e.target.files[0] })}
      />
      <label htmlFor="raised-button-file" className={style.uploadButton}>
        <Button
          variant="contained"
          id="fileSelectBtn"
          component="span"
          startIcon={<UploadIcon />}
        >
          {get(state, 'file.name', 'Select SSH Private Key')}
        </Button>
      </label>
      <Button
        variant="contained"
        id="saveButton"
        color="primary"
        onClick={handleSave}
        disabled={!has(state, 'file')}
      >
        Save
      </Button>
    </>
  )
}

Settings.propTypes = {
  onSave: PropTypes.func.isRequired,
}

export default Settings
