import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import Settings from './settings'
import saveSshKey from './actions'

const initialState = {
  loading: false,
  showSuccessMessage: false,
}

const PmsSettings = () => {
  const handleError = useErrorHandler()
  const [state, setState] = useState(initialState)
  const { projectCode } = useProject()

  const handleSave = (sshKey) => {
    setState({ loading: true, showSuccessMessage: false })
    saveSshKey(projectCode, sshKey)
      .then(() => setState({ loading: false, showSuccessMessage: true }))
      .catch(handleError)
  }
  return (
    <Grid container>
      <Grid item md={3}>
        <Typography variant="h6">SSH Private Key</Typography>
        <Spinner spinning={state.loading}>
          <Settings onSave={handleSave} />
          <Snackbar open={state.showSuccessMessage} message="SSH private key was saved successfully." autoHideDuration={10000} />
        </Spinner>
      </Grid>
    </Grid>
  )
}

export default PmsSettings
