import calcTotalPages from 'utils/calcTotalPages'
import ReportingEventEmitter from '../../core/eventEmitter'
import { dispatcher, events } from '../../core/dispatcher'

const PAGE_SIZE = 50

class ApprovalStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      items: [],
      selectedItems: [],
      totalPages: 0,
      canApprove: false,
      canDeny: false,
    }
  }

  checkActions = () => {
    if (this.state.selectedItems.length === 0) {
      this.state.canApprove = false
      this.state.canDeny = false
    } else {
      this.state.canApprove = this.state.items.filter(it => this.state.selectedItems.includes(it.testId))
        .every(it => !it.warning)
      this.state.canDeny = true
    }
  }

  hasWarning = testSuite => testSuite.warnings
      && testSuite.warnings.includes('MISSING_TEST_PLAN')

  handleActions(action) {
    switch (action.type) {
    case events.approval.listReceived: {
      this.state.items = action.list.items.map(item => (this.hasWarning(item) ? { ...item, warning: true } : item))
      this.state.totalPages = calcTotalPages({ totalSize: action.list.totalSize, pageSize: PAGE_SIZE })
      this.state.loading = false
      this.state.selectedItems = []
      this.state.isSelectedAll = false
      this.state.error = null
      this.checkActions()
      this.emitChange()
      break
    }
    case events.approval.failed: {
      this.state.error = action.error
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.approval.selected: {
      this.state.selectedItems = action.ids
      this.state.isSelectedAll = (this.state.items.length === this.state.selectedItems.length)
      this.checkActions()
      this.emitChange()
      break
    }
    case events.approval.selectedAll: {
      this.state.isSelectedAll = (this.state.items.length !== this.state.selectedItems.length)
      if (this.state.isSelectedAll) {
        this.state.selectedItems = this.state.items.map(it => it.testId)
      } else {
        this.state.selectedItems = []
      }
      this.checkActions()
      this.emitChange()
      break
    }
    case events.approval.approving: {
      this.state.loading = true
      this.emitChange()
      break
    }
    case events.approval.openMoveDialog: {
      this.state.moveDialogOpen = true
      this.emitChange()
      break
    }
    case events.approval.closeMoveDialog: {
      this.state.moveDialogOpen = false
      this.emitChange()
      break
    }
    case events.approval.performMove: {
      this.state.moveDialogOpen = false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const approvalStore = new ApprovalStore()
dispatcher.register(approvalStore.handleActions.bind(approvalStore))

export default approvalStore
