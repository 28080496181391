import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'

import FilterPanel from '../filterPanel'
import style from './style.module.scss'

const DateFilter = ({ onSubmit, initialDateFrom, initialDateUntil, id }) => {
  const [state, setState] = useState(
    { fromDate: null, fromTime: null, untilDate: null, untilTime: null },
  )

  useEffect(() => {
    setState({ fromDate: initialDateFrom, fromTime: initialDateFrom, untilDate: initialDateUntil, untilTime: initialDateUntil })
  }, [initialDateFrom, initialDateUntil])

  const handleFilter = () => {
    if (state.fromDate == null && state.untilDate == null) return
    const dateFrom = state.fromDate
    if (state.fromDate) {
      dateFrom.hours(state.fromTime ? state.fromTime.hours() : 0)
      dateFrom.minutes(state.fromTime ? state.fromTime.minutes() : 0)
    }

    const dateUntil = state.untilDate
    if (state.untilDate) {
      dateUntil.hours(state.untilTime ? state.untilTime.hours() : 23)
      dateUntil.minutes(state.untilTime ? state.untilTime.minutes() : 59)
    }

    onSubmit(dateFrom ? dateFrom.format() : null, dateUntil ? dateUntil.format() : null)
    setState({
      fromDate: null, fromTime: null, untilDate: null, untilTime: null,
    })
  }

  return (
    <FilterPanel title="Filter by Date" onSubmit={handleFilter} id={id}>
      <div className={style.dateTimeContainer}>
        <div className={style.dateTime}>
          <DatePicker
            id="dateFrom"
            label="Date from"
            maxDate={moment()}
            value={get(state, 'fromDate', initialDateFrom)}
            inputFormat="DD.MM.YYYY"
            mask="__.__.____"
            onChange={(date) => { setState({ ...state, fromDate: date }) }}
            renderInput={params => <TextField {...params} className={style.datePicker} fullWidth />}
          />
          <TimePicker
            id="timeFrom"
            label="Time"
            ampm={false}
            value={get(state, 'fromTime', initialDateFrom)}
            onChange={(date) => { setState({ ...state, fromTime: date }) }}
            renderInput={params => <TextField {...params} fullWidth />}
          />
        </div>
        <div className={style.dateTime}>
          <DatePicker
            id="dateUntil"
            label="Date until"
            maxDate={moment()}
            value={get(state, 'untilDate', initialDateUntil)}
            inputFormat="DD.MM.YYYY"
            mask="__.__.____"
            onChange={(date) => { setState({ ...state, untilDate: date }) }}
            renderInput={params => <TextField {...params} className={style.datePicker} fullWidth />}
          />
          <TimePicker
            id="timeUntil"
            label="Time"
            ampm={false}
            value={get(state, 'untilTime', initialDateUntil)}
            onChange={(date) => { setState({ ...state, untilTime: date }) }}
            renderInput={params => <TextField {...params} fullWidth />}
          />
        </div>
      </div>
    </FilterPanel>
  )
}


DateFilter.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialDateFrom: PropTypes.instanceOf(moment),
  initialDateUntil: PropTypes.instanceOf(moment),
}

DateFilter.defaultProps = {
  id: 'dateFilter',
  initialDateFrom: null,
  initialDateUntil: null,
}

export default memo(DateFilter)
