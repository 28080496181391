import ReportingEventEmitter from 'core/eventEmitter'
import get from 'lodash/get'
import { dispatcher, events } from 'core/dispatcher'

const withDefaults = settings => ({
  enabled: get(settings, 'enabled') || false,
  remoteTest: get(settings, 'remoteTest') || '',
  resultName: get(settings, 'resultName') || '',
  resultPrefix: get(settings, 'resultPrefix') || '',
  template: get(settings, 'template') || '',
  orderName: get(settings, 'orderName') || '',
  tolerance: get(settings, 'tolerance') || '',
  user: get(settings, 'user') || '',
  filesFrom: get(settings, 'filesFrom') || '',
  steps: get(settings, 'steps') || '',
})

class ExcelExportDefaultsStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      defaults: withDefaults(),
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationExcelExport.dataReceived: {
      this.state.defaults = withDefaults(action.defaults)
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.configurationExcelExport.valueChanged: {
      this.state.defaults = action.defaults
      this.emitChange()
      break
    }
    case events.configurationExcelExport.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new ExcelExportDefaultsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
