import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

class ConfirmationButton extends Component {
  state = { showDialog: false }

  handleClick = () => {
    if (this.props.requireConfiguration) {
      this.setState({ showDialog: true })
    } else {
      this.props.onClick()
    }
  }

  render() {
    const {
      onClick, message, requireConfiguration, children, ...otherProps
    } = this.props
    if (this.state.showDialog) {
      return (
        <Dialog
          open
          maxWidth="md"
          fullWidth
          onClose={() => this.setState({ showDialog: false })}
        >
          <DialogTitle>Confirm Approval</DialogTitle>
          <DialogContent>{message}</DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ showDialog: false })}>Cancel</Button>
            <Button color="primary" onClick={() => this.props.onClick()}>Confirm</Button>
          </DialogActions>
        </Dialog>
      )
    }
    return (
      <Button variant="contained" {...otherProps} onClick={this.handleClick}>{children}</Button>
    )
  }
}

ConfirmationButton.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  requireConfiguration: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
}

export default ConfirmationButton
