import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const StepDetailLine = ({ value }) => {
  if (value.trim().startsWith('*')) {
    return (
      <div>
        <a className={style.keyword}>*</a>
        <span>{value.trim().slice(2)}</span>
      </div>
    )
  }
  return (
    <div>
      <span>{value.trim()}</span>
    </div>
  )
}

StepDetailLine.propTypes = {
  value: PropTypes.string.isRequired,
}

export default asLine(StepDetailLine, 'detail')
