import axios from 'axios'
import * as Common from 'services/common'

class YoutrackSettingsService {
  getList = () => {
    const url = '/api/youtrack/settings'
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  update = (settings) => {
    const { externalId } = settings.selectedYoutrack
    const url = `/api/youtrack/settings/${externalId}`
    const request = {
      instanceName: settings.instanceName ? settings.instanceName : settings.selectedYoutrack.instanceName,
      baseUrl: settings.baseUrl ? settings.baseUrl : settings.selectedYoutrack.baseUrl,
      username: settings.username ? settings.username : settings.selectedYoutrack.username,
      password: settings.password ? settings.password : settings.selectedYoutrack.password,
      state: settings.state ? settings.state : settings.selectedYoutrack.state,
      customerState: settings.customerState ? settings.customerState : settings.selectedYoutrack.customerState,
      testCaseExecution: settings.testCaseExecution ? settings.testCaseExecution : settings.selectedYoutrack.testCaseExecution,
      testCaseId: settings.testCaseId ? settings.testCaseId : settings.selectedYoutrack.testCaseId,
    }

    return axios.post(url, request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  add = (settings) => {
    const url = '/api/youtrack/settings'
    const request = {
      instanceName: settings.instanceName,
      baseUrl: settings.baseUrl,
      username: settings.username,
      password: settings.password,
      state: settings.state,
      customerState: settings.customerState,
      testCaseExecution: settings.testCaseExecution,
      testCaseId: settings.testCaseId,
    }

    return axios.post(url, request, Common.requestOptions)
      .catch(Common.catchError)
  }

  delete = (youtrack) => {
    const url = `/api/youtrack/settings/${encodeURIComponent(youtrack.externalId)}`
    return axios.delete(url, Common.requestOptions)
      .catch(Common.catchError)
  }
}

const instance = new YoutrackSettingsService()
export default instance
