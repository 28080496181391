import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { DatePicker, TimePicker } from '@mui/lab'
import TextField from '@mui/material/TextField'

const inlineStyle = {
  wrapper: {
    display: 'flex',
    margin: '0 2px',
  },
}

class DateTimePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date: props.initialValue ? new Date(props.initialValue) : null,
      hour: props.initialValue ? moment(props.initialValue).hour() : 0,
      minute: props.initialValue ? moment(props.initialValue).minute() : 0,
    }
  }

  handleDateChange = (date) => {
    this.setState({ date })
    const fullDate = date.hour(this.state.hour).minute(this.state.minute)
    this.props.onChange(fullDate.format())
  }

  handleTimeChange = (time) => {
    this.setState({ hour: time.hour(), minute: time.minute() })
    if (this.state.date) {
      const fullDate = this.state.date.hour(time.hour()).minute(time.minute())
      this.props.onChange(fullDate.format())
    }
  }

  render() {
    return (
      <div style={inlineStyle.wrapper}>
        <DatePicker
          label="Date"
          container="inline"
          value={this.state.date}
          inputFormat="DD.MM.YYYY"
          mask="__.__.____"
          onChange={this.handleDateChange}
          renderInput={params => <TextField {...params} />}
        />
        <TimePicker
          label="Time"
          ampm={false}
          value={this.state.date}
          onChange={this.handleTimeChange}
          renderInput={params => <TextField {...params} />}
        />
      </div>
    )
  }
}

DateTimePicker.propTypes = {
  initialValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

DateTimePicker.defaultProps = {
  initialValue: null,
}

export default DateTimePicker
