import { dispatcher, events } from 'core/dispatcher'
import HpqcService from 'services/hpqc/hpqcService'

export function retrieveInitialData(project) {
  HpqcService.retriveHpqcSettings(project)
    .then((result) => {
      dispatcher.dispatch({
        type: events.configurationHpqcExcelImport.dataReceived,
        excelImportSettings: result.excelImportSettings,
        customFields: result.customFields,
      })
    })
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcExcelImport.failed, message }) })
}

export function handleAddRow() {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.addRow })
}

export function handleCancelRow() {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.cancelEdit })
}

export function handleCancelPart() {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.cancelPartEdit })
}

export function handleEdit(field) {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.editRow, field })
}

export function handleAddPart(field) {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.addedPart, field })
}

function extractList(gettingList) {
  gettingList
    .then((response) => {
      dispatcher.dispatch({
        type: events.configurationHpqcExcelImport.saved,
        list: response.hpqc,
        verificationTime: response.verificationTime,
        verifiedBy: response.verifiedBy,
      })
    })
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcExcelImport.failed, message }) })
}

function updateSettings(project) {
  return (importSettings) => {
    extractList(HpqcService.updateExcelImportSettings(project, importSettings))
  }
}

export function handlePartDelete(project, field) {
  return (column) => {
    dispatcher.dispatch({
      type: events.configurationHpqcExcelImport.deletedPart,
      save: updateSettings(project),
      field,
      column,
    })
  }
}

export function handlePartMoveUp(project, field) {
  return (column) => {
    dispatcher.dispatch({
      type: events.configurationHpqcExcelImport.movedPartUp,
      save: updateSettings(project),
      field,
      column,
    })
  }
}

export function handlePartMoveDown(project, field) {
  return (column) => {
    dispatcher.dispatch({
      type: events.configurationHpqcExcelImport.movedPartDown,
      save: updateSettings(project),
      field,
      column,
    })
  }
}

export function saveHeader({ projectCode, headerRow, sheet, syncColumn }) {
  dispatcher.dispatch({
    type: events.configurationHpqcExcelImport.updatedHeader,
    save: updateSettings(projectCode),
    headerRow,
    sheet,
    syncColumn,
  })
}


export function save(project) {
  return newRow => dispatcher.dispatch({ type: events.configurationHpqcExcelImport.savedRow, save: updateSettings(project), newRow })
}

export function handleSavePart(project, newPart) {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.savedPart, save: updateSettings(project), newPart })
}

export function handleDelete(project, field) {
  dispatcher.dispatch({ type: events.configurationHpqcExcelImport.deleted, save: updateSettings(project), field })
}
