import axios from 'axios'
import * as Common from 'services/common'
import * as Utils from 'components/utils'

class YoutrackService {
  INVALID_TICKET_ID = 'Invalid Ticket ID'

  getTicketStatus = (testSuiteId) => {
    const url = `/api/youtrack/${testSuiteId}`
    return axios.get(url, {}, Common.requestOptions)
      .then(response => (response.status === 204 ? null : response.data))
      .catch(Common.catchError)
  }

  saveTicketId = (newValue, testSuiteId) => {
    const url = `/api/youtrack/${testSuiteId}`
    const request = { id: newValue }
    return axios.post(url, request, Common.requestOptions)
      .catch((error) => {
        const authFailure = Common.handleAuthenticationFailure(error)
        if (authFailure) return authFailure
        if (error.response && error.response.status === 404) {
          return Promise.reject(this.INVALID_TICKET_ID)
        }
        return Promise.reject(Utils.extractMessage(error))
      })
  }
}

const instance = new YoutrackService()
export default instance
