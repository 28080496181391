import { useEffect, useReducer } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Snackbar from '@mui/material/Snackbar'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import Settings from './settings'
import { loadSettings, saveSettings } from './actions'
import reducer from './reducer'

const initialState = {
  loading: true,
  showSuccessMessage: false,
  settings: {},
}

const CheqSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    loadSettings(projectCode)
      .then(settings => dispatch({ type: 'loaded', settings }))
      .catch(handleError)
  }, [handleError, projectCode])
  const handleSave = settings => saveSettings(projectCode, settings)
    .then(() => dispatch({ type: 'saved' }))
    .catch(handleError)
  return (
    <Card>
      <CardHeader title="CheQ Settings" />
      <CardContent>
        <Spinner spinning={state.loading}>
          <Settings settings={state.settings} onSave={handleSave} />
          <Snackbar open={state.showSuccessMessage} message="Settings were saved successfully." autoHideDuration={10000} />
        </Spinner>
      </CardContent>
    </Card>
  )
}

export default CheqSettings
