import { useCallback, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import JiraUploadsTable from 'components/executionDetails/jiraUploadsTable'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { retrieve, upload } from './actions'
import style from './style.module.scss'


const initialValue = { loading: true, list: [] }
const reducer = (state, action) => {
  switch (action.type) {
  case 'list received':
    return { loading: false, list: action.list }
  default:
    throw new Error('unknown event')
  }
}

const BasicJiraDetails = ({ executionId, testId }) => {
  const [state, dispatch] = useReducer(reducer, initialValue)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  const loadUploadHistory = () => retrieve(projectCode, executionId, testId)
    .then(response => dispatch({ type: 'list received', list: response.uploads }))
    .catch(errorHandler)


  useEffect(() => { loadUploadHistory() }, [executionId, testId])
  useEffect(() => ReactTooltip.rebuild())

  const handleUpload = useCallback(() => {
    upload(projectCode, executionId, testId)
      .then(() => loadUploadHistory())
      .catch(errorHandler)
  }, [executionId, testId])

  return (
    <Card className={style.card}>
      <CardHeader title="Jira Uploads" />
      <Spinner spinning={state.loading}>
        <JiraUploadsTable list={state.list} />
        <div className={style.buttons}>
          <Button variant="contained" onClick={handleUpload}>Upload</Button>
        </div>
      </Spinner>
    </Card>
  )
}

BasicJiraDetails.propTypes = {
  executionId: PropTypes.string.isRequired,
  testId: PropTypes.number.isRequired,
}

export default BasicJiraDetails
