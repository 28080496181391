import { useState, useCallback } from 'react'

const useErrorHandler = () => {
  const [error, setError] = useState()
  if (error != null) {
    throw Error(error)
  }
  return useCallback((message) => {
    setError(message)
  }, [])
}

export default useErrorHandler
