import { memo } from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'

import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import FormattedDate from 'components/formattedDate'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import Table from 'components/table'

import PropTypes from 'prop-types'
import style from './style.module.scss'

const TestSuiteTable = ({ items, projectCode, totalPages }) => {
  const columns = [
    {
      Header: '',
      accessor: 'lastStatus',
      Cell: item => <TestStatusIcon status={item.value} />,
      width: 48,
      disableResizing: true,
    },
    {
      Header: '',
      accessor: 'id',
      Cell: item => (
        <Link to={`/${projectCode}/reports/test-run-report?testSuiteId=${item.value}`}>
          <IconButton size="small"><SearchIcon /></IconButton>
        </Link>
      ),
      width: 48,
      disableResizing: true,
    },
    {
      Header: 'Name',
      accessor: 'name',
      Cell: item => (
        <Link to={`/${projectCode}/test-suite/details/${item.row.original.id}`}>
          <TrimmedText text={item.value} maxLength={30} />
        </Link>
      ),
      sortable: true,
    },
    {
      Header: 'Error Category',
      accessor: 'lastErrorCategory',
      Cell: item => (
        <ErrorCategoryColumn
          className={style.errorCategoryColumn}
          labels={item.row.original.labels}
          errorCategories={[item.value]}
        />
      ),
    },
    {
      Header: 'Last Executed',
      accessor: 'lastExecutionTime',
      Cell: item => <FormattedDate value={item.value} />,
      sortable: true,
    },
    {
      Header: 'Last Success',
      accessor: 'lastPassedTime',
      Cell: item => <FormattedDate value={item.value} />,
      sortable: true,
    },
    {
      Header: 'Last Failure',
      accessor: 'lastFailureTime',
      Cell: item => <FormattedDate value={item.value} />,
      sortable: true,
    },
  ]

  return (
    <Table
      id="testSuite"
      columns={columns}
      data={items}
      totalPages={totalPages}
    />
  )
}

TestSuiteTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  projectCode: PropTypes.string.isRequired,
  totalPages: PropTypes.number,
}


TestSuiteTable.defaultProps = {
  items: [],
  totalPages: undefined,
}

export default memo(TestSuiteTable)
