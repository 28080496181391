import { useEffect, useReducer } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { VictoryPie } from 'victory'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { getSuccessRate, updateChart } from './actions'
import { initialState, reducer } from './reducer'
import style from './successRate.module.css'

const inlineStyle = {
  periodSelection: {
    width: '140px', margin: '16px', position: 'absolute', top: '0', right: '0',
  },
  chartParent: { parent: { overflow: 'visible', maxWidth: '600px', margin: '0 auto' } },
}

const colorScale = ['#3c8863', '#c62828', '#e5cd38']

const SuccessRate = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  useEffect(() => {
    getSuccessRate(projectCode, state.timePeriod)
      .then(data => dispatch({ type: 'received', data }))
      .catch(errorHandler)
  }, [errorHandler, projectCode, state.timePeriod])

  const handleChartPeriodSelection = (e) => {
    dispatch({ type: 'periodUpdated', period: e.target.value })
    updateChart(projectCode, e.target.value)
  }

  return (
    <Card className={style.paperChartContainer}>
      <CardHeader
        title="Success Rate"
        subheader={state.timePeriod}
        action={(
          <Select
            value={state.timePeriod}
            onChange={handleChartPeriodSelection}
            style={inlineStyle.periodSelection}
          >
            <MenuItem key="Today" value="Today">Today</MenuItem>
            <MenuItem key="LastWeek" value="Last week">Last week</MenuItem>
            <MenuItem key="LastMonth" value="Last month">Last month</MenuItem>
            <MenuItem key="All" value="Project">All</MenuItem>
          </Select>
        )}
      />
      {state.noExecutions
        && (
          <div className={style.noExecutions}>
            <a>No executions!</a>
          </div>
        )}
      {!state.noExecutions
        && (
          <Spinner spinning={state.loading}>
            <div className={style.chart}>
              <VictoryPie colorScale={colorScale} data={state.data} style={inlineStyle.chartParent} width={600} height={400} />
            </div>
          </Spinner>
        )}
    </Card>
  )
}

export default SuccessRate
