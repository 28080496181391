import { dispatcher, events } from 'core/dispatcher'
import TestCategoryService from 'services/testCategoryService'
import HpqcService from 'services/hpqc/hpqcService'

export function retrieveInitialData(project) {
  Promise.all([TestCategoryService.findAll(project), HpqcService.retriveHpqcConfiguration(project)])
    .then((result) => {
      dispatcher.dispatch({
        type: events.configurationHpqcTestSets.dataReceived,
        testCategories: result[0],
        hpqc: result[1].hpqc,
        verifiedBy: result[1].verifiedBy,
        verificationTime: result[1].verificationTime,
      })
    })
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcTestSets.failed, message }) })
}

export function handleNameChange(value) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldNameChanged, value })
}

export function handleTestCategoryChange(value) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldCategoryChanged, value })
}

export function handleDateFromChange(value) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldDateFromChanged, value })
}

export function handleDateToChange(value) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldDateToChanged, value })
}

export function handleTestSetIdChange(value) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldTestSetIdChanged, value })
}

export function handleEnabledChange(enabled) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.fieldEnabledChanged, enabled })
}

export function handleAdd() {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.addConfig })
}

export function handleCancel() {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.cancelEdit })
}

export function handleEdit(id) {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.editConfig, id })
}

export function validate() {
  dispatcher.dispatch({ type: events.configurationHpqcTestSets.validated })
}

function extractList(gettingList) {
  gettingList
    .then((response) => {
      dispatcher.dispatch({
        type: events.configurationHpqcTestSets.saved,
        list: response.hpqc,
        verificationTime: response.verificationTime,
        verifiedBy: response.verifiedBy,
      })
    })
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcTestSets.failed, message }) })
}

function add(config, project) {
  extractList(HpqcService.addHpqcConfiguration(config, project))
}

function update(config, project) {
  extractList(HpqcService.updateHpqcConfiguration(config, project))
}

export function verify(project) {
  extractList(HpqcService.verifyHpqcConfiguration(project))
}

export function handleDelete(project, id) {
  extractList(HpqcService.deleteHpqcConfiguration(project, id))
}

export function save(config, project) {
  if (config.id == null) {
    add(config, project)
  } else {
    update(config, project)
  }
}
