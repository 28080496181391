import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import withErrorBoundary from 'core/errorBoundary'
import useProject from 'hooks/useProject'

import { retrieveData, handleExport, handleImport, addLabel, removeLabel, create, remove, update } from './actions'
import ErrorCategoryDetails from './details'
import ErrorCategoryList from './list'

const initialState = {
  loading: true,
  list: [],
  labels: [],
}

const ErrorCategoryConfiguration = ({ match, history }) => {
  const [state, setState] = useState(initialState)
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  useEffect(() => {
    retrieveData(projectCode)
      .then(({ list, labels }) => setState({ loading: false, labels, list }))
      .catch(errorHandler)
  }, [errorHandler, projectCode])
  if (state.error != null) { throw Error(state.error) }
  const handleExportClick = () => handleExport(projectCode)
  const handleImportClick = (file) => {
    setState(s => ({ ...s, loading: true }))
    handleImport(projectCode, file)
      .then(list => setState(s => ({ ...s, loading: false, list })))
      .catch(errorHandler)
  }
  const showUpdatedList = (list) => {
    setState(s => ({ ...s, loading: false, list }))
    history.push(match.url)
  }
  const handleCreateCategory = (category) => {
    setState(s => ({ ...s, loading: true }))
    create(projectCode, category).then(showUpdatedList).catch(errorHandler)
  }
  const handleUpdateCategory = (category) => {
    setState(s => ({ ...s, loading: true }))
    update(projectCode, category).then(showUpdatedList).catch(errorHandler)
  }
  const handleDelete = (category) => {
    setState(s => ({ ...s, loading: true }))
    remove(projectCode, category).then(showUpdatedList).catch(errorHandler)
  }
  const handleCancel = () => {
    history.push(match.url)
  }
  const handleAddLabel = (category, label) => {
    setState(s => ({ ...s, loading: true }))
    addLabel(projectCode, category, label).then(showUpdatedList).catch(errorHandler)
  }
  const handleDeleteLabel = (category, label) => {
    setState(s => ({ ...s, loading: true }))
    removeLabel(projectCode, category, label).then(showUpdatedList).catch(errorHandler)
  }
  return (
    <Spinner spinning={state.loading}>
      <Switch>
        <Route
          path={match.url}
          exact
          render={() => (
            <ErrorCategoryList
              list={state.list}
              labels={state.labels}
              onDelete={handleDelete}
              onExport={handleExportClick}
              onImport={handleImportClick}
              onAddLabel={handleAddLabel}
              onRemoveLabel={handleDeleteLabel}
            />
          )}
        />
        <Route
          path={`${match.url}/new`}
          render={() => (
            <ErrorCategoryDetails onSave={handleCreateCategory} onCancel={handleCancel} />
          )}
        />
        <Route
          path={`${match.url}/:id`}
          render={(props) => {
            const { id } = props.match.params
            const selectedCategory = state.list.find(it => it.externalId === id)
            if (!selectedCategory) { throw Error('Error category not found!') }
            return (
              <ErrorCategoryDetails category={selectedCategory} onCancel={handleCancel} onSave={handleUpdateCategory} />
            )
          }}
        />
      </Switch>
    </Spinner>
  )
}

ErrorCategoryConfiguration.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
    url: PropTypes.string,
  }).isRequired,
}

export default withErrorBoundary(withRouter(ErrorCategoryConfiguration))
