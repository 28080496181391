import { useEffect } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import useProject from 'hooks/useProject'

import ApiSecretForm from 'components/user/settings/apiSecretForm'
import saveApiSecret from './actions'
import style from './style.module.scss'

const UserSettings = () => {
  const { projectCode, user } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Personal Settings'
  }, [])
  return (
    <Card>
      <CardHeader title={`Personal Settings: ${user.name}`} />
      <CardContent className={style.contents}>
        <ApiSecretForm onSubmit={saveApiSecret} />
        <br />
        <div>
          <p>Insert the following section to your project configuration file</p>
          <div className={style.code}>{
            `ReportingService = {
              enabled: true
              baseUrl: "${window.location.origin}"
              project: "${projectCode || '[PROJECT CODE]'}"
            }`
          }
          </div>
        </div>
        <div>
          <p>Insert the following section to you intaQt global server configuration file</p>
          <div className={style.code}>{
            `ReportingServiceUser = {
              email: "${user.email}"
              apiSecret: "[Your report API secret]"
            }`
          }
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default UserSettings
