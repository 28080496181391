import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class ConditionsStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      list: [],
      error: null,
      selectedRow: null,
      addCondition: false,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationSuccessConditions.dataReceived: {
      this.state.list = action.list.sort((a, b) => a.id.localeCompare(b.id))
      this.state.selectedRow = null
      this.state.addCondition = false
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.configurationSuccessConditions.editCondition: {
      this.state.selectedRow = action.id
      this.state.newConditionConfig = { ...this.state.list.find(it => it.id === action.id) }
      this.state.addCondition = false
      this.emitChange()
      break
    }
    case events.configurationSuccessConditions.addCondition: {
      this.state.selectedRow = null
      this.state.addCondition = true
      this.emitChange()
      break
    }
    case events.configurationSuccessConditions.cancelEdit: {
      this.state.selectedRow = null
      this.state.addCondition = false
      this.emitChange()
      break
    }
    case events.configurationSuccessConditions.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const conditionsStore = new ConditionsStore()
dispatcher.register(conditionsStore.handleActions.bind(conditionsStore))

export default conditionsStore
