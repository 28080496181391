import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const TagLine = ({ tags }) => (
  <div>
    { tags.map(tag => <span className={style.tag} key={tag}>{tag}</span>)}
  </div>
)

TagLine.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default asLine(TagLine)
