import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Snackbar from '@mui/material/Snackbar'

import { ExportIcon, UploadIcon } from 'components/icons'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import HpqcReadyReportTable from './table'
import Store from './store'
import * as Actions from './actions'
import style from './style.module.css'

class HpqcReadyReport extends Component {
  state = Store.getState()

  componentDidMount = () => {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Executions ready to upload to HPQC'
    Store.on('change', this.updateState)
    Actions.retrieveReport(projectCode)
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { match } = this.props
    const {
      error, loading, items, selectedExecutions, showSuccessMessage,
    } = this.state
    if (error != null) {
      throw Error(error)
    }

    return (
      <Spinner spinning={loading}>
        <Card className={style.card}>
          <CardHeader title="Executions ready to upload to HPQC" />
          <div className={style.buttonBar}>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedExecutions.length === 0}
              onClick={() => Actions.downloadReports(this.context.projectCode, selectedExecutions)}
              endIcon={<ExportIcon />}
            >
              Export
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedExecutions.length === 0}
              onClick={() => Actions.uploadReports(this.context.projectCode, selectedExecutions)}
              endIcon={<UploadIcon />}
            >
              Upload
            </Button>
          </div>
          <HpqcReadyReportTable
            items={items}
            selectedExecutions={selectedExecutions}
            onSelect={Actions.updateSelectedExecutions}
            projectCode={match.params.projectCode}
          />
          <Snackbar
            open={showSuccessMessage}
            message="Executions were exported to the transfer service successfully."
            autoHideDuration={0}
            onClose={Actions.handleSnackBarClose}
          />
        </Card>
      </Spinner>
    )
  }
}

HpqcReadyReport.contextType = ProjectContext

HpqcReadyReport.propTypes = { match: PropTypes.shape({ params: PropTypes.object }).isRequired }

export default withRouter(HpqcReadyReport)
