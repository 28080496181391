import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class TestSuiteStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      list: [],
      selectedItems: [],
      loading: true,
      defaultPageSize: 100,
      confirmationMessage: '',
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testPlanList.listReceived: {
      this.state.list = action.list.items
      this.state.totalSize = action.list.totalSize
      this.state.selectedItems = []
      this.state.isSelectedAll = false
      this.state.selectedHidden = false
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testPlanList.listExpired: {
      this.state.loading = true
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testPlanList.failed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testPlanList.confirmed: {
      this.state.confirmationMessage = ''
      this.emitChange()
      break
    }
    case events.testPlanList.imported: {
      this.state.confirmationMessage = 'Excel imported.'
      this.emitChange()
      break
    }
    case events.testPlanList.importFailed: {
      this.state.confirmationMessage = `Excel import failed: ${action.error}`
      this.emitChange()
      break
    }
    case events.testPlanList.selected: {
      this.state.selectedItems = action.ids
      this.state.isSelectedAll = (this.state.list.length === this.state.selectedItems.length)
      this.state.selectedHidden = false
      this.emitChange()
      break
    }
    case events.testPlanList.selectedAll: {
      this.state.isSelectedAll = (this.state.list.length !== this.state.selectedItems.length) || action.includeHidden
      if (this.state.isSelectedAll || action.includeHidden) {
        this.state.selectedItems = this.state.list.map(it => it.reference)
      } else {
        this.state.selectedItems = []
      }
      this.state.selectedHidden = action.includeHidden
      this.emitChange()
      break
    }
    case events.testPlanList.testInstancesCreated: {
      this.state.successMessage = 'Test instance creation is being done in background.\nThis may take a while.'
      this.state.selectedItems = []
      this.state.isSelectedAll = false
      this.state.selectedHidden = false
      this.emitChange()
      break
    }
    case events.testPlanList.testPlansCreated: {
      this.state.successMessage = 'Test plan creation is being done in background.\nThis may take a while.'
      this.state.selectedItems = []
      this.state.isSelectedAll = false
      this.state.selectedHidden = false
      this.emitChange()
      break
    }
    case events.testPlanList.testPlansExported: {
      this.state.successMessage = 'Test plans exported to transfer service successfully.'
      this.emitChange()
      break
    }
    case events.testPlanList.testInstancesExported: {
      this.state.successMessage = 'Test instances exported to transfer service successfully.'
      this.emitChange()
      break
    }
    case events.testPlanList.notificationConfirmed: {
      this.state.successMessage = null
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new TestSuiteStore()
dispatcher.register(store.handleActions.bind(store))

export default store
