import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import calcTotalPages from 'utils/calcTotalPages'

const PAGE_SIZE = 50

class HpqcExecutionStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      items: [],
      loading: true,
      totalPages: 0,
      pageSize: PAGE_SIZE,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.hpqcExecutionReport.listReceived: {
      this.state.items = action.list.items
        .map(item => ({ ...item, uploadSuccessful: item.uploadStatus === 'UPLOADED', defectsString: item.defects.map(it => it.id).toString() }))
      this.state.totalPages = calcTotalPages({ totalSize: action.list.totalSize, pageSize: PAGE_SIZE })
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.hpqcExecutionReport.failed: {
      this.state = { error: action.error }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcExecutionStore()
dispatcher.register(store.handleActions.bind(store))

export default store
