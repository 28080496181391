import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import DataField from 'components/executionDetails/dataField'
import Switch from '@mui/material/Switch'
import style from './settings.module.css'


const validateSettings = (settings) => {
  let errors = {}
  if (settings.enabled && (!settings.baseUrl || settings.baseUrl.length === 0)) {
    errors = { ...errors, baseUrl: 'Cannot be empty' }
  }
  if (settings.enabled && (!settings.authToken || settings.authToken.length === 0)) {
    errors = { ...errors, authToken: 'Cannot be empty' }
  }

  // eslint-disable-next-line
  if (settings.socketTimeoutMillis && (settings.socketTimeoutMillis != parseInt(settings.socketTimeoutMillis, 10))) {
    errors = { ...errors, socketTimeoutMillis: 'Must be a number' }
  }

  // eslint-disable-next-line
  if (settings.pollCdrsAfterMinutes && (settings.pollCdrsAfterMinutes != parseInt(settings.pollCdrsAfterMinutes, 10))) {
    errors = { ...errors, pollCdrsAfterMinutes: 'Must be a number' }
  }
  return errors
}

const LinqSettings = ({ settings, onChange }) => {
  const updateSettings = (s) => {
    const errors = validateSettings(s)
    onChange(
      { enabled: s.enabled,
        authToken: s.authToken,
        baseUrl: s.baseUrl,
        socketTimeoutMillis: s.socketTimeoutMillis,
        pollCdrsAfterMinutes: s.pollCdrsAfterMinutes,
        allowSelfSignedCertificates: s.allowSelfSignedCertificates,
        errors },
    )
  }
  return (
    <div className={style.content}>
      <FormControlLabel
        label="Enabled"
        control={(
          <Checkbox
            id="enabledField"
            checked={settings.enabled}
            onChange={e => updateSettings({ ...settings, enabled: e.target.checked })}
          />
        )}
      />
      <DataField name="Url">
        <TextField
          id="urlField"
          fullWidth
          value={settings.baseUrl}
          error={settings.errors.baseUrl != null}
          label={settings.errors.baseUrl}
          onChange={e => updateSettings({ ...settings, baseUrl: e.target.value })}
          disabled={!settings.enabled}
        />
      </DataField>
      <DataField name="Authentication Token">
        <TextField
          id="authTokenField"
          fullWidth
          value={settings.authToken}
          error={settings.errors.authToken != null}
          label={settings.errors.authToken}
          onChange={e => updateSettings({ ...settings, authToken: e.target.value })}
          disabled={!settings.enabled}
        />
      </DataField>
      <DataField name="Socket Timeout (milliseconds)">
        <TextField
          id="socketTimeoutMillisField"
          fullWidth
          value={settings.socketTimeoutMillis}
          error={settings.errors.socketTimeoutMillis != null}
          label={settings.errors.socketTimeoutMillis}
          onChange={e => updateSettings({ ...settings, socketTimeoutMillis: e.target.value })}
          disabled={!settings.enabled}
        />
      </DataField>
      <DataField name="Allow self-signed certificates">
        <Switch
          id="linqAllowSelfSigned"
          checked={settings.allowSelfSignedCertificates}
          label={settings.errors.allowSelfSignedCertificates}
          onChange={e => updateSettings({ ...settings, allowSelfSignedCertificates: e.target.checked })}
        />
      </DataField>
      <DataField name="Poll CDRs after (minutes)">
        <TextField
          id="pollCdrsAfterMinutesField"
          fullWidth
          value={settings.pollCdrsAfterMinutes}
          error={settings.errors.pollCdrsAfterMinutes != null}
          label={settings.errors.pollCdrsAfterMinutes}
          onChange={e => updateSettings({ ...settings, pollCdrsAfterMinutes: e.target.value })}
          disabled={!settings.enabled}
        />
      </DataField>
    </div>
  )
}

LinqSettings.propTypes = {
  settings: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LinqSettings
