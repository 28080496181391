import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const defaultSettings = {
  csvSeparator: ';',
}

class ProjectConfigStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      blacklist: [],
      settings: defaultSettings,
      showSuccessMessage: false,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationProject.dataReceived: {
      this.state.settings = action.config || defaultSettings
      this.state.blacklist = this.state.settings.blacklist || []
      this.state.loading = false
      this.state.error = null
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.configurationProject.settingsChanged: {
      this.state.showSuccessMessage = false
      this.state.settings = action.settings
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.configurationProject.blacklistChanged: {
      this.state.blacklist = action.blacklist
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.configurationProject.failed: {
      this.state.showSuccessMessage = false
      this.state.error = action.message
      this.emitChange()
      break
    }
    case events.configurationProject.saved: {
      this.state.showSuccessMessage = true
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new ProjectConfigStore()
dispatcher.register(store.handleActions.bind(store))

export default store
