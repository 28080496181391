import calcTotalPages from 'utils/calcTotalPages'
import ReportingEventEmitter from '../../core/eventEmitter'
import { dispatcher, events } from '../../core/dispatcher'

const PAGE_SIZE = 50

class VerificationStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      items: [],
      selectedItems: [],
      totalPages: 0,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.verification.listReceived: {
      this.state.items = action.list.items
      this.state.totalPages = calcTotalPages({ totalSize: action.list.totalSize, pageSize: PAGE_SIZE })
      this.state.loading = false
      this.state.selectedItems = []
      this.state.isSelectedAll = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.verification.failed: {
      this.state.error = action.error
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.verification.selected: {
      this.state.selectedItems = action.ids
      this.state.isSelectedAll = (this.state.items.length === this.state.selectedItems.length)
      this.emitChange()
      break
    }
    case events.verification.selectedAll: {
      this.state.isSelectedAll = (this.state.items.length !== this.state.selectedItems.length)
      if (this.state.isSelectedAll) {
        this.state.selectedItems = this.state.items.map(it => it.testId)
      } else {
        this.state.selectedItems = []
      }
      this.emitChange()
      break
    }
    case events.verification.verifying: {
      this.state.loading = true
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const verificationStore = new VerificationStore()
dispatcher.register(verificationStore.handleActions.bind(verificationStore))

export default verificationStore
