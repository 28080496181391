import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'
import JiraFieldTypeSelect from './jiraFieldTypeSelectField'
import style from './settings.module.css'

const EditRow = ({
  newField, errors, onChange, onCancel, onAdd,
}) => (
  <form onSubmit={(e) => {
    onAdd()
    e.preventDefault()
  }}
  >
    <div className={style.itemRow}>
      <div className={style.nameColumn}>
        <TextField
          id="name"
          fullWidth
          value={newField.name || ''}
          error={errors.has('fieldName')}
          label={errors.get('fieldName')}
          onChange={e => onChange({ ...newField, name: e.target.value })}
        />
      </div>
      <div className={style.codeColumn}>
        <TextField
          id="externalCode"
          fullWidth
          value={newField.externalCode || ''}
          error={errors.has('fieldExternalCode')}
          label={errors.get('fieldExternalCode')}
          onChange={e => onChange({ ...newField, externalCode: e.target.value })}
        />
      </div>
      <div className={style.tagColumn}>
        <TextField
          id="tag"
          fullWidth
          value={newField.tag || ''}
          error={errors.has('fieldTag')}
          label={errors.get('fieldTag')}
          onChange={e => onChange({ ...newField, tag: e.target.value })}
        />
      </div>
      <div className={style.defaultColumn}>
        <TextField
          id="tag"
          fullWidth
          value={newField.defaultValue || ''}
          error={errors.has('fieldDefaultValue')}
          label={errors.get('fieldDefaultValue')}
          onChange={e => onChange({ ...newField, defaultValue: e.target.value })}
        />
      </div>
      <div className={style.typeColumn}>
        <JiraFieldTypeSelect
          id="tagField"
          fullWidth
          value={newField.type || ''}
          onChange={e => onChange({ ...newField, type: e.target.value })}
        />
      </div>
      <div className={style.buttonColumn}>
        <IconButton src="/images/accept.svg" onClick={onAdd} />
        <IconButton src="/images/cancel.svg" onClick={onCancel} />
        <input type="submit" hidden />
      </div>
    </div>
  </form>
)


EditRow.propTypes = {
  errors: PropTypes.instanceOf(Map).isRequired,
  newField: PropTypes.shape().isRequired,
  onAdd: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default EditRow
