import PropTypes from 'prop-types'

import style from './style.module.scss'

const SeparatorLine = ({ onClick }) => (
  <div className={style.separator}>
    <div onClick={onClick} role="presentation" />
    <div className={style.separatorCut} />
  </div>
)

SeparatorLine.propTypes = {
  onClick: PropTypes.func,
}

SeparatorLine.defaultProps = {
  onClick: () => {},
}

export default SeparatorLine
