import PropTypes from 'prop-types'
import CardHeader from '@mui/material/CardHeader'

import IconButton from 'components/iconButton'

import HeaderRow from './headerRow'
import ItemRow from './itemRow'
import style from './settings.module.css'

const CustomFields = ({
  list, onAdd, children, onDelete, missingFields,
}) => (
  <div className="col-md-6">
    <CardHeader title="Custom Fields" />
    <div className={style.wrapper}>
      <HeaderRow key="header" />
      {list.map(item => <ItemRow key={item.name} {...item} onDelete={onDelete} />)}
      { children }
      <div key="buttonRow" className={style.buttonRow}>
        <IconButton onClick={onAdd} src="/images/add.svg" />
      </div>
      {missingFields.length > 0
      && <div className={style.errorRow}>Required jira custom fields are missing: {missingFields.map(item => <div>{item}</div>)}</div>}
    </div>
  </div>
)

CustomFields.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  children: PropTypes.node,
  missingFields: PropTypes.arrayOf(PropTypes.string),
}

CustomFields.defaultProps = {
  children: null,
  missingFields: [],
}


export default CustomFields
