import { dispatcher, events } from 'core/dispatcher'
import { Client } from '@stomp/stompjs'

const client = new Client({
  connectHeaders: {
    login: 'testmanager',
    passcode: 'guest',
  },
  reconnectDelay: 10000,
  heartbeatIncoming: 4000,
  heartbeatOutgoing: 4000,
})

export const send = (queue, message) => {
  client.publish({ destination: `/api/socket/${queue}`, body: message })
}

export const connect = (url) => {
  const protocol = location.protocol === 'http:' ? 'ws:' : 'wss:' // eslint-disable-line no-restricted-globals
  client.brokerURL = `${protocol}//${location.host}${url}` // eslint-disable-line no-restricted-globals
  client.onConnect = () => {
    dispatcher.dispatch({ type: events.messaging.connected })
    client.subscribe('/user/topic/notification', (message) => {
      dispatcher.dispatch({ type: `notification.${message.headers.action}`, payload: message.body })
    })
  }
  client.onStompError = (frame) => {
    dispatcher.dispatch({ type: 'messaging.error', message: frame.headers.message })
  }
  client.activate()
}

export const disconnect = () => {
  client.deactivate()
}
