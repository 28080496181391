import { memo } from 'react'
import style from './settings.module.css'

const HeaderRow = memo(() => (
  <div className={style.headerRow}>
    <div className={style.nameColumn}>Name</div>
    <div className={style.codeColumn}>Jira code</div>
    <div className={style.tagColumn}>Metadata name</div>
    <div className={style.defaultColumn}>Default value</div>
    <div className={style.typeColumn}>Field type</div>
    <div className={style.buttonColumn} />
  </div>
))

export default HeaderRow
