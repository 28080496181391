import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import style from './style.module.scss'

const ResultIcon = ({ result, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <Icon
      data-tip={result}
      data-class={style.iconTooltip}
      className={style.icon}
    >
      visibility
    </Icon>
  </a>
)

ResultIcon.propTypes = { result: PropTypes.string.isRequired, link: PropTypes.string.isRequired }

export default ResultIcon
