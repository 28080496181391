import { dispatcher, events } from 'core/dispatcher'
import PmsExportService from 'services/pms/exportService'

export default function retrieveInitialData(project, pmsExportId) {
  return PmsExportService.findOne(project, pmsExportId)
    .then((pmsExportDetails) => {
      dispatcher.dispatch({ type: events.pmsExportDetail.detailsReceived, pmsExportDetails })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.pmsExportDetail.exportDetailsFailed, error }) })
}
