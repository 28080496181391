import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import calcTotalPages from 'utils/calcTotalPages'

import style from './style.module.css'


const withPaging = (Component) => {
  const C = ({ items, totalSize, pageSize, projectCode }) => {
    useEffect(() => { ReactTooltip.rebuild() })
    return (
      <Card className={style.card}>
        <div className={style.reportsContainer}>
          <CardHeader title="Executions uploaded to Jira" />
          <Component list={items} projectCode={projectCode} totalPages={calcTotalPages({ totalSize, pageSize })} />
        </div>
      </Card>
    )
  }
  C.propTypes = {
    projectCode: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalSize: PropTypes.number.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  return C
}


export default withPaging
