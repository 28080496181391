import { useEffect, useReducer } from 'react'
import Snackbar from '@mui/material/Snackbar'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import Settings from './settings'
import { loadSettings, saveSettings } from './actions'

const initialState = {
  loading: true,
  showSuccessMessage: false,
  settings: {},
}

const reducer = (state, action) => {
  switch (action.type) {
  case 'loaded':
    return { loading: false, showSuccessMessage: false, settings: action.settings || {} }
  case 'saving':
    return { ...state, loading: true }
  case 'saved':
    return { ...state, showSuccessMessage: true, loading: false }
  default:
    throw new Error()
  }
}

const JiraSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    loadSettings(projectCode)
      .then(settings => dispatch({ type: 'loaded', settings }))
      .catch(handleError)
  }, [handleError, projectCode])
  const handleSave = settings => saveSettings(projectCode, settings)
  return (
    <div>
      <Spinner spinning={state.loading}>
        <Settings settings={state.settings} onSave={handleSave} />
        <Snackbar open={state.showSuccessMessage} message="Settings were saved successfully." autoHideDuration={10000} />
      </Spinner>
    </div>
  )
}

export default withRouter(JiraSettings)
