import { memo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import FilterPanel from 'components/reports/common/filterPanel'

const TesterFilter = ({ testers, onSubmit, initialValue, id }) => {
  const [selection, setSelection] = useState([])

  useEffect(() => {
    setSelection(testers.filter(it => initialValue.includes(it.email)))
  }, [initialValue, testers])

  const handleFilter = () => {
    onSubmit(selection.map(it => it.email))
    setSelection([])
  }

  const handleTesterChange = (e) => {
    setSelection(e.target.value)
  }

  return (
    <FilterPanel title="Filter by Tester" onSubmit={handleFilter} id={id}>
      <FormControl fullWidth>
        <InputLabel>Select a tester</InputLabel>
        <Select
          id="testerFilterSelect"
          multiple
          autoFocus
          value={selection}
          renderValue={selected => selected.map(it => it.name).join(', ')}
          onChange={handleTesterChange}
        >
          {testers.map(tester => (
            <MenuItem key={tester.email} value={tester} id={tester.name}>
              <Checkbox checked={selection.includes(tester)} />
              <ListItemText primary={tester.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </FilterPanel>
  )
}

TesterFilter.propTypes = {
  id: PropTypes.string,
  testers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.arrayOf(PropTypes.string),
}

TesterFilter.defaultProps = {
  id: 'testerFilter',
  initialValue: [],
}

export default memo(TesterFilter)
