import ExecutionDetailsService from 'services/executionDetailsService'
import { dispatcher, events } from 'core/dispatcher'


function retrieveLogs(executionId, testId, stepId, logType) {
  ExecutionDetailsService.findStepLogs(executionId, testId, stepId, logType)
    .then((response) => { dispatcher.dispatch({ type: events.testDetailsStepView.logsReceived, ...response, logType }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsStepView.logsFailed, error, logType }) })
}

export function retrieveStep(executionId, testId, stepId) {
  return ExecutionDetailsService.findStep(executionId, testId, stepId)
    .then((step) => {
      dispatcher.dispatch({ type: events.testDetailsStepView.detailsReceived, step })
      return step
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testDetailsStepView.failed, error }) })
}

export function retrieveDetailsAndLogs(executionId, testId, stepId) {
  retrieveStep(executionId, testId, stepId).then((step) => {
    if (!step.deleted) {
      retrieveLogs(executionId, testId, stepId, 'PROTOCOL')
      retrieveLogs(executionId, testId, stepId, 'TRACE')
    }
  })
  retrieveLogs(executionId, testId, stepId, 'SERVER')
}
