import { memo } from 'react'
import PropTypes from 'prop-types'

const JiraFieldType = memo(({ value }) => {
  switch (value) {
  case 'TEXT': return 'Text'
  case 'SELECT': return 'Select'
  case 'MULTI_SELECT': return 'Multi Select'
  default: return ''
  }
})

JiraFieldType.propTypes = { value: PropTypes.string }
JiraFieldType.defaultProps = { value: null }

export default JiraFieldType
