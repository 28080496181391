import axios from 'axios'
import * as Common from 'services/common'

class ApprovalService {
  changeApprovalStatus = (executionId, status) => {
    const url = `/api/approval/${executionId}?status=${status}`
    return axios.post(url, {}, Common.requestOptions)
      .catch(Common.catchError)
  }

  changeMultipleApprovalStatus = (executions, status) => {
    const url = '/api/approval'
    const request = { status, executions }
    return axios.post(url, request, Common.requestOptions)
      .catch(Common.catchError)
  }

  getApprovalStats = (project) => {
    const url = `/api/approval?project=${project}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new ApprovalService()
export default instance
