import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const emptyTicketData = { id: '', state: '' }

class TestSuiteDetailsStore extends ReportingEventEmitter {
  state = {
    customFields: [],
    loading: true,
    loadingTicket: true,
    loadingTestPlan: true,
    openErrorMessage: false,
    testSuite: { name: '', lastStatus: '' },
    testPlan: {},
    ticketData: emptyTicketData,
    showEditButtons: false,
    showYouTrackButton: false,
  }

  hasTicketIdChanged = () => this.state.ticketId !== this.state.ticketData.id

  hasTicket = () => this.state.ticketId !== null && this.state.ticketId !== ''

  handleActions(action) {
    switch (action.type) {
    case events.testSuiteDetails.retrieveDetails: {
      this.state.loading = true
      this.state.loadingTicket = true
      this.state.ticketData = emptyTicketData
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testSuiteReceived: {
      this.state.testSuite = action.testSuite.testSuite
      this.state.executions = action.testSuite.executions
      this.state.showTicketWarning = action.testSuite.testSuite.warnings && action.testSuite.testSuite.warnings.includes('MISSING_TEST_PLAN')
      this.state.loading = false
      this.state.error = null
      this.state.hpqcError = null
      this.emitChange()
      break
    }
    case events.testSuiteDetails.ticketDataReceived: {
      this.state.loadingTicket = false
      this.state.ticketData = action.ticketData || emptyTicketData
      this.state.ticketId = this.state.ticketData.id
      this.state.showYouTrackButton = this.hasTicket() && !this.hasTicketIdChanged()
      this.state.showEditButtons = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.resetTicketId: {
      this.state.ticketId = this.state.ticketData.id
      this.state.ticketData.error = null
      this.state.showYouTrackButton = this.hasTicket() && !this.hasTicketIdChanged()
      this.state.showEditButtons = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.modifyTicketId: {
      this.state.ticketId = action.value
      this.state.showYouTrackButton = this.hasTicket() && !this.hasTicketIdChanged()
      this.state.showEditButtons = this.hasTicketIdChanged()
      this.state.ticketData.error = null
      this.emitChange()
      break
    }
    case events.testSuiteDetails.ticketIdUpdated: {
      this.state.loadingTicket = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.failedRetrievingTicketData: {
      this.state.loadingTicket = false
      this.state.ticketData = {
        error: 'Failed retrieving data.',
        ...emptyTicketData,
      }
      this.state.showYouTrackButton = false
      this.state.showEditButtons = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.failedUpdatingTicketId: {
      this.state.loadingTicket = false
      this.state.showYouTrackButton = false
      this.state.showEditButtons = false
      this.state.ticketData.error = 'Failed updating ticket.'
      this.emitChange()
      break
    }
    case events.testSuiteDetailsRetrievingTestSuiteDetails: {
      this.state = { error: action.error, loading: false, loadingTicket: false }
      this.emitChange()
      break
    }
    case events.testSuiteDetails.invalidTicketId: {
      this.state.loadingTicket = false
      this.state.ticketData.error = 'Invalid ticket number'
      this.emitChange()
      break
    }
    case events.testSuiteDetails.ticketIdSubmitted: {
      this.state.loadingTicket = true
      this.emitChange()
      break
    }
    case events.testSuiteDetails.setOpenErrorMsg: {
      this.state.openErrorMessage = action.openErrorMessage
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testPlanReceived: {
      this.state.loadingTestPlan = false
      this.state.testPlan = action.testPlan || {}
      this.state.customFields = (action.customFields || []).map(it => it.internalName)
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testPlanIdUpdated: {
      this.state.loadingTestPlan = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testPlanIdSubmitted: {
      this.state.loadingTestPlan = true
      this.emitChange()
      break
    }
    case events.testSuiteDetails.failedUpdatingTestPlanId: {
      this.state.loadingTestPlan = false
      this.state.showTestPlanDialog = false
      this.state.hpqcError = `Failed updating HPQC Test Plan. ${action.error || ''}`
      this.emitChange()
      break
    }
    case events.testSuiteDetails.failedRetrievingTestPlan: {
      this.state.loadingTestPlan = false
      this.state.hpqcError = 'Failed retrieving HPQC Test Plan ID.'
      this.emitChange()
      break
    }
    case events.testSuiteDetails.failedRetrievingTestSuiteDetails: {
      this.state.loading = false
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testSuiteDetails.toggleTestPlanDetails: {
      this.state.showTestPlanDialog = action.show
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testPlanUpdated: {
      this.state.showTestPlanDialog = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testPlanCreated: {
      this.state.loadingTestPlan = true
      this.emitChange()
      break
    }
    case events.testSuiteDetails.testInstanceCreationSuccess: {
      this.state.loadingTestPlan = false
      this.emitChange()
      break
    }
    case events.testSuiteDetails.updateNameSuccess: {
      this.state.testSuite = { ...this.state.testSuite, name: action.name }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new TestSuiteDetailsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
