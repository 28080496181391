import AttachmentService from 'services/attachmentService'
import ExecutionDetailsService from 'services/executionDetailsService'
import { dispatcher, events } from 'core/dispatcher'
import * as Utils from 'components/utils'
import downloadFile from 'utils/downloadFile'


export function retrieveAttachments(referenceId) {
  AttachmentService.findAllByReferenceId(referenceId)
    .then(attachments => dispatcher.dispatch({ type: events.attachmentList.received, attachments }))
    .catch((error) => { dispatcher.dispatch({ type: events.attachmentList.failed, error }) })
}

export function removeAttachment(attachmentId, reportId) {
  AttachmentService.removeAttachment(attachmentId)
    .then(dispatcher.dispatch({ type: events.attachmentList.removed }))
    .then(() => retrieveAttachments(reportId))
    .catch((error) => { dispatcher.dispatch({ type: events.attachmentList.failed, error }) })
}

export function undoRemoveAttachment(attachmentId, reportId) {
  AttachmentService.undoRemoveAttachment(attachmentId)
    .then(dispatcher.dispatch({ type: events.attachmentList.removeUndone }))
    .then(() => retrieveAttachments(reportId))
    .catch((error) => { dispatcher.dispatch({ type: events.attachmentList.failed, error }) })
}

export function downloadAttachment(id) {
  const url = AttachmentService.createDownloadAttachmentLink(id)

  downloadFile(url)
}

export async function previewAttachment(id, name, type) {
  if (type.startsWith('audio/') || type.startsWith('image/')) {
    const file = AttachmentService.createDownloadAttachmentLink(id)
    dispatcher.dispatch({ type: events.attachmentList.preview, mimetype: type, file, name })
  } else {
    try {
      const file = await AttachmentService.downloadAttachment(id, name, type)
      dispatcher.dispatch({ type: events.attachmentList.preview, mimetype: type, file, name })
    } catch (error) {
      dispatcher.dispatch({ type: events.attachmentList.failed, error })
    }
  }
}

export async function downloadPackage(reportId) {
  const url = AttachmentService.createDownloadPackageLink(reportId)

  downloadFile(url)
}

export async function downloadServerLog(reportId, eventTarget) {
  try {
    const log = await ExecutionDetailsService.findReportLogs(reportId, 'SERVER')
    const file = new Blob(log.map(it => it.concat('\n')), { type: 'text/plain' })
    Utils.downloadFile('intaqt.log', file, eventTarget)
  } catch (error) {
    dispatcher.dispatch({ type: events.attachmentList.failed, error })
  }
}

export function closePreview() {
  dispatcher.dispatch({ type: events.attachmentList.closePreview })
}
