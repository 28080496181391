import axios from 'axios'
import * as Common from 'services/common'

class UserService {
  getCurrentUser = (project) => {
    const url = project ? `/api/user/roles?projectCode=${project}` : '/api/user/roles'
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
  }

  findAll = () => {
    const url = '/api/user'
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findOne = (email) => {
    const url = `/api/user/${encodeURIComponent(email)}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  addUser = ({ email, name, admin }) => {
    const url = '/api/user'
    return axios.post(url, { email, name, admin }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateUser = ({ email, name, admin }) => {
    const url = `/api/user/${encodeURIComponent(email)}`
    return axios.post(url, { name, admin }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  removeUser = (email) => {
    const url = `/api/user/${encodeURIComponent(email)}`
    return axios.delete(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  addPermission = (email, { project, role }) => {
    const url = `/api/user/${encodeURIComponent(email)}/roles`
    return axios.post(url, { project, role }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  removePermission = (email, { project, role }) => {
    const url = `/api/user/${encodeURIComponent(email)}/roles?project=${project}&role=${role}`
    return axios.delete(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateApiSecret = (apiSecret) => {
    const url = '/api/user/api-secret'
    return axios.post(url, { apiSecret }, Common.requestOptions)
      .catch(Common.catchError)
  }

  resetApiSecret = (email, apiSecret) => {
    const url = `/api/user/${encodeURIComponent(email)}/api-secret`
    return axios.post(url, { apiSecret }, Common.requestOptions)
      .catch(Common.catchError)
  }

  sendCode = (tfaCode) => {
    const url = '/api/user/tfa-code'
    return axios.post(url, { tfaCode }, Common.requestOptions)
      .catch(Common.catchError)
  }
}

const instance = new UserService()
export default instance
