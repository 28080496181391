import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'

import * as Actions from './actions'
import EditRow from './editRow'
import UserRow from './userRow'
import style from './style.module.css'


const UserList = () => {
  const errorHandler = useErrorHandler()
  const [userList, setUserList] = useState({ loading: true, list: [] })

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Users'
    Actions.retrieveData().then(list => setUserList({ loading: false, list: list.sort((a, b) => a.email.localeCompare(b.email)) }))
      .catch(errorHandler)
  }, [errorHandler])

  const handleCancel = () => setUserList({ ...userList, selectedRow: null, addUser: false })
  const handleDelete = email => Actions.remove(email).then(list => setUserList({ loading: false, list })).catch(errorHandler)
  const handleAdd = (user) => {
    setUserList({ ...userList, loading: true })
    Actions.add(user)
      .then(list => setUserList({ loading: false, list }))
      .catch(errorHandler)
  }
  const handleUpdate = (user) => {
    setUserList({ ...userList, loading: true })
    Actions.save(user)
      .then(list => setUserList({ loading: false, list }))
      .catch(errorHandler)
  }
  const handleEdit = item => () => setUserList({ ...userList, selectedRow: item.email, addUser: false })

  return (
    <Spinner spinning={userList.loading}>
      <Card>
        <div className={style.usersContainer}>
          <CardHeader title="Users" />
          <div className={style.wrapper}>
            <div key="header" className={style.headerRow}>
              <div className={style.emailColumn}>Email</div>
              <div className={style.nameColumn}>Name</div>
              <div className={style.adminColumn}>Administrator</div>
              <div className={style.buttonColumn} />
            </div>
            {userList.list.map(item => (item.email === userList.selectedRow
              ? <EditRow user={item} key={item.email} onSubmit={handleUpdate} onCancel={handleCancel} />
              : <UserRow key={item.email} user={item} onEdit={handleEdit(item)} onDelete={handleDelete} />
            ))}
            { userList.addUser && <EditRow key="new" onSubmit={handleAdd} onCancel={handleCancel} />}
          </div>
          { !userList.addUser && (
            <div className={style.wrapper}>
              <IconButton id="addUserButton" onClick={() => setUserList({ ...userList, addUser: true, selectedRow: null })} src="/images/add.svg" />
            </div>
          )}
        </div>
      </Card>
    </Spinner>
  )
}

export default UserList
