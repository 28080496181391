import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class PmsExportStore extends ReportingEventEmitter {
  getInitialState() {
    return { loading: true, defaultPageSize: 20 }
  }

  handleActions(action) {
    switch (action.type) {
    case events.pmsExportReport.listReceived: {
      this.state = {
        loading: false,
        list: action.data.items,
        totalSize: action.data.totalSize,
      }
      this.emitChange()
      break
    }
    case events.pmsExportReport.exportListFailed: {
      this.state = {
        error: action.error.message,
      }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new PmsExportStore()
dispatcher.register(store.handleActions.bind(store))

export default store
