import { useState } from 'react'
import PropTypes from 'prop-types'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import NewExternalCodeRow from './externalCodesNewRow'
import style from './style.module.scss'

const ExternalCodes = ({ list, onSave }) => {
  const [addMode, setAddMode] = useState(false)
  const handleAccept = (newCode) => {
    onSave(list.concat(newCode))
    setAddMode(false)
  }
  return (
    <div className={style.codeRow}>
      {list.map(it => (
        <div key={it.system}>
          <span className={style.column}>{it.system}</span>
          <span className={style.column}>{it.code}</span>
          <div className={style.buttonsColumn}>
            <IconButton size="small" onClick={() => onSave(list.filter(ec => ec.system !== it.system))}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      ))}
      { addMode
        ? <NewExternalCodeRow key="new" onAdd={handleAccept} onCancel={() => setAddMode(false)} />
        : <IconButton size="small" onClick={() => setAddMode(true)}><AddCircleIcon /></IconButton> }
    </div>
  )
}

ExternalCodes.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
}

export default ExternalCodes
