import { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { ExportErrorCategoriesIcon } from 'components/icons'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import ExportView from './export'
import Store from './store'
import * as Actions from './actions'

class Export extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount() {
    Store.on('change', this.updateState)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleOpenExportView = () => Actions.handleToggleExportView(true)

  handleCloseExportView = () => Actions.handleToggleExportView(false)

  render() {
    const { className, query, metadata } = this.props
    const { projectCode } = this.context
    const { showDialog, templates } = this.state

    return (
      <div className={className}>
        <Tooltip title="Export as CSV">
          <IconButton onClick={this.handleOpenExportView}>
            <ExportErrorCategoriesIcon />
          </IconButton>
        </Tooltip>
        { showDialog
          && (
            <ExportView
              templates={templates}
              onCancel={this.handleCloseExportView}
              onExport={selectedFields => Actions.handleDownload(projectCode, selectedFields, query, metadata)}
              onSaveTemplates={(t) => { Actions.handleSave(t) }}
            />
          )}
      </div>
    )
  }
}

Export.propTypes = {
  query: PropTypes.objectOf(PropTypes.string).isRequired,
  metadata: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
}

Export.defaultProps = {
  className: null,
}

Export.contextType = ProjectContext

export default memo(withRouter(withQuery(Export)))
