import { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Snackbar from '@mui/material/Snackbar'
import TextField from '@mui/material/TextField'

import useErrorHandler from 'core/hooks'
import Spinner from 'components/spinner'
import useProject from 'hooks/useProject'

import { load, save } from './actions'
import style from './style.module.scss'

const defaultConfiguration = {
  loading: true,
  enabled: false,
  deletionSchedule: '0 0 4 * * *',
  uploadPolicyEnabled: false,
  failedPolicyEnabled: false,
  failedPolicyPeriod: '',
  passedPolicyEnabled: false,
  passedPolicyPeriod: '',
}

const DataRetentionConfiguration = () => {
  const [config, setConfig] = useState(defaultConfiguration)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const errorHandler = useErrorHandler()
  const { projectCode } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Data Retention Policy'
    load(projectCode)
      .then(data => setConfig({
        ...data,
        deletionSchedule: data.deletionSchedule || defaultConfiguration.deletionSchedule,
        failedPolicyPeriod: data.failedPolicyPeriod || defaultConfiguration.failedPolicyPeriod,
        passedPolicyPeriod: data.passedPolicyPeriod || defaultConfiguration.passedPolicyPeriod,
      }))
      .catch(errorHandler)
  }, [errorHandler, projectCode])
  const handleSave = () => {
    if (isNaN(config.failedPolicyPeriod)) { // eslint-disable-line no-restricted-globals
      setErrors({ failedPolicyPeriod: 'Must be a number.' })
      return
    }
    if (isNaN(config.passedPolicyPeriod)) { // eslint-disable-line no-restricted-globals
      setErrors({ passedPolicyPeriod: 'Must be a number.' })
      return
    }
    if (errors.failedPolicyPeriod || errors.passedPolicyPeriod) {
      setErrors({})
    }
    save(projectCode, config)
      .then(() => setShowSuccessMessage(true))
      .catch(errorHandler)
  }
  return (
    <Card>
      <CardHeader title="Data Retention Policy" />
      <CardContent className={style.contents}>
        <Spinner spinning={config.loading === true}>
          <div className={style.list}>
            <FormControlLabel
              label="Enable data retention"
              control={<Checkbox checked={config.enabled} id="enableCheckbox" onChange={e => setConfig({ ...config, enabled: e.target.checked })} />}
            />
          </div>
          <div className={style.list}>
            <TextField
              fullWidth
              value={config.deletionSchedule}
              id="DeletionSchedule"
              label="Deletion schedule"
              onChange={e => setConfig({ ...config, deletionSchedule: e.target.value })}
            />
            <FormHelperText>Cron expression for deleting scheduled executions (in UTC).</FormHelperText>
          </div>
          <div className={style.list}>
            <FormControlLabel
              label="Keep intaQt logs"
              control={(
                <Checkbox
                  checked={config.keepServerLogs}
                  id="keepServerLogsCheckbox"
                  onChange={e => setConfig({ ...config, keepServerLogs: e.target.checked })}
                />
              )}
            />
            <FormHelperText>Delete protocol and trace logs of the execution but keep the intaQt log.</FormHelperText>
          </div>
          <h3>Uploaded Executions Policy </h3>
          <div className={style.list}>
            <FormControlLabel
              label="Enable"
              control={(
                <Checkbox
                  checked={config.uploadPolicyEnabled}
                  id="uploadPolicyEnabledCheckbox"
                  onChange={e => setConfig({ ...config, uploadPolicyEnabled: e.target.checked })}
                />
              )}
            />
            <FormHelperText>When an execution is uploaded, all previous execution of the same test suite are deleted. Approved
              and already uploaded previous executions are excluded from deletion.
            </FormHelperText>
          </div>
          <h3>Failed Executions Policy </h3>
          <div className={style.list}>
            <FormControlLabel
              label="Enable"
              control={(
                <Checkbox
                  checked={config.failedPolicyEnabled}
                  id="failedPolicyEnabledCheckbox"
                  onChange={e => setConfig({ ...config, failedPolicyEnabled: e.target.checked })}
                />
              )}
            />
            <FormHelperText>Enables the policy that deletes all failed executions after defined time period.</FormHelperText>
          </div>
          <div className={style.list}>
            <span className={style.shortTitle}><div>Retention period (days)</div></span>
            <TextField
              fullWidth
              id="failedPolicyPeriod"
              value={config.failedPolicyPeriod}
              onChange={e => setConfig({ ...config, failedPolicyPeriod: e.target.value })}
              label={errors.failedPolicyPeriod}
              error={errors.failedPolicyPeriod != null}
            />
            <FormHelperText>Number of days after which failed executions gets deleted.</FormHelperText>
          </div>
          <h3>Passed Executions Policy </h3>
          <div className={style.list}>
            <FormControlLabel
              label="Enable"
              control={(
                <Checkbox
                  id="passedPolicyEnabledCheckbox"
                  checked={config.passedPolicyEnabled}
                  onChange={e => setConfig({ ...config, passedPolicyEnabled: e.target.checked })}
                />
              )}
            />
            <FormHelperText>Enables the policy that deletes all successful executions after defined time period.</FormHelperText>
          </div>
          <div className={style.list}>
            <span className={style.shortTitle}><div>Retention period (days)</div></span>
            <TextField
              fullWidth
              id="passedPolicyPeriod"
              value={config.passedPolicyPeriod}
              onChange={e => setConfig({ ...config, passedPolicyPeriod: e.target.value })}
              label={errors.passedPolicyPeriod}
              error={errors.passedPolicyPeriod != null}
            />
            <FormHelperText>Number of days after which passed executions gets deleted.</FormHelperText>
          </div>
          <div className={style.buttonRow}>
            <Button variant="contained" color="primary" onClick={handleSave} id="saveButton">Save</Button>
          </div>
        </Spinner>
      </CardContent>
      <Snackbar
        id="snackBar"
        message="Settings were saved successfully."
        autoHideDuration={10000}
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      />
    </Card>
  )
}

export default DataRetentionConfiguration
