import { memo } from 'react'
import PropTypes from 'prop-types'

import IconButton from 'components/iconButton'
import JiraFieldType from './jiraFieldType'
import style from './settings.module.css'

const ItemRow = memo(({
  name, externalCode, tag, defaultValue, type, onDelete,
}) => (
  <div className={style.itemRow}>
    <div className={style.nameColumn}>{name}</div>
    <div className={style.codeColumn}>{externalCode}</div>
    <div className={style.tagColumn}>{tag}</div>
    <div className={style.defaultColumn}>{defaultValue}</div>
    <div className={style.typeColumn}><JiraFieldType value={type} /></div>
    <div className={style.buttonColumn}>
      <IconButton src="/images/delete.svg" onClick={() => onDelete(name)} />
    </div>
  </div>
))

ItemRow.propTypes = {
  name: PropTypes.string.isRequired,
  externalCode: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}

ItemRow.defaultProps = { defaultValue: '' }

export default ItemRow
