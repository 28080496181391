import axios from 'axios'
import * as Common from 'services/common'
import * as Utils from 'components/utils'

class PmsExportService {
  findAll = (project, filters) => {
    const pageNumber = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
    const pageSize = filters.pageSize ? `&size=${filters.pageSize}` : ''
    const dateFromFilter = filters.dateFrom ? `&dateFrom=${encodeURIComponent(Utils.formatDateISOZ(filters.dateFrom))}` : ''
    const dateToFilter = filters.dateUntil ? `&dateTo=${encodeURIComponent(Utils.formatDateISOZ(filters.dateUntil))}` : ''
    const url = `/api/module/pms/${project}/export?${pageNumber}${pageSize}${dateFromFilter}${dateToFilter}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findOne = (project, pmsExportId) => {
    const url = `/api/module/pms/${project}/export/${pmsExportId}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new PmsExportService()
export default instance
