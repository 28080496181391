import { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import StringFilter from 'components/reports/common/widgets/stringFilter'
import DateFilter from 'components/reports/common/widgets/dateFilter'
import StatusFilter from 'components/reports/common/widgets/statusFilter'

import style from './style.module.scss'

const TestSuiteFilterDrawer = ({ open, filters, redirect }) => {
  const handleDateFilter = useCallback((from, until) => {
    redirect({ dateFrom: from, dateUntil: until, pageNumber: 1 })
  }, [redirect])

  const useFilterChange = filter => useMemo(() => (value) => {
    const newFilter = {}
    newFilter[filter] = value
    newFilter.pageNumber = 1
    redirect(newFilter)
  }, [filter, redirect])

  return (
    <>
      <Box className={`${style.drawer} ${open ? '' : style.hidden}`}>
        <div>
          <StringFilter onSubmit={useFilterChange('testName')} field="Test Suite Name" initialValue={filters.testName} />
          <DateFilter onSubmit={handleDateFilter} initialDateFrom={filters.dateFrom} initialDateUntil={filters.dateUntil} />
          <StatusFilter onSubmit={useFilterChange('status')} initialValue={filters.status} />
        </div>
      </Box>
    </>
  )
}

TestSuiteFilterDrawer.propTypes = {
  redirect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  filters: PropTypes.shape().isRequired,
}

export default TestSuiteFilterDrawer
