import axios from 'axios'
import * as Common from 'services/common'

class ExecutionDetailsService {
  handleLogNotFound = (error) => {
    if (Common.getErrorCode(error) === 'LOG_NOT_FOUND') {
      return { deleted: true, log: [] }
    }
    throw error
  }

  findExecution = (executionId) => {
    const url = `/api/test-suite-execution/${executionId}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findExecutionLogs = (executionId, logType) => {
    const url = `/api/test-suite-execution/${executionId}/logs/${logType}`
    return axios.get(url, { ...Common.requestOptions, timeout: 10000 })
      .then(response => ({ logs: response.data }))
      .catch(this.handleLogNotFound)
      .catch(Common.catchError)
  }

  findReportLogs = (reportId, logType) => {
    const url = `/api/test-suite-report/${reportId}/logs/${logType}`
    return axios.get(url, { ...Common.requestOptions, timeout: 10000 })
      .then(response => (response.data))
      .catch(this.handleLogNotFound)
      .catch(Common.catchError)
  }

  findTest = (executionId, test) => {
    const url = `/api/test-suite-execution/${executionId}/${test}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findTestLogs = (executionId, test, logType) => {
    const url = `/api/test-suite-execution/${executionId}/${test}/logs/${logType}`
    return axios.get(url, { ...Common.requestOptions, timeout: 10000 })
      .then(response => ({ logs: response.data }))
      .catch(this.handleLogNotFound)
      .catch(Common.catchError)
  }

  findStep = (executionId, test, step) => {
    const url = `/api/test-suite-execution/${executionId}/${test}/${step}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  findStepLogs = (executionId, test, step, logType) => {
    const url = `/api/test-suite-execution/${executionId}/${test}/${step}/logs/${logType}`
    return axios.get(url, { ...Common.requestOptions, timeout: 10000 })
      .then(response => ({ logs: response.data }))
      .catch(this.handleLogNotFound)
      .catch(Common.catchError)
  }

  saveDefects = (executionId, defects) => {
    const url = `/api/test-suite-execution/${executionId}/defects`
    return axios.post(url, { defects }, { ...Common.requestOptions, maxRedirects: 0 })
      .then(() => defects)
      .catch(Common.catchError)
  }

  changeRetentionStatus = (executionId, status) => {
    const url = `/api/test-suite-execution/${executionId}/retention-status`
    return axios.post(url, { status }, Common.requestOptions)
      .catch(Common.catchError)
  }

  changeTestCategory = (executionId, testCategory) => {
    const url = `/api/test-suite-execution/${executionId}/test-category`
    return axios.post(url, { testCategory }, Common.requestOptions)
      .catch(Common.catchError)
  }

  updateNote = (executionId, note) => {
    const url = `/api/test-suite-execution/${executionId}/note`
    return axios.post(url, { note }, Common.requestOptions)
      .catch(Common.catchError)
  }

  uploadCdrFile = (verificationId, executionId, file, projectCode) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/${verificationId}/attachments`
    const formData = new FormData()
    const json = { name: file.name, mimeType: file.type }
    formData.append('json', new Blob([JSON.stringify(json)], { type: 'application/json' }))
    formData.append('attachment', file)
    const config = { headers: { 'content-type': 'multipart/form-data' } }
    return axios.post(url, formData, config)
      .catch(Common.catchError)
  }

  retrieveCdrVerifications = (executionId, projectCode) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/`
    return axios.get(url, Common.requestOptions)
      .then(response => (response.data))
      .catch(Common.catchError)
  }

  retrieveQonformVerifications = (executionId, projectCode) => {
    const url = `/api/module/qonform/${projectCode}/${executionId}/qonform-verifications/`
    return axios.get(url, Common.requestOptions)
      .then(response => (response.data))
      .catch(Common.catchError)
  }

  runVerification = (executionId, verificationExternalId, projectCode) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/${verificationExternalId}`
    return axios.post(url, Common.requestOptions)
      .catch(Common.catchError)
  }

  runQonformVerification = (executionId, verificationExternalId, projectCode) => {
    const url = `/api/module/qonform/${projectCode}/${executionId}/qonform-verifications/${verificationExternalId}`
    return axios.post(url, Common.requestOptions)
      .catch(Common.catchError)
  }

  runCdrSearch = (executionId, verificationExternalId, projectCode) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/${verificationExternalId}/run-search`
    return axios.post(url, Common.requestOptions)
      .catch(Common.catchError)
  }

  getContextPreview = (executionId, verificationExternalId, projectCode, contextOverrides) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/${verificationExternalId}/context/preview`
    const responseType = 'text'
    return axios.put(url, { contextOverrides }, { ...Common.requestOptions, responseType, transformResponse: [data => data] })
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getQonformContextPreview = (executionId, verificationExternalId, projectCode, contextOverrides) => {
    const url = `/api/module/qonform/${projectCode}/${executionId}/qonform-verifications/${verificationExternalId}/context/preview`
    const responseType = 'text'
    return axios.put(url, { contextOverrides }, { ...Common.requestOptions, responseType, transformResponse: [data => data] })
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateContext = (executionId, verificationExternalId, projectCode, contextOverrides) => {
    const url = `/api/module/cdr/${projectCode}/${executionId}/verifications/${verificationExternalId}/context`
    return axios.put(url, { contextOverrides }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateQonformContext = (executionId, verificationExternalId, projectCode, contextOverrides) => {
    const url = `/api/module/qonform/${projectCode}/${executionId}/qonform-verifications/${verificationExternalId}/context`
    return axios.put(url, { contextOverrides }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new ExecutionDetailsService()
export default instance
