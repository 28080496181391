import moment from 'moment'

const getDayOfWeek = date => moment(date).format('ddd')

export const initialState = {
  loading: true,
  bars: { passed: [], failed: [], pending: [], total: [] },
}

export const reducer = (state, action) => {
  switch (action.type) {
  case 'received': {
    const bars = {
      passed: action.data.map(day => ({ x: getDayOfWeek(day.date), y: day.passed, label: day.passed })),
      failed: action.data.map(day => ({ x: getDayOfWeek(day.date), y: day.failed, label: day.failed })),
      pending: action.data.map(day => ({ x: getDayOfWeek(day.date), y: day.pending, label: day.pending })),
      total: action.data.map(day => ({ x: getDayOfWeek(day.date),
        y: day.passed + day.failed + day.pending,
        label: day.passed + day.failed + day.pending })),
    }
    const categories = { x: action.data.map(day => getDayOfWeek(day.date)) }

    const noExecutionsLastWeek = action.data.map(it => it.passed + it.failed + it.pending).reduce((a, b) => a + b, 0) === 0
    bars.passed[bars.passed.length - 1].x = 'Today'
    bars.failed[bars.failed.length - 1].x = 'Today'
    bars.pending[bars.pending.length - 1].x = 'Today'
    bars.total[bars.total.length - 1].x = 'Today'
    categories.x[7] = 'Today'

    return { ...state, loading: false, bars, categories, noExecutionsLastWeek }
  }
  default: {
    throw new Error()
  }
  }
}
