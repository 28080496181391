import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'

import style from './style.module.css'


const TestPlanChips = ({
  testSuiteName, testPlanId, dateFrom, dateUntil, onRemove,
}) => {
  const chips = []
  if (testSuiteName) {
    chips.push(<Chip key="testSuiteNameChip" onDelete={() => onRemove('testSuiteName')} label={`Test name: ${testSuiteName}`} />)
  }
  if (testPlanId) {
    const label = `Test Plan ID: ${testPlanId === 'MISSING' ? 'Missing' : 'Existing'}`
    chips.push(<Chip key="testPlanIdChip" onDelete={() => onRemove('testPlanId')} label={label} />)
  }
  if (dateFrom) {
    chips.push(<Chip key="dateFromChip" onDelete={() => onRemove('dateFrom')} label={`Date from: ${dateFrom}`} />)
  }
  if (dateUntil) {
    chips.push(<Chip key="dateUntilChip" onDelete={() => onRemove('dateUntil')} label={`Date until: ${dateUntil}`} />)
  }

  if (chips.length === 0) return null

  return (
    <Paper zDepth={1} className={style.sideBox}>
      {chips}
    </Paper>
  )
}


TestPlanChips.propTypes = {
  testSuiteName: PropTypes.string,
  testPlanId: PropTypes.string,
  dateFrom: PropTypes.string,
  dateUntil: PropTypes.string,
  onRemove: PropTypes.func,
}

TestPlanChips.defaultProps = {
  testSuiteName: null,
  testPlanId: null,
  dateFrom: null,
  dateUntil: null,
  onRemove: null,
}

export default TestPlanChips
