import moment from 'moment'
import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import * as Utils from 'components/utils'

const defaultConfig = { name: '', testSetId: '', enabled: true, testCategory: '' }
class HpqcTestSetsStore extends ReportingEventEmitter {
  constructor() {
    super()
    this.state = {
      loading: true,
      items: [],
      newConfig: { ...defaultConfig },
      addConfig: false,
      canSubmit: false,
      showError: false,
    }
  }

  validate = () => {
    this.state.canSubmit = false
    if (!this.state.newConfig.name || this.state.newConfig.name === '') {
      this.state.validationError = 'Please enter a name.'
      return
    }
    if (!this.state.newConfig.testCategory) {
      this.state.validationError = 'Please select a test category.'
      return
    }
    if (!this.state.newConfig.dateFrom || !this.state.newConfig.dateTo) {
      this.state.validationError = 'Please select a date period.'
      return
    }
    if (moment(this.state.newConfig.dateFrom) > moment(this.state.newConfig.dateTo)) {
      this.state.validationError = 'Start date cannot be later than end date.'
      return
    }
    if (!this.state.newConfig.testSetId || this.state.newConfig.testSetId === '') {
      this.state.validationError = 'Please enter a test set id.'
      return
    }
    this.state.validationError = null
    this.state.canSubmit = true
  }

  storeList = (list) => {
    this.state.items = list.map(it => ({
      ...it,
      formattedDateFrom: Utils.formatDate(it.dateFrom),
      formattedDateTo: Utils.formatDate(it.dateTo),
    })).sort((a, b) => (a.name === b.name ? a.dateFrom > b.dateFrom : a.name > b.name))
    this.state.newConfig = { ...defaultConfig }
    this.state.addConfig = false
    this.state.showError = false
    this.state.canSubmit = false
    this.state.selectedRow = null
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationHpqcTestSets.dataReceived: {
      this.state.testCategories = action.testCategories
      this.storeList(action.hpqc)
      this.state.verifiedBy = action.verifiedBy
      this.state.verificationTime = Utils.formatDate(action.verificationTime)
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.addConfig: {
      this.state.selectedRow = null
      this.state.newConfig = { ...defaultConfig }
      this.state.addConfig = true
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.editConfig: {
      this.state.selectedRow = action.id
      this.state.newConfig = { ...this.state.items.find(it => it.id === action.id) }
      this.state.addConfig = false
      this.state.validationError = null
      this.state.canSubmit = true
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.cancelEdit: {
      this.state.selectedRow = null
      this.state.newConfig = { ...defaultConfig }
      this.state.addConfig = false
      this.state.validationError = null
      this.state.canSubmit = false
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldNameChanged: {
      this.state.newConfig.name = action.value
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldCategoryChanged: {
      this.state.newConfig.testCategory = action.value
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldDateFromChanged: {
      this.state.newConfig.dateFrom = action.value
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldDateToChanged: {
      this.state.newConfig.dateTo = action.value
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldTestSetIdChanged: {
      this.state.newConfig.testSetId = action.value
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.fieldEnabledChanged: {
      this.state.newConfig.enabled = action.enabled
      this.state.showError = false
      this.validate()
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.validated: {
      this.validate()
      this.state.showError = true
      this.emitChange()
      break
    }
    case events.configurationHpqcTestSets.saved: {
      this.storeList(action.list)
      this.state.verifiedBy = action.verifiedBy
      this.state.verificationTime = Utils.formatDate(action.verificationTime)
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcTestSetsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
