import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Icon from '@mui/material/Icon'
import { passedResultColor, failedResultColor, pendingResultColor, skippedResultColor, unknownResultColor } from 'core/themeProvider'

class TestStatusIcon extends PureComponent {
  render() {
    const { status, conditionsState, large, ...otherProps } = this.props
    const fontSize = large ? '34px' : '16px'
    const marginRight = large ? '0.5em' : '0'
    switch (status) {
    case 'PASSED':
      switch (conditionsState) {
      case 'PASSED':
        return <Icon style={{ fontSize, marginRight, color: passedResultColor }} {...otherProps}>check_box</Icon>
      case 'FAILED':
        return <Icon style={{ fontSize, marginRight, color: failedResultColor }} {...otherProps}>indeterminate_check_box</Icon>
      default:
        return <Icon style={{ fontSize, marginRight, color: pendingResultColor }} {...otherProps}>help_outline</Icon>
      }
    case 'FAILED':
      switch (conditionsState) {
      case 'PASSED':
        return <Icon style={{ fontSize, marginRight, color: failedResultColor }} {...otherProps}>indeterminate_check_box</Icon>
      case 'FAILED':
        return <Icon style={{ fontSize, marginRight, color: failedResultColor }} {...otherProps}>indeterminate_check_box</Icon>
      default:
        return <Icon style={{ fontSize, marginRight, color: pendingResultColor }} {...otherProps}>help_outline</Icon>
      }
    case 'SKIPPED':
      return <Icon style={{ fontSize, marginRight, color: skippedResultColor }} {...otherProps}>indeterminate_check_box</Icon>
    default:
      return <Icon style={{ fontSize, marginRight, color: unknownResultColor }} {...otherProps}>check_box_outline_blank</Icon>
    }
  }
}

TestStatusIcon.propTypes = {
  status: PropTypes.string,
  conditionsState: PropTypes.string,
  large: PropTypes.bool,
}

TestStatusIcon.defaultProps = {
  status: 'UNDEFINED',
  conditionsState: 'PASSED',
  large: false,
}

export default TestStatusIcon
