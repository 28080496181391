import { Component } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import Store from './store'
import * as Actions from './actions'
import style from './excelExportDefaults.module.css'

class ExcelExportDefaults extends Component {
  state = Store.getInitialState()

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Excel Export Default Parameters'
    Store.on('change', this.updateState)
    Actions.retrieveDefaults(projectCode)
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const { projectCode } = this.context
    const { defaults, loading, error } = this.state

    if (error != null) {
      return ExcelExportDefaults.renderError(error)
    }

    return (
      <div>
        <Card className={style.card}>
          <CardHeader title="Excel Export Default Parameters" />
          <Spinner spinning={loading}>
            <CardContent className={style.content}>
              <DataField name="Remote Test">
                <TextField
                  fullWidth
                  id="remoteTestField"
                  value={defaults.remoteTest}
                  onChange={e => Actions.handleValueChange({ ...defaults, remoteTest: e.target.value })}
                />
              </DataField>
              <DataField name="Result Name Prefix">
                <TextField
                  fullWidth
                  id="resultPrefixField"
                  value={defaults.resultPrefix}
                  onChange={e => Actions.handleValueChange({ ...defaults, resultPrefix: e.target.value })}
                />
              </DataField>
              <DataField name="Template">
                <TextField
                  fullWidth
                  id="templateField"
                  value={defaults.template}
                  onChange={e => Actions.handleValueChange({ ...defaults, template: e.target.value })}
                />
              </DataField>
              <DataField name="Order Name">
                <TextField
                  fullWidth
                  id="orderNameField"
                  value={defaults.orderName}
                  onChange={e => Actions.handleValueChange({ ...defaults, orderName: e.target.value })}
                />
              </DataField>
              <DataField name="Tolerance">
                <TextField
                  fullWidth
                  id="toleranceField"
                  value={defaults.tolerance}
                  onChange={e => Actions.handleValueChange({ ...defaults, tolerance: e.target.value })}
                />
              </DataField>
              <DataField name="User">
                <TextField
                  fullWidth
                  id="userField"
                  value={defaults.user}
                  onChange={e => Actions.handleValueChange({ ...defaults, user: e.target.value })}
                />
              </DataField>
              <DataField name="Files From">
                <TextField
                  fullWidth
                  id="filesFromField"
                  value={defaults.filesFrom}
                  onChange={e => Actions.handleValueChange({ ...defaults, filesFrom: e.target.value })}
                />
              </DataField>
              <DataField name="Steps">
                <TextField
                  fullWidth
                  id="stepsField"
                  value={defaults.steps}
                  onChange={e => Actions.handleValueChange({ ...defaults, steps: e.target.value })}
                />
              </DataField>
              <Button variant="contained" id="saveButton" color="primary" onClick={() => Actions.save(projectCode, defaults)}>Save</Button>
            </CardContent>
          </Spinner>
        </Card>
      </div>
    )
  }
}

ExcelExportDefaults.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

ExcelExportDefaults.contextType = ProjectContext

export default ExcelExportDefaults
