import axios from 'axios'
import * as Common from 'services/common'

class JiraBasicService {
  retrieveConnectionSettings = (project) => {
    const url = `/api/module/jira-basic/${project}/settings/connection`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  retrieveFieldSettings = (project) => {
    const url = `/api/module/jira-basic/${project}/settings/fields`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  retrieveSettingsConfiguration = (project) => {
    const url = `/api/module/jira-basic/${project}/settings/configuration`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
  }

  retrieveSettingsFallbackConfiguration = (project) => {
    const url = `/api/module/jira-basic/${project}/settings/configuration-fallback`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateConnectionSettings = (project, settings) => {
    const url = `/api/module/jira-basic/${project}/settings/connection`
    return axios.post(url, settings, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateFieldSettings = (project, settings) => {
    const url = `/api/module/jira-basic/${project}/settings/fields`
    return axios.post(url, settings, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new JiraBasicService()
export default instance
