import axios from 'axios'
import * as Common from 'services/common'

class VerificationService {
  changeVerificationStatus = (executionId, status) => {
    const url = `/api/verification/${executionId}?status=${status}`
    return axios.post(url, {}, Common.requestOptions)
      .catch(Common.catchError)
  }

  changeMultipleVerificationStatus = (executions, status) => {
    const url = '/api/verification'
    const request = { status, executions }
    return axios.post(url, request, Common.requestOptions)
      .catch(Common.catchError)
  }

  getVerificationStats = (project, user) => {
    const url = `/api/verification?project=${project}&user=${user}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new VerificationService()
export default instance
