import PropTypes from 'prop-types'


const SuccessConditionName = ({ name, link }) => {
  if (!link) {
    return name
  }
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {name}
    </a>
  )
}
SuccessConditionName.propTypes = { name: PropTypes.string.isRequired, link: PropTypes.string }
SuccessConditionName.defaultProps = { link: null }

export default SuccessConditionName
