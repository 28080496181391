import { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { keys, load, write } from 'core/storage'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import style from '../style.module.css'
import ProjectSelector from './projectSelector'

class MoveDialog extends Component {
  constructor(props, context) {
    super(props, context)

    const { projectCode, projectList } = context

    const lastSelected = load(keys.LAST_PROJECT_MOVE)
    if (lastSelected && lastSelected[projectCode]) {
      const lastCode = lastSelected[projectCode]

      const projCode = projectList
        .filter(project => project.enabled)
        .map(project => project.code)
        .find(code => code === lastCode)

      if (projCode) {
        this.state = { selectedProjectCode: projCode }
        return
      }
    }

    const projCode = projectList
      .filter(project => project.enabled)
      .map(project => project.code)
      .find(code => code !== projectCode)

    this.state = { selectedProjectCode: projCode }
  }

  projectChanged = (projCode) => {
    write(keys.LAST_PROJECT_MOVE, projCode, this.context.projectCode)
    this.setState({ selectedProjectCode: projCode })
  }

  render() {
    const { onCancel, onMove } = this.props
    const selectedCode = this.state.selectedProjectCode
    if (selectedCode) {
      return (
        <Dialog
          open
          maxWidth="xs"
          fullWidth
          onClose={onCancel}
        >
          <DialogContent className={style.attachmentDialogContent}>
            <div className="row">
              <div className={style.moveText}>Move Report to</div>
              <div>
                <ProjectSelector selectedProjectCode={this.state.selectedProjectCode} onProjectChanged={this.projectChanged} />
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button id="closeBtn" color="primary" onClick={onCancel}>Cancel</Button>
            <Button id="moveBtn" variant="contained" color="primary" onClick={onMove(this.state.selectedProjectCode)}>Move</Button>
          </DialogActions>
        </Dialog>
      )
    }
    return (
      <Dialog
        open
        maxWidth="xs"
        fullWidth
        onClose={onCancel}
      >
        <DialogContent className={style.attachmentDialogContent}>
          <div className="row">
            <div className={style.moveText}>No available projects to move to</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="closeBtn" color="primary" onClick={onCancel}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

MoveDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
}

MoveDialog.contextType = ProjectContext

export default MoveDialog
