import { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import union from 'lodash/union'
import without from 'lodash/without'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import Spinner from 'components/spinner'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import * as Utils from 'components/utils'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'
import calcTotalPages from 'utils/calcTotalPages'

import Store from './store'
import * as Actions from './actions'
import style from './style.module.css'


class UnassignedExecutionsReport extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Unassigned Executions Report'
    Store.on('change', this.updateState)
    Actions.retrieveData(query)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { query } = this.props
      Actions.retrieveData(query)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleSelectAll = () => {
    const { items, reportsToMove } = this.state
    if (items.length > reportsToMove.length) {
      Actions.updateReportsToMove(items.map(it => it.testId))
    } else {
      Actions.updateReportsToMove([])
    }
  }

  handleRowSelect = testId => (e) => {
    const { reportsToMove } = this.state
    Actions.updateReportsToMove(get(e, 'target.checked', false) ? union(reportsToMove, [testId]) : without(reportsToMove, testId))
  }

  render() {
    const { query } = this.props
    const { projectList } = this.context
    const {
      error, loading, defaultPageSize, items, reportsToMove, projectToMove, totalSize,
    } = this.state

    if (error != null) {
      return UnassignedExecutionsReport.renderError(error)
    }

    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize
    const columns = [
      {
        Header: (
          <Checkbox
            indeterminate={reportsToMove.length > 0 && reportsToMove.length < items.length}
            checked={items.length === reportsToMove.length && items.length > 0}
            disabled={items.length === 0}
            onChange={this.handleSelectAll}
          />
        ),
        accessor: 'testId',
        Cell: item => <Checkbox checked={reportsToMove.includes(item.value)} onChange={this.handleRowSelect(item.value)} />,
        padding: 'checkbox',
        width: 48,
        disableResizing: true,
      },
      {
        Header: '',
        accessor: 'result',
        Cell: item => <TestStatusIcon status={item.value} />,
        width: 48,
        disableResizing: true,
      },
      {
        Header: 'Test Name',
        accessor: 'testSuite',
        Cell: item => <TrimmedText text={item.row.original.testName} maxLength={30} />,
        sortable: true,
      },
      {
        Header: 'Tester',
        accessor: 'tester',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
      },
      {
        Header: 'Start time',
        accessor: 'startTime',
        Cell: item => Utils.formatDate(item.value),
        sortable: true,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        Cell: item => Utils.formatDuration(item.value),
      },
    ]

    return (
      <Spinner spinning={loading}>
        <Card className={style.card}>
          <div className={style.unassignedContainer}>
            <CardHeader id="cardHeader" title="Unassigned Test Run Report" className={style.cardHeader} />
            <FormControl className={style.moveToProjectDiv}>
              <span>
                <InputLabel htmlFor="projectList">Project</InputLabel>
                <Select
                  fullWidth
                  id="projectList"
                  value={projectToMove || ''}
                  onChange={event => Actions.setProjectToMoveReports(event.target.value)}
                >
                  {projectList.map(project => <MenuItem key={project.code} value={project.code}>{project.name}</MenuItem>)}
                </Select>
              </span>
              <Button
                id="assignButton"
                variant="contained"
                color="primary"
                disabled={reportsToMove.length === 0 || !projectToMove}
                onClick={() => Actions.moveReportsToProject(reportsToMove, projectToMove)}
              >
                Assign
                <Icon className={style.addToProjectButtonIcon}>group_add</Icon>
              </Button>
            </FormControl>
            <Table
              id="unassignedReport"
              columns={columns}
              data={items}
              totalPages={calcTotalPages({ totalSize, pageSize })}
            />
          </div>
        </Card>
      </Spinner>
    )
  }
}

UnassignedExecutionsReport.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

UnassignedExecutionsReport.propTypes = {
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

UnassignedExecutionsReport.contextType = ProjectContext

export default withQuery(withRouter(UnassignedExecutionsReport))
