import { dispatcher, events } from 'core/dispatcher'
import ReportingEventEmitter from 'core/eventEmitter'
import { keys, load, write } from 'core/storage'

class ExportCsvStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      showDialog: false,
      selectedFields: new Set(),
      templates: load(keys.TEMPLATES) || [],
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.csvExport.toggleFieldSelection: {
      this.state.showDialog = action.show
      this.emitChange()
      break
    }
    case events.csvExport.templatesSaved: {
      this.state.templates = action.templates
      write(keys.TEMPLATES, this.state.templates)
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}


const store = new ExportCsvStore()
dispatcher.register(store.handleActions.bind(store))

export default store
