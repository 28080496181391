import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const defaultFields = ['testName', 'owner', 'rootFolderId', 'folderName', 'subfolderName', 'description', 'testPlanId']

class HpqcExcelImportSettingsStore extends ReportingEventEmitter {
  state = {
    loading: true,
    headerRow: '0',
    columnMapping: [],
    customFields: [],
    fields: [...defaultFields],
    selectedRow: null,
    addingRow: false,
    canSubmit: false,
    showError: false,
  }

  validateNewRow = (newRow) => {
    this.state.canSubmit = false
    if (!newRow.field) {
      this.state.validationError = 'Please select a field.'
      return
    }
    if (!newRow.column && !newRow.aggregates) {
      this.state.validationError = 'Please enter a column header.'
      return
    }
    this.state.validationError = null
    this.state.canSubmit = true
  }

  validateNewPart = (newPart) => {
    this.state.canSubmit = false
    if (!newPart.column) {
      this.state.validationError = 'Please enter a column header.'
      return
    }
    const duplicateColumn = this.state.columnMapping.find(it => it.field === this.state.newPartField)
      .aggregates
      .map(it => it.column)
      .includes(newPart.column)
    if (duplicateColumn) {
      this.state.validationError = `Column ${newPart.column} is already entered above.`
      return
    }
    this.state.validationError = null
    this.state.canSubmit = true
  }

  sortByField = list => (list.sort((a, b) => a.field > b.field))

  getImportSettings = () => ({
    columnMapping: this.state.columnMapping,
    headerRow: this.state.headerRow,
    sheet: this.state.sheet,
    syncColumn: this.state.syncColumn,
  })

  handleActions(action) {
    switch (action.type) {
    case events.configurationHpqcExcelImport.dataReceived: {
      this.state.columnMapping = action.excelImportSettings ? this.sortByField(action.excelImportSettings.columnMapping) : []
      this.state.customFields = (action.customFields || []).map(it => it.internalName)
      this.state.headerRow = action.excelImportSettings ? `${action.excelImportSettings.headerRow}` : ''
      this.state.sheet = action.excelImportSettings ? action.excelImportSettings.sheet : ''
      this.state.syncColumn = action.excelImportSettings ? action.excelImportSettings.syncColumn : ''
      this.state.fields = defaultFields.concat(this.state.customFields)
        .filter(field => !this.state.columnMapping.map(it => it.field).includes(field))
      this.state.error = null
      this.state.loading = false
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.failed: {
      this.state.error = action.message
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.addRow: {
      this.state.addingRow = true
      this.state.selectedRow = null
      this.state.newPart = null
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.updatedHeader: {
      if (isNaN(action.headerRow)) { // eslint-disable-line no-restricted-globals
        this.state.validationError = 'Header row value is invalid. You must enter the number of the row in excel sheet.'
        this.state.showError = true
      } else {
        this.state.headerRow = action.headerRow
        this.state.sheet = action.sheet
        this.state.syncColumn = action.syncColumn
        action.save(this.getImportSettings())
        this.state.showError = false
      }
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.savedRow: {
      this.validateNewRow(action.newRow)
      if (this.state.canSubmit) {
        this.state.columnMapping = this.sortByField([...this.state.columnMapping.filter(it => it.field !== action.newRow.field), action.newRow])
        action.save(this.getImportSettings())
        this.state.fields = defaultFields.concat(this.state.customFields)
          .filter(field => !this.state.columnMapping.map(it => it.field).includes(field))
        this.state.addingRow = false
        this.state.selectedRow = null
      } else {
        this.state.showError = true
      }
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.savedPart: {
      this.validateNewPart(action.newPart)
      if (this.state.canSubmit) {
        this.state.columnMapping.find(it => it.field === this.state.newPartField).aggregates.push(action.newPart)
        this.state.columnMapping = this.sortByField(this.state.columnMapping)
        action.save(this.getImportSettings())
        this.state.newPartField = null
      } else {
        this.state.showError = true
      }
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.deleted: {
      this.state.columnMapping = this.state.columnMapping.filter(it => it.field !== action.field)
      action.save(this.getImportSettings())
      this.state.fields = defaultFields.concat(this.state.customFields)
        .filter(field => !this.state.columnMapping.map(it => it.field).includes(field))
      this.state.newPartField = null
      this.state.addingRow = false
      this.state.selectedRow = null
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.deletedPart: {
      this.state.columnMapping = this.state.columnMapping.map(it => (it.field === action.field
        ? { ...it, aggregates: it.aggregates.filter(agg => agg.column !== action.column) }
        : it))
      action.save(this.getImportSettings())
      this.state.newPartField = null
      this.state.addingRow = false
      this.state.selectedRow = null
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.movedPartUp: {
      this.state.columnMapping = this.state.columnMapping.map((it) => {
        if (it.field === action.field) {
          const index = it.aggregates.findIndex(agg => agg.column === action.column)
          if (index > 0) {
            const part = it.aggregates.splice(index, 1)[0]
            it.aggregates.splice(index - 1, 0, part)
          }
        }
        return it
      })
      action.save(this.getImportSettings())
      this.state.newPartField = null
      this.state.addingRow = false
      this.state.selectedRow = null
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.movedPartDown: {
      this.state.columnMapping = this.state.columnMapping.map((it) => {
        if (it.field === action.field) {
          const index = it.aggregates.findIndex(agg => agg.column === action.column)
          const part = it.aggregates.splice(index, 1)[0]
          it.aggregates.splice(index + 1, 0, part)
        }
        return it
      })
      action.save(this.getImportSettings())
      this.state.newPartField = null
      this.state.addingRow = false
      this.state.selectedRow = null
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.addedPart: {
      this.state.selectedRow = null
      this.state.addingRow = false
      this.state.newPartField = action.field
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.editRow: {
      this.state.selectedRow = action.field
      this.state.addingRow = false
      this.state.validationError = null
      this.state.canSubmit = true
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.cancelEdit: {
      this.state.selectedRow = null
      this.state.addingRow = false
      this.state.validationError = null
      this.state.canSubmit = false
      this.state.showError = false
      this.emitChange()
      break
    }
    case events.configurationHpqcExcelImport.cancelPartEdit: {
      this.state.newPartField = null
      this.state.validationError = null
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcExcelImportSettingsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
