import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitIcon from '@mui/icons-material/ExitToApp'
import Button from '@mui/material/Button'
import KeyIcon from '@mui/icons-material/VpnKey'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import style from './header.module.scss'

const anchorOrigin = { horizontal: 'right', vertical: 'bottom' }

const User = ({ name, history }) => {
  const [state, setState] = useState({ open: false, anchorEl: null })
  const handleOpenMenu = useCallback((e) => {
    e.preventDefault()
    setState({ open: true, anchorEl: e.currentTarget })
  }, [])
  const handleCloseMenu = useCallback(() => setState(s => ({ ...s, open: false })), [])
  const handleOpenReportApiSecret = useCallback(() => history.push('/user/settings'), [])
  const handleLogout = useCallback(() => history.push('/logout'), [])
  return (
    <>
      <Button onClick={handleOpenMenu} startIcon={<AccountCircleIcon />} className={style.appBarBtn}>
        <span className={style.userName}>
          {name}
        </span>
      </Button>
      <Menu
        open={state.open}
        anchorEl={state.anchorEl}
        anchorOrigin={anchorOrigin}
        onClose={handleCloseMenu}
      >
        <MenuItem divider className={style.mobileUserName}>
          {name}
        </MenuItem>
        <MenuItem
          id="ReportApiSecretButton"
          onClick={() => { handleOpenReportApiSecret(); handleCloseMenu() }}
        >
          <ListItemIcon>
            <KeyIcon />
          </ListItemIcon>
          Report API Secret
        </MenuItem>
        <MenuItem
          id="LogoutButton"
          onClick={() => { handleLogout(); handleCloseMenu() }}
        >
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
    </>
  )
}

User.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  name: PropTypes.string.isRequired,
}

export default withRouter(User)
