import PropTypes from 'prop-types'
import moment from 'moment'

const formatDatePattern = (date, pattern) => {
  if (!date) {
    return ''
  }
  return moment(date).format(pattern)
}


const FormattedDate = ({ pattern, value }) => (
  <a>{formatDatePattern(value, pattern)}</a>
)

FormattedDate.propTypes = {
  pattern: PropTypes.string,
  value: PropTypes.string,
}

FormattedDate.defaultProps = {
  pattern: 'DD.MM.YYYY, HH:mm:ss',
  value: null,
}

export default FormattedDate
