import { useEffect, useReducer } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Typography from '@mui/material/Typography'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import Settings from './settings'
import { loadSettings, saveSettings } from './actions'
import reducer from './reducer'
import style from './style.module.scss'

const initialState = {
  loading: true,
  showSuccessMessage: false,
  settings: {
    port: 22,
    socketTimeoutMillies: 60000,
    uploadIntervalMinutes: 1,
    enabled: false,
    recordType: 'QT10_TC',
  },
}

const PmsSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    loadSettings(projectCode)
      .then(settings => dispatch({ type: 'loaded', settings }))
      .catch(handleError)
  }, [handleError, projectCode])
  const handleSave = (settings) => {
    dispatch({ type: 'saving', settings })
    saveSettings(projectCode, settings)
      .then(() => dispatch({ type: 'saved' }))
      .catch(handleError)
  }
  return (
    <div className={style.pmsContainer}>
      <Typography variant="h6">Settings</Typography>
      <Spinner spinning={state.loading}>
        <Settings settings={state.settings} onSave={handleSave} />
        <Snackbar open={state.showSuccessMessage} message="Settings were saved successfully." autoHideDuration={10000} />
      </Spinner>
    </div>
  )
}

export default PmsSettings
