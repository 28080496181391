import { useEffect, useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'

import * as Utils from 'components/utils'

import ExternalCodes from './externalCodes'
import style from './style.module.scss'

const defaultCategory = { name: '', regex: '', group: '', externalCodes: [], scope: 'MESSAGE' }

const reducer = (state, action) => {
  switch (action.type) {
  case 'validation':
    return { ...state, validation: action.validation }
  case 'change': {
    const { [action.field]: oldValue, ...originalCategory } = state.category
    const { [action.field]: oldError, ...originalValidation } = state.validation
    return { category: { ...originalCategory, [action.field]: action.value }, validation: { ...originalValidation } }
  }
  default:
    throw new Error()
  }
}

const ErrorCategoryDetails = ({ category, onSave, onCancel }) => {
  useEffect(() => {
    document.title = `QiTASC ConQlude - Error Category Details: ${category.name}`
  }, [category.name])
  const initialState = { category, validation: {} }
  const [state, dispatch] = useReducer(reducer, initialState)

  const handleSave = () => {
    const nameError = (state.category.name.length < 3) ? 'Name must be at least 3 characters.' : null
    const regexError = (state.category.regex.length === 0) || !Utils.isRegexValid(state.category.regex) ? 'Invalid regex' : null
    if (nameError || regexError) {
      dispatch({ type: 'validation', validation: { name: nameError, regex: regexError } })
    } else {
      onSave(state.category)
    }
  }

  const handleTextChange = field => e => dispatch({ type: 'change', field, value: e.target.value })
  const handleSelectChange = field => e => dispatch({ type: 'change', field, value: e.target.value })
  const handleExternalCodesChange = useCallback(value => dispatch({ type: 'change', field: 'externalCodes', value }), [])

  return (
    <Card>
      <CardHeader title="Error Category Details" />
      <CardContent className={style.detailsCard}>
        <TextField
          value={state.category.name}
          id="nameField"
          fullWidth
          onChange={handleTextChange('name')}
          error={state.validation.name != null}
          helperText={state.validation.name}
          label="Name"
        />
        <div>
          <FormControl fullWidth>
            <InputLabel>Scope</InputLabel>
            <Select
              value={state.category.scope}
              onChange={handleSelectChange('scope')}
            >
              <MenuItem key="MESSAGE" value="MESSAGE">Error message</MenuItem>
              <MenuItem key="STEP_LOG" value="STEP_LOG">Step logs</MenuItem>
              <MenuItem key="TEST_LOG" value="TEST_LOG">Test logs</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TextField
          value={state.category.regex}
          id="regexField"
          fullWidth
          onChange={handleTextChange('regex')}
          error={state.validation.regex != null}
          helperText={
            state.validation.regex != null
              ? state.validation.regex
              : 'Use the double backslash to escape any special character and interpret it literally.'
          }
          label="Regex"
        />
        <TextField
          value={state.category.group}
          id="groupField"
          fullWidth
          onChange={handleTextChange('group')}
          error={state.validation.group != null}
          helperText={state.validation.group}
          label="Group"
        />
        <div>
          <span>External Codes</span>
          <div>
            <ExternalCodes
              id="groupField"
              list={state.category.externalCodes}
              onSave={handleExternalCodesChange}
            />
          </div>
        </div>
        <div>
          <Button variant="contained" id="saveButton" onClick={handleSave} color="primary">Save</Button>
          <Button variant="contained" id="cancelButton" onClick={onCancel}>Cancel</Button>
        </div>
      </CardContent>
    </Card>
  )
}

ErrorCategoryDetails.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    regex: PropTypes.string,
    scope: PropTypes.string,
    group: PropTypes.string,
    externalCodes: PropTypes.arrayOf(PropTypes.object),
  }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

ErrorCategoryDetails.defaultProps = { category: defaultCategory }

export default ErrorCategoryDetails
