import axios from 'axios'
import get from 'lodash/get'
import { catchError, requestOptions } from 'services/common'

class ProjectService {
  findAll = () => {
    const url = '/api/project'
    return axios.get(url, requestOptions)
      .then(response => response.data)
      .then(list => list.map(project => ({
        ...project,
        enabledModules: get(project, 'enabledModules', []),
      })))
      .catch(catchError)
  }

  updateProject = (project, name, enabled) => {
    const request = { name, enabled }
    const url = `/api/project/${project}`
    return axios.post(url, request, requestOptions)
      .then(response => response.data)
      .catch(catchError)
  }

  addProject = (code, name, enabled) => {
    const request = { code, name, enabled }
    const url = '/api/project'
    return axios.post(url, request, requestOptions)
      .then(response => response.data)
      .catch(catchError)
  }

  updateSettings = ({ timeoutInSeconds, youTrackExternalId, code, ...rest }) => {
    const request = {
      ...rest,
      timeoutInSeconds: parseInt(timeoutInSeconds, 10),
      youTrackExternalId: youTrackExternalId === 'disabled' ? null : youTrackExternalId,
    }
    const url = `/api/project/${code}/settings`
    return axios.post(url, request, requestOptions)
      .then(response => response.data)
      .catch(catchError)
  }
}

const instance = new ProjectService()
export default instance
