import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { NavLink } from 'react-router-dom'

import style from './style.module.scss'

const HeaderButton = ({ label, url, matchPath, ...otherProps }) => (
  <Button
    variant="contained"
    component={NavLink}
    to={url}
    {...otherProps}
    color={window.location.pathname.startsWith(matchPath || url) ? 'secondary' : 'inherit'}
    className={style.button}
  >
    {label}
  </Button>
)

HeaderButton.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  matchPath: PropTypes.string,
}

HeaderButton.defaultProps = { matchPath: null }

export default HeaderButton
