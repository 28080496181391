import PropTypes from 'prop-types'
import ImageIcon from '@mui/icons-material/Image'
import ReceiptIcon from '@mui/icons-material/Receipt'
import VideoIcon from '@mui/icons-material/Videocam'
import VolumeIcon from '@mui/icons-material/VolumeUp'
import SdCardIcon from '@mui/icons-material/SdCard'

const inlineStyle = { width: '20px', height: '20px' }

const xml = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="20" height="20">
    <g>
      <path
        d="M 28 10 L 172 10 C 182 10 190 18 190 28 L 190 172 C 190 182 182 190 172 190 L 28 190 C 18 190 10 182 10 172 L 10 28 C 10 18 18 10 28 10 Z"
        fill="rgba(0, 0, 0, 0.87)"
      />
      <path
        d=" M 33 112 L 33 101 L 71 85 L 75 95 L 47 106 L 75 118 L 71 128 L 33 112 Z  M 121 63 L 91 150 L 77 150 L 107 63 L 121 63 Z  M 165 101 L 165
        112 L 127 128 L 123 118 L 151 106 L 123 95 L 127 85 L 165 101 Z "
        fill="rgb(255,255,255)"
      />
    </g>
  </svg>
)

const png = (
  <svg viewBox="0 0 20 20" fill="rgba(0, 0, 0, 0.87)" width="20" height="20">
    <path d="M 19 17 L 19 3 C 19 2 18 1 17 1 L 3 1 C 2 1 1 2 1 3 L 1 17 C 1 18 2 19 3 19 L 17 19 C 18 19 19 18 19 17 Z  M 7 11 L 9 15 L 13 10 L 17 16 L
      3 16 L 7 11 Z"
    />
  </svg>
)

const txt = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="20" height="20">
    <g>
      <path
        d="M 28 10 L 172 10 C 182 10 190 18 190 28 L 190 172 C 190 182 182 190 172 190 L 28 190 C 18 190 10 182 10 172 L 10 28 C 10 18 18 10 28 10 Z"
        fill="rgba(0, 0, 0, 0.87)"
      />
      <path
        d=" M 24 74 L 70 74 L 70 85 L 54 85 L 54 130 L 41 130 L 41 85 L 24 85 L 24 74 Z  M 125 130 L 110 130 L 110 130 Q 108 125 105 120 L 105 120 L
        105 120 Q 102 116 99 111 L 99 111 L 99 111 Q 98 112 96 115 L 96 115 L 96 115 Q 94 117 93 120 L 93 120 L 93 120 Q 91 123 89 126 L 89 126 L 89 126
        Q 88 128 87 130 L 87 130 L 73 130 L 73 130 Q 76 123 81 116 L 81 116 L 81 116 Q 86 109 91 101 L 91 101 L 74 74 L 89 74 L 100 92 L 110 74 L 124 74
        L 107 101 L 107 101 Q 113 110 118 117 L 118 117 L 118 117 Q 123 125 125 130 L 125 130 Z  M 128 74 L 174 74 L 174 85 L 157 85 L 157 130 L 144 130
        L 144 85 L 128 85 L 128 74 Z "
        fill="#fff"
      />
    </g>
  </svg>
)

const html = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="20" height="20">
    <g>
      <path
        d="M 28 10 L 172 10 C 182 10 190 18 190 28 L 190 172 C 190 182 182 190 172 190 L 28 190 C 18 190 10 182 10 172 L 10 28 C 10 18 18 10 28 10 Z"
        fill="rgba(0, 0, 0, 0.87)"
      />
      <path
        d=" M 45 97 L 45 81 L 54 81 L 54 122 L 45 122 L 45 105 L 29 105 L 29 122 L 20 122 L 20 81 L 29 81 L 29 97 L 45 97 Z  M 60 81 L 95 81 L 95 89 L 82
        89 L 82 122 L 73 122 L 73 89 L 60 89 L 60 81 Z  M 103 81 L 112 81 L 112 81 Q 113 83 114 86 L 114 86 L 114 86 Q 116 89 117 92 L 117 92 L 117 92 Q 119
        95 120 99 L 120 99 L 120 99 Q 122 102 123 105 L 123 105 L 123 105 Q 124 102 126 99 L 126 99 L 126 99 Q 127 95 129 92 L 129 92 L 129 92 Q 130 89 132
        86 L 132 86 L 132 86 Q 133 83 134 81 L 134 81 L 143 81 L 143 81 Q 143 85 144 90 L 144 90 L 144 90 Q 144 95 145 101 L 145 101 L 145 101 Q 145 106 146
        112 L 146 112 L 146 112 Q 146 117 146 122 L 146 122 L 137 122 L 137 122 Q 137 116 137 109 L 137 109 L 137 109 Q 136 102 136 95 L 136 95 L 136 95 Q 135
        97 133 100 L 133 100 L 133 100 Q 132 103 131 106 L 131 106 L 131 106 Q 129 109 128 112 L 128 112 L 128 112 Q 127 115 126 117 L 126 117 L 120 117 L 120
        117 Q 119 115 118 112 L 118 112 L 118 112 Q 116 109 115 106 L 115 106 L 115 106 Q 114 103 112 100 L 112 100 L 112 100 Q 111 97 110 95 L 110 95 L 110
        95 Q 109 102 109 109 L 109 109 L 109 109 Q 109 116 109 122 L 109 122 L 99 122 L 99 122 Q 100 117 100 112 L 100 112 L 100 112 Q 100 106 101 101 L 101
        101 L 101 101 Q 101 95 102 90 L 102 90 L 102 90 Q 102 85 103 81 L 103 81 Z  M 164 114 L 182 114 L 182 122 L 155 122 L 155 81 L 164 81 L 164 114 Z"
        fill="#fff"
      />
    </g>
  </svg>
)

const json = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="20" height="20">
    <g>
      <path
        d="M 28 10 L 172 10 C 182 10 190 18 190 28 L 190 172 C 190 182 182 190 172 190 L 28 190 C 18 190 10 182 10 172 L 10 28 C 10 18 18 10 28 10 Z"
        fill="rgba(0, 0, 0, 0.87)"
      />
      <path
        d=" M 162 101 L 162 112 L 162 112 Q 158 112 156 114 L 156 114 L 156 114 Q 154 117 154 120 L 154 120 L 154 134 L 154 134 Q 154 138 153 141 L 153
        141 L 153 141 Q 152 144 150 146 L 150 146 L 150 146 Q 148 148 144 149 L 144 149 L 144 149 Q 140 150 134 150 L 134 150 L 133 150 L 133 139 L 135 139
        L 135 139 Q 139 139 140 138 L 140 138 L 140 138 Q 142 136 142 132 L 142 132 L 142 120 L 142 120 Q 142 115 143 112 L 143 112 L 143 112 Q 144 109 148
        107 L 148 107 L 148 107 Q 144 104 143 101 L 143 101 L 143 101 Q 142 98 142 93 L 142 93 L 142 81 L 142 81 Q 142 77 140 75 L 140 75 L 140 75 Q 139 74
        135 74 L 135 74 L 133 74 L 133 63 L 134 63 L 134 63 Q 140 63 144 64 L 144 64 L 144 64 Q 148 65 150 67 L 150 67 L 150 67 Q 152 69 153 72 L 153 72 L
        153 72 Q 154 75 154 79 L 154 79 L 154 93 L 154 93 Q 154 97 156 99 L 156 99 L 156 99 Q 158 101 162 101 L 162 101 Z  M 107 94 L 107 94 L 107 94 Q 107
        98 105 101 L 105 101 L 105 101 Q 102 103 99 103 L 99 103 L 99 103 Q 96 103 93 101 L 93 101 L 93 101 Q 91 98 91 94 L 91 94 L 91 94 Q 91 90 93 88 L 93
        88 L 93 88 Q 96 86 99 86 L 99 86 L 99 86 Q 102 86 105 88 L 105 88 L 105 88 Q 107 90 107 94 Z  M 93 120 L 107 120 L 107 120 Q 107 122 107 123 L 107
        123 L 107 123 Q 107 124 107 124 L 107 124 L 107 124 Q 107 127 107 130 L 107 130 L 107 130 Q 106 133 105 136 L 105 136 L 105 136 Q 104 139 103 142 L
        103 142 L 103 142 Q 102 145 100 148 L 100 148 L 89 145 L 89 145 Q 92 140 92 134 L 92 134 L 92 134 Q 93 129 93 126 L 93 126 L 93 126 Q 93 125 93 124
        L 93 124 L 93 124 Q 93 123 93 123 L 93 123 L 93 123 Q 93 122 93 121 L 93 121 L 93 121 Q 93 120 93 120 L 93 120 Z  M 36 112 L 36 101 L 36 101 Q 40 101
        42 99 L 42 99 L 42 99 Q 44 97 44 93 L 44 93 L 44 79 L 44 79 Q 44 75 45 72 L 45 72 L 45 72 Q 46 69 48 67 L 48 67 L 48 67 Q 50 65 54 64 L 54 64 L 54 64
        Q 58 63 64 63 L 64 63 L 65 63 L 65 74 L 63 74 L 63 74 Q 59 74 58 75 L 58 75 L 58 75 Q 56 77 56 81 L 56 81 L 56 93 L 56 93 Q 56 98 55 101 L 55 101 L 55
        101 Q 54 104 50 107 L 50 107 L 50 107 Q 54 109 55 112 L 55 112 L 55 112 Q 56 115 56 120 L 56 120 L 56 132 L 56 132 Q 56 136 58 138 L 58 138 L 58 138 Q
        59 139 63 139 L 63 139 L 65 139 L 65 150 L 64 150 L 64 150 Q 58 150 54 149 L 54 149 L 54 149 Q 50 148 48 146 L 48 146 L 48 146 Q 46 144 45 141 L 45 141
        L 45 141 Q 44 138 44 134 L 44 134 L 44 120 L 44 120 Q 44 117 42 114 L 42 114 L 42 114 Q 40 112 36 112 L 36 112 Z "
        fill="#fff"
      />
    </g>
  </svg>
)

const pcap = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="20" height="20">
    <g>
      <path
        d="M 28 10 L 172 10 C 182 10 190 18 190 28 L 190 172 C 190 182 182 190 172 190 L 28 190 C 18 190 10 182 10 172 L 10 28 C 10 18 18 10 28 10 Z"
        fill="rgba(0, 0, 0, 0.87)"
      />
      <path
        d=" M 37 81 L 37 81 L 37 81 Q 46 81 51 84 L 51 84 L 51 84 Q 56 87 56 95 L 56 95 L 56 95 Q 56 102 51 105 L 51 105 L 51 105 Q 46 109 36 109 L 36 109
        L 33 109 L 33 122 L 24 122 L 24 82 L 24 82 Q 27 81 31 81 L 31 81 L 31 81 Q 34 81 37 81 Z  M 37 89 L 37 89 L 37 89 Q 36 89 35 89 L 35 89 L 35 89 Q 34
        89 33 89 L 33 89 L 33 101 L 36 101 L 36 101 Q 41 101 44 99 L 44 99 L 44 99 Q 46 98 46 94 L 46 94 L 46 94 Q 46 93 46 92 L 46 92 L 46 92 Q 45 91 44 90 L
        44 90 L 44 90 Q 43 89 41 89 L 41 89 L 41 89 Q 39 89 37 89 Z  M 82 123 L 82 123 L 82 123 Q 72 123 66 118 L 66 118 L 66 118 Q 61 112 61 102 L 61 102 L
        61 102 Q 61 96 63 92 L 63 92 L 63 92 Q 64 88 67 85 L 67 85 L 67 85 Q 70 83 74 81 L 74 81 L 74 81 Q 78 80 82 80 L 82 80 L 82 80 Q 85 80 87 80 L 87 80 L
        87 80 Q 89 81 91 81 L 91 81 L 91 81 Q 92 82 93 82 L 93 82 L 93 82 Q 95 83 95 83 L 95 83 L 92 90 L 92 90 Q 90 89 88 89 L 88 89 L 88 89 Q 85 88 82 88 L
        82 88 L 82 88 Q 80 88 78 89 L 78 89 L 78 89 Q 76 89 74 91 L 74 91 L 74 91 Q 73 93 72 95 L 72 95 L 72 95 Q 71 98 71 101 L 71 101 L 71 101 Q 71 104 71
        107 L 71 107 L 71 107 Q 72 109 73 111 L 73 111 L 73 111 Q 75 113 77 114 L 77 114 L 77 114 Q 79 115 83 115 L 83 115 L 83 115 Q 85 115 86 115 L 86 115 L
        86 115 Q 88 114 89 114 L 89 114 L 89 114 Q 90 114 91 113 L 91 113 L 91 113 Q 92 113 93 113 L 93 113 L 96 120 L 96 120 Q 94 121 90 122 L 90 122 L 90
        122 Q 86 123 82 123 Z  M 140 122 L 130 122 L 130 122 Q 129 120 128 118 L 128 118 L 128 118 Q 127 116 126 113 L 126 113 L 110 113 L 110 113 Q 109 116
        109 118 L 109 118 L 109 118 Q 108 120 107 122 L 107 122 L 97 122 L 97 122 Q 100 116 102 110 L 102 110 L 102 110 Q 104 104 106 99 L 106 99 L 106 99 Q
        108 94 110 90 L 110 90 L 110 90 Q 112 85 114 81 L 114 81 L 123 81 L 123 81 Q 125 85 127 90 L 127 90 L 127 90 Q 129 94 131 99 L 131 99 L 131 99 Q 133
        104 135 110 L 135 110 L 135 110 Q 137 116 140 122 L 140 122 Z  M 118 90 L 118 90 L 118 90 Q 118 91 117 93 L 117 93 L 117 93 Q 117 94 116 96 L 116 96 L
        116 96 Q 115 98 114 101 L 114 101 L 114 101 Q 113 103 112 106 L 112 106 L 124 106 L 124 106 Q 123 103 122 101 L 122 101 L 122 101 Q 121 98 121 96 L
        121 96 L 121 96 Q 120 94 119 93 L 119 93 L 119 93 Q 119 91 118 90 Z  M 157 81 L 157 81 L 157 81 Q 167 81 172 84 L 172 84 L 172 84 Q 177 87 177 95 L
        177 95 L 177 95 Q 177 102 172 105 L 172 105 L 172 105 Q 167 109 157 109 L 157 109 L 154 109 L 154 122 L 145 122 L 145 82 L 145 82 Q 148 81 151 81 L
        151 81 L 151 81 Q 155 81 157 81 Z  M 158 89 L 158 89 L 158 89 Q 157 89 156 89 L 156 89 L 156 89 Q 155 89 154 89 L 154 89 L 154 101 L 157 101 L 157 101
        Q 162 101 164 99 L 164 99 L 164 99 Q 167 98 167 94 L 167 94 L 167 94 Q 167 93 166 92 L 166 92 L 166 92 Q 166 91 165 90 L 165 90 L 165 90 Q 163 89 162
        89 L 162 89 L 162 89 Q 160 89 158 89 Z "
        fill="#fff"
      />
    </g>
  </svg>
)


const PreviewIcon = (props) => {
  if (props.iconType.startsWith('text/xml')) {
    return xml
  } if (props.iconType.startsWith('text/plain')) {
    return txt
  } if (props.iconType.startsWith('application/report')) {
    return <ReceiptIcon style={inlineStyle} />
  } if (props.iconType.startsWith('application/json')) {
    return json
  } if (props.iconType.startsWith('application/vnd.tcpdump.pcap')) {
    return pcap
  } if (props.iconType.startsWith('image/png')) {
    return png
  } if (props.iconType.startsWith('text/html')) {
    return html
  } if (props.iconType.startsWith('application/vnd.tcpdump.pcap')) {
    return pcap
  } if (props.iconType.startsWith('image/')) {
    return <ImageIcon style={inlineStyle} />
  } if (props.iconType.startsWith('text/')) {
    return <ReceiptIcon style={inlineStyle} />
  } if (props.iconType.startsWith('audio/')) {
    return <VolumeIcon style={inlineStyle} />
  } if (props.iconType === 'audio/mp4') {
    return <VideoIcon style={inlineStyle} />
  }
  return <SdCardIcon style={inlineStyle} />
}

PreviewIcon.propTypes = {
  iconType: PropTypes.string.isRequired,
}

export default PreviewIcon
