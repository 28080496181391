import { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Snackbar from '@mui/material/Snackbar'

import { loadSettings, saveSetting } from './actions'
import style from './style.module.scss'

const GlobalSettings = () => {
  const [state, setState] = useState({ allowInvalidProjects: false, disableReports: false })
  useEffect(() => {
    document.title = 'QiTASC ConQlude - Global Settings'
    loadSettings().then((settings) => {
      const allowInvalidProjects = settings.find(it => it.name === 'ALLOW_INVALID_PROJECTS').value
      const disableReports = settings.find(it => it.name === 'DISABLE_REPORTS').value
      setState({ allowInvalidProjects, disableReports })
    })
  }, [])
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const handleAllowInvalidProjectsChange = (value) => {
    setState({ ...state, allowInvalidProjects: value })
    saveSetting('ALLOW_INVALID_PROJECTS', value).then(() => {
      setShowSuccessMessage(true)
    })
  }
  const handleDisableReportsChange = (value) => {
    setState({ ...state, disableReports: value })
    saveSetting('DISABLE_REPORTS', value).then(() => {
      setShowSuccessMessage(true)
    })
  }
  return (
    <Card>
      <CardHeader title="Global application settings" />
      <CardContent className={style.contents}>
        <div>
          <FormControlLabel
            label="Allow invalid projects"
            control={(
              <Checkbox
                checked={state.allowInvalidProjects}
                onChange={e => handleAllowInvalidProjectsChange(e.target.checked)}
              />
            )}
          />
          <FormHelperText>Enables verification and approval processes.</FormHelperText>
        </div>
        <div>
          <FormControlLabel
            label="Disable reports"
            control={(
              <Checkbox
                checked={state.disableReports}
                onChange={e => handleDisableReportsChange(e.target.checked)}
              />
            )}
          />
          <FormHelperText>Disables receiving reports from intaQt. No reports can be sent by users.</FormHelperText>
        </div>
      </CardContent>
      <Snackbar
        message="Settings were saved successfully."
        autoHideDuration={10000}
        open={showSuccessMessage}
        onClose={() => setShowSuccessMessage(false)}
      />
    </Card>
  )
}

export default GlobalSettings
