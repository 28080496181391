import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class TestSuiteStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      list: [],
      loading: true,
      defaultPageSize: 20,
      openDrawer: true,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testSuiteList.listReceived: {
      this.state.list = action.list.items
      this.state.totalSize = action.list.totalSize
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testSuiteList.listExpired: {
      this.state.loading = true
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testSuiteList.failed: {
      this.state.error = action.error
      this.emitChange()
      break
    }
    case events.testSuiteList.openDrawer: {
      this.state.openDrawer = action.open || false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new TestSuiteStore()
dispatcher.register(store.handleActions.bind(store))

export default store
