import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import * as Utils from 'components/utils'
import DefectIcon from 'components/reports/testRunReport/widgets/defectIcon'
import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import Spinner from 'components/spinner'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'

import style from './style.module.css'
import Store from './store'
import retrieveReport from './actions'

class HpqcExecutionReport extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount = () => {
    const { projectCode } = this.context
    const { pageSize } = this.state
    document.title = 'QiTASC ConQlude - Executions uploaded to HPQC'
    Store.on('change', this.updateState)
    retrieveReport(projectCode, pageSize, this.parsePageNumber())
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { pageSize } = this.state
      const { projectCode } = this.context
      retrieveReport(projectCode, pageSize, this.parsePageNumber(this.props))
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  parsePageNumber = (props) => {
    const { location } = this.props
    const query = props == null ? Utils.parseQuery(location.search) : Utils.parseQuery(props.location.search)
    return query && query.pageNumber ? Number.parseInt(query.pageNumber, 10) : 1
  }

  render() {
    const { match } = this.props
    const { error, loading, items, totalPages } = this.state
    const columns = [
      {
        Header: '',
        accessor: 'testId',
        Cell: item => <TestStatusIcon status={item.value} />,
        disableResizing: true,
        width: 48,
      },
      {
        Header: 'Test Name',
        accessor: 'testName',
        Cell: item => (
          <>
            <Link to={`/${match.params.projectCode}/detail/${item.row.original.testId}`}>
              <TrimmedText text={item.value} maxLength={30} />
            </Link>
            <DefectIcon defects={item.row.original.defects} warnings={item.row.original.warnings} />
          </>
        ),
      },
      {
        Header: 'Defects',
        accessor: 'defectsString',
        width: 130,
      },
      {
        Header: 'Error Category',
        accessor: 'errorCategories',
        Cell: item => (
          <ErrorCategoryColumn
            className={style.errorCategoryColumn}
            labels={item.row.original.labels}
            errorCategories={item.value}
            errorMessage={item.row.original.errorMessage}
          />
        ),
        width: 140,
      },
      {
        Header: 'Tester',
        accessor: 'tester',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        width: 140,
      },
      {
        Header: 'Start time',
        accessor: 'startTime',
        Cell: item => Utils.formatDate(item.value),
        width: 140,
      },
      {
        Header: 'Upload result',
        accessor: 'uploadSuccessful',
        Cell: item => (item.value ? <UploadSuccessfulIcon /> : <UploadFailedIcon />),
        width: 100,
      },
    ]

    if (error != null) {
      return HpqcExecutionReport.renderError(error.message)
    }

    return (
      <Spinner spinning={loading}>
        <Card className={style.card}>
          <CardHeader title="Executions uploaded to HPQC" />
          <Table
            id="executionReport"
            columns={columns}
            data={items}
            totalPages={totalPages}
          />
        </Card>
      </Spinner>
    )
  }
}

HpqcExecutionReport.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

HpqcExecutionReport.contextType = ProjectContext

HpqcExecutionReport.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default withRouter(HpqcExecutionReport)
