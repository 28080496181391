import { memo } from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'

import TrimmedText from 'components/trimmedText'
import styles from './style.module.css'

const ERROR_MESSAGE_MAX_LENGTH = 300


const ErrorCategoryColumn = ({
  errorCategories, labels, errorMessage, className, style,
}) => {
  const getErrorCategoryName = () => {
    if (errorCategories && errorCategories.length > 0) {
      return errorCategories.length === 1 ? errorCategories[0] : 'Several Failures'
    }
    return ''
  }

  const getErrorCategoryTooltip = () => {
    if (!errorCategories) {
      return ''
    }
    if (errorCategories.length > 1) {
      return errorCategories.join('\n')
    }
    if (errorMessage) {
      return errorMessage.length < ERROR_MESSAGE_MAX_LENGTH ? errorMessage
        : errorMessage.substring(0, ERROR_MESSAGE_MAX_LENGTH).trim().concat('...')
    }
    return ''
  }

  return (
    <div
      className={className}
      style={style}
      data-tip={getErrorCategoryTooltip()}
      data-class={styles.errorCategoryTooltip}
      data-type="error"
    >
      <TrimmedText text={getErrorCategoryName()} maxLength={22} />
      { labels.map(label => (
        <Chip key={label} label={label} size="small" />
      ))}
    </div>
  )
}

ErrorCategoryColumn.propTypes = {
  errorCategories: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({ backgroundColor: PropTypes.string }),
}

ErrorCategoryColumn.defaultProps = {
  errorCategories: [],
  labels: [],
  errorMessage: '',
  className: null,
  style: {},
}

export default memo(ErrorCategoryColumn)
