import axios from 'axios'
import * as Common from 'services/common'

class HpqcService {
  getDetails = (project, executionId) => {
    const url = `/api/module/hpqc/${project}/details/${executionId}`
    return axios.get(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateHpqcStatus = (project, executionId, status) => {
    const url = `/api/module/hpqc/${project}/details/${executionId}/status`
    return axios.post(url, { status }, Common.requestOptions)
      .catch(Common.catchError)
  }

  updateNote = (project, executionId, note) => {
    const url = `/api/module/hpqc/${project}/details/${executionId}/note`
    return axios.post(url, { note }, Common.requestOptions)
      .catch(Common.catchError)
  }

  getTestPlan = (project, testSuiteId) => {
    const url = `/api/module/hpqc/${project}/test-suite/${testSuiteId}/testPlan`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateTestPlan = ({ project, testSuiteId, testPlan }) => {
    const url = `/api/module/hpqc/${project}/test-suite/${testSuiteId}/testPlan`
    return axios.post(url, testPlan, Common.requestOptions)
      .catch(Common.catchError)
  }

  createTestPlan = (project, testSuiteId) => {
    const url = `/api/module/hpqc/${project}/test-suite/${testSuiteId}/testPlan`
    return axios.put(url, {}, Common.requestOptions)
      .catch(Common.catchError)
  }

  createTestInstance = ({ project, testSuiteId, testSetId }) => {
    const url = `/api/module/hpqc/${project}/test-suite/${testSuiteId}/testInstance`
    return axios.put(url, { testSetId }, Common.requestOptions)
      .catch(Common.catchError)
  }

  updateTestPlanId = (project, testSuiteId, testPlanId) => {
    const url = `/api/module/hpqc/${project}/test-suite/${testSuiteId}/testPlanId`
    return axios.post(url, { testPlanId }, Common.requestOptions)
      .catch(Common.catchError)
  }

  retriveHpqcConfiguration = (project) => {
    const url = `/api/module/hpqc/${project}/test-set-config`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  deleteHpqcConfiguration = (project, id) => {
    const url = `/api/module/hpqc/${project}/test-set-config/${id}`
    return axios.delete(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  addHpqcConfiguration = (config, project) => {
    const url = `/api/module/hpqc/${project}/test-set-config`
    return axios.post(url, config, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateHpqcConfiguration = (config, project) => {
    const url = `/api/module/hpqc/${project}/test-set-config/${config.id}`
    return axios.post(url, config, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  verifyHpqcConfiguration = (project) => {
    const url = `/api/module/hpqc/${project}/test-set-config/verification`
    return axios.post(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  retriveHpqcSettings = (project) => {
    const url = `/api/module/hpqc/${project}/settings`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateHpqcSettings = (project, settings) => {
    const url = `/api/module/hpqc/${project}/settings`
    const isInt = settings.delayBeforeAutoSubmit == parseInt(settings.delayBeforeAutoSubmit, 10) // eslint-disable-line eqeqeq
    const request = isInt ? settings : { ...settings, delayBeforeAutoSubmit: null }
    return axios.post(url, request, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateExcelImportSettings = (project, settings) => {
    const url = `/api/module/hpqc/${project}/settings/excel-import`
    return axios.post(url, settings, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateCustomFields = (project, list) => {
    const url = `/api/module/hpqc/${project}/settings/custom-fields`
    return axios.post(url, list, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getCredentials = (project) => {
    const url = `/api/module/hpqc/${project}/settings/credentials`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateCredentials = (project, credentials) => {
    const url = `/api/module/hpqc/${project}/settings/credentials`
    return axios.post(url, credentials, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateBlacklist = (project, blacklist) => {
    const url = `/api/module/hpqc/${project}/settings/blacklist`
    return axios.post(url, blacklist, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateName = ({ testSuiteId, name }) => {
    const url = `/api/test-suite/${testSuiteId}`
    return axios.patch(url, { name }, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new HpqcService()
export default instance
