import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'


const TemplateMenu = ({ onDelete, onRename, buttonClassName }) => {
  const [open, setOpen] = useState(false)
  const buttonRef = useRef(null)

  const handleClick = () => { setOpen(true) }

  const handleClose = () => { setOpen(false) }

  return (
    <>
      <IconButton
        onClick={handleClick}
        ref={buttonRef}
        className={buttonClassName}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={onRename}>Rename</MenuItem>
        <MenuItem onClick={onDelete}>Delete</MenuItem>
      </Menu>
    </>
  )
}

TemplateMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  buttonClassName: PropTypes.string,
}

TemplateMenu.defaultProps = { buttonClassName: null }

export default TemplateMenu
