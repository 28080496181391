import axios from 'axios'
import * as Common from 'services/common'

class TestCategoryService {
  constructor() {
    this.testCategories = new Map()
  }

  extractList = project => (response) => {
    this.testCategories.set(project, response.data)
    return response.data
  }

  findAll = (project) => {
    if (this.testCategories.has(project)) {
      return Promise.resolve(this.testCategories.get(project))
    }
    const url = `/api/project/${project}/configuration/testCategories`
    return axios.get(url, Common.requestOptions)
      .then(this.extractList(project))
      .catch(Common.catchError)
  }

  delete = (project, id) => {
    const url = `/api/project/${project}/configuration/testCategories/${id}`
    return axios.delete(url, {}, Common.requestOptions)
      .then(this.extractList(project))
      .catch(Common.catchError)
  }

  update = (testCategory, project) => {
    const url = `/api/project/${project}/configuration/testCategories/${testCategory.id}`
    return axios.post(url, testCategory, Common.requestOptions)
      .then(this.extractList(project))
      .catch(Common.catchError)
  }

  add = (testCategory, project) => {
    const url = `/api/project/${project}/configuration/testCategories`
    return axios.post(url, testCategory, Common.requestOptions)
      .then(this.extractList(project))
      .catch(Common.catchError)
  }
}

const instance = new TestCategoryService()
export default instance
