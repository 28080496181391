import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import PageNotFound from 'pages/pageNotFound'

class ErrorPage extends PureComponent {
  componentDidMount() {
    document.title = 'QiTASC ConQlude - Error'
  }

  render() {
    const { location } = this.props
    if (location.state && location.state.message) {
      return (
        <Card>
          <CardHeader title="Something went wrong..." />
          <CardContent>
            <p>{location.state.message}</p>
          </CardContent>
        </Card>
      )
    }
    return (
      <PageNotFound />
    )
  }
}

ErrorPage.propTypes = {
  location: PropTypes.shape({ state: PropTypes.object }).isRequired,
}

export default withRouter(ErrorPage)
