import { Component } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import DataField from 'components/executionDetails/dataField'
import DateTimePicker from 'components/dateTimePicker'
import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import HpqcStore from './store'
import * as Actions from './actions'
import style from './testSets.module.css'

class HpqcTestSets extends Component {
  constructor(props) {
    super(props)
    this.state = HpqcStore.getState()
  }

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - HPQC Test Set Configuration'
    HpqcStore.on('change', this.updateState)
    Actions.retrieveInitialData(projectCode)
  }

  componentWillUnmount = () => {
    HpqcStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(HpqcStore.getState())
  }

  handleSave = () => {
    const { projectCode } = this.context
    const { canSubmit, newConfig } = this.state
    if (canSubmit) {
      Actions.save(newConfig, projectCode)
    } else {
      Actions.validate()
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  renderEditRow = (id) => {
    const { newConfig, testCategories } = this.state
    return (
      <form key={id} onSubmit={this.handleSubmit}>
        <div className={style.configRow} id="editRow">
          <div className={style.nameColumn}>
            <TextField
              id="nameField"
              name="name"
              label="Name"
              fullWidth
              value={newConfig.name}
              onChange={e => Actions.handleNameChange(e.target.value)}
            />
          </div>
          <FormControl className={style.categoryColumn}>
            <InputLabel>Test Category</InputLabel>
            <Select
              id="testCategoryField"
              fullWidth
              value={newConfig.testCategory}
              onChange={e => Actions.handleTestCategoryChange(e.target.value)}
            >
              {testCategories.map(category => (
                <MenuItem key={category.id} value={category.name}>{category.name}</MenuItem>))}
            </Select>
          </FormControl>
          <div className={style.dateColumn}>
            <DateTimePicker id="dateFromField" initialValue={newConfig.dateFrom} onChange={Actions.handleDateFromChange} />
          </div>
          <div className={style.dateColumn}>
            <DateTimePicker id="dateToField" initialValue={newConfig.dateTo} onChange={Actions.handleDateToChange} />
          </div>
          <div className={style.testSetIdColumn}>
            <TextField
              id="testSetIdField"
              name="addTSID"
              label="Test Set ID"
              fullWidth
              value={newConfig.testSetId}
              onChange={e => Actions.handleTestSetIdChange(e.target.value)}
            />
          </div>
          <div className={style.enabledColumn}>
            <Checkbox id="enabledCheckBox" checked={newConfig.enabled} onChange={e => Actions.handleEnabledChange(e.target.checked)} />
          </div>
          <div className={style.buttonColumn}>
            <IconButton src="/images/accept.svg" onClick={this.handleSave} id="saveButton" />
            <IconButton src="/images/cancel.svg" onClick={Actions.handleCancel} id="cancelButton" />
          </div>
        </div>
      </form>
    )
  }

  render() {
    const { projectCode } = this.context
    const {
      error, loading, items, selectedRow, addConfig, showError, validationError, verifiedBy, verificationTime,
    } = this.state
    if (error != null) {
      return HpqcTestSets.renderError(error)
    }

    return (
      <div>
        <Typography variant="h6">HPQC Test Set Configuration</Typography>
        <Spinner spinning={loading}>
          <Box marginBottom={3}>
            <div className={style.wrapper}>
              <div key="header" className={style.headerRow}>
                <div className={style.nameColumn}>Name</div>
                <div className={style.categoryColumn}>Test Category</div>
                <div className={style.dateColumn}>Start Date</div>
                <div className={style.dateColumn}>End Date</div>
                <div className={style.testSetIdColumn}>ALM Test Set ID</div>
                <div className={style.enabledColumn}>Enabled</div>
                <div className={style.buttonColumn} />
              </div>
              {items.map(item => (item.id === selectedRow ? this.renderEditRow(item.id) : (
                <div key={item.id} id={`${item.id}_row`} className={style.configRow}>
                  <div className={style.nameColumn}>{item.name}</div>
                  <div className={style.categoryColumn}>{item.testCategory}</div>
                  <div className={style.dateColumn}>{item.formattedDateFrom}</div>
                  <div className={style.dateColumn}>{item.formattedDateTo}</div>
                  <div className={style.testSetIdColumn}>{item.testSetId}</div>
                  <div className={style.enabledColumn}>
                    <Checkbox checked={item.enabled} disabled />
                  </div>
                  <div className={style.buttonColumn}>
                    <IconButton onClick={() => Actions.handleDelete(projectCode, item.id)} src="/images/delete.svg" id={`${item.id}_deleteButton`} />
                    <IconButton onClick={() => Actions.handleEdit(item.id)} src="/images/edit.svg" id={`${item.id}_editButton`} />
                  </div>
                </div>
              )))}
              { addConfig && this.renderEditRow('new')}
            </div>
            { !addConfig && (
              <div className={style.wrapper}>
                <IconButton onClick={Actions.handleAdd} src="/images/add.svg" id="addButton" />
              </div>
            )}
            {showError && (
              <div className={style.wrapper}>
                <a className={style.validationError}>{validationError}</a>
              </div>
            )}
          </Box>
          <Typography variant="h6">Verification</Typography>
          <Box>
            <DataField name="Verified By" value={verifiedBy} />
            <DataField name="Verification Time" value={verificationTime} />
            <br />
            <Button variant="contained" id="verifyNowButton" onClick={() => Actions.verify(projectCode)}>Verify for today</Button>
          </Box>
        </Spinner>
      </div>
    )
  }
}

HpqcTestSets.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

HpqcTestSets.contextType = ProjectContext

export default HpqcTestSets
