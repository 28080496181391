import TfaCredentialsService from 'services/tfaCredentialsService'

export const load = async (project) => {
  const credentials = await TfaCredentialsService.load(project)
  return credentials
}

export const save = async (project, credentials) => {
  await TfaCredentialsService.save(project, credentials)
}
