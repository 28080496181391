import { useCallback } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import find from 'lodash/find'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import HpqcExecutionReport from 'components/reports/hpqcExecutionReport/hpqcExecutionReport'
import HpqcReadyReport from 'components/reports/hpqcReadyReport/hpqcReadyReport'
import HpqcUploadedReport from 'components/reports/hpqcUploadedReport/hpqcUploadedReport'
import JiraUploads from 'components/reports/jiraUploads'
import JiraBasicUploads from 'components/reports/jiraBasicUploads'
import PageNotFound from 'pages/pageNotFound'
import TestRunReport from 'components/reports/testRunReport/testRunReport'
import TestSuiteHistory from 'components/reports/testSuiteHistory/testSuiteHistory'
import PmsExportReport from 'components/reports/pmsExportReport/pmsExportReport'
import UnassignedReport from 'components/reports/unassignedReport/unassigned'
import ErrorCategoryHistory from 'components/reports/errorCategoryHistory/errorCategoryHistory'
import useProject from 'hooks/useProject'

const DEFAULT_TAB = 'test-run-report'

const ReportPage = ({ match, history }) => {
  const {
    roles,
    hpqcEnabled,
    jiraZephyrEnabled,
    jiraBasicEnabled,
    pmsEnabled,
  } = useProject()
  const isTestManager = roles.includes('ROLE_TEST_MANAGER')
  const tabs = [
    { name: 'test-run-report', label: 'Test Run Report' },
    { name: 'test-suite-history', label: 'Test Suite History' },
    { name: 'error-categories-history', label: 'Error Category History' },
    { name: 'unassigned-reports', label: 'Unassigned Reports' },
    { name: 'pms-export', label: 'PMS Exports', enabled: pmsEnabled },
    { name: 'hpqc-ready', label: 'Executions Ready To Upload', enabled: hpqcEnabled && isTestManager },
    { name: 'hpqc-executions', label: 'Uploaded Executions', enabled: hpqcEnabled && isTestManager },
    { name: 'hpqc-uploads', label: 'HPQC Uploads', enabled: hpqcEnabled && isTestManager },
    { name: 'jira-uploads', label: 'Jira Uploads', enabled: jiraZephyrEnabled && isTestManager },
    { name: 'jira-basic-uploads', label: 'Jira Uploads', enabled: jiraBasicEnabled && isTestManager },
  ]
  const handleChange = useCallback((_, val) => {
    const selectedTab = find(tabs, { name: val })
    const link = get(selectedTab, 'name', val)
    history.push(`${match.url}/${link}`)
  }, [])
  const matchingTab = find(tabs, it => window.location.pathname.startsWith(`${match.url}/${get(it, 'name')}`))
  return (
    <>
      <Tabs
        onChange={handleChange}
        value={matchingTab ? matchingTab.name : DEFAULT_TAB}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs
          .filter(tab => get(tab, 'enabled', true))
          .map(tab => <Tab id={tab.name} key={tab.name} value={tab.name} label={tab.label} />)}
      </Tabs>
      <Switch>
        <Route path={`${match.path}`} exact component={TestRunReport} />
        <Route path={`${match.path}/test-run-report`} component={TestRunReport} />
        <Route path={`${match.path}/test-suite-history`} component={TestSuiteHistory} />
        <Route path={`${match.path}/error-categories-history`} component={ErrorCategoryHistory} />
        <Route path={`${match.path}/pms-export`} component={PmsExportReport} />
        <Route path={`${match.path}/unassigned-reports`} component={UnassignedReport} />
        <Route path={`${match.path}/hpqc-ready`} component={HpqcReadyReport} />
        <Route path={`${match.path}/hpqc-executions`} component={HpqcExecutionReport} />
        <Route path={`${match.path}/hpqc-uploads`} component={HpqcUploadedReport} />
        <Route path={`${match.path}/jira-uploads`} component={JiraUploads} />
        <Route path={`${match.path}/jira-basic-uploads`} component={JiraBasicUploads} />
        <Route component={PageNotFound} />
      </Switch>
    </>
  )
}

ReportPage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export default withRouter(ReportPage)
