import { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'

import FormattedDate from 'components/formattedDate'
import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'

import { create, load, save } from './actions'
import EditRow from './editRow'
import reducer from './reducer'
import style from './style.module.css'

const initialState = {
  loading: true,
  list: [],
}

const ProjectList = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    document.title = 'QiTASC ConQlude - Projects'
    load()
      .then(list => dispatch({ type: 'loaded', list }))
      .catch(handleError)
  }, [handleError])

  const handleSave = (code, name, enabled) => {
    dispatch({ type: 'saving' })
    save(code, name, enabled).catch(handleError)
  }

  const handleEdit = code => dispatch({ type: 'editing', code })

  const handleEditCancel = () => dispatch({ type: 'editCanceled' })

  const handleAdd = () => dispatch({ type: 'adding' })

  const handleCreate = (code, name, enabled) => {
    create(code, name, enabled)
      .then(() => load())
      .then(list => dispatch({ type: 'loaded', list }))
      .catch(handleError)
  }

  const {
    loading, list, selectedRow, addProject, selectedProject,
  } = state

  return (
    <Spinner spinning={loading}>
      <Card>
        <div className={style.projectsContainer}>
          <CardHeader title="Projects" />
          <div className={style.wrapper}>
            <div key="header" className={style.headerRow}>
              <div className={style.statusColumn}>Enabled</div>
              <div className={style.codeColumn}>Code</div>
              <div className={style.nameColumn}>Name</div>
              <div className={style.dateColumn}>Creation Time</div>
              <div className={style.buttonColumn} />
            </div>
            {list.map(item => (item.code === selectedRow ? (
              <EditRow
                project={item}
                key={item.code}
                onCancel={handleEditCancel}
                onSubmit={handleSave}
              />
            )
              : (
                <div key={item.code} className={style.row} id={`${item.code}_row`}>
                  <div className={style.statusColumn}>
                    <Checkbox defaultChecked={item.enabled} disabled />
                  </div>
                  <div className={style.codeColumn}>{item.code}</div>
                  <div className={style.nameColumn}>{item.name}</div>
                  <div className={style.dateColumn}><FormattedDate value={item.creationTime} /></div>
                  <div className={style.buttonColumn}>
                    <Link to={`/administration/projects/${item.code}`}>
                      <img alt="Open project settings" src="/images/settings.svg" id={`${item.code}_settingsButton`} />
                    </Link>
                    <IconButton onClick={() => handleEdit(item.code)} src="/images/edit.svg" id={`${item.code}_editButton`} />
                  </div>
                </div>
              )))}
            { addProject && (
              <EditRow
                project={selectedProject}
                add
                key="new"
                onCancel={handleEdit}
                onSubmit={handleCreate}
              />
            )}
          </div>
          { !addProject && (
            <div className={style.wrapper}>
              <IconButton onClick={handleAdd} src="/images/add.svg" />
            </div>
          )}
        </div>
      </Card>
    </Spinner>
  )
}

export default ProjectList
