import moment from 'moment'
import { dispatcher, events } from 'core/dispatcher'
import ReportingEventEmitter from 'core/eventEmitter'


/* eslint no-bitwise: ["error", { "allow": ["<<", "&="] }] */
const calculateHashCode = (word) => {
  let hash = 0
  if (word.length === 0) return hash
  for (let i = 0; i < word.length; i += 1) {
    const char = word.charCodeAt(i)
    hash = ((hash << 5) - hash) + char
    hash &= hash
  }
  return hash
}

const getColourByName = (category) => {
  const digits = 4.0
  const multiplier = (10.0 ** digits)
  const upper = Math.floor(calculateHashCode(category) / multiplier)
  const hueCalc = 360 * ((calculateHashCode(category) - (upper * multiplier)) / multiplier)
  const hue = Math.abs(Math.floor(hueCalc))
  return `hsl(${hue}, 60%, 75%)`
}

class ErrorCategoryHistoryStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      errorCategoriesHistory: [],
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.errorCategoryHistory.listReceived: {
      const data = action.list
        .map(rec => ({ ...rec, day: rec.day.slice(-5) }))
        .reduce((acc, rec) => {
          const item = acc.find(it => it.name === rec.errorCategory)
          if (item == null) {
            acc.push({ name: rec.errorCategory, count: rec.count, items: [rec] })
          } else {
            item.count += rec.count
            item.items.push(rec)
          }
          return acc
        }, [])
        .sort((a, b) => b.count - a.count)

      this.state.errorCategoriesList = data.map(it => it.name)
      this.state.date = action.date ? moment(action.date) : moment().subtract(30, 'days')
      this.state.days = Array.from(Array(30).keys())
        .map(it => moment(this.state.date).add(it, 'days').format('MM-DD'))

      this.state.errorCategoriesHistory = data
        .map(it => ({
          errorCategory: it.name,
          map: this.state.days
            .map(day => it.items.find(rec => rec.day === day) || ({ day, count: 0 }))
            .map(rec => ({ x: rec.day, y: rec.count, label: `${it.name} (${rec.count})` })),
        }))

      this.state.colors = this.state.errorCategoriesList.map(it => getColourByName(it))
      this.state.legend = data.map(it => ({
        name: `${it.name} (${it.count})`,
        symbol: { type: 'square', fill: getColourByName(it.name) },
      }))
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.errorCategoryHistory.listFailed: {
      this.state = {
        error: action.error,
        loading: false,
      }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const errorCategoryHistoryStore = new ErrorCategoryHistoryStore()
dispatcher.register(errorCategoryHistoryStore.handleActions.bind(errorCategoryHistoryStore))

export default errorCategoryHistoryStore
