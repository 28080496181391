import axios from 'axios'
import { dispatcher, events } from 'core/dispatcher'
import moment from 'moment'
import { formatDateISOZ } from 'components/utils'

const TEST_SUITE_HISTORY = '/api/test-suite-execution/history'

export function retrieveInitialData(project, filters) {
  const nameFilter = filters.name ? `&name=${filters.name}` : ''
  const dateFilter = filters.date ? `&date=${encodeURIComponent(formatDateISOZ(moment(filters.date)))}` : ''
  const pageFilter = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
  const sizeFilter = filters.pageSize ? `&size=${filters.pageSize}` : ''
  const url = `${TEST_SUITE_HISTORY}?project=${project}${dateFilter}${nameFilter}${pageFilter}${sizeFilter}`

  return axios.get(url, { timeout: 5000 })
    .then((response) => {
      dispatcher.dispatch({
        type: events.testSuiteHistory.listReceived,
        totalSize: response.data.totalSize,
        days: response.data.days,
        list: response.data.list,
        name: filters.name,
        date: filters.date,
        pageNumber: filters.pageNumber,
        pageSize: filters.pageSize,
      })
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.testSuiteHistory.listFailed, error })
    })
}

export function handleFilterChange(filters) {
  dispatcher.dispatch({ type: events.testSuiteHistory.filterChanged, filters })
}
