import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Paper from '@mui/material/Paper'

import AttachmentButton from 'components/executionDetails/attachmentButton/attachmentButton'
import DataField from 'components/executionDetails/dataField'
import ErrorMessage from 'components/executionDetails/errorMessage'
import Link from 'components/common/link'
import LogLines from 'components/executionDetails/logLines'
import Spinner from 'components/spinner'
import Title from 'components/title'

import StepStore from './store'
import * as Actions from './actions'
import style from './style.module.css'

class StepView extends Component {
  constructor(props) {
    super(props)
    this.state = StepStore.getState()
    if (!this.dataLoaded()) {
      this.state = StepStore.resetState()
    }
  }

  componentDidMount() {
    const { match } = this.props
    StepStore.on('change', this.updateState)
    if (!this.dataLoaded()) {
      const { executionId, test, step } = match.params
      Actions.retrieveDetailsAndLogs(executionId, test, step)
    }
  }

  componentWillUnmount() {
    StepStore.removeListener('change', this.updateState)
  }

  dataLoaded = () => {
    const { match } = this.props
    const { step } = this.state
    return step.executionId === match.params.executionId
      && String(step.testIndex) === match.params.test
      && String(step.index) === match.params.step
  }

  updateState = () => {
    const state = StepStore.getState()
    if (state.attachmentsHaveBeenUpdated) {
      Actions.retrieveStep(state.step.executionId, state.step.testIndex, state.step.index)
    }
    this.setState(state)
  }

  render() {
    const { match } = this.props
    const {
      error, loading, step, attachments, protocol, trace, server,
    } = this.state

    if (error != null) {
      return StepView.renderError(error)
    }

    const {
      name, testSuiteName, testName, result, errorMessage,
    } = step

    return (
      <Spinner spinning={loading}>
        <div>
          <Paper className={style.paper} zdepth={1}>
            <Title status={result} name={name} stepDetails={(step.docString || {}).value} />
            <DataField name="Test suite">
              <Link to={`/${match.params.projectCode}/detail/${match.params.executionId}`}>{testSuiteName}</Link>
            </DataField>
            <DataField name="Test">
              <Link to={`/${match.params.projectCode}/detail/${match.params.executionId}/${match.params.test}`}>{testName}</Link>
            </DataField>

            {errorMessage && (
              <DataField name="Error message">
                <ErrorMessage value={errorMessage} />
              </DataField>
            )}
          </Paper>

          <Card className={style.logCard}>
            <CardHeader title="Attachments" />
            <CardContent>
              <List>
                { attachments.map(item => (
                  <ListItem key={item.id}>
                    <AttachmentButton attachment={item} />
                    {item.name}
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
          <LogLines title="Protocol" logs={protocol.logs} status={protocol.status} error={protocol.error} />
          <LogLines title="Trace Log" logs={trace.logs} status={trace.status} error={trace.error} />
          <LogLines title="intaQt Log" logs={server.logs} status={server.status} error={server.error} />
        </div>
      </Spinner>
    )
  }
}

StepView.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

StepView.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
    params: PropTypes.object,
  }).isRequired,
}

export default withRouter(StepView)
