import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'
import CustomFieldList from './customFieldList'
import FieldWithTag from './fieldWithTagFallback'
import Portal from './newWindowPortal'
import style from './fields.module.scss'

const validateSettings = (settings) => {
  let errors = {}
  if (settings.enabled && (!settings.baseUrl || settings.baseUrl.length === 0)) {
    errors = { ...errors, baseUrl: 'Base URL cannot be empty' }
  }
  return errors
}

const Settings = ({ settings, onSave, customFields }) => {
  const initialSettings = {
    ...settings,
    parentIssueFields: settings.parentIssueFields || {},
    testIssueFields: settings.testIssueFields || {},
    executionIssueFields: settings.executionIssueFields || {},
  }
  const [state, setState] = useState({ ...initialSettings, errors: {}, showWindowPortal: false })

  const updateSettings = (s) => {
    const mergedSettings = { ...state, ...s }
    const errors = validateSettings(mergedSettings)
    setState({ ...mergedSettings, errors })
  }

  const openWindowPortal = () => {
    setState({ ...state, showWindowPortal: true })
  }

  const closeWindowPortal = () => {
    setState({ ...state, showWindowPortal: false })
  }

  const handleSave = () => onSave(state)
  return (
    <>
      <Button id="OpenPortalButton" className={style.portalButton} onClick={openWindowPortal} label="Show Custom Fields" />
      <h4 className={style.title}>Parent Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Link with parent issue">
            <TextField
              id="linkIdField"
              value={state.linkId}
              onChange={e => updateSettings({ linkId: e.target.value })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Best rate field">
            <TextField
              id="bestRateField"
              value={state.parentIssueFields.bestRate}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, bestRate: e.target.value } })}
            />
          </DataField>
          <DataField name="Worst rate field">
            <TextField
              id="worstRateField"
              value={state.parentIssueFields.worstRate}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, worstRate: e.target.value } })}
            />
          </DataField>
          <DataField name="Averate rate field">
            <TextField
              id="averageRateField"
              value={state.parentIssueFields.averageRate}
              disabled={customFields.length === 0}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, averageRate: e.target.value } })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Result field">
            <TextField
              id="resultField"
              value={state.parentIssueFields.result}
              onChange={e => updateSettings({ parentIssueFields: { ...state.parentIssueFields, result: e.target.value } })}
            />
          </DataField>
        </div>
      </div>
      <h4 className={style.title}>Test Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Issue type">
            <TextField
              id="testIssueField"
              value={state.testIssueType}
              onChange={e => updateSettings({ testIssueType: e.target.value })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <DataField name="Best rate field">
            <TextField
              id="bestRateField"
              value={state.testIssueFields.bestRate}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, bestRate: e.target.value } })}
            />
          </DataField>
          <DataField name="Worst rate field">
            <TextField
              id="worstRateField"
              value={state.testIssueFields.worstRate}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, worstRate: e.target.value } })}
            />
          </DataField>
          <DataField name="Averate rate field">
            <TextField
              id="averageRateField"
              value={state.testIssueFields.averageRate}
              onChange={e => updateSettings({ testIssueFields: { ...state.testIssueFields, averageRate: e.target.value } })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Test Case ID field"
            fields={customFields}
            field={state.testIssueFields.testCaseId}
            tag={state.testIssueFields.testCaseIdTag}
            onChange={(testCaseId, testCaseIdTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, testCaseId, testCaseIdTag } })}
          />
          <FieldWithTag
            name="App field"
            fields={customFields}
            field={state.testIssueFields.app}
            tag={state.testIssueFields.appTag}
            onChange={(app, appTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, app, appTag } })}
          />
          <FieldWithTag
            name="Platform field"
            fields={customFields}
            field={state.testIssueFields.platform}
            tag={state.testIssueFields.platformTag}
            onChange={(platform, platformTag) => updateSettings({ testIssueFields: { ...state.testIssueFields, platform, platformTag } })}
          />
        </div>
      </div>
      <h4 className={style.title}>Execution Issue</h4>
      <div className="row">
        <div className="col-md-4">
          <DataField name="Issue type">
            <TextField
              id="executionIssueField"
              value={state.executionIssueType}
              onChange={e => updateSettings({ executionIssueType: e.target.value })}
            />
          </DataField>
          <DataField name="PGW Version">
            <TextField
              id="pgwVersionTagField"
              value={state.executionIssueFields.pgwVersionTag}
              onChange={e => updateSettings({ executionIssueFields: { ...state.executionIssueFields, pgwVersionTag: e.target.value } })}
            />
          </DataField>
          <DataField id="attachmentRegexField" name="Attachment Regex">
            <TextField
              id="attachmentRegexField"
              value={state.executionIssueFields.attachmentRegex}
              onChange={e => updateSettings({ executionIssueFields: { ...state.executionIssueFields, attachmentRegex: e.target.value } })}
            />
          </DataField>
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Recognition rate field"
            fields={customFields}
            field={state.executionIssueFields.recognitionRate}
            tag={state.executionIssueFields.recognitionRateTag}
            onChange={(recognitionRate, recognitionRateTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, recognitionRate, recognitionRateTag } })
            }}
          />
          <FieldWithTag
            name="Total bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesTotal}
            tag={state.executionIssueFields.bytesTotalTag}
            onChange={(bytesTotal, bytesTotalTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesTotal, bytesTotalTag } })
            }}
          />
          <FieldWithTag
            name="Matched bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesMatched}
            tag={state.executionIssueFields.bytesMatchedTag}
            onChange={(bytesMatched, bytesMatchedTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesMatched, bytesMatchedTag } })
            }}
          />
          <FieldWithTag
            name="Unmatched bytes field"
            fields={customFields}
            field={state.executionIssueFields.bytesUnmatched}
            tag={state.executionIssueFields.bytesUnmatchedTag}
            onChange={(bytesUnmatched, bytesUnmatchedTag) => {
              updateSettings({ executionIssueFields: { ...state.executionIssueFields, bytesUnmatched, bytesUnmatchedTag } })
            }}
          />
        </div>
        <div className="col-md-4">
          <FieldWithTag
            name="Cycle field"
            fields={customFields}
            field={state.executionIssueFields.cycle}
            tag={state.executionIssueFields.cycleTag}
            onChange={(cycle, cycleTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, cycle, cycleTag } })}
          />
          <FieldWithTag
            name="Tester field"
            fields={customFields}
            field={state.executionIssueFields.tester}
            tag={state.executionIssueFields.testerTag}
            onChange={(tester, testerTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, tester, testerTag } })}
          />
          <FieldWithTag
            name="App field"
            fields={customFields}
            field={state.executionIssueFields.app}
            tag={state.executionIssueFields.appTag}
            onChange={(app, appTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, app, appTag } })}
          />
          <FieldWithTag
            name="Platform field"
            fields={customFields}
            field={state.executionIssueFields.platform}
            tag={state.executionIssueFields.platformTag}
            onChange={(platform, platformTag) => updateSettings({ executionIssueFields: { ...state.executionIssueFields, platform, platformTag } })}
          />
        </div>
      </div>
      <div className={style.buttonRow}>
        <Button
          variant="contained"
          id="saveBtn"
          color="primary"
          onClick={handleSave}
          disabled={Object.keys(state.errors) > 0}
        >Save
        </Button>
      </div>
      {state.showWindowPortal && (
        <Portal onClose={closeWindowPortal}>
          <CustomFieldList list={customFields} />
        </Portal>
      )}
    </>
  )
}

Settings.propTypes = {
  customFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
}

export default Settings
