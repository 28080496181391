import { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

import { ExportErrorCategoriesIcon } from 'components/icons'
import useProject from 'hooks/useProject'

import Import from './import'
import LabelChips from './labelChips'
import style from './style.module.scss'

const inlineStyle = {
  exportButton: { minWidth: '30px', width: '30px', height: '30px' },
}

const mapScope = (scope) => {
  switch (scope) {
  case 'STEP_LOG': return 'Step logs'
  case 'TEST_LOG': return 'Test logs'
  default: return 'Message'
  }
}

const ErrorCategoryList = ({
  onImport, onExport, onDelete, list, labels, onRemoveLabel, onAddLabel,
}) => {
  const { projectCode } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Error Category Details'
  }, [])
  return (
    <Card className={style.card}>
      <CardHeader
        title="Error Categories"
        className={style.cardHeader}
        action={(
          <>
            <Import onSelect={onImport} />
            <Button id="exportButton" onClick={onExport} style={inlineStyle.exportButton}><ExportErrorCategoriesIcon /></Button>
          </>
        )}
      />
      <CardContent className={style.wrapper}>
        <div key="header" className={style.headerRow}>
          <div className={style.nameColumn}>Name</div>
          <div className={style.scopeColumn}>Scope</div>
          <div className={style.regexColumn}>Regex</div>
          <div className={style.buttonColumn} />
        </div>
        {list.map(item => (
          <div key={item.externalId} className={style.categoryRow} id={item.name}>
            <div className={style.nameColumn}>
              <Link to={`/${projectCode}/configuration/error-category/${item.externalId}`}>{item.name}</Link>
            </div>
            <div className={style.scopeColumn}>{mapScope(item.scope)}</div>
            <div className={style.regexColumn}>{item.regex}</div>
            <div className={style.buttonColumn}>
              <LabelChips
                labels={item.labels}
                allLabels={labels}
                onRemove={label => onRemoveLabel(item, label)}
                onAdd={label => onAddLabel(item, label)}
              />
              <IconButton size="small" id="deleteButton" onClick={() => onDelete(item)}><DeleteIcon /></IconButton>
            </div>
          </div>
        ))}
      </CardContent>
      <div className={style.wrapper}>
        <Link to={`/${projectCode}/configuration/error-category/new`}>
          <IconButton>
            <AddCircleIcon color="secondary" id="addCategoryButton" />
          </IconButton>
        </Link>
      </div>
    </Card>
  )
}

ErrorCategoryList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onAddLabel: PropTypes.func.isRequired,
  onRemoveLabel: PropTypes.func.isRequired,
}

export default memo(ErrorCategoryList)
