import axios from 'axios'
import * as Common from 'services/common'
import * as Utils from 'components/utils'

function handleNotFound(error) {
  if (error.response && error.response.status === 404) {
    throw new Error('Test suite not found!')
  } else {
    throw error
  }
}

class AttachmentService {
  findAllByReferenceId = (referenceId) => {
    const url = `/api/test-suite-report/${referenceId}/attachments`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(handleNotFound)
      .catch(Common.catchError)
  }

  downloadAttachment = (id, name, mimeType) => {
    const url = `/api/test-suite-report/attachment/${id}`
    const responseType = Utils.isText(mimeType) || Utils.isHtml(mimeType) ? 'text' : 'blob'
    return axios.get(url, { ...Common.requestOptions, responseType, transformResponse: [data => data] })
      .then(response => response.data)
      .catch(handleNotFound)
      .catch(Common.catchError)
  }

  createDownloadAttachmentLink = id => `/api/test-suite-report/attachment/${id}`

  createDownloadPackageLink = reportId => `/api/test-suite-report/${reportId}/attachments/package`

  removeAttachment = (attachmentId) => {
    const url = `/api/test-suite-report/attachment/${attachmentId}`
    return axios.delete(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  undoRemoveAttachment = (attachmentId) => {
    const url = `/api/test-suite-report/attachment/${attachmentId}`
    return axios.patch(url, Common.requestOptions, { removed: false })
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new AttachmentService()
export default instance
