import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import ReactTooltip from 'react-tooltip'
import ActionDelete from '@mui/icons-material/Delete'
import ArchiveIcon from '@mui/icons-material/Archive'
import ActionRowing from '@mui/icons-material/Rowing'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import FileFileDownload from '@mui/icons-material/GetApp'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import AttachmentPreview from 'components/executionDetails/attachmentPreviewDialog'
import PreviewIcon from 'components/executionDetails/previewIcon'
import Spinner from 'components/spinner'
import Table from 'components/table'
import * as Utils from 'components/utils'
import * as Actions from './actions'
import AttachmentButtonStore from './store'
import style from './style.module.scss'

class AttachmentsList extends Component {
  constructor(props) {
    super(props)
    this.state = AttachmentButtonStore.getState()
  }

  componentDidMount() {
    AttachmentButtonStore.on('change', this.updateState)
    const { match } = this.props
    const { params } = match
    const { reportId } = params
    Actions.retrieveAttachments(reportId)
  }

  componentDidUpdate() {
    ReactTooltip.rebuild()
  }

  componentWillUnmount() {
    AttachmentButtonStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(AttachmentButtonStore.getState())
  }

  handlePreview = (id, name, type) => {
    Actions.previewAttachment(id, name, type)
  }

  handleDownload = (id, name, mimeType, e) => {
    e.persist()
    e.preventDefault()
    Actions.downloadAttachment(id)
  }

  handleDownloadPackage = (e) => {
    const { match } = this.props
    const { params } = match
    const { reportId } = params
    e.persist()
    Actions.downloadPackage(reportId)
  }

  handleDownloadServerLog = (e) => {
    e.persist()
    const { match } = this.props
    const { params } = match
    const { reportId } = params
    Actions.downloadServerLog(reportId, e.target)
  }

  handleClosePreview = () => {
    Actions.closePreview()
  }

  render() {
    const {
      loading, error, attachments, removedAttachments, previewName, previewFile, previewType,
    } = this.state
    const attachmentColumns = [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: '',
        accessor: 'preview',
        Cell: item => AttachmentsList.renderPreviewIcon(item.row.original, this.handlePreview),
        width: 80,
        disableResizing: true,
      },
      {
        Header: '',
        accessor: 'name',
        Cell: item => (
          <IconButton id="downloadBtn" onMouseUp={e => this.handleDownload(item.row.original.id, item.value, item.row.original.type, e)} disableTouchRipple>
            <FileFileDownload />
          </IconButton>
        ),
        width: 80,
        disableResizing: true,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: item => (
          <IconButton id="removeBtn" onClick={() => Actions.removeAttachment(item.value, this.props.match.params.reportId)}>
            <ActionDelete />
          </IconButton>
        ),
        width: 80,
        disableResizing: true,
      },
    ]
    const removedAttachmentsColumns = [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: '',
        accessor: 'preview',
        Cell: item => AttachmentsList.renderPreviewIcon(item.row.original, this.handlePreview),
        width: 80,
        disableResizing: true,
      },
      {
        Header: '',
        accessor: 'id',
        Cell: item => (
          <IconButton onClick={() => Actions.undoRemoveAttachment(item.value, this.props.match.params.reportId)}>
            <ActionRowing />
          </IconButton>
        ),
        width: 80,
        disableResizing: true,
      },
    ]

    if (error != null) {
      return AttachmentsList.renderError(error)
    }

    return (
      <Spinner spinning={loading}>
        <div>
          <Card className={style.card}>
            <div className={style.overflowWrapper}>
              <CardHeader
                title="Attachments"
                action={(
                  <>
                    <Tooltip title="Download Zip Package">
                      <IconButton id="downloadZipBtn" onMouseUp={this.handleDownloadPackage}><ArchiveIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Download Server Log">
                      <IconButton id="downloadLogBtn" onMouseUp={this.handleDownloadServerLog}><FileFileDownload /></IconButton>
                    </Tooltip>
                  </>
                )}
              />
              <CardMedia>
                <Table
                  id="attachmentsTable"
                  columns={attachmentColumns}
                  data={attachments}
                />
              </CardMedia>
            </div>
          </Card>

          <Card className={style.card}>
            <div className={style.overflowWrapper}>
              <CardHeader title="Removed Attachments" />
              <CardMedia>
                <Table
                  id="removedAttachments"
                  columns={removedAttachmentsColumns}
                  data={removedAttachments}
                />
              </CardMedia>
            </div>
          </Card>

          <AttachmentPreview name={previewName} type={previewType} file={previewFile} onClose={this.handleClosePreview} />
        </div>
      </Spinner>
    )
  }
}

AttachmentsList.renderPreviewIcon = (item, handlePreview) => {
  if (!Utils.canPreview(item.type)) {
    return null
  }
  return (
    <IconButton id="previewBtn" onClick={() => handlePreview(item.id, item.name, item.type)}>
      <PreviewIcon iconType={item.type} />
    </IconButton>
  )
}

AttachmentsList.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

AttachmentsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
}

export default withRouter(AttachmentsList)
