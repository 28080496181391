import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import FormattedDate from 'components/formattedDate'
import TestStatusIcon from 'components/testStatusIcon'
import WarningIcons from 'components/reports/testRunReport/widgets/defectIcon'
import CustomTable from 'components/table'

const ExecutionList = ({ projectCode, list }) => {
  const columns = useMemo(() => [
    {
      Header: '',
      accessor: 'result',
      Cell: item => <TestStatusIcon status={item.value} />,
      width: 32,
      disableResizing: true,
    },
    {
      Header: 'Name',
      accessor: 'testName',
      Cell: item => (
        <>
          <Link to={`/${projectCode}/detail/${item.row.original.testId}`}>
            {item.value}
          </Link>
          <WarningIcons defects={item.row.original.defects} warnings={item.row.original.warnings} />
        </>
      ),
    },
    {
      Header: 'Error Category',
      accessor: 'errorCategories',
      Cell: item => (
        <ErrorCategoryColumn
          errorCategories={item.value}
          errorMessage={item.row.original.errorMessage}
          labels={item.row.original.labels}
        />
      ),
    },
    {
      Header: 'Tester',
      accessor: 'tester',
    },
    {
      Header: 'Start Time',
      accessor: 'startTime',
      Cell: item => <FormattedDate value={item.value} />,
    },
  ], [])

  return (
    <CustomTable
      id="executions"
      columns={columns}
      data={list}
    />
  )
}

ExecutionList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    testId: PropTypes.string,
    startTime: PropTypes.string,
    result: PropTypes.string,
    tester: PropTypes.string,
    defects: PropTypes.arrayOf(PropTypes.object),
    warnings: PropTypes.arrayOf(PropTypes.string),
  })),
  projectCode: PropTypes.string.isRequired,
}

ExecutionList.defaultProps = { list: [] }

export default memo(ExecutionList)
