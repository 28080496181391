import { Component } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import DataField from 'components/executionDetails/dataField'
import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import TextBoxWithSubmit from 'components/textBoxWithSubmit'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import AggregateDetails from './aggregateDetails'
import SimpleRow from './simpleRow'
import Store from './store'
import * as Actions from './actions'
import style from './excelImportSettings.module.css'

class HpqcExcelImportSettings extends Component {
  constructor(props) {
    super(props)
    this.state = Store.getState()
  }

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - HPQC Excel Import Settings'
    Store.on('change', this.updateState)
    Actions.retrieveInitialData(projectCode)
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  handleSave = () => {
    const { projectCode } = this.context
    const { newRow } = this.state
    Actions.save(newRow, projectCode)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  render() {
    const { projectCode } = this.context
    const {
      error, loading, headerRow, sheet, syncColumn, addConfig, validationError, fields, selectedRow, newPartField, addingRow, showError, columnMapping,
    } = this.state
    if (error != null) {
      return HpqcExcelImportSettings.renderError(error)
    }
    return (
      <div>
        <Typography variant="h6">Excel Import Settings</Typography>
        <Spinner spinning={loading}>
          <DataField name="Header Row">
            <TextBoxWithSubmit
              defaultText={headerRow}
              minRows={1}
              maxRows={1}
              onClick={row => Actions.saveHeader({
                headerRow: row, sheet, syncColumn, projectCode,
              })}
            />
          </DataField>
          <DataField name="Sheet Name">
            <TextBoxWithSubmit
              defaultText={sheet}
              minRows={1}
              maxRows={1}
              onClick={value => Actions.saveHeader({
                headerRow, sheet: value, syncColumn, projectCode,
              })}
            />
          </DataField>
          <DataField name="Sync Column Name">
            <TextBoxWithSubmit
              defaultText={syncColumn}
              minRows={1}
              maxRows={1}
              onClick={column => Actions.saveHeader({
                headerRow, sheet, syncColumn: column, projectCode,
              })}
            />
          </DataField>
          <Box marginTop={3}>
            <div className={style.wrapper}>
              <div key="header" className={style.headerRow}>
                <div className={style.nameColumn}>Field</div>
                <div className={style.fieldColumn}>Excel Column</div>
                <div className={style.aggregateColumn}>Aggregate</div>
                <div className={style.buttonColumn} />
              </div>
              {columnMapping.map((item) => {
                const row = (
                  <SimpleRow
                    key={item.field}
                    row={item}
                    edit={item.field === selectedRow}
                    onAddPart={Actions.handleAddPart}
                    onDelete={() => Actions.handleDelete(projectCode, item.field)}
                    onEdit={Actions.handleEdit}
                    onCancel={Actions.handleCancelRow}
                    onSave={Actions.save(projectCode)}
                  />
                )
                if (item.aggregates == null) {
                  return row
                }
                const details = (
                  <AggregateDetails
                    key={`${row.field}_parts`}
                    aggregates={item.aggregates}
                    addPart={item.field === newPartField}
                    onPartAdd={newPart => Actions.handleSavePart(projectCode, newPart)}
                    onPartCancel={Actions.handleCancelPart}
                    onPartDelete={Actions.handlePartDelete(projectCode, item.field)}
                    onMovePartUp={Actions.handlePartMoveUp(projectCode, item.field)}
                    onMovePartDown={Actions.handlePartMoveDown(projectCode, item.field)}
                  />
                )
                return [row, details]
              })}
              { addingRow && (
                <SimpleRow
                  key="new"
                  edit
                  fields={fields}
                  onCancel={Actions.handleCancelRow}
                  onSave={Actions.save(projectCode)}
                />
              )}
            </div>
            { !addConfig && fields.length > 0 && (
              <div className={style.wrapper}>
                <IconButton onClick={Actions.handleAddRow} src="/images/add.svg" />
              </div>
            )}
            {showError && (
              <div className={style.wrapper}>
                <a className={style.validationError}>{validationError}</a>
              </div>
            )}
          </Box>
        </Spinner>
      </div>
    )
  }
}

HpqcExcelImportSettings.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

HpqcExcelImportSettings.contextType = ProjectContext

export default HpqcExcelImportSettings
