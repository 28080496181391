import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import isEqual from 'lodash/isEqual'
import DatePicker from '@mui/lab/DatePicker'
import TextField from '@mui/material/TextField'
import { VictoryBar, VictoryChart, VictoryLegend, VictoryStack, VictoryTooltip } from 'victory'
import moment from 'moment'

import Spinner from 'components/spinner'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import ErrorCategoryHistoryStore from './store'
import retrieveInitialData from './actions'
import style from './style.module.css'

const inlineStyle = {
  chart: { parent: { maxWidth: '1800px', margin: '0 auto' } },
  domainPadding: { x: 50 },
  chartHeight: 600,
  chartWidth: 1600,
  barStyle: { data: { width: 40 } },
  legend: { parent: { maxWidth: '1800px' } },
  legendWrapper: { padding: '0 50px' },
  legendWidth: 1400,
  legendHeight: 55,
  legendItemsPerRow: 5,
}

class ErrorCategoryHistory extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = ErrorCategoryHistoryStore.getState()
  }

  componentDidMount() {
    const { query } = this.props
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - Error Category History'
    ErrorCategoryHistoryStore.on('change', this.updateState)
    retrieveInitialData(projectCode, query)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode } = this.context
      const { query } = this.props
      retrieveInitialData(projectCode, query)
    }
  }

  componentWillUnmount() {
    ErrorCategoryHistoryStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(ErrorCategoryHistoryStore.getState())
  }

  handleFilter = (date) => {
    const { redirect } = this.props
    redirect({
      date: moment(date).format('YYYY-MM-DD'),
    })
  }

  render() {
    const {
      error, loading, date, legend, days, colors, errorCategoriesHistory,
    } = this.state

    if (error != null) {
      return ErrorCategoryHistory.renderError(error)
    }
    return (
      <Spinner spinning={loading}>
        <div className={style.dateFilter}>
          <DatePicker
            id="dateFilter"
            emptyLabel="Start date"
            value={date ? date.toDate() : null}
            maxDate={moment().subtract(30, 'days')}
            onChange={d => this.handleFilter(d)}
            renderInput={params => <TextField {...params} />}
            inputFormat="DD.MM.YYYY"
            mask="__.__.____"
          />
        </div>
        <div>
          <div style={inlineStyle.legendWrapper}>
            { legend && legend.length > 0 && (
              <VictoryLegend
                orientation="horizontal"
                data={legend}
                style={inlineStyle.legend}
                width={inlineStyle.legendWidth}
                height={inlineStyle.legendHeight}
                itemsPerRow={inlineStyle.legendItemsPerRow}
              />
            )}
          </div>
          <VictoryChart
            domainPadding={inlineStyle.domainPadding}
            height={inlineStyle.chartHeight}
            width={inlineStyle.chartWidth}
            style={inlineStyle.chart}
          >
            <VictoryStack categories={{ x: days }} colorScale={colors}>
              {errorCategoriesHistory.map(it => (
                <VictoryBar style={inlineStyle.barStyle} key={it.errorCategory} data={it.map} labelComponent={<VictoryTooltip />} />))}
            </VictoryStack>
          </VictoryChart>
        </div>
      </Spinner>
    )
  }
}

ErrorCategoryHistory.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

ErrorCategoryHistory.propTypes = {
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

ErrorCategoryHistory.contextType = ProjectContext

export default withQuery(withRouter(ErrorCategoryHistory))
