import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import ActionSettings from '@mui/icons-material/Settings'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import ColumnNames from './columnNames'
import SettingsView from './settingsView'


const SettingsButton = ({
  className, columns, onRemoveColumn, onAddColumn, onCancel,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [changed, setChanged] = useState(false)
  const handleCancel = () => {
    setShowDialog(false)
    onCancel(changed)
  }
  const handleAdd = (name) => {
    onAddColumn(name)
    if (!Object.keys(ColumnNames).includes(name)) {
      setChanged(true)
    }
  }
  return (
    <div className={className}>
      <Tooltip title="Report Settings">
        <IconButton id="settingsBtn" onClick={() => setShowDialog(true)}>
          <ActionSettings />
        </IconButton>
      </Tooltip>
      { showDialog && (
        <SettingsView onCancel={handleCancel} columns={columns} onRemoveColumn={onRemoveColumn} onAddColumn={handleAdd} />
      )}
    </div>
  )
}

SettingsButton.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onAddColumn: PropTypes.func.isRequired,
  onRemoveColumn: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

SettingsButton.defaultProps = {
  className: null,
}

export default memo(SettingsButton)
