import ExcelExportService from 'services/excelExportService'
import { dispatcher, events } from 'core/dispatcher'
import downloadFile from 'utils/downloadFile'

export function getDefaults(project) {
  ExcelExportService.getDefaults(project)
    .then(defaults => dispatcher.dispatch({ type: events.excelExport.dataReceived, defaults }))
    .catch((message) => { dispatcher.dispatch({ type: events.excelExport.failed, message }) })
}

export function handleValueChange(settings) {
  dispatcher.dispatch({ type: events.excelExport.fieldChecked, settings })
}

export function toggleFieldSelection(show) {
  dispatcher.dispatch({ type: events.excelExport.toggleFieldSelection, show })
}

export function selectTemplate(event, id) {
  event.preventDefault()
  dispatcher.dispatch({ type: events.excelExport.templateSelected, id })
}

export function removeTemplate(id) {
  dispatcher.dispatch({ type: events.excelExport.templateRemoved, id })
}

export function addTemplate() {
  dispatcher.dispatch({ type: events.excelExport.templateAdded })
}

export function renameTemplate(id) {
  dispatcher.dispatch({ type: events.excelExport.templateRenameStarted, id })
}

export function cancelRenameTemplate() {
  dispatcher.dispatch({ type: events.excelExport.templateRenameCanceled })
}

export function saveTemplateName() {
  dispatcher.dispatch({ type: events.excelExport.templateRenamed })
}

export function updateTemplateName(name) {
  dispatcher.dispatch({ type: events.excelExport.templateNameChanged, name })
}

export function handleDownload(project, query, settings) {
  const url = ExcelExportService.createExcelUrl(project, query, settings)
  downloadFile(url)
  dispatcher.dispatch({ type: events.excelExport.toggleFieldSelection, show: false })
}
