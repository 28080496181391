import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'

import ApiSecretForm from 'components/user/settings/apiSecretForm'
import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import { withRouter } from 'react-router'
import useProject from 'hooks/useProject'

import * as Actions from './actions'
import EditRow from './editRow'
import style from './style.module.css'

export const roleList = [
  { code: 'ROLE_USER', name: 'User' },
  { code: 'ROLE_TEST_MANAGER', name: 'Test Manager' },
]

const UserSettings = ({ match }) => {
  const email = decodeURIComponent(match.params.email)
  const [state, setState] = useState({ loading: true, list: [], roles: [] })
  const errorHandler = useErrorHandler()
  const { projectList } = useProject()

  const setPermissions = (user) => {
    setState({
      loading: false,
      addRole: false,
      roles: user.roles,
      email: user.email,
    })
  }

  useEffect(() => {
    document.title = 'QiTASC ConQlude - User Permissions'
    Actions.retrieveData(email).then(setPermissions).catch(errorHandler)
  }, [email, errorHandler])

  const projectName = (code) => {
    const project = projectList.find(it => it.code === code)
    return project ? project.name : code
  }

  const findRoleName = (code) => {
    const role = roleList.find(it => it.code === code)
    return role ? role.name : code
  }

  const handleNewPermisison = (permission) => {
    setState({ ...state, loading: true })
    Actions.add(email, permission).then(setPermissions).catch(errorHandler)
  }

  const handleRevokePermission = (permission) => {
    setState({ ...state, loading: true })
    Actions.revoke(email, permission).then(setPermissions).catch(errorHandler)
  }

  return (
    <Spinner spinning={state.loading}>
      <Card>
        <CardHeader title="Users Permissions" />
        <div className={style.wrapper}>
          <div key="header" className={style.headerRow}>
            <div className={style.projectColumn}>Project</div>
            <div className={style.roleColumn}>Role</div>
            <div className={style.buttonColumn} />
          </div>
          {state.roles.map(item => (
            <div key={item.project + item.role} className={style.roleRow} id={item.project + item.role}>
              <div className={style.projectColumn}>{projectName(item.project)}</div>
              <div className={style.roleColumn}>{findRoleName(item.role)}</div>
              <div className={style.buttonColumn}>
                <IconButton onClick={() => handleRevokePermission(item)} src="/images/delete.svg" id="deleteButton" />
              </div>
            </div>
          ))}
          { state.addRole && (
            <EditRow
              projectList={projectList}
              roleList={roleList}
              onCancel={() => setState({ ...state, addRole: false })}
              onSubmit={handleNewPermisison}
            />
          ) }
        </div>
        { !state.addRole && (
          <div className={style.wrapper}>
            <IconButton onClick={() => setState({ ...state, addRole: true })} src="/images/add.svg" id="addButton" />
          </div>
        )}
      </Card>
      <Card className={style.card}>
        <CardHeader title="Report API Secret" />
        <div className={style.apiSecretForm}>
          <ApiSecretForm onSubmit={apiSecret => Actions.saveApiSecret(email, apiSecret).catch(errorHandler)} />
        </div>
      </Card>
    </Spinner>
  )
}

UserSettings.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default withRouter(UserSettings)
