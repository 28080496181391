import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'

import AdministrationPage from 'pages/administrationPage'
import Approval from 'components/approval/approval'
import ConfigurationPage from 'pages/configurationPage'
import ErrorPage from 'pages/error/index'
import ExecutionDetailsPage from 'pages/executionDetailsPage'
import Header from 'components/header/header'
import HomePage from 'pages/home/index'
import PmsExportDetailsPage from 'pages/pmsExportDetails/pmsExportDetailsPage'
import ReportPage from 'pages/reportPage'
import TestSuitePage from 'pages/testSuitePage'
import UserSettings from 'components/user/settings'
import Verification from 'components/verification/verification'
import WebSocketProvider from 'core/webSocket'

import ProjectProvider from './projectProvider/projectProvider'

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    position: 'relative',
    flex: '1 1 auto',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    alignContent: 'stretch',
    overflow: 'auto',
    padding: ' 0 12px 12px 12px',
  },
}


const PageOutline = ({ match }) => (
  <ProjectProvider>
    <WebSocketProvider url="/api/socket/gs-guide-websocket">
      <div style={style.container}>
        <Header />
        <div style={style.content} id="content">
          <Switch>
            <Route path="/administration" component={AdministrationPage} />
            <Route path="/user/settings" component={UserSettings} />
            <Route path={`${match.path}`} exact component={HomePage} />
            <Route path={`${match.path}/reports`} component={ReportPage} />
            <Route path={`${match.path}/detail`} component={ExecutionDetailsPage} />
            <Route path={`${match.path}/test-suite`} component={TestSuitePage} />
            <Route path={`${match.path}/pmsExportDetail`} component={PmsExportDetailsPage} />
            <Route path={`${match.path}/verification`} component={Verification} />
            <Route path={`${match.path}/approval`} component={Approval} />
            <Route path={`${match.path}/configuration`} component={ConfigurationPage} />
            <Route component={ErrorPage} />
          </Switch>
        </div>
        <ReactTooltip effect="solid" />
      </div>
    </WebSocketProvider>
  </ProjectProvider>
)

PageOutline.propTypes = {
  match: PropTypes.shape({ path: PropTypes.string }).isRequired,
}

export default withRouter(PageOutline)
