import axios from 'axios'
import * as Common from 'services/common'

class JiraService {
  retrieveJiraSettings = (project) => {
    const url = `/api/module/jira-zephyr/${project}/settings`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateJiraSettings = (project, settings) => {
    const url = `/api/module/jira-zephyr/${project}/settings`
    return axios.post(url, settings, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  testJiraSettings = (project) => {
    const url = `/api/module/jira-zephyr/${project}/settings/test`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new JiraService()
export default instance
