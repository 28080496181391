import { Component } from 'react'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Spinner from 'components/spinner'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'
import Table from 'components/table'

import YoutrackSettingsStore from './store'
import * as Actions from './actions'
import style from './style.module.css'


class YoutrackSettings extends Component {
  state = YoutrackSettingsStore.getState()

  componentDidMount() {
    document.title = 'QiTASC ConQlude - YouTrack Connection Settings'
    YoutrackSettingsStore.on('change', this.updateState)
    Actions.retrieveInitialData()
  }

  componentWillUnmount() {
    YoutrackSettingsStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(YoutrackSettingsStore.getState())
  }

  handleChange = (field, value) => {
    this.setState(prevState => ({ ...prevState, selectedYoutrack: { ...prevState.selectedYoutrack, [field]: value } }))
  }

  render() {
    const {
      updated, error, loading, settings, addMode, editMode, selectedYoutrack, invalidInput,
    } = this.state
    const columns = [
      {
        Header: 'YouTrack Instance Name',
        accessor: 'instanceName',
      },
      {
        Header: 'Base URL',
        accessor: 'baseUrl',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: '',
        accessor: 'externalId',
        disableResizing: true,
        Cell: item => (
          <>
            <IconButton id="editBtn" onClick={() => Actions.editYouTrack(item.row.original)} src="/images/edit.svg" />
            <IconButton id="deleteBtn" onClick={() => Actions.deleteYouTrack(item.row.original)} src="/images/delete.svg" />
          </>
        ),
        width: 70,
      },
    ]

    if (updated) {
      Actions.retrieveInitialData()
    }
    if (error != null) {
      return YoutrackSettings.renderError(error.message)
    }

    return (
      <Spinner spinning={loading}>
        <Card>
          <div className={style.trackContainer}>
            <CardHeader
              title="YouTrack Connection Settings"
              action={<AddCircleOutlineOutlinedIcon onClick={Actions.addYouTrack} />}
            />
            <Table
              id="trackList"
              columns={columns}
              data={settings}
            />
          </div>
        </Card>
        <Dialog
          open={addMode || editMode}
          maxWidth="lg"
          onClose={Actions.closePanel}
        >
          <DialogTitle>YouTrack Connection Settings</DialogTitle>
          <DialogContent className={style.dialogContent}>
            <TextField
              id="nameField"
              fullWidth
              label="YouTrack Instance Name"
              value={selectedYoutrack.instanceName}
              onChange={e => this.handleChange('instanceName', e.target.value)}
            />
            <TextField
              id="baseUrlField"
              fullWidth
              label="Base URL"
              value={selectedYoutrack.baseUrl}
              onChange={e => this.handleChange('baseUrl', e.target.value)}
            />
            <TextField
              id="usernameField"
              fullWidth
              label="Username"
              value={selectedYoutrack.username}
              onChange={e => this.handleChange('username', e.target.value)}
            />
            <TextField
              id="passwordField"
              fullWidth
              label="Password"
              value={selectedYoutrack.password}
              onChange={e => this.handleChange('password', e.target.value)}
            />
            <TextField
              id="stateField"
              fullWidth
              label="State Field"
              value={selectedYoutrack.state}
              onChange={e => this.handleChange('state', e.target.value)}
            />
            <TextField
              id="customerStateField"
              fullWidth
              label="Customer State Field"
              value={selectedYoutrack.customerState}
              onChange={e => this.handleChange('customerState', e.target.value)}
            />
            <TextField
              id="testCaseExecutionField"
              fullWidth
              label="Test Case Execution Field"
              value={selectedYoutrack.testCaseExecution}
              onChange={e => this.handleChange('testCaseExecution', e.target.value)}
            />
            <TextField
              id="testCaseIdField"
              fullWidth
              label="Test Case Id Field"
              value={selectedYoutrack.testCaseId}
              onChange={e => this.handleChange('testCaseId', e.target.value)}
            />
            {invalidInput && (
              <p className={style.validationError}>
                All fields are required! Please set all inputs and save again.
              </p>
            )}
          </DialogContent>
          <DialogActions>
            <Button id="saveBtn" color="primary" onClick={() => Actions.handleAddOrEdit(this.state)}>Save</Button>
            <Button id="cancelBtn" onClick={Actions.closePanel}>Cancel</Button>
          </DialogActions>
        </Dialog>
      </Spinner>
    )
  }
}

YoutrackSettings.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

export default YoutrackSettings
