import { Fragment, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import startsWith from 'lodash/startsWith'
import size from 'lodash/size'

import AttachmentPreview from 'components/executionDetails/attachmentPreview'
import ExpansionPanel from 'components/executionDetails/expansionPanel'
import Spinner from 'components/spinner'

import * as Actions from '../attachmentButton/actions'
import style from '../style.module.css'

const reducer = (state, action) => {
  switch (action.type) {
  case 'received':
    return { ...state, files: { ...state.files, [action.index]: action.file } }
  default:
    throw new Error()
  }
}

const Result = ({ attachments }) => {
  const [state, dispatch] = useReducer(reducer, { files: {} })

  const downloadFile = async (type, id, name) => (startsWith(type, 'audio/') || startsWith(type, 'image/')
    ? Actions.getAttachmentUrl(id)
    : Actions.previewAttachment(id, name, type))

  useEffect(() => {
    attachments.forEach(async (attachment, index) => {
      const file = await downloadFile(attachment.type, attachment.id, attachment.name)
      dispatch({ type: 'received', index, file })
    })
  }, [attachments])

  if (size(attachments) === 0) {
    return null
  }
  return (
    <ExpansionPanel className={style.logPanel} title="Result">
      { attachments.map((attachment, index) => (
        <Fragment key={attachment.id}>
          <ExpansionPanel className={style.logPanel} title={attachment.name}>
            <Spinner spinning={state.files[index] == null}>
              <AttachmentPreview name={attachment.name} type={attachment.type} file={state.files[index]} />
            </Spinner>
          </ExpansionPanel>
        </Fragment>
      )) }
    </ExpansionPanel>
  )
}

Result.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape),
}

Result.defaultProps = {
  attachments: [],
}

export default Result
