import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'

import ColumnNames from './columnNames'
import style from './style.module.scss'


const EnabledChip = ({ id, name, onDelete }) => {
  const handleRequestDelete = useCallback(() => { onDelete(id) }, [id])
  return (
    <Chip
      className={style.columnChip}
      onDelete={handleRequestDelete}
      clickable={false}
      label={name}
    />
  )
}

const DisabledChip = ({ id, name, onClick }) => {
  const handleClick = useCallback(() => { onClick(id) }, [id])
  return (
    <Chip
      className={style.columnChip}
      onClick={handleClick}
      clickable
      label={name}
    />
  )
}

const GetColumnKey = (name) => {
  const optionalColumn = Object.entries(ColumnNames)
    .find(entry => entry[1].toUpperCase() === name.toUpperCase())
  return optionalColumn ? optionalColumn[0] : name
}

const SettingsView = ({ onCancel, columns, onRemoveColumn, onAddColumn }) => {
  const [metadata, setMetadata] = useState('')
  const handleRequestAdd = useCallback((id) => {
    onAddColumn(GetColumnKey(id))
    setMetadata('')
  }, [])
  const handleAddClick = () => {
    onAddColumn(metadata)
    setMetadata('')
  }
  const metadataColumns = columns.filter(it => !Object.keys(ColumnNames).includes(it))
  const isEnabledColumn = key => key && columns.includes(key)
  const buttonDisabled = metadata.length === 0 || isEnabledColumn(GetColumnKey(metadata))
  return (
    <Dialog
      open
      maxWidth="sm"
      fullWidth
      onClose={onCancel}
    >
      <DialogTitle>Report Settings</DialogTitle>
      <DialogContent className={style.attachmentDialogContent}>
        <div className="row">
          <div className="col-md-8">
            <DataField name="Add column">
              <TextField
                id="metadataNameField"
                placeholder="Metadata Name"
                value={metadata}
                onChange={e => setMetadata(e.target.value)}
              />
            </DataField>
            <div className={style.buttons}>
              <Button variant="contained" id="addBtn" color="primary" disabled={buttonDisabled} onClick={handleAddClick}>Add</Button>
            </div>
          </div>
          <div className={`col-md-4 ${style.columnsSection}`}>
            { Object.keys(ColumnNames)
              .concat(metadataColumns)
              .map(name => (columns.includes(name)
                ? <EnabledChip onDelete={onRemoveColumn} name={ColumnNames[name] || name} id={name} key={name} />
                : <DisabledChip onClick={handleRequestAdd} name={ColumnNames[name]} id={name} key={name} />))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button id="closeBtn" color="primary" onClick={onCancel}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

SettingsView.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onRemoveColumn: PropTypes.func.isRequired,
  onAddColumn: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
}
SettingsView.defaultProps = {
}
EnabledChip.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
}
DisabledChip.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}


export default SettingsView
