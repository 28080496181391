import {
  createContext,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import get from 'lodash/get'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useParams, useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'

import Spinner from 'components/spinner'
import * as Actions from './actions'
import ProjectProviderStore from './store'

export const ProjectContext = createContext({})

const ProjectProvider = ({ children }) => {
  const [state, setState] = useState(ProjectProviderStore.getState())
  const params = useParams()
  const history = useHistory()
  const {
    error,
    projectsLoaded,
    projectCode,
    projectList,
  } = state

  useEffect(() => {
    ProjectProviderStore.on('change', updateState)
    Actions.loadProjectList(params.projectCode)

    return () => ProjectProviderStore.removeListener('change', updateState)
  }, [])

  useEffect(() => {
    if (params.projectCode) {
      Actions.selectProject(params.projectCode)
    }
  }, [params.projectCode])

  const isModuleEnabled = (code, module, projects) => {
    const project = find(projects, { code })

    return project ? project.enabledModules.includes(module) : false
  }

  const canMoveTests = (code, projects) => {
    const project = find(projects, { code })

    return project ? project.moveTests : false
  }

  const updateState = debounce(() => {
    const projectState = ProjectProviderStore.getState()

    if (!projectState.projectsLoaded) {
      Actions.loadProjectList()
    } else {
      setState(prev => ({
        ...prev,
        ...projectState,
        projectList: projectState.projects,
        ...getProjectSettings(projectState.projects),
        roles: get(projectState.user, 'roles', []),
        updateProjectContext: project => Actions.updateProject(project),
      }))
    }
  }, 300)

  const getProjectSettings = projects => ({
    cdrEnabled: isModuleEnabled(projectCode, 'CDR', projects),
    cheqEnabled: isModuleEnabled(projectCode, 'CHEQ', projects),
    hpqcEnabled: isModuleEnabled(projectCode, 'HPQC', projects),
    jiraZephyrEnabled: isModuleEnabled(projectCode, 'JIRA_ZEPHYR', projects),
    jiraBasicEnabled: isModuleEnabled(projectCode, 'JIRA_BASIC', projects),
    jiraOsfEnabled: isModuleEnabled(projectCode, 'JIRA_OSF', projects),
    pmsEnabled: isModuleEnabled(projectCode, 'PMS', projects),
    canMoveTests: canMoveTests(projectCode, projects),
    postProcessEnabled: get(find(projects, { code: projectCode }), 'postProcessEnabled', false),
    qonformEnabled: isModuleEnabled(projectCode, 'QONFORM', projects),
  })

  const handleProjectSelection = (e) => {
    history.push(`/${e.target.value}`)
  }

  if (error) { throw error }

  return (
    !projectCode || !projectsLoaded ? (
      <Spinner spinning={!projectsLoaded}>
        <Dialog open maxWidth="sm" fullWidth>
          <DialogTitle>Select a project to continue</DialogTitle>
          <DialogContent sx={{ marginBottom: '1em' }}>
            <FormControl fullWidth>
              <InputLabel>Project</InputLabel>
              <Select fullWidth onChange={handleProjectSelection}>
                {projectList
                  ?.filter(project => project.enabled)
                  .map(project => (
                    <MenuItem key={project.code} value={project.code}>
                      {project.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
        </Dialog>
      </Spinner>
    ) : (
      <ProjectContext.Provider value={state}>
        {children}
      </ProjectContext.Provider>
    )
  )
}

ProjectProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ProjectProvider
