import PropTypes from 'prop-types'
import Spinner from 'components/spinner'

import ExpansionPanel from './expansionPanel'
import style from './style.module.css'

const LogLines = ({ logs, status, title, error }) => {
  if (status === 'deleted') {
    return (
      <ExpansionPanel className={style.logPanel} title={title}>
        <p className={style.deletedMessage}>
          <img src="/images/deleted.svg" alt="Logs are deleted" className={style.deletedIcon} />
          <span>Logs have been deleted.</span>
        </p>
      </ExpansionPanel>
    )
  }
  if (status === 'failed') {
    return (
      <ExpansionPanel className={style.logPanel} title={title}>
        <p className={style.deletedMessage}>
          <img src="/images/cancel.svg" alt="Failed loading logs" />
          <span>
            There was an error loading logs:
            {error}
          </span>
        </p>
      </ExpansionPanel>
    )
  }
  return (
    <ExpansionPanel className={style.logPanel} title={title}>
      <Spinner spinning={status === 'loading'}>
        <div className={style.text}>
          { logs.reduce((acc, cur) => `${acc}${cur}\n`, '') }
        </div>
      </Spinner>
    </ExpansionPanel>
  )
}

LogLines.propTypes = {
  logs: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['deleted', 'loading', 'ready', 'failed']).isRequired,
  error: PropTypes.string,
}

LogLines.defaultProps = {
  logs: [],
  error: null,
}

export default LogLines
