import { memo } from 'react'
import PropTypes from 'prop-types'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

import IconButton from 'components/iconButton'
import * as Utils from 'components/utils'

import NewPatternRow from './newPatternRow'
import style from './project.module.css'

const BlacklistSettings = memo(({ blacklist, onChange }) => {
  const onAdd = pattern => onChange(blacklist.concat([pattern]))
  const onDelete = pattern => () => { onChange(blacklist.filter(it => it !== pattern)) }
  const validate = (pattern) => {
    if (pattern.trim().length === 0) {
      return 'Value cannot be empty'
    }
    if (blacklist.includes(pattern)) {
      return 'Value already exists'
    }
    if (!Utils.isRegexValid(pattern)) {
      return 'Value must be a valid regex'
    }
    return null
  }
  return (
    <div className="col-md-6">
      <CardHeader title="Attachment Blacklist" />
      <CardContent>
        <div className={style.patternList}>
          { blacklist.map(pattern => (
            <div key={pattern}>
              <span>{pattern}</span>
              <IconButton src="/images/delete.svg" onClick={onDelete(pattern)} />
            </div>
          ))}
          <NewPatternRow onSubmit={onAdd} validate={validate} />
        </div>
      </CardContent>
    </div>
  )
})

BlacklistSettings.propTypes = {
  blacklist: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default BlacklistSettings
