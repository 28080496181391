import { dispatcher, events } from 'core/dispatcher'
import TestPlanService from 'services/hpqc/testPlanService'
import ImportService from 'services/postprocess/importService'

export function retrieveList(projectCode, filters) {
  TestPlanService.findAll(projectCode, filters)
    .then((list) => { dispatcher.dispatch({ type: events.testPlanList.listReceived, list }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
}

export function updateList(projectCode, filters) {
  setTimeout(() => dispatcher.dispatch({ type: events.testPlanList.listExpired }))
  retrieveList(projectCode, filters)
}

export function importExcel({ project, file }) {
  ImportService.import({ project, file })
    .then(() => { dispatcher.dispatch({ type: events.testPlanList.imported }) })
    .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.importFailed, error }) })
}

export function confirmMessage() {
  dispatcher.dispatch({ type: events.testPlanList.confirmed })
}

export function updateRowSelection(ids) {
  dispatcher.dispatch({ type: events.testPlanList.selected, ids })
}

export function selectAll({ includeHidden = false }) {
  dispatcher.dispatch({ type: events.testPlanList.selectedAll, includeHidden })
}

export function createTestPlans({ project, filter, selectedHidden, selectedItems }) {
  if (selectedHidden) {
    TestPlanService.createTestPlansByFilter({ project, filter })
      .then((list) => { dispatcher.dispatch({ type: events.testPlanList.testPlansCreated, list }) })
      .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
  } else if (selectedItems.length > 0) {
    TestPlanService.createTestPlansById({ project, ids: selectedItems })
      .then((list) => { dispatcher.dispatch({ type: events.testPlanList.testPlansCreated, list }) })
      .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
  }
}

export function createTestInstances({
  project, filter, selectedHidden, selectedItems, testSetId,
}) {
  if (selectedHidden) {
    TestPlanService.createTestInstancesByFilter({ project, filter, testSetId })
      .then((list) => { dispatcher.dispatch({ type: events.testPlanList.testInstancesCreated, list }) })
      .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
  } else if (selectedItems.length > 0) {
    TestPlanService.createTestInstancesById({ project, ids: selectedItems, testSetId })
      .then((list) => { dispatcher.dispatch({ type: events.testPlanList.testInstancesCreated, list }) })
      .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
  }
}

export function confirmSuccessMessage() {
  dispatcher.dispatch({ type: events.testPlanList.notificationConfirmed })
}

export function exportTestPlans({ project, filter, selectedHidden, selectedItems }) {
  TestPlanService.exportTestPlans({ project,
    filter: selectedHidden ? filter : null,
    ids: selectedHidden ? null : selectedItems })
    .then(() => dispatcher.dispatch({ type: events.testPlanList.testPlansExported }))
    .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
}

export function exportTestInstances({
  project, filter, selectedHidden, selectedItems, testSetId,
}) {
  TestPlanService.exportTestInstances({ project,
    testSetId,
    filter: selectedHidden ? filter : null,
    ids: selectedHidden ? null : selectedItems })
    .then(() => dispatcher.dispatch({ type: events.testPlanList.testInstancesExported }))
    .catch((error) => { dispatcher.dispatch({ type: events.testPlanList.failed, error }) })
}
