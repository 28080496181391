import ErrorCategoryService from 'services/errorCategoryService'
import ExecutionListService from 'services/executionListService'
import TesterService from 'services/testerService'
import { dispatcher, events } from 'core/dispatcher'

import ColumnNames from './columnNames'


export function retrieveInitialData(projectCode, filters, fields) {
  const metadataFields = fields.filter(it => !Object.keys(ColumnNames).includes(it))
  Promise.all([
    ExecutionListService.findAll(projectCode, filters, metadataFields),
    TesterService.findAll(projectCode),
    ErrorCategoryService.getList(projectCode),
    ErrorCategoryService.getLabels(projectCode),
  ])
    .then((result) => {
      dispatcher.dispatch({
        type: events.testRunReport.listInitialized,
        list: result[0],
        testers: result[1],
        errorCategories: result[2],
        labels: result[3],
        projectCode,
      })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testRunReport.failed, error }) })
}

export function retrieveTestRunList(projectCode, filters, fields) {
  const metadataFields = fields.filter(it => !Object.keys(ColumnNames).includes(it))
  setTimeout(() => dispatcher.dispatch({ type: events.testRunReport.listExpired }))
  ExecutionListService.findAll(projectCode, filters, metadataFields)
    .then((list) => {
      dispatcher.dispatch({ type: events.testRunReport.listReceived, list })
    })
    .catch((error) => { dispatcher.dispatch({ type: events.testRunReport.failed, error }) })
}

export function removeColumn(name, projectCode) {
  dispatcher.dispatch({ type: events.testRunReport.columnRemoved, name, projectCode })
}

export function addColumn(name, projectCode) {
  dispatcher.dispatch({ type: events.testRunReport.columnAdded, name, projectCode })
}

export function setDrawerOpen(open) {
  dispatcher.dispatch({ type: events.testRunReport.openDrawer, open })
}
