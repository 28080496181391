import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'

const style = {
  wrapper: { position: 'relative', height: '24px', marginLeft: '4px' },
  icon: { width: '24px', height: '24px', marginBottom: '-6px' },
}

const DefectIcon = (props) => {
  const { defects, warnings, ...otherProps } = props
  const hasDefect = defects && defects.length > 0
  const verified = warnings && warnings.includes('VERIFIED')
  const approved = warnings && warnings.includes('APPROVED')
  const denied = warnings && warnings.includes('DENIED')
  const uploaded = warnings && warnings.includes('UPLOADED')
  const scheduled = warnings && warnings.includes('DELETION_SCHEDULED')
  const deleted = warnings && warnings.includes('DELETED')
  const missingTestCategory = warnings && warnings.includes('MISSING_CATEGORY')
  const hpqcStatus = warnings && warnings.includes('HPQC_STATUS')

  if (!hasDefect && !scheduled && !deleted && !verified && !approved && !denied && !uploaded && !missingTestCategory && !hpqcStatus) {
    return null
  }
  return (
    <span>
      { hasDefect
      && (
        <Tooltip title={`Defect: ${defects.map(it => it.id).join()}`}>
          <span {...otherProps} style={style.wrapper}>
            <img src="/images/bug.svg" alt="defect" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { verified
      && (
        <Tooltip title="Verified" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/verified.svg" alt="Verified" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { approved
      && (
        <Tooltip title="Approved" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/approved.svg" alt="Approved" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { denied
      && (
        <Tooltip title="Denied" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/denied.svg" alt="Denied" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { uploaded
      && (
        <Tooltip title="Uploaded" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/uploaded.svg" alt="Uploaded" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { deleted
      && (
        <Tooltip title="Deleted" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/deleted.svg" alt="deleted" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { scheduled
      && (
        <Tooltip title="Scheduled for deletion" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/scheduled.svg" alt="scheduled for deletion" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { missingTestCategory
      && (
        <Tooltip title="Test category is not set" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/missingtag.svg" alt="Test category is not set" style={style.icon} />
          </span>
        </Tooltip>
      )}
      { hpqcStatus
      && (
        <Tooltip title="ALM upload status changed after upload" {...otherProps} style={style.wrapper}>
          <span>
            <img src="/images/hpwarning.svg" alt="ALM upload status changed after upload" style={style.icon} />
          </span>
        </Tooltip>
      )}
    </span>
  )
}

DefectIcon.propTypes = {
  defects: PropTypes.arrayOf(PropTypes.object),
  warnings: PropTypes.arrayOf(PropTypes.string),
}

DefectIcon.defaultProps = {
  defects: null,
  warnings: null,
}

export default DefectIcon
