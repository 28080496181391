import { useEffect, useReducer } from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Grid from '@mui/material/Grid'
import Snackbar from '@mui/material/Snackbar'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import LinqSettings from './linqSettings'
import VerificationServiceSettings from './verificationServiceSettings'
import { loadSettings, saveSettings } from './actions'
import reducer from './reducer'

const defaultVerificationServiceSettings = {
  baseUrl: '',
  socketTimeoutMillis: 0,
  allowSelfSignedCertificates: false,
  authToken: '',
  enabled: false,
  errors: {},
}

const defaultLinqSettings = {
  baseUrl: '',
  socketTimeoutMillis: 0,
  authToken: '',
  enabled: false,
  pollCdrsAfterMinutes: 65,
  allowSelfSignedCertificates: false,
  errors: {},
}

const initialState = {
  loading: true,
  showSuccessMessage: false,
  verificationServiceSettings: defaultVerificationServiceSettings,
  linqSettings: defaultLinqSettings,
}

const CdrSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    loadSettings(projectCode)
      .then(settings => dispatch({ type: 'loaded', settings }))
      .catch(handleError)
  }, [handleError, projectCode])

  function createSettings(verificationServiceSettings, linqSettings) {
    return {
      project: projectCode,
      verificationServiceEnabled: verificationServiceSettings.enabled,
      verificationServiceBaseUrl: verificationServiceSettings.baseUrl,
      verificationServiceAuthToken: verificationServiceSettings.authToken,
      verificationServiceSocketTimeoutMillis: verificationServiceSettings.socketTimeoutMillis,
      verificationServiceAllowSelfSignedCertificates: verificationServiceSettings.allowSelfSignedCertificates,
      linqEnabled: linqSettings.enabled,
      linqBaseUrl: linqSettings.baseUrl,
      linqAuthToken: linqSettings.authToken,
      linqSocketTimeoutMillis: linqSettings.socketTimeoutMillis,
      linqAllowSelfSignedCertificates: linqSettings.allowSelfSignedCertificates,
      linqPollCdrsAfterMinutes: linqSettings.pollCdrsAfterMinutes,
    }
  }

  const handleSave = () => saveSettings(projectCode, createSettings(state.verificationServiceSettings, state.linqSettings))
    .then(() => dispatch({ type: 'saved' }))
    .catch(handleError)
  const handleVerificationServiceSettingsChange = settings => dispatch({ type: 'verification_service_settings_changed', settings })
  const handleLinqSettingsChange = settings => dispatch({ type: 'linq_settings_changed', settings })
  const handleCloseSnackbar = () => dispatch({ type: 'close_snackbar' })

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card>
          <CardHeader title="CDR Verification Service Settings" />
          <CardContent>
            <Spinner spinning={state.loading}>
              <VerificationServiceSettings
                settings={state.verificationServiceSettings}
                onSave={handleSave}
                onChange={handleVerificationServiceSettingsChange}
              />
            </Spinner>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardHeader title="CDR LinQ Settings" />
          <CardContent>
            <Spinner spinning={state.loading}>
              <LinqSettings settings={state.linqSettings} onSave={handleSave} onChange={handleLinqSettingsChange} />
            </Spinner>
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={Object.keys(state.verificationServiceSettings.errors).length > 0 || Object.keys(state.linqSettings.errors).length > 0}
          onClick={() => handleSave()}
        >
          Save
        </Button>
      </Grid>
      <Snackbar open={state.showSuccessMessage} message="Settings were saved successfully." autoHideDuration={5000} onClose={handleCloseSnackbar} />
    </Grid>

  )
}

export default CdrSettings
