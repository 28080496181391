import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import { withRouter } from 'react-router'

import ConnectionSettings from 'components/configuration/pms/settings'
import FieldSettings from 'components/configuration/pms/sshKey'
import HeaderButton from 'pages/headerButton'


const PmsConfiguration = ({ match }) => {
  useEffect(() => {
    document.title = 'QiTASC ConQlude - PMS Settings'
  }, [])
  return (
    <Card>
      <CardHeader title="PMS Configuration" />
      <CardContent>
        <Box marginBottom={2}>
          <HeaderButton id="connectionSettings" label="Settings" url={`${match.url}/settings`} />
          <HeaderButton id="sshKey" label="SSH Key" url={`${match.url}/ssh-key`} />
        </Box>
        <Switch>
          <Route path={`${match.url}/settings`} component={ConnectionSettings} />
          <Route path={`${match.url}/ssh-key`} component={FieldSettings} />
        </Switch>
      </CardContent>
    </Card>
  )
}

PmsConfiguration.propTypes = {
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}


export default withRouter(PmsConfiguration)
