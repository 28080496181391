import { cloneElement, Component } from 'react'

import DescriptionLine from './descriptionLine'
import EmptyLine from './emptyLine'
import ExampleLine from './exampleLine'
import { ExampleTitle, BackgroundTitle, SuiteLine } from './statement'
import CommentLine from './commentLine'
import MetadataLine from './metadataLine'
import SeparatorLine from './separatorLine'
import StepLine from './stepLine'
import TagLine from './tagLine'
import TestLine from './testLine'
import StepDetailLine from './stepDetailLine'
import style from './style.module.scss'

const NUMBER_OF_LINES_TO_COLLAPSE = 5
const setLineNumber = (line, lineNumber) => ({ ...line, lineNumber })

const buildComments = (level, comments = [], metadata = []) => {
  const metadataLineNumbers = metadata.map(it => it.lineNumber)
  return comments.map(comment => (metadataLineNumbers.includes(comment.lineNumber)
    ? ({
      lineNumber: comment.lineNumber,
      view: <MetadataLine comment={comment.value} {...metadata.find(it => it.lineNumber === comment.lineNumber)} level={level} />,
    })
    : ({
      lineNumber: comment.lineNumber,
      view: <CommentLine {...comment} level={level} />,
    })))
}

const buildBackgroundStep = (step) => {
  const lines = []
  lines.push(...buildComments('step', step.comments, (step.metadata || []).map(it => ({ lineNumber: it.lineNumber }))))
  const { startTime, result, ...stepDetails } = step
  lines.push({
    lineNumber: step.lineNumber,
    view: <StepLine {...stepDetails} />,
  })
  if (!step.docString) {
    return lines
  }
  const details = step.docString.value.split('\n')
    .map((detail, i) => {
      const lineNumber = step.docString.lineNumber + i
      return {
        lineNumber,
        view: <StepDetailLine value={detail} />,
      }
    })
  return lines.concat(details)
}

const buildBackgroundSteps = (test, background = {}) => {
  if (!test.steps || test.steps.length === 0 || !test.steps[0].isBackground) {
    return []
  }
  const firstStepLineNumber = test.steps[0].comments.length > 0 ? test.steps[0].comments[0].lineNumber : test.steps[0].lineNumber
  const backgroundTitleLineNumber = background.lineNumber || firstStepLineNumber - 1
  const commentLines = (background.comments || []).map(comment => ({
    lineNumber: comment.lineNumber,
    view: <CommentLine {...comment} level="test" />,
  }))
  const descriptionLines = background.description ? background.description.split('\n')
    .map((description, i) => ({
      lineNumber: backgroundTitleLineNumber + 1 + i,
      view: <DescriptionLine value={description} level="step" />,
    })) : []
  const statementLine = {
    lineNumber: backgroundTitleLineNumber,
    view: <BackgroundTitle name={background.name} keyword={background.keyword} />,
  }
  return commentLines.concat([statementLine]).concat(descriptionLines).concat(
    test.steps.filter(step => step.isBackground)
      .map(step => buildBackgroundStep(step))
      .reduce((groups, current) => groups.concat(current), []),
  )
}

const buildStep = (step, executionId, testIndex, projectCode) => {
  const lines = []
  lines.push(...buildComments('step', step.comments, step.metadata).map(line => ({ ...line, isBackground: step.isBackground })))
  lines.push({
    lineNumber: step.lineNumber,
    isBackground: step.isBackground,
    view: <StepLine {...step} executionId={executionId} testIndex={testIndex} projectCode={projectCode} />,
  })
  if (!step.docString) {
    return lines
  }
  const details = step.docString.value.split('\n')
    .map((detail, i) => {
      const lineNumber = step.docString.lineNumber + i
      if (detail.trim().startsWith('#')) {
        return {
          lineNumber,
          isBackground: step.isBackground,
          view: <CommentLine value={detail} level="detail" />,
        }
      }
      if (detail.trim().length === 0) {
        return {
          lineNumber,
          isBackground: step.isBackground,
          view: <EmptyLine value={detail} />,
        }
      }
      return {
        lineNumber,
        isBackground: step.isBackground,
        view: <StepDetailLine value={detail} />,
      }
    })
  return lines.concat(details)
}

const buildTest = ({ suite, projectCode }) => {
  const test = suite
  const commentLines = buildComments('test', test.comments, test.metadata)
  const lineNumber = test.lineNumber || 0
  const tagLine = test.tags && test.tags.length > 0 ? [{
    lineNumber: lineNumber - 1,
    view: <TagLine lineNumber={lineNumber - 1} level="test" tags={test.tags} />,
  }] : []
  const stepLines = (test.steps || [])
    .map(item => buildStep(item, test.executionId, test.index, projectCode))
    .reduce((a, b) => a.concat(b), [])
  const testLine = { lineNumber, view: <TestLine {...test} lineNumber={lineNumber} projectCode={projectCode} /> }
  const allLines = commentLines.concat(tagLine).concat(testLine).concat(stepLines)
  return allLines
}

const buildExample = ({ test, selectedTestIndex }) => {
  const example = {
    lineNumber: test.parameterLineNumber,
    view: <ExampleLine {...test} values={test.paddedParamValues} selected={test.index === selectedTestIndex} />,
  }
  const commentLines = buildComments('detail', test.parameterComments, test.parameterMetadata)
  return commentLines.concat(example)
}

const buildExampleTitle = (examples = {}) => {
  const exampleTitle = {
    lineNumber: examples.lineNumber,
    view: <ExampleTitle name={examples.name} keyword={examples.keyword} />,
  }
  const commentLines = (examples.comments || []).map(comment => ({
    lineNumber: comment.lineNumber,
    view: <CommentLine {...comment} level="step" />,
  }))
  const descriptionLine = examples.description ? examples.description.split('\n')
    .map((description, i) => ({
      lineNumber: examples.lineNumber + 1 + i,
      view: <DescriptionLine value={description} level="step" />,
    })) : []
  return commentLines.concat([exampleTitle]).concat(descriptionLine)
}

const buildExamples = ({ tests, selectedTestIndex }) => tests.reduce((exampleGroups, test) => {
  if (test.examples && test.examples.length > exampleGroups.length) {
    return exampleGroups.concat([{ examples: test.examples[test.examples.length - 1], tests: [test] }])
  }
  if (exampleGroups.length === 0) {
    return exampleGroups.concat([{ tests: [test] }])
  }
  const lastGroup = exampleGroups[exampleGroups.length - 1]
  return exampleGroups.slice(0, -1).concat({ ...lastGroup, tests: lastGroup.tests.concat([test]) })
}, []).map((group) => {
  const exampleTitleLines = buildExampleTitle(group.examples || {})
  const sortedTests = group.tests.map(test => ({ ...test, parameters: (test.parameters || []).sort((a, b) => a.name > b.name) }))
  const headerValues = sortedTests[0].parameters.map(param => param.name)
  const exampleValues = sortedTests.map(test => test.parameters.map(param => param.value))
  const columnWidths = [headerValues].concat(exampleValues)
    .map(values => values.map(val => val.length))
    .reduce((max, c) => c.map((l, i) => (max[i] ? Math.max(l, max[i]) : l)))
  const exampleHeader = {
    lineNumber: group.examples && group.examples.headerLine,
    view: <ExampleLine values={headerValues.map((val, i) => val.padEnd(columnWidths[i], ' '))} />,
  }
  const parameters = sortedTests
    .map(test => ({ ...test, paddedParamValues: test.parameters.map((param, i) => param.value.padEnd(columnWidths[i], ' ')) }))
    .map(test => buildExample({ test, selectedTestIndex }))
    .reduce((acc, cur) => acc.concat(cur), [])
  return exampleTitleLines.concat(exampleHeader).concat(parameters)
}).reduce((acc, x) => acc.concat(x), [])

const buildScenarioOutline = ({ tests, selectedTests, projectCode }) => {
  const selectedTestIndex = tests.map(test => test.index).find(index => selectedTests.includes(index)) || tests[0].index
  const selectedTest = tests.find(test => test.index === selectedTestIndex)
  const commentLines = buildComments('test', selectedTest.comments, selectedTest.metadata)
  const testLine = { lineNumber: selectedTest.lineNumber, view: <TestLine {...selectedTest} projectCode={projectCode} /> }
  const stepLines = selectedTest.steps
    .map(item => buildStep(item, selectedTest.executionId, selectedTest.index, projectCode))
    .reduce((a, b) => a.concat(b), [])
  const exampleLines = buildExamples({ tests, selectedTestIndex })
  return commentLines.concat([testLine]).concat(stepLines).concat(exampleLines)
}

const buildSuite = ({ suite, selectedTests, projectCode }) => {
  const commentLines = buildComments('suite', suite.comments, suite.metadata)
  const lineNumber = suite.lineNumber || 0
  const tagLine = suite.tags && suite.tags.length > 0 ? [{
    lineNumber: lineNumber - 1,
    view: <TagLine lineNumber={lineNumber - 1} level="suite" tags={suite.tags} />,
  }] : []
  const backgroundStepLines = (suite.tests && suite.tests.length > 0) ? buildBackgroundSteps(suite.tests[0], suite.background || {}) : []
  const testLines = (suite.tests && suite.tests.length > 0) ? suite.tests
    .map(test => ({ ...test, executionId: suite.executionId }))
    .reduce((groups, current) => {
      if (current.isScenarioOutline) {
        if (groups.length > 0 && Array.isArray(groups[groups.length - 1]) && groups[groups.length - 1][0].name === current.name) {
          groups[groups.length - 1].push(current)
          return groups
        }
        return [...groups, [current]]
      }
      return groups.concat(current)
    }, [])
    .map((test) => {
      if (Array.isArray(test)) {
        return buildScenarioOutline({ tests: test, selectedTests, projectCode })
      }
      return buildTest({ suite: test, projectCode: suite.projectCode })
    })
    .reduce((a, b) => a.concat(b), []) : []
  const suiteTitle = {
    lineNumber,
    view: <SuiteLine {...suite} lineNumber={lineNumber} />,
  }
  return commentLines.concat(tagLine).concat(suiteTitle).concat(backgroundStepLines).concat(testLines)
}

const recalculateNumbers = lines => lines.reduce((acc, current) => {
  const currentLineNumber = current.lineNumber
  if (acc.length === 0) return [!currentLineNumber || currentLineNumber < 1 ? setLineNumber(current, 1) : current]
  if (current.isBackground) return acc.concat([setLineNumber(current, null)])
  const previousLineNumber = acc.slice().reverse().filter(it => it.isBackground !== true)[0].lineNumber
  if (currentLineNumber > previousLineNumber) return acc.concat(current)
  return acc.concat([setLineNumber(current, previousLineNumber + 1)])
}, [])

const fillMissingLines = lines => lines.reduce((acc, line, index, arr) => {
  const previousLine = arr.slice(0, index).filter(it => it.isBackground !== true).reverse()[0]
  if (previousLine && !line.isBackground && line.lineNumber > previousLine.lineNumber + NUMBER_OF_LINES_TO_COLLAPSE) {
    return acc.concat([{ view: <SeparatorLine /> }, line])
  } if (previousLine && !line.isBackground && line.lineNumber > previousLine.lineNumber + 1) {
    const currentLineNumber = previousLine.lineNumber + 1
    return acc.concat(Array.from(
      { length: line.lineNumber - currentLineNumber },
      (x, i) => ({
        lineNumber: currentLineNumber + i,
        view: <EmptyLine lineNumber={currentLineNumber + i} />,
      }),
    ).concat([line]))
  }
  return acc.concat(line)
}, [])

const setKeys = (lines) => {
  let backgroundLineCount = 0
  return lines.map((line) => {
    /* eslint-disable-next-line no-plusplus */
    const newProps = { key: line.lineNumber || `bg${backgroundLineCount++}`, lineNumber: line.lineNumber, isBackground: line.isBackground }
    return cloneElement(line.view, newProps)
  })
}

const pipe = (...fns) => arg => fns.reduce((y, f) => f(y), arg)

const renderTest = pipe(buildTest, recalculateNumbers, fillMissingLines, setKeys)
const renderSuite = pipe(buildSuite, recalculateNumbers, fillMissingLines, setKeys)

const outline = pipeLine => (class Outline extends Component {
  state = {
    expanded: false,
    selectedTests: [],
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { expanded, selectedTests } = this.state
    return expanded !== nextState.expanded
    || selectedTests.length !== nextState.selectedTests.length
    || !selectedTests.every((v, i) => v === nextState.selectedTests[i])
    || this.visibleAttachmentCount(this.props) !== this.visibleAttachmentCount(nextProps)
  }

  getTestByIndex = (props, testIndex) => props.tests.find(test => test.index === testIndex)

  visibleAttachmentCount = props => (props.steps || props.tests.reduce((a, b) => a.concat(b.steps), []))
    .reduce((a, b) => a.concat(b.attachments), [])
    .filter(attachment => !attachment.removed)
    .length

  handleTestSelection = (testIndex) => {
    if (!testIndex) {
      return
    }
    const selectedTest = this.getTestByIndex(this.props, testIndex)
    if (!selectedTest.isScenarioOutline) {
      return
    }
    const { selectedTests } = this.state
    const newSelection = selectedTests
      .filter(index => this.getTestByIndex(this.props, index).name !== selectedTest.name)
      .concat(testIndex)
    this.setState({ selectedTests: newSelection })
  }

  render() {
    /* eslint-disable-next-line react/prop-types */
    const { projectCode } = this.props
    const { expanded, selectedTests } = this.state
    const clazz = expanded ? `${style.fileContainer} ${style.expanded}` : style.fileContainer
    return (
      <div className={clazz} id="outline">
        { pipeLine({ suite: this.props, selectedTests, projectCode }).map((line) => {
          const eventHandlers = {
            onClick: () => this.setState(prevState => ({ expanded: !prevState.expanded })),
            onSelect: this.handleTestSelection,
          }
          return cloneElement(line, eventHandlers)
        }) }
      </div>
    )
  }
})

export const SuiteOutline = outline(renderSuite)
export const TestOutline = outline(renderTest)
