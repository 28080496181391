import PropTypes from 'prop-types'
import moment from 'moment'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'

import style from './style.module.scss'

const FilterChips = ({
  onRemove, testers, tester, testName, dateFrom, dateUntil, status, conditionsState, uploadStatus, testSuiteId, defect, errorCategory, label, intactVersion,
  tag, metadataName, metadataValue,
}) => {
  const handleRemove = filter => () => {
    const newFilter = {}
    newFilter[filter] = null
    if (filter === 'metadata') {
      newFilter.metadataName = null
      newFilter.metadataValue = null
    }
    newFilter.pageNumber = 1
    onRemove(newFilter)
  }


  const chips = []
  if (testName) {
    chips.push(<Chip id="test-name-chip" key="test-name-chip" label={`Test name: ${testName}`} onDelete={handleRemove('testName')} />)
  }
  if (tester && tester.length > 0) {
    const matchedTester = testers.find(t => t.email === tester[0])
    const testerName = matchedTester ? matchedTester.name : tester[0]
    chips.push(<Chip
      id="tester-chip"
      key="tester-chip"
      onDelete={handleRemove('tester')}
      label={`Tester: ${testerName + (tester.length > 1 ? ` and ${tester.length - 1} more` : '')}`}
    />)
  }
  if (dateFrom) {
    chips.push(
      <Chip id="date-from-chip" key="date-from-chip" label={`Date from: ${dateFrom.format('DD.MM.YYYY, HH:mm')}`} onDelete={handleRemove('dateFrom')} />,
    )
  }
  if (dateUntil) {
    chips.push(
      <Chip id="date-until-chip" key="date-until-chip" label={`Date until: ${dateUntil.format('DD.MM.YYYY, HH:mm')}`} onDelete={handleRemove('dateUntil')} />,
    )
  }
  if (status) {
    chips.push(<Chip
      id="status-chip"
      key="status-chip"
      label={`intaQt result: ${status === 'PASSED' ? 'Passed' : 'Failed'}`}
      onDelete={handleRemove('status')}
    />)
  }
  if (conditionsState) {
    const readableState = conditionsState[0].toUpperCase() + conditionsState.slice(1).toLowerCase()
    chips.push(<Chip
      id="conditions-state-chip"
      key="conditions-state-chip"
      label={`Success Conditions: ${readableState}`}
      onDelete={handleRemove('conditionsState')}
    />)
  }
  if (testSuiteId) {
    chips.push(<Chip id="test-suite-chip" key="test-suite-id-chip" label={`Test suite id: ${testSuiteId}`} onDelete={handleRemove('testSuiteId')} />)
  }
  if (defect) {
    chips.push(<Chip id="defect-chip" key="defect-chip" label={`Defect: ${defect}`} onDelete={handleRemove('defect')} />)
  }
  if (errorCategory) {
    chips.push(<Chip
      id="error-category-chip"
      key="error-category-chip"
      label={`Error category: ${errorCategory}`}
      onDelete={handleRemove('errorCategory')}
    />)
  }
  if (label) {
    chips.push(<Chip id="label-chip" key="label-chip" label={`Label: ${label}`} onDelete={handleRemove('label')} />)
  }
  if (intactVersion) {
    chips.push(<Chip id="intact-version-chip" key="intact-version-chip" label={`intaQt: ${intactVersion}`} onDelete={handleRemove('intactVersion')} />)
  }
  if (tag) {
    chips.push(<Chip id="tag-chip" key="tag-chip" label={`Tag: ${tag}`} onDelete={handleRemove('tag')} />)
  }
  if (uploadStatus) {
    const mapStatus = code => ({
      UPLOADED: 'Uploaded',
      FAILED: 'Failed',
      'READY,FAILED': 'Not uploaded',
      'FAILED,READY': 'Not uploaded',
    })[code]
    chips.push(<Chip
      id="upload-status-chip"
      key="upload-status-chip"
      label={`Upload Status: ${mapStatus(uploadStatus)}`}
      onDelete={handleRemove('uploadStatus')}
    />)
  }
  if (metadataName) {
    chips.push(<Chip id="metadata-chip" key="metadata-chip" label={`${metadataName}: ${metadataValue}`} onDelete={handleRemove('metadata')} />)
  }

  return chips.length === 0 ? null : (
    <Paper zdepth={1} className={style.chipBox} id="chipBox">
      {chips}
    </Paper>
  )
}

FilterChips.propTypes = {
  testers: PropTypes.arrayOf(PropTypes.object),
  tester: PropTypes.arrayOf(PropTypes.string),
  testName: PropTypes.string,
  dateFrom: PropTypes.instanceOf(moment),
  dateUntil: PropTypes.instanceOf(moment),
  status: PropTypes.string,
  conditionsState: PropTypes.string,
  uploadStatus: PropTypes.string,
  testSuiteId: PropTypes.string,
  defect: PropTypes.string,
  errorCategory: PropTypes.string,
  label: PropTypes.string,
  intactVersion: PropTypes.string,
  tag: PropTypes.string,
  metadataName: PropTypes.string,
  metadataValue: PropTypes.string,
  onRemove: PropTypes.func,
}

FilterChips.defaultProps = {
  testers: [],
  tester: null,
  testName: null,
  dateFrom: null,
  dateUntil: null,
  status: null,
  conditionsState: null,
  uploadStatus: null,
  testSuiteId: null,
  defect: null,
  errorCategory: null,
  label: null,
  intactVersion: null,
  tag: null,
  metadataName: null,
  metadataValue: null,
  onRemove: null,
}

export default FilterChips
