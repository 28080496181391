import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'


class SessionStore extends ReportingEventEmitter {
  getInitialState() {
    return { loggedIn: false }
  }

  handleActions = (action) => {
    switch (action.type) {
    case events.session.authenticated: {
      this.state = { ...action.user, loggedIn: true }
      this.emitChange()
      break
    }
    case events.session.authenticationFailed: {
      this.state = { loggedIn: false }
      this.emitChange()
      break
    }
    case events.session.unauthorized: {
      this.state = { loggedIn: false }
      this.emitChange()
      break
    }
    case events.session.authenticationError: {
      this.state = {
        loggedIn: false,
        authenticationError: true,
        error: action.message,
      }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}


const sessionStore = new SessionStore()
dispatcher.register(sessionStore.handleActions)

export default sessionStore
