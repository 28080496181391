import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import Link from 'components/common/link'
import TestStatusIcon from 'components/testStatusIcon'
import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import DefectsColumn from 'components/reports/defectsColumn'
import FormattedDate from 'components/formattedDate'
import TrimmedText from 'components/trimmedText'
import * as Utils from 'components/utils'
import Table from 'components/table'
import DefectIcon from './widgets/defectIcon'
import ColumnNames from './columnNames'

const inlineStyle = {
  testSuiteName: { display: 'flex' },
  testSuiteNameText: { marginTop: 'auto', overflow: 'hidden' },
}

const ReportTable = ({
  items, fields, onRemoveColumn, projectCode, totalPages,
}) => {
  const parseField = (name) => {
    const Header = ColumnNames[name]

    switch (name) {
    case 'verificationState': {
      return {
        Header,
        accessor: 'verificationState',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        minWidth: 160,
        optional: true,
      }
    }
    case 'verifiedBy': {
      return {
        Header,
        accessor: 'verifiedBy',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
        optional: true,
      }
    }
    case 'verificationTime': {
      return {
        Header,
        accessor: 'verificationTime',
        Cell: item => <FormattedDate value={item.value} />,
        minWidth: 160,
        sortable: true,
        optional: true,
      }
    }
    case 'approvalState': {
      return {
        Header,
        accessor: 'approvalState',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
        optional: true,
      }
    }
    case 'approvalAction': {
      return {
        Header,
        accessor: 'approvalAction',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        minWidth: 220,
        sortable: true,
        optional: true,
      }
    }
    case 'approvalChangedBy': {
      return {
        Header,
        accessor: 'approvalChangedBy',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        minWidth: 190,
        sortable: true,
        optional: true,
      }
    }
    case 'approvalLastChanged': {
      return {
        Header,
        accessor: 'approvalLastChanged',
        Cell: item => <FormattedDate value={item.value} />,
        minWidth: 190,
        sortable: true,
        optional: true,
      }
    }
    case 'almState': {
      return {
        Header,
        accessor: 'almState',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
        optional: true,
      }
    }
    case 'almUploadState': {
      return {
        Header,
        accessor: 'almUploadState',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        minWidth: 170,
        sortable: true,
        optional: true,
      }
    }
    case 'almComment': {
      return {
        Header,
        accessor: 'almComment',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
        optional: true,
      }
    }
    case 'defects': {
      return {
        Header,
        accessor: 'defects',
        Cell: item => (
          <DefectsColumn
            defects={item.value}
          />
        ),
        optional: true,
      }
    }
    case 'errorCategory': {
      return {
        Header,
        accessor: 'errorCategory',
        Cell: item => (
          <ErrorCategoryColumn
            labels={item.row.original.labels}
            errorCategories={item.row.original.errorCategories}
            errorMessage={item.row.original.errorMessage}
          />
        ),
        optional: true,
      }
    }
    case 'tester': {
      return {
        Header,
        accessor: 'tester',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        sortable: true,
        optional: true,
      }
    }
    case 'startTime': {
      return {
        Header,
        accessor: 'startTime',
        Cell: item => <FormattedDate value={item.value} />,
        sortable: true,
        optional: true,
      }
    }
    case 'duration': {
      return {
        Header,
        accessor: 'duration',
        Cell: item => Utils.formatDuration(item.value),
        optional: true,
      }
    }
    case 'note': {
      return {
        Header,
        accessor: 'note',
        Cell: item => <TrimmedText text={item.value} maxLength={30} />,
        optional: true,
      }
    }
    default: {
      return {
        Header: name,
        id: name,
        Cell: (item) => {
          const { metadata } = item.row.original
          const text = metadata?.length > 0
            ? metadata
              .filter(it => it.name === name)
              .map(it => it.value).join(', ')
            : ''

          return (
            <a title={text}>{text}</a>
          )
        },
        optional: true,
      }
    }
    }
  }
  const columns = useMemo(() => (
    fields.reduce((acc, cur) => [...acc, parseField(cur)], [
      {
        Header: '',
        accessor: 'conditionsState',
        Cell: item => <TestStatusIcon status={item.row.original.result} conditionsState={item.row.original.conditionsState} />,
        disableResizing: true,
        width: 48,
      },
      {
        Header: 'Test Name',
        accessor: 'testSuite',
        Cell: item => (
          <div style={inlineStyle.testSuiteName}>
            <Link style={inlineStyle.testSuiteNameText} to={`/${projectCode}/detail/${item.row.original.testId}`}>
              <div data-tip={item.row.original.testName}>
                {item.row.original.testName}
              </div>
            </Link>
            <DefectIcon defects={item.row.original.defects} warnings={item.row.original.warnings} />
          </div>
        ),
        sortable: true,
      },
    ])
  ), [fields])

  return (
    <Table
      columns={columns}
      data={items}
      id="reportTable"
      totalPages={totalPages}
      onRemoveColumn={onRemoveColumn}
    />
  )
}

ReportTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  fields: PropTypes.arrayOf(PropTypes.string),
  onRemoveColumn: PropTypes.func.isRequired,
  projectCode: PropTypes.string.isRequired,
  totalPages: PropTypes.number,
}

ReportTable.defaultProps = {
  items: [],
  fields: [],
  totalPages: undefined,
}

export default memo(ReportTable)
