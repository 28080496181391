import { memo } from 'react'
import PropTypes from 'prop-types'

const style = {
  wrapper: {
    display: 'block',
    margin: '2em',
  },
  row: { display: 'flex' },
  idColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  nameColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '300px',
  },
}

const CustomFieldList = ({ list }) => (
  <div style={style.wrapper}>
    <div style={style.row}>
      <div style={style.nameColumn}><h3>Custom Field Name</h3></div>
      <div><h3>ID</h3></div>
    </div>
    { list.map(field => (
      <div style={style.row}>
        <div style={style.nameColumn}>{field.name}</div>
        <div>{field.id}</div>
      </div>
    ))}
  </div>
)

CustomFieldList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default memo(CustomFieldList)
