import axios from 'axios'
import * as Common from 'services/common'

class CdrService {
    isEnabled = (project) => {
      const url = `/api/module/cdr/${project}/settings/enabled`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    retrieveSettings = (project) => {
      const url = `/api/module/cdr/${project}/settings`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    updateSettings = (project, settings) => {
      const request = {
        ...settings,
        verificationServiceEnabled: settings.verificationServiceEnabled || false,
        verificationServiceBaseUrl: settings.verificationServiceBaseUrl || '',
        verificationServiceTimeoutMillis: settings.verificationServiceTimeoutMillis || 0,
        verificationServiceAuthToken: settings.verificationServiceAuthToken || '',
        verificationServiceAllowSelfSignedCertificates: settings.verificationServiceAllowSelfSignedCertificates || false,
        linqEnabled: settings.linqEnabled || false,
        linqBaseUrl: settings.linqBaseUrl || '',
        linqAuthToken: settings.linqAuthToken || '',
        linqTimeoutMillis: settings.linqTimeoutMillis || 0,
        linqAllowSelfSignedCertificates: settings.linqAllowSelfSignedCertificates || false,
        linqPollCdrsAfterMinutes: settings.linqPollCdrsAfterMinutes || 65,
      }
      const url = `/api/module/cdr/${project}/settings`
      return axios.post(url, request, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }
}

const instance = new CdrService()
export default instance
