import find from 'lodash/find'
import map from 'lodash/map'
import get from 'lodash/get'
import moment from 'moment'

const statuses = ['ARCHIVED', 'VERIFIED', 'NEW']

const getCategories = () => [...Array(8).keys()].reverse()
  .map(n => moment().startOf('day').subtract(n, 'days'))

const filterStatus = (data, status, date) => {
  const item = find(data, d => d.day.isSame(date) && d.status === status)
  const count = item ? item.count : 0
  return { x: date.format('YYYY-MM-DD'), y: count, label: count }
}

export const initialState = {
  loading: true,
  categories: getCategories().map(date => date.format('YYYY-MM-DD')),
  chart: [],
  newCount: 'N/A',
}

export const reducer = (state, action) => {
  switch (action.type) {
  case 'received': {
    const data = map(action.data, it => ({ day: moment(it.day), status: it.verificationStatus, count: it.count }))
    const chart = map(statuses, status => ({ key: status, data: getCategories().map(date => filterStatus(data, status, date)) }))

    const noVerifications = get(action, 'data', [])
      .filter(it => statuses.includes(it.verificationStatus))
      .map(it => it.count)
      .reduce((a, b) => a + b, 0) === 0

    return { ...state, loading: false, chart, noVerifications }
  }
  case 'countReceived': {
    return { ...state, newCount: action.count }
  }
  default: {
    throw new Error()
  }
  }
}
