import ExecutionListService from 'services/executionListService'
import VerificationService from 'services/postprocess/verificationService'


export function getData(project, user) {
  return VerificationService.getVerificationStats(project, user)
}

export function getCount(project, user) {
  return ExecutionListService.findAllReadyForVerification(project, user, 1, 3)
}
