import { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import FilterPanel from 'components/reports/common/filterPanel'

const ErrorCategoryFilter = ({
  categories, labels, onSubmit, initialCategory, initialLabel, id,
}) => {
  const [state, setState] = useState({ errorCategory: '', label: '' })

  useEffect(() => {
    setState({ errorCategory: initialCategory, label: initialLabel })
  }, [initialCategory, initialLabel])

  const handleFilter = () => {
    onSubmit(state.errorCategory, state.label)
    setState({ errorCategory: '', label: '' })
  }

  return (
    <FilterPanel title="Filter by Error Category" onSubmit={handleFilter} id={id}>
      <FormControl fullWidth>
        <InputLabel>Select an error category</InputLabel>
        {Array.isArray(categories)
          && (
            <Select
              id="errorCategoryFilterSelect"
              value={state.errorCategory}
              onChange={e => setState({ ...state, errorCategory: e.target.value })}
            >
              {categories.map(category => <MenuItem key={category} value={category}>{category}</MenuItem>)}
            </Select>
          )}
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Select a label</InputLabel>
        {Array.isArray(labels)
          && (
            <Select
              id="labelSelect"
              value={state.label}
              onChange={e => setState({ ...state, label: e.target.value })}
            >
              {labels.map(label => <MenuItem key={label} value={label}>{label}</MenuItem>)}
            </Select>
          )}
      </FormControl>
    </FilterPanel>
  )
}

ErrorCategoryFilter.propTypes = {
  id: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialCategory: PropTypes.string,
  initialLabel: PropTypes.string,
}

ErrorCategoryFilter.defaultProps = {
  id: 'errorCategoryFilter',
  initialCategory: '',
  initialLabel: '',
}

export default memo(ErrorCategoryFilter)
