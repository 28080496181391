import { Component } from 'react'

import IconButton from 'components/iconButton'
import Spinner from 'components/spinner'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import EditMetadataRow from './editMetadataRow'
import ConditionsStore from './store'
import * as Actions from './actions'
import style from './testCategories.module.css'


class Metadata extends Component {
  state = ConditionsStore.getState()

  componentDidMount() {
    const { projectCode } = this.context
    document.title = 'QiTASC ConQlude - All Test cases'
    ConditionsStore.on('change', this.updateState)
    Actions.retrieveData(projectCode)
  }

  componentWillUnmount = () => {
    ConditionsStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(ConditionsStore.getState())
  }

  handleSave = (condition) => {
    const { projectCode } = this.context
    Actions.saveSuccessConditionConfig(condition, projectCode)
  }

  render() {
    const { projectCode } = this.context
    const {
      error, loading, list, selectedRow, addCondition,
    } = this.state
    if (error) {
      throw Error(error)
    }

    return (
      <Spinner spinning={loading}>

        <div className={style.wrapper}>
          <div key="header" className={style.headerRow}>
            <div className={style.tagColumn}>Key</div>
            <div className={style.buttonColumn} />
          </div>
          {list.filter(item => item.type === 'METADATA').map(item => (item.id === selectedRow ? (
            <EditMetadataRow
              key={item.id}
              condition={item}
              onSubmit={this.handleSubmit}
              onSave={this.handleSave}
              onCancel={Actions.handleCancel}
            />
          )
            : (
              <div key={item.id} className={style.conditionRow}>
                <div className={`${style.tagColumn} ${style.singleColumn}`}>{item.tag}</div>
                <div className={style.buttonColumn}>
                  <IconButton onClick={() => Actions.handleDelete(projectCode, item.id)} src="/images/delete.svg" />
                  <IconButton onClick={() => Actions.handleEdit(item.id)} src="/images/edit.svg" />
                </div>
              </div>
            )))}
          { addCondition && (
            <EditMetadataRow
              key="new"
              onSubmit={this.handleSubmit}
              onSave={this.handleSave}
              onCancel={Actions.handleCancel}
            />
          )}
        </div>
        { !addCondition && (
          <div className={style.wrapper}>
            <IconButton onClick={Actions.handleAdd} src="/images/add.svg" />
          </div>
        )}
      </Spinner>
    )
  }
}

Metadata.contextType = ProjectContext

export default Metadata
