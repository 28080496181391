import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'


const JiraFieldTypeSelect = props => (
  <Select {...props}>
    <MenuItem value="TEXT">Text</MenuItem>
    <MenuItem value="SELECT">Select</MenuItem>
    <MenuItem value="MULTI_SELECT">Multi Select</MenuItem>
  </Select>
)

export default JiraFieldTypeSelect
