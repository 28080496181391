import { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import values from 'lodash/values'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'

import ExcelExport from 'components/reports/excelExport/export'
import Export from 'components/reports/csvExport'
import FilterChips from 'components/reports/common/widgets/filterChips'
import Header from 'components/reports/common/reportHeader'
import Spinner from 'components/spinner'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import calcTotalPages from 'utils/calcTotalPages'

import * as Actions from './actions'
import style from './style.module.scss'
import Table from './table'
import Settings from './settingsButton'
import TestRunReportDrawer from './drawer'
import TestRunStore from './store'
import ColumnNames from './columnNames'


class TestRunReport extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = TestRunStore.resetState(context.projectCode)
  }

  componentDidMount = () => {
    const { projectCode } = this.context
    const { query } = this.props
    const { defaultPageSize, fields } = this.state
    document.title = 'QiTASC ConQlude - Test Run Report'
    TestRunStore.on('change', this.updateState)
    Actions.retrieveInitialData(projectCode, {
      ...query,
      pageSize: query.pageSize || defaultPageSize,
    }, fields)
    ReactTooltip.hide()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode } = this.context
      const { defaultPageSize, fields } = this.state
      const { query } = this.props
      Actions.retrieveTestRunList(projectCode, {
        ...query,
        pageSize: query.pageSize || defaultPageSize,
      }, fields)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    TestRunStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(TestRunStore.getState())
  }

  handleRemoveColumn = (name) => {
    const { projectCode } = this.context
    Actions.removeColumn(name, projectCode)
  }

  handleSettingsUpdate = (changed) => {
    const { projectCode } = this.context
    const { query } = this.props
    if (changed) {
      const { defaultPageSize, fields } = this.state
      Actions.retrieveTestRunList(projectCode, {
        ...query,
        pageSize: query.pageSize || defaultPageSize,
      }, fields)
    }
  }

  handleAddColumn = (name) => {
    const { projectCode } = this.context
    Actions.addColumn(name, projectCode)
  }

  handleToggleFilters = () => {
    const { openDrawer } = this.state
    Actions.setDrawerOpen(!openDrawer)
  }

  handleNewFilter = (filter) => {
    const { redirect } = this.props
    redirect(filter)
  }

  render() {
    const { query, redirect } = this.props
    const { projectCode } = this.context
    const {
      error, loading, defaultPageSize, testers, labels, errorCategories,
      items, totalSize, passedCount, failedCount, fields, openDrawer,
    } = this.state
    if (error != null) {
      return TestRunReport.renderError(error)
    }

    const { pageNumber, pageSize, ...otherParams } = query
    const currentSize = pageSize ? Number.parseInt(pageSize, 10) : defaultPageSize
    const filters = {
      ...otherParams,
      dateFrom: otherParams.dateFrom ? moment(otherParams.dateFrom) : undefined,
      dateUntil: otherParams.dateUntil ? moment(otherParams.dateUntil) : undefined,
      tester: otherParams.tester ? otherParams.tester.split(',') : undefined,
    }

    const areReportsFiltered = some(values(filters), it => it != null)

    const metadataFields = fields.filter(it => !Object.keys(ColumnNames).includes(it))

    return (
      <div className={style.container}>
        <div className={style.content}>
          <Header
            title="Test Run Report"
            actions={(
              <>
                <ExcelExport />
                <Export metadata={metadataFields} />
                <Settings
                  columns={fields}
                  onRemoveColumn={this.handleRemoveColumn}
                  onAddColumn={this.handleAddColumn}
                  onCancel={this.handleSettingsUpdate}
                />
              </>
            )}
            totalSize={totalSize}
            showFilterButton={!openDrawer}
            passedCount={areReportsFiltered ? passedCount : undefined}
            failedCount={areReportsFiltered ? failedCount : undefined}
            onToggleFilters={this.handleToggleFilters}
          />
          <FilterChips
            {...filters}
            testers={testers}
            onRemove={this.handleNewFilter}
          />
          <Spinner spinning={loading}>
            <Table
              items={items}
              fields={fields}
              onRemoveColumn={this.handleRemoveColumn}
              projectCode={projectCode}
              totalPages={calcTotalPages({ totalSize, pageSize: currentSize })}
            />
          </Spinner>
        </div>
        <TestRunReportDrawer
          open={openDrawer}
          testers={testers}
          labels={labels}
          errorCategories={errorCategories}
          filters={filters}
          redirect={redirect}
        />
      </div>
    )
  }
}

TestRunReport.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

TestRunReport.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  redirect: PropTypes.func.isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

TestRunReport.contextType = ProjectContext

export default withQuery(withRouter(TestRunReport))
