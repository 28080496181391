import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'
import get from 'lodash/get'
import find from 'lodash/find'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

import PageNotFound from 'pages/pageNotFound'
import TestSuiteList from 'components/testSuite/testSuiteList/testSuiteList'
import TestSuiteDetails from 'components/testSuite/testSuiteDetails/testSuiteDetails'
import TestPlanList from 'components/testSuite/testPlan/testPlan'

import useProject from 'hooks/useProject'

const DEFAULT_TAB = 'list'

const TestSuitePage = ({ history, match }) => {
  const { roles, hpqcEnabled } = useProject()
  const isTestManager = roles.includes('ROLE_TEST_MANAGER')
  const tabs = [
    { name: 'list', label: 'Test Suites' },
    { name: 'test-plans', label: 'Test Plans', enabled: hpqcEnabled && isTestManager },
  ]

  const handleChange = useCallback((_, val) => {
    const selectedTab = find(tabs, { name: val })
    const link = get(selectedTab, 'name', val)
    history.push(`${match.url}/${link}`)
  }, [])
  const matchingTab = find(tabs, it => window.location.pathname.startsWith(`${match.url}/${get(it, 'name')}`))

  return (
    <div>
      <Tabs
        onChange={handleChange}
        value={matchingTab ? matchingTab.name : DEFAULT_TAB}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs
          .filter(tab => get(tab, 'enabled', true))
          .map(tab => <Tab id={tab.name} key={tab.name} value={tab.name} label={tab.label} />)}
      </Tabs>
      <Switch>
        <Route path={`${match.path}/list`} component={TestSuiteList} />
        <Route path={`${match.path}/test-plans`} component={TestPlanList} />
        <Route path={`${match.path}/details/:testSuiteId`} component={TestSuiteDetails} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  )
}

TestSuitePage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export default withRouter(TestSuitePage)
