import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import union from 'lodash/union'
import without from 'lodash/without'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import { UploadSuccessfulIcon, UploadFailedIcon } from 'components/icons'
import EditRow from 'components/administration/users/settings/editRow'
import { roleList } from 'components/administration/users/settings/index'
import CustomIconButton from 'components/iconButton'
import useProject from 'hooks/useProject'

import {
  retrieveSettings,
  updateSettings,
  fetchUsers,
  addUser,
  revokeUser,
} from './actions'
import style from './style.module.css'


const isNum = str => Number.isInteger(str) || (str && /^\d+$/.test(str.replace(/\s/g, '')))
const isValidTimeout = value => isNum(value) && value >= 1 && value <= 600

const ProjectSettings = ({ match }) => {
  const handleError = useErrorHandler()
  const [project, setProject] = useState({ settings: { enabledModules: [] }, youTrackList: [], loading: true })
  const [timeout, setTimeoutInSeconds] = useState('')
  const [addingRole, setRoleAdding] = useState(false)
  const [userList, setUserList] = useState([])
  const { updateProjectContext } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - Projects Settings'
    retrieveSettings(match.params.project).then((response) => {
      setTimeoutInSeconds(response.project.timeoutInSeconds)
      setProject({
        settings: response.project,
        youTrackList: response.youTrackList,
        loading: false,
        users: response.project.users.reduce((acc, cur) => {
          const roles = cur.roles.filter(i => i.project === match.params.project)

          return [...acc, roles.length > 1 ? roles.map(i => ({ ...cur, role: i.role })) : { ...cur, role: roles[0].role }].flat()
        }, []),
      })
    }).catch((message) => { throw Error(message) })

    fetchUsers().then(list => setUserList(
      list.sort((a, b) => a.email.localeCompare(b.email)).map(i => ({ code: i.email, name: i.name })),
    )).catch(handleError)
  }, [match.params.project])

  const updateProject = (p) => {
    updateSettings(p).then((response) => {
      setProject({ settings: response, youTrackList: project.youTrackList, loading: false })
      setTimeoutInSeconds(response.timeoutInSeconds)
      updateProjectContext(p)
    }).catch(handleError)
  }

  const handleStatusChange = (updatedProject) => {
    setProject({ ...project, loading: true })
    updateProject(updatedProject)
  }

  const handleModuleChange = (module, enabled) => {
    const enabledModules = enabled
      ? union(project.settings.enabledModules, [module])
      : without(project.settings.enabledModules, module)
    setProject({ ...project, loading: true })
    updateProject({ ...project.settings, enabledModules })
  }

  const handleNewUser = (email, role) => {
    const projectCode = project.settings.code

    addUser(email, { project: projectCode, role })
      .then(() => {
        setProject(prev => ({
          ...prev,
          users: [...(prev.users || []), { name: userList.find(i => i.code === email).name, email, role }],
        }))
        setRoleAdding(false)
      })
      .catch(handleError)
  }

  const handleRevokeUser = (email, permission) => {
    revokeUser(email, permission)
      .then(() => setProject(prev => ({ ...prev, users: prev.users.filter(i => !(i.email === email && i.role === permission.role)) })))
      .catch(handleError)
  }

  const timeoutValidationMessage = isValidTimeout(timeout) ? null : 'Must be a number between 1 and 600'

  return (
    <Spinner spinning={project.loading}>
      <Card>
        <CardHeader title={`Project Settings: ${project.settings.name}`} />
        <CardContent className={style.wrapper}>
          <div>
            <FormControlLabel
              label="Post-execution processes"
              control={(
                <Checkbox
                  id="enablePEP"
                  checked={project.settings.postProcessEnabled}
                  onChange={e => handleStatusChange({ ...project.settings, postProcessEnabled: e.target.checked })}
                />
              )}
            />
            <FormHelperText>Enables verification and approval processes.</FormHelperText>
          </div>
          <div>
            <FormControlLabel
              label="Use tests"
              control={(
                <Checkbox
                  id="useTests"
                  checked={project.settings.useTests}
                  onChange={e => handleStatusChange({ ...project.settings, useTests: e.target.checked })}
                />
              )}
            />
            <FormHelperText>If checked, dashboard charts will use scenario count rather than feature count.</FormHelperText>
          </div>
          <div>
            <FormControlLabel
              label="Allow moving reports to other projects"
              control={(
                <Checkbox
                  id="moveTests"
                  checked={project.settings.moveTests}
                  onChange={e => handleStatusChange({ ...project.settings, moveTests: e.target.checked })}
                />
              )}
            />
            <FormHelperText>If checked, reports can be moved to other projects by test managers</FormHelperText>
          </div>
          <div>
            <FormControlLabel
              label="CDR Verification Integration"
              control={(
                <Checkbox
                  id="enableCdr"
                  checked={project.settings.enabledModules.includes('CDR')}
                  onChange={e => handleModuleChange('CDR', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="CheQ integration"
              control={(
                <Checkbox
                  id="enableCheq"
                  checked={project.settings.enabledModules.includes('CHEQ')}
                  onChange={e => handleModuleChange('CHEQ', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="Qonform Integration"
              control={(
                <Checkbox
                  id="enableQonform"
                  checked={project.settings.enabledModules.includes('QONFORM')}
                  onChange={e => handleModuleChange('QONFORM', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="HPQC integration"
              control={(
                <Checkbox
                  id="enableHpqc"
                  checked={project.settings.enabledModules.includes('HPQC')}
                  onChange={e => handleModuleChange('HPQC', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="Jira/Zephyr integration"
              control={(
                <Checkbox
                  id="enableJiraZephyr"
                  checked={project.settings.enabledModules.includes('JIRA_ZEPHYR')}
                  onChange={e => handleModuleChange('JIRA_ZEPHYR', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="Basic Jira integration"
              control={(
                <Checkbox
                  id="enableBasicJira"
                  checked={project.settings.enabledModules.includes('JIRA_BASIC')}
                  onChange={e => handleModuleChange('JIRA_BASIC', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="PM DB integration"
              control={(
                <Checkbox
                  id="enablePMS"
                  checked={project.settings.enabledModules.includes('PMS')}
                  onChange={e => handleModuleChange('PMS', e.target.checked)}
                />
              )}
            />
          </div>
          <div>
            <FormControlLabel
              label="Report API Authentication"
              control={(
                <Checkbox
                  id="enableAuth"
                  checked={project.settings.authenticationEnabled}
                  onChange={e => handleStatusChange({ ...project.settings, authenticationEnabled: e.target.checked })}
                />
              )}
            />
            <FormHelperText>If checked, It will not be possible to submit reports from intaQt without providing personal API secret.</FormHelperText>
          </div>
          <FormControl fullWidth>
            <InputLabel>YouTrack</InputLabel>
            <Select
              fullWidth
              id="projectSettingsYouTrackSettings"
              value={project.settings.youTrackExternalId || ''}
              onChange={event => handleStatusChange({ ...project.settings, youTrackExternalId: event.target.value })}
            >
              <MenuItem value="">Disabled</MenuItem>
              {project.youTrackList.map(it => (
                <MenuItem key={it.externalId} value={it.externalId}>{it.instanceName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div>
            <div className={style.timeoutContainer}>
              <TextField
                id="TimeoutField"
                value={timeout}
                error={timeoutValidationMessage != null}
                label={timeoutValidationMessage || 'Timeout(seconds)'}
                onChange={e => setTimeoutInSeconds(e.target.value)}
              />
              { timeout !== project.settings.timeoutInSeconds && (
                <span>
                  <IconButton
                    id="acceptButton"
                    disabled={timeoutValidationMessage != null}
                    onClick={() => handleStatusChange({ ...project.settings, timeoutInSeconds: timeout })}
                  >
                    <UploadSuccessfulIcon />
                  </IconButton>
                  <IconButton
                    id="cancelButton"
                    onClick={() => setTimeoutInSeconds(project.settings.timeoutInSeconds)}
                  >
                    <UploadFailedIcon />
                  </IconButton>
                </span>
              )}
            </div>
          </div>
          <div>
            <CardHeader title="Users Permissions" sx={{ padding: 0 }} />
            <div>
              <div key="header" className={style.headerRow}>
                <div className={style.userColumn}>Name</div>
                <div className={style.userColumn}>Email</div>
                <div className={style.roleColumn}>Role</div>
                <div className={style.buttonColumn} />
              </div>
              {project.users?.map(item => (
                <div key={item.email + item.role} className={style.roleRow}>
                  <div className={style.userColumn}>{item.name}</div>
                  <div className={style.userColumn}>{item.email}</div>
                  <div className={style.roleColumn}>{roleList.find(i => i.code === item.role).name}</div>
                  <div className={style.buttonColumn}>
                    <CustomIconButton
                      onClick={() => {
                        handleRevokeUser(
                          item.email,
                          {
                            project: project.settings.code,
                            role: item.role,
                          },
                        )
                      }}
                      src="/images/delete.svg"
                    />
                  </div>
                </div>
              ))}
              {addingRole && (
                <EditRow
                  projectList={userList}
                  roleList={roleList}
                  onCancel={() => setRoleAdding(false)}
                  onSubmit={({ project: email, role }) => handleNewUser(email, role)}
                />
              )}
            </div>
            {!addingRole && (
              <div className={style.addUserWrapper}>
                <CustomIconButton onClick={() => setRoleAdding(true)} src="/images/add.svg" />
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Spinner>
  )
}

ProjectSettings.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default withRouter(ProjectSettings)
