import { Component } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import union from 'lodash/union'
import without from 'lodash/without'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Checkbox from '@mui/material/Checkbox'

import * as Utils from 'components/utils'
import ErrorCategoryColumn from 'components/reports/errorCategoryColumn'
import FormattedDate from 'components/formattedDate'
import Spinner from 'components/spinner'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'

import VerificationStore from './store'
import * as Actions from './actions'
import style from './verification.module.css'


class Verification extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = VerificationStore.getState()
  }

  componentDidMount = () => {
    const { projectCode, user } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Verification'
    VerificationStore.on('change', this.updateState)
    Actions.retrieveList(projectCode, user.email, query.pageNumber)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode, user } = this.context
      const { query } = this.props
      const { pageNumber } = query
      Actions.retrieveList(projectCode, user.email, pageNumber)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    VerificationStore.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(VerificationStore.getState())
  }

  handleRowSelection = testId => (e) => {
    const { selectedItems } = this.state
    Actions.updateRowSelection(get(e, 'target.checked', false) ? union(selectedItems, [testId]) : without(selectedItems, testId))
  }

  handleArchive = () => {
    const { projectCode, user } = this.context
    const { selectedItems } = this.state
    Actions.archive(selectedItems, projectCode, user.email)
  }

  handleVerify = () => {
    const { projectCode, user } = this.context
    const { selectedItems } = this.state
    Actions.verify(selectedItems, projectCode, user.email)
  }

  render() {
    const {
      error, loading, items, isSelectedAll, selectedItems, totalPages,
    } = this.state
    const { match } = this.props
    const columns = [
      {
        Header: (
          <Checkbox
            indeterminate={selectedItems.length > 0 && selectedItems.length < items.length}
            checked={isSelectedAll}
            disabled={items.length === 0}
            onChange={Actions.selectAll}
          />
        ),
        accessor: 'testId',
        Cell: item => (
          <Checkbox checked={selectedItems.includes(item.value)} onChange={this.handleRowSelection(item.value)} />
        ),
        padding: 'checkbox',
        width: 48,
        disableResizing: true,
      },
      {
        Header: '',
        accessor: 'result',
        Cell: item => <TestStatusIcon status={item.value} />,
        width: 48,
        disableResizing: true,
      },
      {
        Header: 'Test Name',
        accessor: 'testName',
        Cell: item => (
          <Link to={`/${match.params.projectCode}/detail/${item.row.original.testId}`}>
            <TrimmedText text={item.value} maxLength={30} />
          </Link>
        ),
      },
      {
        Header: 'Error Category',
        accessor: 'errorCategories',
        Cell: item => (
          <ErrorCategoryColumn
            className={style.errorCategoryColumn}
            labels={item.row.original.labels}
            errorCategories={item.value}
            errorMessage={item.row.original.errorMessage}
          />
        ),
      },
      {
        Header: 'Start time',
        accessor: 'startTime',
        Cell: item => <FormattedDate value={item.value} />,
      },
      {
        Header: 'Duration',
        accessor: 'duration',
        Cell: item => Utils.formatDuration(item.value),
      },
    ]

    if (error != null) {
      return Verification.renderError(error.message)
    }

    return (
      <Card className={style.card}>
        <CardHeader title="Executions ready for verification" />
        <Spinner spinning={loading}>
          <div className={style.verificationContainer}>
            <div className={style.buttonPanel}>
              <Button
                variant="contained"
                id="archiveButton"
                disabled={selectedItems.length === 0}
                onClick={this.handleArchive}
                color="primary"
              >
                Archive
              </Button>
              <Button
                variant="contained"
                id="verifyButton"
                disabled={selectedItems.length === 0}
                onClick={this.handleVerify}
                color="primary"
              >
                Verify
              </Button>
            </div>
            <Table
              id="verification"
              columns={columns}
              data={items}
              totalPages={totalPages}
            />
          </div>
        </Spinner>
      </Card>
    )
  }
}

Verification.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

Verification.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

Verification.contextType = ProjectContext

export default withQuery(withRouter(Verification))
