/* eslint-disable no-console */
import { memo, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import FilterPanel from 'components/reports/common/filterPanel'

const reducer = (state, action) => {
  switch (action.type) {
  case 'nameChanged':
    return { ...state, name: action.value, canSubmit: action.value !== '' }
  case 'valueChanged':
    return { ...state, value: action.value }
  case 'initialStateChange':
    return { ...state, name: action.initialName, value: action.initialValue }
  default:
    throw new Error()
  }
}

const MetadataFilter = ({ initialName, initialValue, onSubmit, id }) => {
  const initialState = {
    name: initialName || '',
    value: initialValue || '',
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  useEffect(() => {
    dispatch({ type: 'initialStateChange', initialName, initialValue })
  }, [initialName, initialValue])

  const handleNameChange = (e) => {
    dispatch({ type: 'nameChanged', value: e.target.value || '' })
  }

  const handleValueChange = (e) => {
    dispatch({ type: 'valueChanged', value: e.target.value || '' })
  }

  const handleFilter = () => {
    console.log('md handleFilter')
    if (state.canSubmit) {
      onSubmit({ name: state.name, value: state.value })
    }
  }

  return (
    <FilterPanel title="Filter by Metadata" onSubmit={handleFilter} id={id}>
      <TextField
        id="nameField"
        label="Name"
        fullWidth
        autoFocus
        onChange={handleNameChange}
        value={state.name}
      />
      <TextField
        id="valueField"
        label="Value"
        fullWidth
        onChange={handleValueChange}
        value={state.value}
      />
    </FilterPanel>
  )
}

MetadataFilter.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  initialName: PropTypes.string,
  initialValue: PropTypes.string,
}

MetadataFilter.defaultProps = {
  id: 'metadataFilter',
  initialName: '',
  initialValue: '',
}

export default memo(MetadataFilter)
