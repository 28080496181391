import axios from 'axios'
import * as Common from 'services/common'
import * as Utils from 'components/utils'

class TestSuiteService {
  findAll = (project, filters) => {
    const projectParam = `project=${project}`
    const sortParams = filters.sortField ? `&sort=${filters.sortField}${filters.sortDirection ? (`,${filters.sortDirection}`) : ''}` : ''
    const pageNumber = filters.pageNumber ? `&page=${filters.pageNumber - 1}` : ''
    const pageSize = filters.pageSize ? `&size=${filters.pageSize}` : ''
    const testNameFilter = filters.testName ? `&name=${encodeURIComponent(filters.testName)}` : ''
    const dateFromFilter = filters.dateFrom ? `&dateFrom=${encodeURIComponent(Utils.formatDateISOZ(filters.dateFrom))}` : ''
    const dateUntilFilter = filters.dateUntil ? `&dateUntil=${encodeURIComponent(Utils.formatDateISOZ(filters.dateUntil))}` : ''
    const statusFilter = filters.status ? `&status=${filters.status}` : ''
    const url = `/api/test-suite?${projectParam}${sortParams}${pageNumber}${pageSize}${testNameFilter}${dateFromFilter}${dateUntilFilter}${statusFilter}`

    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  getDetails = (testSuiteId, projectCode) => {
    const url = `/api/test-suite/${testSuiteId}?projectCode=${projectCode}`
    return axios.get(url, {}, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }
}

const instance = new TestSuiteService()
export default instance
