import { useEffect, useReducer } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { VictoryBar, VictoryChart, VictoryStack, VictoryTooltip } from 'victory'

import Spinner from 'components/spinner'
import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import { getData, getCount } from './actions'
import { initialState, reducer } from './reducer'
import style from './verification.module.css'

const inlineStyle = {
  periodSelection: { float: 'right', width: '140px', marginTop: '10px' },
  rightAlign: { textAlign: 'right' },
  chart: { parent: { maxWidth: '800px', margin: '0 auto' } },
}

const colorScale = ['#8c8c8e', '#3c8863', '#f7941e']

const Verification = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const errorHandler = useErrorHandler()
  const { projectCode, user } = useProject()

  useEffect(() => {
    getData(projectCode, user.email)
      .then(data => dispatch({ type: 'received', data }))
      .catch(errorHandler)
    getCount(projectCode, user.email)
      .then(({ totalSize }) => dispatch({ type: 'countReceived', count: totalSize }))
      .catch(errorHandler)
  }, [user, errorHandler, projectCode])
  const {
    loading, noVerifications, chart, categories, newCount,
  } = state
  return (
    <Card className={style.paperChartContainer}>
      <CardHeader title="My verification overview" subheader="Last week" />
      <Spinner spinning={loading}>
        <div>
          {noVerifications && (
            <div className={style.noVerifications}>
              <a>No verifications from last week!</a>
            </div>
          )}
          {!noVerifications && (
            <VictoryChart domainPadding={{ x: 50 }} width={800} style={inlineStyle.chart}>
              <VictoryStack categories={{ x: categories }} colorScale={colorScale}>
                {chart.map(it => (
                  <VictoryBar key={it.key} data={it.data} labelComponent={<VictoryTooltip />} />))}
              </VictoryStack>
            </VictoryChart>
          )}
          <div>
            {newCount === 0 && <p>No new executions.</p> }
            {newCount === 1 && <p>You have <Link to={`/${projectCode}/verification`}>an execution</Link> to verify.</p> }
            {newCount > 1 && <p>You have <Link to={`/${projectCode}/verification`}>{newCount} executions</Link> to verify.</p> }
          </div>
        </div>
      </Spinner>
    </Card>
  )
}

export default withRouter(Verification)
