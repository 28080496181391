import { Component } from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'

import { ProjectContext } from 'core/projectProvider/projectProvider'

class ProjectSelector extends Component {
  handleProjectSelection = (event) => {
    this.props.onProjectChanged(event.target.value)
  }

  render() {
    const { projectCode, projectList } = this.context
    return (
      <Select
        id="projectSelector"
        value={this.props.selectedProjectCode}
        onChange={this.handleProjectSelection}
      >
        {projectList
          .filter(project => project.enabled)
          .filter(project => project.code !== projectCode)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(project => (
            <MenuItem key={project.code} value={project.code}>
              {project.name}
            </MenuItem>
          ))}
      </Select>
    )
  }
}

ProjectSelector.propTypes = {
  selectedProjectCode: PropTypes.string.isRequired,
  onProjectChanged: PropTypes.func.isRequired,
}

ProjectSelector.contextType = ProjectContext

export default ProjectSelector
