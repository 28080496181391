import { Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'

import DataField from 'components/executionDetails/dataField'

import style from './style.module.css'

const withDefaults = testplan => ({
  testName: get(testplan, 'testName') || '',
  owner: get(testplan, 'owner') || '',
  rootFolderId: get(testplan, 'rootFolderId') || '',
  folderName: get(testplan, 'folderName') || '',
  subfolderName: get(testplan, 'subfolderName') || '',
  description: get(testplan, 'description') || '',
  customFields: get(testplan, 'customFields') || [],
})

class TestPlanEditor extends Component {
  constructor(props) {
    super(props)
    this.state = withDefaults(props.testPlan)
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    onSubmit({ ...this.state })
  }

  handleChange = (field, value) => {
    this.setState(preState => ({ customFields: { ...preState.customFields, [field]: value } }))
  }

  render() {
    const { customFields, onCancel } = this.props
    const { testName } = this.state
    const { rootFolderId } = this.state
    const { folderName } = this.state
    const { subfolderName } = this.state
    const { description } = this.state
    const { owner } = this.state
    return (
      <Dialog open onClose={onCancel} fullWidth maxWidth="sm">
        <DialogTitle>Test Plan Details</DialogTitle>
        <DialogContent>
          <form onSubmit={this.handleSubmit} className={style.ticketIdForm}>
            <DataField name="Test Name">
              <TextField
                value={testName}
                name="testName"
                fullWidth
                onChange={e => this.setState({ testName: e.target.value })}
              />
            </DataField>
            <DataField name="Root Folder ID">
              <TextField
                value={rootFolderId}
                name="rootFolderId"
                fullWidth
                onChange={e => this.setState({ rootFolderId: e.target.value })}
              />
            </DataField>
            <DataField name="Folder Name">
              <TextField
                value={folderName}
                name="folderName"
                fullWidth
                onChange={e => this.setState({ folderName: e.target.value })}
              />
            </DataField>
            <DataField name="Subfolder Name">
              <TextField
                value={subfolderName}
                name="subfolderName"
                fullWidth
                onChange={e => this.setState({ subfolderName: e.target.value })}
              />
            </DataField>
            <DataField name="Description">
              <TextField
                value={description}
                name="description"
                fullWidth
                multiline
                rows={3}
                onChange={e => this.setState({ description: e.target.value })}
              />
            </DataField>
            <DataField name="Owner">
              <TextField
                value={owner}
                name="owner"
                fullWidth
                onChange={e => this.setState({ owner: e.target.value })}
              />
            </DataField>
            { customFields.map(field => (
              <DataField name={field} key={field}>
                <TextField
                  id={`${field}Btn`}
                  value={this.state.customFields[field] || ''}
                  onChange={e => this.handleChange(field, e.target.value)}
                />
              </DataField>
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="saveBtn" color="primary" onClick={this.handleSubmit}>Save</Button>
          <Button id="cancelBtn" onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TestPlanEditor.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  testPlan: PropTypes.shape({
    testName: PropTypes.string,
    owner: PropTypes.string,
    rootFolderId: PropTypes.string,
    folderName: PropTypes.string,
    subfolderName: PropTypes.string,
    description: PropTypes.string,
    customFields: PropTypes.object,
  }),
  customFields: PropTypes.arrayOf(PropTypes.string).isRequired,
}

TestPlanEditor.defaultProps = {
  testPlan: null,
}

export default TestPlanEditor
