import { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import isEqual from 'lodash/isEqual'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import FormattedDate from 'components/formattedDate'
import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import Spinner from 'components/spinner'
import TestStatusIcon from 'components/testStatusIcon'
import TrimmedText from 'components/trimmedText'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'
import Table from 'components/table'
import calcTotalPages from 'utils/calcTotalPages'

import * as Actions from './actions'
import Store from './store'
import style from './style.module.css'


class HpqcUploadedReport extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = Store.getState()
  }

  componentDidMount = () => {
    const { projectCode } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Executions uploaded to HPQC'
    Store.on('change', this.updateState)
    Actions.retrieveReport(projectCode, query)
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      const { projectCode } = this.context
      const { query } = this.props
      Actions.updateReport(projectCode, query)
      ReactTooltip.hide()
    }
    ReactTooltip.rebuild()
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const {
      error, loading, items, defaultPageSize, totalSize,
    } = this.state
    const columns = [
      {
        Header: '',
        accessor: 'testExecutionStatus',
        Cell: item => <TestStatusIcon status={item.value} />,
        width: 48,
        disableResizing: true,
      },
      {
        Header: 'Test Name',
        accessor: 'executionId',
        Cell: item => (
          <Link to={`/${match.params.projectCode}/detail/${item.value}`}>
            <TrimmedText text={item.row.original.name} maxLength={30} />
          </Link>
        ),
      },
      {
        Header: 'Test Set Id',
        accessor: 'testSetId',
      },
      {
        Header: 'Test Plan Id',
        accessor: 'externalEntityId',
      },
      {
        Header: 'Upload time',
        accessor: 'uploadTime',
        Cell: item => <FormattedDate value={item.value} />,
      },
      {
        Header: 'Uploaded status',
        accessor: 'uploadedStatus',
      },
      {
        Header: 'Execution result',
        accessor: 'errorMessage',
        Cell: item => (item.value ? <UploadFailedIcon message={item.value} /> : <UploadSuccessfulIcon />),
      },
    ]

    if (error != null) {
      return HpqcUploadedReport.renderError(error)
    }
    const { match, query } = this.props
    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

    return (
      <Spinner spinning={loading}>
        <Card className={style.card}>
          <div className={style.hpqcContainer}>
            <CardHeader title="Executions uploaded to HPQC" />
            <Table
              id="hpqcUploadedReport"
              columns={columns}
              data={items}
              totalPages={calcTotalPages({ totalSize, pageSize })}
            />
          </div>
        </Card>
      </Spinner>
    )
  }
}

HpqcUploadedReport.renderError = message => (
  <div className={style.error}>
    <a>{message}</a>
  </div>
)

HpqcUploadedReport.contextType = ProjectContext

HpqcUploadedReport.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withQuery(withRouter(HpqcUploadedReport))
