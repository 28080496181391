import { Component } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'
import style from './excelImportSettings.module.css'

const emptyPart = { column: '', withHeader: true, newLineBreak: true }

class AggregateDetails extends Component {
  state = { newPart: { ...emptyPart } }

  handleCancel = () => {
    this.props.onPartCancel()
    this.setState({ newPart: { ...emptyPart } })
  }

  handleAddPart = () => {
    this.props.onPartAdd(this.state.newPart)
    this.setState({ newPart: { ...emptyPart } })
  }

  handleChange = (field, value) => {
    this.setState(prevState => ({ newPart: { ...prevState.newPart, [field]: value } }))
  }

  render() {
    const {
      addPart, aggregates, onPartDelete, onMovePartDown, onMovePartUp,
    } = this.props
    if (aggregates.length === 0 && !addPart) {
      return null
    }
    return (
      <div className={style.aggregateRow}>
        {aggregates.map(it => (
          <div key={it.column}>
            <div className={style.partColumn}>{it.column}</div>
            <div className={style.withHeaderColumn}><Checkbox checked={it.withHeader} label="with header" disabled /></div>
            <div className={style.withHeaderColumn}><Checkbox checked={it.newLineBreak} label="with line break" disabled /></div>
            <div className={style.detailButtonsColumn}>
              <IconButton id="deleteBtn" onClick={() => onPartDelete(it.column)} src="/images/delete.svg" />
              <IconButton id="downBtn" onClick={() => onMovePartDown(it.column)} src="/images/downarrow.svg" />
              <IconButton id="upBtn" onClick={() => onMovePartUp(it.column)} src="/images/uparrow.svg" />
            </div>
          </div>
        ))}
        {addPart
        && (
          <div key="new">
            <div className={style.partColumn}>
              <TextField
                name="column"
                fullWidth
                id="columnField"
                label="Excel column header"
                style={{ height: '36px', lineHeight: '14px', fontSize: '14px' }}
                value={this.state.newPart.column}
                onChange={e => this.handleChange('column', e.target.value)}
              />
            </div>
            <div className={style.withHeaderColumn}>
              <Checkbox
                id="withHeaderField"
                label="with header"
                checked={this.state.newPart.withHeader}
                onChange={e => this.handleChange('withHeader', e.target.checked)}
              />
            </div>
            <div className={style.withHeaderColumn}>
              <Checkbox
                id="withLineBreakField"
                label="with line break"
                checked={this.state.newPart.newLineBreak}
                onChange={e => this.handleChange('newLineBreak', e.target.checked)}
              />
            </div>
            <div className={style.detailButtonsColumn}>
              <IconButton id="acceptBtn" src="/images/accept.svg" onClick={this.handleAddPart} />
              <IconButton id="cancelBtn" src="/images/cancel.svg" onClick={this.handleCancel} />
            </div>
          </div>
        )}
      </div>
    )
  }
}

AggregateDetails.propTypes = {
  aggregates: PropTypes.arrayOf(PropTypes.object).isRequired,
  addPart: PropTypes.bool.isRequired,
  onPartAdd: PropTypes.func.isRequired,
  onPartCancel: PropTypes.func.isRequired,
  onPartDelete: PropTypes.func.isRequired,
  onMovePartDown: PropTypes.func.isRequired,
  onMovePartUp: PropTypes.func.isRequired,
}

export default AggregateDetails
