import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'
import * as Utils from 'components/utils'


class HpqcUploadedStore extends ReportingEventEmitter {
  state = {
    items: [],
    loading: true,
    defaultPageSize: 20,
  }

  mapStatus = (status) => {
    const item = Utils.hpqcStatuses.find(it => it.id === status)
    return item ? item.name : ''
  }

  handleActions(action) {
    switch (action.type) {
    case events.hpqcUploadReport.listReceived: {
      this.state.totalSize = action.totalSize
      this.state.items = action.items.map(item => ({ ...item, uploadStatus: this.mapStatus(item.uploadStatus) }))
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.hpqcUploadReport.listExpired: {
      this.state.loading = true
      this.emitChange()
      break
    }
    case events.hpqcUploadReport.failed: {
      this.state = { error: action.error }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcUploadedStore()
dispatcher.register(store.handleActions.bind(store))

export default store
