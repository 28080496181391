const fields = {
  column1: [
    { code: 'id', text: 'ID' },
    { code: 'suiteName', text: 'Feature name' },
    { code: 'testName', text: 'Scenario name' },
    { code: 'startTime', text: 'Feature start time' },
    { code: 'endTime', text: 'Feature end time' },
    { code: 'numberOfScenarios', text: 'Number of scenarios' },
    { code: 'result', text: 'Scenario result' },
    { code: 'errorCategory', text: 'Error category' },
    { code: 'errorCategoryLabels', text: 'Error category labels' },
    { code: 'tester', text: 'Tester' },
    { code: 'durationInSeconds', text: 'Test duration (in seconds)' },
    { code: 'steps', text: 'List of steps' },
    { code: 'failedStep', text: 'Failed step' },
    { code: 'link', text: 'Link' },
    { code: 'intaqtVersion', text: 'intaQt Version' },
  ],
  column2: [
    { code: 'parties', text: 'All Parties' },
    { code: 'defects', text: 'Defects' },
    { code: 'metadata', text: 'Metadata' },
    { code: 'verificationState', text: 'Verification State' },
    { code: 'verifiedBy', text: 'Verified by' },
    { code: 'verificationTime', text: 'Verification Time' },
    { code: 'approvalState', text: 'Approval State' },
    { code: 'approvalAction', text: 'Approval Change Reason' },
    { code: 'approvalChangedBy', text: 'Approval Change By' },
    { code: 'approvalLastChanged', text: 'Approval Change At' },
    { code: 'almState', text: 'ALM State' },
    { code: 'almComment', text: 'ALM Comment' },
    { code: 'almUploadState', text: 'ALM Upload State' },
  ],
}

export default fields
