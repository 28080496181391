import ErrorCategoryService from 'services/errorCategoryService'
import { dispatcher, events } from 'core/dispatcher'

export default function retrieveInitialData(project, query) {
  ErrorCategoryService.getHistory(project, query.date)
    .then((response) => {
      dispatcher.dispatch({
        type: events.errorCategoryHistory.listReceived,
        list: response.data,
        date: query.date,
      })
    })
    .catch((error) => {
      dispatcher.dispatch({ type: events.errorCategoryHistory.listFailed, error })
    })
}
