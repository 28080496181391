import style from './header.module.scss'

const Title = () => (
  <div className={style.title}>
    <img src="/images/logo.png" alt="ConQlude Logo" className={style.logo} />
    QiTASC ConQlude
    <sup>®</sup>
  </div>
)


export default Title
