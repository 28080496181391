import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const appendFullName = attachment => ({
  ...attachment,
  fullName: attachment.location ? `${attachment.location}/${attachment.name}` : attachment.name,
})

class AttachmentButtonStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      attachments: [],
      removedAttachments: [],
      previewUrl: null,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.attachmentList.received: {
      this.state.loading = false
      this.state.error = null
      this.state.attachments = action.attachments
        .filter(it => !it.removed)
        .map(appendFullName)
      this.state.removedAttachments = action.attachments
        .filter(it => it.removed)
        .map(appendFullName)
      this.state.previewFile = null
      this.emitChange()
      break
    }
    case events.attachmentList.removeUndone:
    case events.attachmentList.removed: {
      this.state.loading = true
      this.state.attachments = []
      this.state.removedAttachments = []
      this.emitChange()
      break
    }
    case events.attachmentList.preview: {
      this.state.previewFile = action.file
      this.state.previewName = action.name
      this.state.previewType = action.mimetype
      this.emitChange()
      break
    }
    case events.attachmentList.closePreview: {
      this.state.previewFile = null
      this.state.previewName = null
      this.state.previewType = null
      this.emitChange()
      break
    }
    case events.attachmentList.failed: {
      this.state.error = action.error
      this.state.loading = false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const attachmentButtonStore = new AttachmentButtonStore()
dispatcher.register(attachmentButtonStore.handleActions.bind(attachmentButtonStore))

export default attachmentButtonStore
