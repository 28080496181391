import axios from 'axios'
import * as Common from 'services/common'

class SuccessConditionService {
    findAllConfigs = (project) => {
      const url = `/api/project/${project}/configuration/success-condition`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    updateConfig = (condition, project) => {
      const url = `/api/project/${project}/configuration/success-condition/${condition.id}`
      return axios.post(url, condition, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    addConfig = (condition, project) => {
      const url = `/api/project/${project}/configuration/success-condition`
      return axios.post(url, condition, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    delete = (project, id) => {
      const url = `/api/project/${project}/configuration/success-condition/${id}`
      return axios.delete(url, {}, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    getByExecution = (project, execution) => {
      const url = `/api/project/${project}/execution/${execution}/success-condition`
      return axios.get(url, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }

    updateCondition = ({
      project, execution, condition, state, comment,
    }) => {
      const request = {
        state,
        comment,
      }
      const url = `/api/project/${project}/execution/${execution}/success-condition/${condition}`
      return axios.post(url, request, Common.requestOptions)
        .then(response => response.data)
        .catch(Common.catchError)
    }
}

const instance = new SuccessConditionService()
export default instance
