import { useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import IconButton from 'components/iconButton'

import style from './style.module.css'

const EditRow = ({ project, add, onCancel, onSubmit }) => {
  const [state, setState] = useState({ project, add })

  const handleSubmit = () => {
    const { code, name, enabled } = state.project
    let errorsFound = false
    if (!code) {
      setState({ ...state, codeError: 'Code is required' })
      errorsFound = true
    } else if (code.length < 3) {
      setState({ ...state, codeError: 'Code must be at least 3 chars' })
      errorsFound = true
    } else if (code.length > 20) {
      setState({ ...state, codeError: 'Code must be shorter than 20 chars' })
      errorsFound = true
    }
    if (!name) {
      setState({ ...state, nameError: 'Name is required' })
      errorsFound = true
    } else if (name && name.length > 50) {
      setState({ ...state, nameError: 'Name must be shorter than 50 chars' })
      errorsFound = true
    }
    if (!errorsFound) {
      onSubmit(code, name, enabled)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.categoryRow}>
        <div className={style.statusColumn}>
          <Checkbox
            id="enabledCheckBox"
            checked={state.project.enabled}
            onChange={e => setState({ ...state, project: { ...state.project, enabled: e.target.checked } })}
          />
        </div>
        <div className={style.codeColumn}>
          { state.add
            ? (
              <TextField
                id="codeField"
                fullWidth
                label={state.codeError || 'Code'}
                error={state.codeError != null}
                value={state.project.code}
                onChange={e => setState({ ...state, project: { ...state.project, code: e.target.value }, codeError: null })}
              />
            )
            : state.code }
        </div>
        <div className={style.nameColumn}>
          <TextField
            id="nameButton"
            fullWidth
            label={state.nameError || 'Name'}
            error={state.nameError != null}
            value={state.project.name}
            onChange={e => setState({ ...state, project: { ...state.project, name: e.target.value }, nameError: null })}
          />
        </div>
        <div className={style.dateColumn}>{state.project.creationTime}</div>
        <div className={style.buttonColumn}>
          <IconButton id="acceptButton" src="/images/accept.svg" onClick={handleSubmit} />
          <IconButton id="cancelButton" src="/images/cancel.svg" onClick={onCancel} />
        </div>
      </div>
    </form>
  )
}

EditRow.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  project: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    enabled: PropTypes.bool,
    creationTime: PropTypes.string,
  }),
  add: PropTypes.bool,
}

EditRow.defaultProps = {
  add: false,
  project: {
    code: '', name: '', enabled: true, creationTime: '',
  },
}

export default EditRow
