import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'

import DataField from 'components/executionDetails/dataField'
import style from './settings.css'

const validateSettings = (settings) => {
  let errors = {}
  if (settings.enabled && (!settings.address || settings.address.length === 0)) {
    errors = { ...errors, address: 'Cannot be empty' }
  }
  if (settings.enabled && (!settings.authToken || settings.authToken.length === 0)) {
    errors = { ...errors, authToken: 'Cannot be empty' }
  }

  // eslint-disable-next-line eqeqeq
  if (settings.port && (settings.port != parseInt(settings.port, 10))) {
    errors = { ...errors, port: 'Must be a number' }
  }
  return errors
}

const Settings = ({ settings, onSave }) => {
  const [state, setState] = useState({ ...settings, errors: {} })

  const updateSettings = (s) => {
    const errors = validateSettings(s)
    setState({ ...s, errors })
  }
  return (
    <div className={style.content}>
      <FormControlLabel
        label="Enabled"
        control={(
          <Checkbox
            id="enabledField"
            checked={state.enabled}
            onChange={e => updateSettings({ ...state, enabled: e.target.checked })}
          />
        )}
      />
      <DataField name="Address">
        <TextField
          id="addressField"
          fullWidth
          value={state.address}
          error={state.errors.address != null}
          label={state.errors.address}
          onChange={e => updateSettings({ ...state, address: e.target.value })}
        />
      </DataField>
      <DataField name="Port">
        <TextField
          id="port"
          fullWidth
          value={state.port}
          error={state.errors.port != null}
          label={state.errors.port}
          onChange={e => updateSettings({ ...state, port: e.target.value })}
        />
      </DataField>
      <DataField name="Allow self-signed certificates">
        <Switch
          id="allowSelfSigned"
          checked={state.allowSelfSignedCertificates}
          label={state.errors.allowSelfSignedCertificates}
          onChange={e => updateSettings({ ...state, allowSelfSignedCertificates: e.target.checked })}
        />
      </DataField>
      <DataField name="Authentication Token">
        <TextField
          id="authTokenField"
          fullWidth
          value={state.authToken}
          error={state.errors.authToken != null}
          label={state.errors.authToken}
          onChange={e => updateSettings({ ...state, authToken: e.target.value })}
        />
      </DataField>
      <div className={style.buttonRow}>
        <Button
          variant="contained"
          id="saveBtn"
          color="primary"
          onClick={() => onSave(state)}
          disabled={!(Object.keys(state.errors).length === 0 && state.errors.constructor === Object)}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

Settings.propTypes = {
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
}

export default Settings
