import { useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import DataField from 'components/executionDetails/dataField'
import style from './settings.module.css'

const validateSettings = (settings) => {
  let errors = {}
  if (settings.enabled && (!settings.baseUrl || settings.baseUrl.length === 0)) {
    errors = { ...errors, baseUrl: 'Base URL cannot be empty' }
  }
  if (settings.enabled && (!settings.authToken || settings.authToken.length === 0)) {
    errors = { ...errors, authToken: 'Authentication token cannot be empty' }
  }
  return errors
}

const Settings = ({ settings, onSave }) => {
  const [state, setState] = useState({ ...settings, errors: {} })

  const updateSettings = (s) => {
    const errors = validateSettings(s)
    setState({ ...s, errors })
  }
  return (
    <div className={style.content}>
      <FormControlLabel
        label="Enabled"
        control={(
          <Checkbox
            id="enabledField"
            checked={state.enabled}
            onChange={e => updateSettings({ ...state, enabled: e.target.checked })}
          />
        )}
      />
      <DataField name="Url">
        <TextField
          id="urlField"
          fullWidth
          value={state.baseUrl}
          error={state.errors.baseUrl != null}
          label={state.errors.baseUrl}
          onChange={e => updateSettings({ ...state, baseUrl: e.target.value })}
        />
      </DataField>
      <FormControlLabel
        label="Enable Two-Factor-Authentication"
        control={(
          <Checkbox
            id="tfaEnabledField"
            checked={state.tfaEnabled}
            onChange={e => updateSettings({ ...state, tfaEnabled: e.target.checked })}
          />
        )}
      />
      <DataField name="Authentication Token">
        <TextField
          id="authTokenField"
          fullWidth
          value={state.authToken}
          error={state.errors.authToken != null}
          label={state.errors.authToken}
          onChange={e => updateSettings({ ...state, authToken: e.target.value })}
        />
      </DataField>
      <DataField name="Jira Project">
        <TextField
          id="jiraProjectField"
          fullWidth
          value={state.jiraProjectKey}
          error={state.errors.jiraProjectKey != null}
          label={state.errors.jiraProjectKey}
          onChange={e => updateSettings({ ...state, jiraProjectKey: e.target.value })}
        />
      </DataField>
      <DataField name="Socket Timeout (milliseconds)">
        <TextField
          id="socketTimeoutMillisField"
          fullWidth
          value={state.socketTimeoutMillis}
          error={state.errors.socketTimeoutMillis != null}
          label={state.errors.socketTimeoutMillis}
          onChange={e => updateSettings({ ...state, socketTimeoutMillis: e.target.value })}
        />
      </DataField>
      <div className={style.buttonRow}>
        <Button
          variant="contained"
          id="saveBtn"
          color="primary"
          onClick={() => onSave(state)}
          disabled={Object.keys(state.errors) > 0}
        >
          Save
        </Button>
      </div>
    </div>
  )
}

Settings.propTypes = {
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
}

export default Settings
