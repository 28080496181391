import { Redirect, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import useProject from 'hooks/useProject'

import ProjectProvider from './projectProvider/projectProvider'

const ProjectRedirector = () => (
  <ProjectProvider>
    <Redirector />
  </ProjectProvider>
)

const redirector = () => {
  const { projectCode } = useProject()
  const path = '/'.concat(encodeURIComponent(projectCode))

  return (
    <Switch>
      <Redirect from="/" to={path} />
    </Switch>
  )
}

const Redirector = withRouter(redirector)

export default ProjectRedirector
