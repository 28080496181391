import { Component } from 'react'
import PropTypes from 'prop-types'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'

import style from './style.module.scss'


class LabelChips extends Component {
  state = { showDialog: false, text: '' }

  handleAdd = () => {
    const { onAdd } = this.props
    const { text } = this.state
    this.setState({ showDialog: false, text: '' })
    onAdd(text)
  }

  render() {
    const { labels, onRemove } = this.props
    const { text, showDialog } = this.state
    return (
      <>
        { labels.map(label => (
          <Chip key={label} label={label} onDelete={() => onRemove(label)} className={style.labelChip} />
        ))}
        <IconButton id="addLabelButton" onClick={() => this.setState({ showDialog: true })} size="small">
          <AddCircleIcon />
        </IconButton>
        <Dialog
          id="addLabelDialog"
          open={showDialog}
          onClose={() => this.setState({ showDialog: false })}
        >
          <DialogTitle>Add Label to Error Category</DialogTitle>
          <DialogContent>
            <TextField
              label="Inset Label"
              value={text}
              autoFocus
              onChange={e => this.setState({ text: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button id="addLabelBtn" color="primary" disabled={text == null || text.length === 0} onClick={this.handleAdd}>Add</Button>
            <Button id="cancelBtn" onClick={() => this.setState({ showDialog: false })}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}

LabelChips.propTypes = {
  labels: PropTypes.arrayOf(PropTypes.string),
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

LabelChips.defaultProps = {
  labels: [],
}

export default LabelChips
