export default (url) => {
  fetch(url).then(async (res) => {
    const blob = await res.blob()
    const fileName = res.headers.get('content-disposition').split('filename="').pop().replace('"', '')

    const file = window.URL.createObjectURL(blob)

    const a = document.createElement('a')

    a.setAttribute('download', fileName)
    a.setAttribute('href', file)
    a.click()
    a.remove()
  })
}
