import { useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

import DateFilter from 'components/reports/common/widgets/dateFilter'
import StringFilter from 'components/reports/common/widgets/stringFilter'
import StatusFilter from 'components/reports/common/widgets/statusFilter'
import ConditionsStateFilter from 'components/reports/common/widgets/conditionsStateFilter'

import style from './style.module.scss'
import TesterFilter from './widgets/testerFilter'
import ErrorCategoryFilter from './widgets/errorCategoryFilter'
import UploadStatusFilter from './widgets/uploadStatusFilter'
import MetadataFilter from './widgets/metadataFilter'


const TestRunReportDrawer = ({
  open, testers, labels, errorCategories, filters, redirect,
}) => {
  const handleDateFilter = useCallback((from, until) => {
    redirect({ dateFrom: from, dateUntil: until, pageNumber: 1 })
  }, [redirect])

  const handleErrorCategoryFilter = useCallback((error, labelValue) => {
    redirect({ errorCategory: error, label: labelValue, pageNumber: 1 })
  }, [redirect])

  const handleMetadataFilter = useCallback(({ name, value }) => {
    redirect({ metadataName: name, metadataValue: value })
  }, [redirect])

  const useFilterChange = filter => useCallback((value) => {
    const newFilter = {}
    newFilter[filter] = value
    newFilter.pageNumber = 1
    redirect(newFilter)
  }, [filter, redirect])

  return (
    <>
      <Box className={`${style.drawer} ${open ? '' : style.hidden}`}>
        <div>
          <StringFilter id="testNameFilter" onSubmit={useFilterChange('testName')} field="Test Name" initialValue={filters.testName} />
          <TesterFilter onSubmit={useFilterChange('tester')} testers={testers} initialValue={filters.tester} />
          <DateFilter onSubmit={handleDateFilter} initialDateFrom={filters.dateFrom} initialDateUntil={filters.dateUntil} />
          <StatusFilter onSubmit={useFilterChange('status')} initialValue={filters.status} />
          <ConditionsStateFilter onSubmit={useFilterChange('conditionsState')} initialValue={filters.conditionsState} />
          <StringFilter id="defectFilter" onSubmit={useFilterChange('defect')} field="Defect" initialValue={filters.defect} />
          <ErrorCategoryFilter
            categories={errorCategories}
            labels={labels}
            initialCategory={filters.errorCategory}
            initialLabel={filters.label}
            onSubmit={handleErrorCategoryFilter}
          />
          <StringFilter id="intaqtVersionFilter" onSubmit={useFilterChange('intactVersion')} field="intaQt Version" initialValue={filters.intactVersion} />
          <StringFilter id="tagFilter" onSubmit={useFilterChange('tag')} field="Tag" initialValue={filters.tag} />
          <UploadStatusFilter onSubmit={useFilterChange('uploadStatus')} initialValue={filters.uploadStatus} />
          <MetadataFilter onSubmit={handleMetadataFilter} initialName={filters.metadataName} initialValue={filters.metadataValue} />
        </div>
      </Box>
    </>
  )
}

TestRunReportDrawer.propTypes = {
  redirect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  testers: PropTypes.arrayOf(PropTypes.object),
  labels: PropTypes.arrayOf(PropTypes.string),
  errorCategories: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.shape().isRequired,
}

TestRunReportDrawer.defaultProps = {
  testers: [],
  labels: [],
  errorCategories: [],
}

export default TestRunReportDrawer
