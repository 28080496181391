import { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'
import Link from 'components/common/link'
import TestStatusIcon from './testStatusIcon'
import style from './style.module.css'

const Title = ({ link, ...otherProps }) => (
  link ? (
    <Link to={link}>
      <TitleWithoutLink {...otherProps} />
    </Link>
  ) : (
    <TitleWithoutLink {...otherProps} />
  ))

const TitleWithoutLink = ({
  status,
  conditionsState,
  name,
  stepDetails,
  onEditingSubmit,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [newName, setNewName] = useState(name)
  const renderedName = newName !== name ? newName : name

  const onSubmit = () => {
    onEditingSubmit(newName)
    setIsEditing(false)
  }

  const cancelEditing = () => {
    setIsEditing(false)
    setNewName(name)
  }

  const renderEditingBtns = () => {
    if (onEditingSubmit) {
      return isEditing ? (
        <div className={style.buttonsEditingContainer}>
          <IconButton src="/images/accept.svg" onClick={onSubmit} />
          <IconButton src="/images/cancel.svg" onClick={cancelEditing} />
        </div>
      ) : (
        <div className={style.editBtnContainer}>
          <IconButton onClick={() => setIsEditing(true)} src="/images/edit.svg" />
        </div>
      )
    }

    return null
  }

  return (
    <>
      <TestStatusIcon status={status} conditionsState={conditionsState} large className={style.statusIcon} />
      {isEditing
        ? <TextField value={newName} onChange={e => setNewName(e.target.value)} autoFocus />
        : <h1 className={style.headline} title={renderedName}>{renderedName}</h1>}
      {renderEditingBtns()}
      { stepDetails && (
        <h2 className={style.stepDetails}>{stepDetails}</h2>
      )}
    </>
  )
}

const propTypes = {
  status: PropTypes.string,
  conditionsState: PropTypes.string,
  name: PropTypes.string.isRequired,
  stepDetails: PropTypes.string,
  onEditingSubmit: PropTypes.func,
}

const defaultProps = {
  status: null,
  conditionsState: 'PASSED',
  stepDetails: null,
  onEditingSubmit: null,
}

TitleWithoutLink.propTypes = propTypes

TitleWithoutLink.defaultProps = defaultProps

Title.propTypes = {
  ...propTypes,
  link: PropTypes.string,
}

Title.defaultProps = {
  ...defaultProps,
  link: null,
}

export default Title
