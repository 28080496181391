import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import Title from 'components/header/title'
import useProject from 'hooks/useProject'

import style from './header.module.scss'
import TabBar from './tabBar'
import Notifications from './notifications'
import ProjectSelector from './projectSelector'
import User from './user'
import MobileMenu from './mobileMenu'

const Header = () => {
  const { user } = useProject()

  return (
    <AppBar position="static" color="default" className={style.appbar}>
      <Toolbar className={style.toolbar}>
        <MobileMenu />
        <Title />
        <TabBar />
        <div className={style.topbar}>
          <ProjectSelector />
          <Notifications />
          <User name={user.name} />
        </div>
      </Toolbar>
    </AppBar>
  )
}


export default Header
