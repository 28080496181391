import { useEffect, useReducer } from 'react'

import useErrorHandler from 'core/hooks'
import useProject from 'hooks/useProject'

import ConnectionSettings from './connection'
import Layout from './layout'
import reducer from './reducer'
import { retrieve, save } from './actions'

const defaultSettings = {
  enabled: false,
  url: '',
  domain: '',
  project: '',
  uploadStepsEnabled: false,
  autoUpload: false,
  schedule: '',
  delayBeforeAutoSubmit: '',
}

const initialState = {
  loading: true,
  showSuccessMessage: false,
  settings: defaultSettings,
}


const HpqcSettings = () => {
  const handleError = useErrorHandler()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { projectCode } = useProject()

  useEffect(() => {
    document.title = 'QiTASC ConQlude - HPQC Connection Settings'
    retrieve(projectCode)
      .then(settings => dispatch({ type: 'loaded', settings, defaultSettings }))
      .catch(handleError)
  }, [handleError, projectCode])

  const handleSave = (settings) => {
    save(projectCode, settings)
      .then(() => dispatch({ type: 'saved', settings }))
      .catch(handleError)
    dispatch({ type: 'submitted' })
  }

  return (
    <Layout loading={state.loading} showSuccessMessage={state.showSuccessMessage}>
      <ConnectionSettings
        initialSettings={state.settings}
        onSave={handleSave}
      />
    </Layout>
  )
}

export default HpqcSettings
