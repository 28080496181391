import { Component } from 'react'
import PropTypes from 'prop-types'

import style from './style.module.css'

const inlineStyle = {
  visible: {
    visibility: 'visible',
  },
  hidden: {
    visibility: 'hidden',
  },
}

class Delete extends Component {
  constructor(props) {
    super(props)
    this.state = { hovered: false }
  }

  render() {
    const { onClick } = this.props
    const { hovered } = this.state
    return (
      <span
        role="button"
        tabIndex={0}
        className={style.buttonRole}
        onKeyPress={() => onClick()}
        onClick={() => onClick()}
        onMouseOver={() => this.setState({ hovered: true })}
        onFocus={() => this.setState({ hovered: true })}
        onMouseOut={() => this.setState({ hovered: false })}
        onBlur={() => this.setState({ hovered: false })}
      >
        <img
          src="/images/delete.svg"
          alt="Delete"
          className={style.icon}
          style={hovered ? inlineStyle.visible : inlineStyle.hidden}
        />
      </span>
    )
  }
}

Delete.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default Delete
