import { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'

import style from './testCategories.module.css'

class EditRow extends Component {
  constructor(props) {
    super(props)
    this.state = props.testCategory
  }

  handleSave = () => {
    const { name, tag } = this.state
    let isValid = true
    if (!name) {
      this.setState({ nameError: 'Name cannot be empty' })
      isValid = false
    }
    if (!tag) {
      this.setState({ tagError: 'Tag cannot be empty' })
      isValid = false
    }
    if (isValid) {
      const { onSave } = this.props
      onSave(this.state)
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.handleSave()
  }

  render() {
    const { onCancel } = this.props
    const { nameError, tagError, name, tag } = this.state
    return (
      <form onSubmit={this.handleSubmit}>
        <div className={style.categoryRow}>
          <div className={style.nameColumn}>
            <TextField
              name="addName"
              fullWidth
              value={name}
              label={nameError || 'Name'}
              error={nameError != null}
              onChange={e => this.setState({ name: e.target.value, nameError: null })}
            />
          </div>
          <div className={style.tagColumn}>
            <TextField
              name="addTag"
              fullWidth
              value={tag}
              label={tagError || 'Tag'}
              error={tagError != null}
              onChange={e => this.setState({ tag: e.target.value, tagError: null })}
            />
          </div>
          <div className={style.buttonColumn}>
            <IconButton src="/images/accept.svg" onClick={this.handleSave} />
            <IconButton src="/images/cancel.svg" onClick={onCancel} />
          </div>
        </div>
      </form>
    )
  }
}

EditRow.propTypes = {
  testCategory: PropTypes.shape({ name: PropTypes.string, tag: PropTypes.string, id: PropTypes.string }),
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}

EditRow.defaultProps = { testCategory: { name: '', tag: '' } }

export default EditRow
