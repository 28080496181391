import { dispatcher, events } from 'core/dispatcher'
import HpqcService from 'services/hpqc/hpqcService'


function dispatchList(listLoading) {
  listLoading.then((result) => {
    dispatcher.dispatch({ type: events.configurationHpqcCustomFields.dataReceived, list: result.customFields })
  }).catch((message) => {
    dispatcher.dispatch({ type: events.configurationHpqcCustomFields.failed, message })
  })
}

function updateFields(project) {
  return (customFields) => {
    dispatchList(HpqcService.updateCustomFields(project, customFields))
  }
}

export function retrieveData(project) {
  dispatchList(HpqcService.retriveHpqcSettings(project))
}

export function handleChange(newItem) {
  dispatcher.dispatch({ type: events.configurationHpqcCustomFields.changed, newItem })
}

export function handleAdd() {
  dispatcher.dispatch({ type: events.configurationHpqcCustomFields.addNew })
}

export function handleCancel() {
  dispatcher.dispatch({ type: events.configurationHpqcCustomFields.cancelEdit })
}

export function handleDelete({ project, internalName }) {
  dispatcher.dispatch({ type: events.configurationHpqcCustomFields.deleted, save: updateFields(project), internalName })
}

export function save(project) {
  dispatcher.dispatch({
    type: events.configurationHpqcCustomFields.saved,
    save: updateFields(project),
  })
}
