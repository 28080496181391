import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import Spinner from 'components/spinner'
import withPaging from 'components/reports/withPaging'
import withQuery from 'components/withQuery'
import { ProjectContext } from 'core/projectProvider/projectProvider'

import * as Actions from './actions'
import List from './list'
import Store from './store'

const ListWithPaging = withPaging(List)

class JiraUploads extends Component {
  state = Store.getState()

  componentDidMount = () => {
    const { projectCode } = this.context
    const { query } = this.props
    document.title = 'QiTASC ConQlude - Executions uploaded to Jira'
    Store.on('change', this.updateState)
    Actions.retrieveReport(projectCode, query)
  }

  componentDidUpdate = (prevProps) => {
    const { projectCode } = this.context
    const { query } = this.props
    if (prevProps.query.pageNumber !== query.pageNumber) {
      Actions.updateReport(projectCode, query)
    }
  }

  componentWillUnmount = () => {
    Store.removeListener('change', this.updateState)
  }

  updateState = () => {
    this.setState(Store.getState())
  }

  render() {
    const {
      error, loading, items, defaultPageSize, totalSize,
    } = this.state
    if (error != null) {
      throw Error(error)
    }
    const { match, query } = this.props
    const pageSize = query.pageSize ? Number.parseInt(query.pageSize, 10) : defaultPageSize

    return (
      <Spinner spinning={loading}>
        <ListWithPaging
          projectCode={match.params.projectCode}
          pageSize={pageSize}
          totalSize={totalSize}
          items={items}
        />
      </Spinner>
    )
  }
}

JiraUploads.contextType = ProjectContext

JiraUploads.propTypes = {
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
  query: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default withQuery(withRouter(JiraUploads))
