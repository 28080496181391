import { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'

import style from './project.module.css'

class NewPatternRow extends Component {
  state = { pattern: '', error: null }

  handleSubmit = (e) => {
    e.preventDefault()
    const { onSubmit, validate } = this.props
    const error = validate(this.state.pattern)
    if (error == null) {
      onSubmit(this.state.pattern)
      this.setState({ pattern: '', error: null })
    } else {
      this.setState({ error })
    }
  }

  render() {
    const { pattern, error } = this.state
    return (
      <div className={style.newPatternRow}>
        <form onSubmit={this.handleSubmit}>
          <TextField
            id="patternField"
            value={pattern}
            fullWidth
            label={error || 'Enter new pattern'}
            error={error != null}
            onChange={e => this.setState({ pattern: e.target.value })}
          />
        </form>
        <IconButton onClick={this.handleSubmit} src="/images/add.svg" />
      </div>
    )
  }
}

NewPatternRow.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
}

export default NewPatternRow
