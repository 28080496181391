import PropTypes from 'prop-types'

import asLine from './line'
import style from './style.module.scss'


const ExampleLine = ({
  values, errorCategory, onSelect, index, selected,
}) => {
  const separator = <a className={style.separator}>|</a>
  const notAvailableLine = index ? <a> Parameters not available </a> : ''
  const text = values.length === 0 ? notAvailableLine
    : values.reduce((all, c) => all.concat(<a className={style.exampleParameter}> {c} </a>).concat(separator), [separator])
      .map((comp, i) => ({ ...comp, key: { i } }))
  const showHandIcon = !selected && index
  return (
    <div
      role="menuitem"
      tabIndex={0}
      className={style.example + (selected ? ` ${style.selectedExample}` : '')}
      onClick={() => onSelect(index)}
      onKeyPress={() => onSelect(index)}
    >
      { showHandIcon && <img src="/images/pointer.svg" alt="click" /> }
      { text }
      { errorCategory && <span className={style.testErrorCategory}>{errorCategory}</span>}
    </div>
  )
}

ExampleLine.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  index: PropTypes.number,
  errorCategory: PropTypes.string,
}

ExampleLine.defaultProps = {
  errorCategory: null,
  index: null,
  selected: false,
}

export default asLine(ExampleLine, 'detail')
