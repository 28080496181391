import moment from 'moment'
import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

const DEFAULT_PAGE_SIZE = 20

const defaultDate = () => moment().subtract(30, 'days')


class TestSuiteHistoryStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      days: [],
      testSuitesHistory: [],
      date: defaultDate(),
      pageSize: DEFAULT_PAGE_SIZE,
      defaultPageSize: DEFAULT_PAGE_SIZE,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.testSuiteHistory.listReceived: {
      this.state.totalSize = action.totalSize
      this.state.testSuitesHistory = action.list
      this.state.days = action.days
      this.state.name = action.name
      this.state.date = action.date ? moment(action.date) : defaultDate()
      this.state.pageNumber = action.pageNumber ? Number.parseInt(action.pageNumber, 10) : null
      this.state.pageSize = action.pageSize ? Number.parseInt(action.pageSize, 10) : DEFAULT_PAGE_SIZE
      this.state.loading = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.testSuiteHistory.filterChanged: {
      const { date, ...newFilters } = action.filters
      const newDate = date ? moment(date) : this.state.date
      this.state = { ...this.state, ...newFilters, date: newDate }
      this.emitChange()
      break
    }
    case events.testSuiteHistory.listFailed: {
      this.state.error = action.error
      this.state.loading = false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const testSuiteHistoryStore = new TestSuiteHistoryStore()
dispatcher.register(testSuiteHistoryStore.handleActions.bind(testSuiteHistoryStore))

export default testSuiteHistoryStore
