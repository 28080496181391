import { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Error extends PureComponent {
  render() {
    const { message } = this.props
    return (
      <div style={{ margin: '1em' }}>
        <a>{message}</a>
      </div>
    )
  }
}

Error.propTypes = {
  message: PropTypes.string.isRequired,
}

export default Error
