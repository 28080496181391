import { Component } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'

const getDisplayName = WrappedComponent => WrappedComponent.displayName || WrappedComponent.name || 'Component'

const withErrorBoundary = (WrappedComponent) => {
  const C = (class extends Component {
      state = { hasError: false }

      componentDidCatch(error) {
        this.setState({ hasError: true, message: error.message })
      }

      render() {
        if (this.state.hasError) {
          return (
            <Card>
              <CardHeader title="Something went wrong..." />
              <CardContent>
                <p>{this.state.message}</p>
              </CardContent>
            </Card>
          )
        }
        return <WrappedComponent />
      }
  })
  C.displayName = `withErrorBoundary(${getDisplayName(WrappedComponent)})`
  return C
}

export default withErrorBoundary
