import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import * as Utils from 'components/utils'
import ResultIcon from './resultIcon'
import ErrorIcon from './errorIcon'
import style from './style.module.scss'
import SuccessConditionName from './successConditionName'

const AuditDetail = ({ date, user, status, comment }) => {
  if (!date || !user) {
    return <></>
  }
  const statusLine = !status ? '' : ` - ${status}`
  const commentLine = !comment ? '' : ` - ${comment}`
  return (
    <Box className={style.paddedBox}>
      {Utils.formatDate(date)} - {user}{statusLine}{commentLine}
    </Box>
  )
}

AuditDetail.propTypes = {
  date: PropTypes.string,
  user: PropTypes.string,
  status: PropTypes.string,
  comment: PropTypes.string,
}

AuditDetail.defaultProps = { date: null, user: null, status: null, comment: null }

const SuccessConditionDetail = ({ item }) => (
  <Box>
    <Box>
      <SuccessConditionName link={item.link} name={item.name} />
      {item.link ? <ResultIcon result={item.automatedFlowResult} link={item.link} /> : <></>}
      {item.error ? <ErrorIcon error={item.error} /> : <></>}
    </Box>
    <AuditDetail date={item.lastActionDate} user={item.lastActionUser} status={item.automatedFlowStatus} comment={item.comment} />
  </Box>
)

SuccessConditionDetail.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    requiresCommentOnChange: PropTypes.bool,
    automatedFlowResult: PropTypes.string,
    automatedFlowStatus: PropTypes.string,
    lastActionDate: PropTypes.string,
    lastActionUser: PropTypes.string,
    error: PropTypes.string,
    comment: PropTypes.string,
  }).isRequired,
}

export default SuccessConditionDetail
