import Button from '@mui/material/Button'
import UserIcon from '@mui/icons-material/PermIdentity'

import { logout } from 'core/sessionActions'

import style from './style.module.scss'

const LogoutPage = () => {
  logout()
  return (
    <div>
      <div className={style.logoutContent}>
        <div className="row">
          <div className="col-lg-6 end-lg col-md-4 col-md-offset-0">
            <img
              className={style.productsHeroIcon}
              alt="productsHeroIcon"
              src="https://www.qitasc.com/assets/images/products/productIcons/conQlude_256x256.png"
            />
          </div>
          <div className="col-md-6 begin-md">
            <img
              className={style.logoImage}
              alt="LogoImage"
              src="https://www.qitasc.com/assets/images/products/productLogos/conQlude.svg"
            />
            <div className={style.slogan}>Integrated reporting and issue tracking</div>
            <p>You are now logged out.</p>
            <p>
              Thanks for using
              <a href="https://www.qitasc.com">QiTASC</a>
              {' '}
              <a href="https://www.qitasc.com/products/conQlude">
                ConQlude
                <sup>®</sup>
              </a>
              – we hope to see you again soon.
            </p>
            <br />
            <a href="/">
              <Button
                variant="contained"
                id="loginButton"
                color="primary"
                icon={<UserIcon />}
              >
                Log in
              </Button>
            </a>
          </div>
        </div>

      </div>
    </div>
  )
}

export default LogoutPage
