import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'


const style = {
  container: {
    display: 'flex',
    width: '100%',
  },
  circle: {
    margin: 'auto',
  },
}

const Spinner = (props) => {
  if (props.spinning === true) {
    return (
      <div style={style.container}>
        <CircularProgress style={style.circle} />
      </div>
    )
  }
  return props.children
}

Spinner.propTypes = {
  children: PropTypes.node.isRequired,
  spinning: PropTypes.bool,
}

Spinner.defaultProps = {
  spinning: null,
}

export default Spinner
