import { useState } from 'react'
import PropTypes from 'prop-types'
import assign from 'lodash/assign'
import keys from 'lodash/keys'
import get from 'lodash/get'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import DataField from 'components/executionDetails/dataField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const validateNotEmpty = (settings, field) => {
  if (!settings[field]) {
    return { [field]: 'Cannot be empty' }
  }
  return {}
}
const validateNaN = (settings, field, minValue = 0) => {
  if (!settings[field] && settings[field] !== 0) {
    return { [field]: 'Cannot be empty' }
  }
  if (isNaN(settings[field])) { // eslint-disable-line no-restricted-globals
    return { [field]: 'Must be a number' }
  }
  if (settings[field] < minValue) {
    return { [field]: `Cannot be less than ${minValue}` }
  }
  return {}
}

const validateSettings = (settings) => {
  const errors = {}
  if (settings.enabled) {
    assign(errors, validateNotEmpty(settings, 'host'))
    assign(errors, validateNaN(settings, 'port', 1))
    assign(errors, validateNotEmpty(settings, 'sshUser'))
    assign(errors, validateNotEmpty(settings, 'remoteDirectory'))
    assign(errors, validateNotEmpty(settings, 'networkId'))
    assign(errors, validateNaN(settings, 'socketTimeoutMillis'))
    assign(errors, validateNaN(settings, 'defaultErrorCode'))
    assign(errors, validateNaN(settings, 'uploadIntervalMinutes', 1))
  }
  return errors
}

const Settings = ({ settings, onSave }) => {
  const [state, setState] = useState({ ...settings, errors: {} })

  const updateSettings = (s) => {
    const errors = validateSettings(s)
    setState({ ...s, errors })
  }
  const handleSave = () => {
    onSave(state)
  }
  return (
    <>
      <FormControlLabel
        label="Enabled"
        control={<Checkbox id="enabledField" checked={state.enabled || false} onChange={e => updateSettings({ ...state, enabled: e.target.checked })} />}
      />
      <DataField name="RecordType">
        <Select
          id="recordTypeField"
          fullWidth
          value={state.recordType}
          onChange={e => updateSettings({
            ...state,
            recordType: e.target.value,
          })}
        >
          <MenuItem key="QT10_TC" value="QT10_TC">QT10_TC</MenuItem>
          <MenuItem key="QT10_CBC" value="QT10_CBC">QT10_CBC</MenuItem>
        </Select>
      </DataField>
      <DataField name="Host">
        <TextField
          fullWidth
          name="hostField"
          id="hostField"
          value={state.host || ''}
          label={state.errors.host}
          error={state.errors.host != null}
          onChange={e => updateSettings({ ...state, host: e.target.value })}
        />
      </DataField>
      <DataField name="Port">
        <TextField
          fullWidth
          name="portField"
          id="portField"
          value={state.port}
          label={state.errors.port}
          error={state.errors.port != null}
          onChange={e => updateSettings({ ...state, port: e.target.value })}
        />
      </DataField>
      <DataField name="SSH username">
        <TextField
          fullWidth
          name="sshUserField"
          id="sshUserField"
          value={state.sshUser || ''}
          label={state.errors.sshUser}
          error={state.errors.sshUser != null}
          onChange={e => updateSettings({ ...state, sshUser: e.target.value })}
        />
      </DataField>
      <DataField name="Remote directory">
        <TextField
          fullWidth
          name="remoteDirectoryField"
          id="remoteDirectoryField"
          value={state.remoteDirectory || ''}
          label={state.errors.remoteDirectory}
          error={state.errors.remoteDirectory != null}
          onChange={e => updateSettings({ ...state, remoteDirectory: e.target.value })}
        />
      </DataField>
      <FormControlLabel
        label="Key Checking"
        control={(
          <Checkbox
            id="keyCheckingSwitch"
            checked={!get(state, 'disableKeyChecking', false)}
            onChange={e => updateSettings({ ...state, disableKeyChecking: !e.target.checked })}
          />
        )}
      />
      <DataField name="Socket Timeout">
        <TextField
          fullWidth
          name="socketTimeoutField"
          id="socketTimeoutField"
          value={state.socketTimeoutMillis || ''}
          label={state.errors.socketTimeoutMillis}
          error={state.errors.socketTimeoutMillis != null}
          onChange={e => updateSettings({ ...state, socketTimeoutMillis: e.target.value })}
        />
      </DataField>
      <DataField name="Upload interval (minutes)">
        <TextField
          fullWidth
          name="uploadIntervalField"
          id="uploadIntervalField"
          value={state.uploadIntervalMinutes || ''}
          label={state.errors.uploadIntervalMinutes}
          error={state.errors.uploadIntervalMinutes != null}
          onChange={e => updateSettings({ ...state, uploadIntervalMinutes: e.target.value })}
        />
      </DataField>
      <DataField name="Network ID">
        <TextField
          fullWidth
          name="networkIdField"
          id="networkIdField"
          value={state.networkId || ''}
          label={state.errors.networkId}
          error={state.errors.networkId != null}
          onChange={e => updateSettings({ ...state, networkId: e.target.value })}
        />
      </DataField>
      <DataField name="Default Error Code">
        <TextField
          fullWidth
          name="defaultErrorCodeField"
          id="defaultErrorCodeField"
          value={state.defaultErrorCode || ''}
          label={state.errors.defaultErrorCode}
          error={state.errors.defaultErrorCode != null}
          onChange={e => updateSettings({ ...state, defaultErrorCode: e.target.value })}
        />
      </DataField>
      <Button
        variant="contained"
        id="saveButton"
        color="primary"
        onClick={handleSave}
        disabled={keys(state.errors).length > 0}
      >
        Save
      </Button>
    </>
  )
}

Settings.propTypes = {
  onSave: PropTypes.func.isRequired,
  settings: PropTypes.shape().isRequired,
}

export default Settings
