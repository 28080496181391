import axios from 'axios'
import * as Common from 'services/common'
import ExecutionListService from 'services/executionListService'


class ExcelExportService {
  getDefaults = (project) => {
    const url = `/api/excel-export/defaults?project=${project}`
    return axios.get(url, Common.requestOptions)
      .then(response => response.data)
      .catch(Common.catchError)
  }

  updateDefaults = (project, defaults) => {
    const url = `/api/excel-export/defaults?project=${project}`
    return axios.post(url, defaults, Common.requestOptions)
      .catch(Common.catchError)
  }

  createExcelUrl = (project, filters, settings) => {
    const {
      remoteTest, resultName, template, orderName, tolerance, user, filesFrom, steps,
    } = settings
    const query = ExecutionListService.createFilterQuery(project, filters)
    return `/api/excel-export?${query}&rt=${encodeURIComponent(remoteTest)}&rn=${encodeURIComponent(resultName)}&dst=${encodeURIComponent(template)}`
      + `&on=${encodeURIComponent(orderName)}&tol=${tolerance}&user=${encodeURIComponent(user)}&ff=${filesFrom}&steps=${encodeURIComponent(steps)}`
  }
}

const instance = new ExcelExportService()
export default instance
