import ReportingEventEmitter from 'core/eventEmitter'
import { dispatcher, events } from 'core/dispatcher'

class HpqcReadyStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      items: [],
      loading: true,
      selectedExecutions: [],
      showSuccessMessage: false,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.hpqcReadyReport.listReceived: {
      this.state.items = (action.items || []).map(item => ({ ...item, defectsString: item.defects.map(it => it.id).toString() }))
      this.state.loading = false
      this.state.error = null
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.hpqcReadyReport.updateSelected: {
      this.state.selectedExecutions = action.executions
      this.emitChange()
      break
    }
    case events.hpqcReadyReport.exported: {
      this.state.showSuccessMessage = true
      this.emitChange()
      break
    }
    case events.hpqcReadyReport.exportStarted: {
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.hpqcReadyReport.uploadStarted: {
      this.state.showSuccessMessage = true
      this.emitChange()
      break
    }
    case events.hpqcReadyReport.failed: {
      this.state = { error: action.error }
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcReadyStore()
dispatcher.register(store.handleActions.bind(store))

export default store
