import ProjectService from 'services/projectService'
import YoutrackSettingsService from 'services/youtrackSettingsService'
import UserService from 'services/userService'

export function retrieveSettings(project) {
  return Promise.all([
    ProjectService.findAll(),
    YoutrackSettingsService.getList(),
  ]).then(result => ({
    project: result[0].find(it => it.code === project),
    youTrackList: result[1],
  }))
}

export function updateSettings(project) {
  return ProjectService.updateSettings(project)
}

export function fetchUsers() {
  return UserService.findAll()
}

export const revokeUser = async (email, permission) => {
  await UserService.removePermission(email, permission)
}

export const addUser = async (email, permission) => {
  await UserService.addPermission(email, permission)
}
