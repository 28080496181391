import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router'

import TestSuiteView from 'components/executionDetails/testSuiteView/testSuiteView'
import AttachmentsList from 'components/executionDetails/attachmentList/attachmentsListView'
import TestView from 'components/executionDetails/testView/testView'
import StepView from 'components/executionDetails/stepView/stepView'
import ErrorPage from 'pages/error/index'
import withErrorBoundary from 'core/errorBoundary'

const TestSuiteDetailsPage = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:executionId`} exact component={withErrorBoundary(TestSuiteView)} />
    <Route path={`${match.path}/:reportId/attachments`} exact component={withErrorBoundary(AttachmentsList)} />
    <Route path={`${match.path}/:executionId/:test`} exact component={withErrorBoundary(TestView)} />
    <Route path={`${match.path}/:executionId/:test/:step`} exact component={withErrorBoundary(StepView)} />
    <Route component={ErrorPage} />
  </Switch>
)

TestSuiteDetailsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
}

export default withRouter(TestSuiteDetailsPage)
