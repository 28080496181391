import { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import { PaginationItem } from '@mui/material'
import { Link } from 'react-router-dom'
import useQuery from 'hooks/useQuery'

const style = {
  wrapper: {
    justifyContent: 'center',
    display: 'flex',
  },
}

const CustomPagination = ({ totalPages }) => {
  const query = useQuery()
  const currentPage = parseInt(query.get('pageNumber'), 10) || 1

  useEffect(() => {
    document.getElementById('content')?.scrollTo(0, 0)
  }, [currentPage])

  return (
    <div style={style.wrapper}>
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          hideNextButton
          hidePrevButton
          siblingCount={2}
          page={currentPage}
          renderItem={(item) => {
            if (item.type.match(/ellipsis/g)) {
              query.set('pageNumber', item.type === 'start-ellipsis' ? currentPage - 3 : currentPage + 3)
            } else {
              query.set('pageNumber', item.page)
            }

            return (
              item.type.match(/ellipsis/g) ? (
                <Button
                  component={Link}
                  to={`?${query.toString()}`}
                  sx={{ minWidth: 36 }}
                >
                  ...
                </Button>
              ) : (
                <PaginationItem
                  component={Link}
                  to={`?${query.toString()}`}
                  {...item}
                />
              )
            )
          }}
        />
      )}
    </div>
  )
}

CustomPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
}

export default memo(CustomPagination)
