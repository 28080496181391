import { useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'

import IconButton from 'components/iconButton'

import style from './style.module.css'


const EditRow = ({ user, onCancel, onSubmit }) => {
  const [userData, setUserData] = useState(user)
  const handleSubmit = () => onSubmit(userData)
  return (
    <form onSubmit={handleSubmit}>
      <div className={style.userRow}>
        <div className={style.emailColumn}>
          {user.email === '' ? (
            <TextField
              id="emailField"
              label="Email"
              fullWidth
              autoFocus
              value={userData.email}
              onChange={e => setUserData({ ...userData, email: e.target.value })}
            />
          )
            : user.email}
        </div>
        <div className={style.nameColumn}>
          <TextField
            id="nameField"
            label="Name"
            fullWidth
            value={userData.name}
            onChange={e => setUserData({ ...userData, name: e.target.value })}
          />
        </div>
        <div className={style.adminColumn}>
          <Checkbox id="isAdminCheckBox" checked={userData.admin} onChange={e => setUserData({ ...userData, admin: e.target.checked })} />
        </div>
        <div className={style.buttonColumn}>
          <IconButton id="acceptButton" src="/images/accept.svg" onClick={handleSubmit} />
          <IconButton id="cancelButton" src="/images/cancel.svg" onClick={onCancel} />
        </div>
      </div>
    </form>
  )
}

EditRow.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    admin: PropTypes.bool,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

EditRow.defaultProps = { user: { email: '', name: '', admin: false } }


export default EditRow
