import UserService from 'services/userService'

export function retrieveData() {
  return UserService.findAll()
}

export function save(user) {
  return UserService.updateUser(user)
    .then(() => retrieveData())
}

export function add(user) {
  return UserService.addUser(user)
    .then(() => retrieveData())
}

export function remove(email) {
  return UserService.removeUser(email)
    .then(() => retrieveData())
}
