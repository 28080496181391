import { useMemo, useEffect } from 'react'
import MUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTable, useFlexLayout, useResizeColumns, useSortBy } from 'react-table'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Icon from '@mui/material/Icon'

import Pagination from 'components/pagination'
import useQuery from 'hooks/useQuery'

import './styles.css'

const Table = ({
  columns,
  data,
  id,
  totalPages,
  onRemoveColumn,
  ...rest
}) => {
  const query = useQuery()
  const history = useHistory()
  const sortField = query.get('sortField')
  const sortDirection = query.get('sortDirection')

  const defaultColumn = useMemo(() => ({ minWidth: 30 }), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable({
    columns,
    data,
    defaultColumn,
    manualSortBy: true,
    initialState: { ...JSON.parse(localStorage.getItem(id)), sortBy: sortField ? [{ id: sortField, desc: sortDirection === 'desc' }] : [] },
  }, useFlexLayout, useResizeColumns, useSortBy)

  const debouncedSetState = debounce(() => localStorage.setItem(id, JSON.stringify(state)), 100)

  useEffect(() => {
    if (Object.keys(state.columnResizing.columnWidths).length > 0) {
      debouncedSetState()
    }
  }, [state])

  useEffect(() => {
    if (state.sortBy[0] && (sortField !== state.sortBy[0].id || sortDirection !== (state.sortBy[0].desc ? 'desc' : 'asc'))) {
      query.set('sortField', state.sortBy[0].id)
      query.set('sortDirection', state.sortBy[0].desc ? 'desc' : 'asc')
      query.set('pageNumber', 1)

      history.push({ search: `?${query.toString()}` })
    } else if (!state.sortBy[0] && sortField) {
      query.delete('sortField')
      query.delete('sortDirection')
      query.set('pageNumber', 1)

      history.push({ search: `?${query.toString()}` })
    }
  }, [state.sortBy])

  const renderArrow = (column) => {
    if (column.isSorted) {
      return <Icon>{column.isSortedDesc ? 'arrow_drop_down' : 'arrow_drop_up'}</Icon>
    }

    return ''
  }

  return (
    <>
      <MUTable {...getTableProps()} {...rest}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableCell
                  {...column.getHeaderProps(column.sortable && !column.isResizing ? column.getSortByToggleProps() : '')}
                  padding={column.padding}
                >
                  <div className="column-header">
                    {renderArrow(column)}
                    {column.render('Header')}
                    {column.optional && (
                      <Icon
                        className="removeFieldButton material-icons"
                        onClick={(e) => {
                          e.stopPropagation()
                          onRemoveColumn(column.id)
                        }}
                      >
                        close
                      </Icon>
                    )}
                  </div>
                  <div
                    {...(column.canResize && column.getResizerProps())}
                    role="button"
                    tabIndex={0}
                    className={`resizer ${column.isResizing ? 'isResizing' : ''} ${!column.canResize && 'hidden'}`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    onKeyPress={(e) => {
                      e.stopPropagation()
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)

            return (
              <TableRow {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <TableCell {...cell.getCellProps()} padding={cell.column.padding}>
                    {cell.render('Cell')}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </MUTable>
      {totalPages && <Pagination totalPages={totalPages} />}
    </>
  )
}


Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  id: PropTypes.string.isRequired,
  totalPages: PropTypes.number,
  onRemoveColumn: PropTypes.func,
}

Table.defaultProps = {
  totalPages: undefined,
  onRemoveColumn: undefined,
}

export default Table
