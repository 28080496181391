const reducer = (state, action) => {
  function extractVerificationServiceSettings(settings) {
    return {
      baseUrl: settings.verificationServiceBaseUrl,
      socketTimeoutMillis: settings.verificationServiceSocketTimeoutMillis,
      allowSelfSignedCertificates: settings.verificationServiceAllowSelfSignedCertificates,
      authToken: settings.verificationServiceAuthToken,
      enabled: settings.verificationServiceEnabled,
      errors: {},
    }
  }

  function extractLinqSettings(settings) {
    return {
      baseUrl: settings.linqBaseUrl,
      socketTimeoutMillis: settings.linqSocketTimeoutMillis,
      allowSelfSignedCertificates: settings.linqAllowSelfSignedCertificates,
      authToken: settings.linqAuthToken,
      enabled: settings.linqEnabled,
      pollCdrsAfterMinutes: settings.linqPollCdrsAfterMinutes,
      errors: {},
    }
  }

  switch (action.type) {
  case 'loaded':
    return {
      loading: false,
      showSuccessMessage: false,
      verificationServiceSettings: extractVerificationServiceSettings(action.settings),
      linqSettings: extractLinqSettings(action.settings),
    }
  case 'saving':
    return { ...state, loading: true }
  case 'saved':
    return { ...state, showSuccessMessage: true, loading: false }
  case 'close_snackbar':
    return { ...state, showSuccessMessage: false }
  case 'verification_service_settings_changed':
    return { ...state, verificationServiceSettings: action.settings }
  case 'linq_settings_changed':
    return { ...state, linqSettings: action.settings }
  default:
    throw new Error()
  }
}

export default reducer
