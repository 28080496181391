import { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Route,
  Switch,
  useParams,
} from 'react-router-dom'
import get from 'lodash/get'
import find from 'lodash/find'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { withRouter } from 'react-router'

import CdrSettings from 'components/configuration/cdr'
import CheqSettings from 'components/configuration/cheq'
import SuccessConditionSettings from 'components/configuration/successConditions'
import DataRetentionConfiguration from 'components/configuration/dataRetention'
import ExcelExportDefaults from 'components/configuration/excelExportDefaults/excelExportDefaults'
import ErrorCategories from 'components/configuration/errorCategories'
import ErrorPage from 'pages/error/index'
import HpqcPage from 'components/configuration/hpqc'
import JiraBasicSettings from 'components/configuration/jira/basic'
import JiraSettings from 'components/configuration/jira/settings'
import PmsSettings from 'components/configuration/pms'
import ProjectConfig from 'components/configuration/project'
import QonformSettings from 'components/configuration/qonform'
import TestTypes from 'components/configuration/testCategories/testCategories'
import useProject from 'hooks/useProject'
import { loadProjectList } from 'core/projectProvider/actions'

import withErrorBoundary from 'core/errorBoundary'

const DEFAULT_TAB = 'project'

const ConfigurationPage = ({ history, match }) => {
  const {
    hpqcEnabled, jiraZephyrEnabled, jiraBasicEnabled, cheqEnabled, pmsEnabled, cdrEnabled, qonformEnabled,
  } = useProject()
  const tabs = [
    { name: 'project', label: 'Project Settings' },
    { name: 'error-category', label: 'Error Categories' },
    { name: 'data-retention', label: 'Data Retention' },
    { name: 'categories', label: 'Test Categories' },
    { name: 'excel-export-defaults', label: 'Excel Export Defaults' },
    { name: 'success-conditions', label: 'Success Conditions', link: 'success-conditions/all-test-cases' },
    { name: 'hpqc', label: 'HPQC Settings', link: 'hpqc/connection', enabled: hpqcEnabled },
    { name: 'jira-basic', label: 'Jira Settings', link: 'jira-basic/connection', enabled: jiraBasicEnabled },
    { name: 'jira-zephyr', label: 'Jira Settings', enabled: jiraZephyrEnabled },
    { name: 'pms', label: 'PMS Settings', link: 'pms/settings', enabled: pmsEnabled },
    { name: 'cheq-settings', label: 'CheQ Settings', enabled: cheqEnabled },
    { name: 'cdr-settings', label: 'CDR Verification', enabled: cdrEnabled },
    { name: 'qonform-settings', label: 'Qonform', enabled: qonformEnabled },
  ]
  const params = useParams()

  useEffect(() => {
    loadProjectList(params.projectCode)
  }, [])

  const handleChange = useCallback((_, val) => {
    const selectedTab = find(tabs, { name: val })
    const link = get(selectedTab, 'link', val)
    history.push(`${match.url}/${link}`)
  }, [])
  const matchingTab = find(tabs, it => window.location.pathname.startsWith(`${match.url}/${get(it, 'name')}`))

  return (
    <>
      <Tabs
        onChange={handleChange}
        value={matchingTab ? matchingTab.name : DEFAULT_TAB}
        scrollButtons="auto"
        variant="scrollable"
      >
        {tabs
          .filter(tab => get(tab, 'enabled', true))
          .map(tab => <Tab id={tab.name} key={tab.name} value={tab.name} label={tab.label} />)}
      </Tabs>
      <div>
        <Switch>
          <Route path={`${match.url}/project`} component={withErrorBoundary(ProjectConfig)} />
          <Route path={`${match.url}/categories`} component={withErrorBoundary(TestTypes)} />
          <Route path={`${match.url}/data-retention`} component={withErrorBoundary(DataRetentionConfiguration)} />
          <Route path={`${match.url}/hpqc`} component={withErrorBoundary(HpqcPage)} />
          <Route path={`${match.url}/jira-zephyr`} component={withErrorBoundary(JiraSettings)} />
          <Route path={`${match.url}/jira-basic`} component={withErrorBoundary(JiraBasicSettings)} />
          <Route path={`${match.url}/excel-export-defaults`} component={withErrorBoundary(ExcelExportDefaults)} />
          <Route path={`${match.url}/success-conditions`} component={withErrorBoundary(SuccessConditionSettings)} />
          <Route path={`${match.url}/error-category`} component={ErrorCategories} />
          <Route path={`${match.url}/error`} component={ErrorPage} />
          <Route path={`${match.url}/cheq-settings`} component={withErrorBoundary(CheqSettings)} />
          <Route path={`${match.url}/cdr-settings`} component={withErrorBoundary(CdrSettings)} />
          <Route path={`${match.url}/qonform-settings`} component={withErrorBoundary(QonformSettings)} />
          <Route path={`${match.url}/pms`} component={withErrorBoundary(PmsSettings)} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </>
  )
}

ConfigurationPage.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
}


export default withRouter(ConfigurationPage)
