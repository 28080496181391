import ReportingEventEmitter from 'core/eventEmitter'
import get from 'lodash/get'
import { dispatcher, events } from 'core/dispatcher'

const defaultCredentials = {
  username: '',
  password: '',
}

class HpqcCredentialsStore extends ReportingEventEmitter {
  getInitialState() {
    return {
      loading: true,
      credentials: defaultCredentials,
      showSuccessMessage: false,
    }
  }

  handleActions(action) {
    switch (action.type) {
    case events.configurationHpqcCredentials.dataReceived: {
      this.state.credentials = {
        username: get(action, 'credentials.username') || '',
        password: get(action, 'credentials.password') || '',
      }
      this.state.loading = false
      this.state.showSuccessMessage = false
      this.state.error = null
      this.emitChange()
      break
    }
    case events.configurationHpqcCredentials.valueChanged: {
      this.state.credentials = {
        username: get(action, 'credentials.username') || '',
        password: get(action, 'credentials.password') || '',
      }
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    case events.configurationHpqcCredentials.saved: {
      this.state.credentials.password = ''
      this.state.showSuccessMessage = true
      this.emitChange()
      break
    }
    case events.configurationHpqcCredentials.failed: {
      this.state.error = action.message
      this.state.showSuccessMessage = false
      this.emitChange()
      break
    }
    default: {
      // empty
    }
    }
  }
}

const store = new HpqcCredentialsStore()
dispatcher.register(store.handleActions.bind(store))

export default store
