import ExecutionListService from 'services/executionListService'
import moment from 'moment'


function getData(projectCode, from, to) {
  return ExecutionListService.getSuccessRateStats(projectCode, from, to)
}

function getDataForPeriod(projectCode, period) {
  if (period === 'Today') {
    return getData(projectCode, moment().startOf('day'), moment())
  }
  if (period === 'Last week') {
    return getData(projectCode, moment().startOf('day').subtract(1, 'weeks'), moment())
  }
  if (period === 'Last month') {
    return getData(projectCode, moment().startOf('day').subtract(1, 'months'), moment())
  }
  return getData(projectCode, moment().startOf('day').subtract(5, 'years'), moment())
}

export function getSuccessRate(projectCode, period) {
  return getDataForPeriod(projectCode, period)
}

export function updateChart(projectCode, period) {
  getDataForPeriod(projectCode, period)
}
