import { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import startsWith from 'lodash/startsWith'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'

import AttachmentPreview from 'components/executionDetails/attachmentPreviewDialog'
import PreviewIcon from 'components/executionDetails/previewIcon'
import * as Utils from 'components/utils'
import * as Actions from './actions'

class AttachmentButton extends Component {
  constructor() {
    super()
    this.state = {
      downloadedFile: null,
      open: false,
    }
    this.anchorEl = createRef()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.attachment.id !== nextProps.attachment.id
    || this.state.downloadedFileName !== nextState.downloadedFileName
    || this.state.playing !== nextState.playing
    || this.state.open !== nextState.open
  }

  handleDownload = (e) => {
    e.preventDefault()
    e.persist()
    Actions.downloadAttachment(this.props.attachment.id)
  }

  handlePreview = async () => {
    const type = get(this.props, 'attachment.type')
    const file = startsWith(type, 'audio/') || startsWith(type, 'image/')
      ? Actions.getAttachmentUrl(this.props.attachment.id)
      : await Actions.previewAttachment(this.props.attachment.id, this.props.attachment.name, this.props.attachment.type)
    this.setState(state => ({
      ...state,
      downloadedFileName: this.props.attachment.name,
      downloadedFileMimeType: this.props.attachment.type,
      downloadedFile: file,
      open: false,
      playing: false,
    }))
  }

  handleRemove = () => {
    Actions.removeAttachment(this.props.attachment.id)
    this.stopPlaying()
  }

  startPlaying = () => {
    const type = get(this.props, 'attachment.type')
    if (startsWith(type, 'audio/')) {
      this.setState(state => ({
        ...state,
        playing: true,
        downloadedFileName: null,
        downloadedFileMimeType: null,
        downloadedFile: null,
      }))
    }
  }

  stopPlaying = () => {
    this.setState(state => ({ ...state, playing: false }))
  }

  render() {
    const { attachment, ...otherProps } = this.props
    const previewTitle = `${this.state.downloadedFileName} ${otherProps.description}`
    return (
      <span
        data-tip={attachment.name}
        ref={this.anchorEl}
        {...otherProps}
        onMouseEnter={this.startPlaying}
        onMouseLeave={this.stopPlaying}
      >
        <IconButton disabled={otherProps.disabled} onClick={() => this.setState(state => ({ ...state, open: true, playing: false }))}>
          <PreviewIcon iconType={attachment.type} />
        </IconButton>
        { this.state.open && (
          <Menu
            keepMounted
            open
            anchorEl={this.anchorEl.current}
            onClose={() => this.setState(state => ({ ...state, open: false }))}
          >
            {Utils.canPreview(attachment.type)
            && <MenuItem id="previewItem" onMouseUp={this.handlePreview}>Preview</MenuItem>}
            <MenuItem id="downloadItem" onMouseUp={this.handleDownload}>Download</MenuItem>
            { attachment.uploadType !== 'CONTEXT' && <MenuItem id="removeItem" onMouseUp={this.handleRemove}>Remove</MenuItem> }
          </Menu>
        )}
        <AttachmentPreview
          name={previewTitle}
          type={this.state.downloadedFileMimeType}
          file={this.state.downloadedFile}
          onClose={() => this.setState(state => ({ ...state, downloadedFile: null, downloadedFileName: null, downloadedFileMimeType: null, playing: false }))}
        />
        { this.state.playing
          && (
            <audio src={Actions.getAttachmentUrl(attachment.id)} autoPlay>
              <track kind="captions" />
            </audio>
          )}
      </span>
    )
  }
}

AttachmentButton.propTypes = {
  attachment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    uploadType: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
  description: PropTypes.string,
}

AttachmentButton.defaultProps = {
  disabled: false,
  description: '',
}

export default AttachmentButton
