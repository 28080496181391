import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import moment from 'moment'
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import * as Actions from './actions'


const CdrSearch = ({ executionId, verificationId, project, cdrSearch }) => (
  <Box paddingBottom={1}>
    <Box>
      <IconButton title="Run CDR Search" aria-label="refresh" onClick={() => { Actions.runCdrSearch(project, executionId, verificationId) }}>
        <RefreshIcon />
      </IconButton>
      {cdrSearch.result
        ? (`CDR Search: ${cdrSearch.result}`)
        : `CDR Search scheduled for ${moment(cdrSearch.scheduledFor).format('YYYY-MM-DD HH:mm')}`}
    </Box>
  </Box>
)

CdrSearch.propTypes = {
  executionId: PropTypes.string.isRequired,
  verificationId: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  cdrSearch: PropTypes.shape({
    scheduledFor: PropTypes.string,
    result: PropTypes.string,
  }).isRequired,
}

export default CdrSearch
