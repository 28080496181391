import { dispatcher, events } from 'core/dispatcher'
import HpqcService from 'services/hpqc/hpqcService'

export function retrieveCredentials(project) {
  HpqcService.getCredentials(project)
    .then(credentials => dispatcher.dispatch({ type: events.configurationHpqcCredentials.dataReceived, credentials }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcCredentials.failed, message }) })
}

export function handleValueChange(credentials) {
  dispatcher.dispatch({ type: events.configurationHpqcCredentials.valueChanged, credentials })
}

export function save(project, credentials) {
  HpqcService.updateCredentials(project, credentials)
    .then(() => dispatcher.dispatch({ type: events.configurationHpqcCredentials.saved }))
    .catch((message) => { dispatcher.dispatch({ type: events.configurationHpqcCredentials.failed, message }) })
}
