import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ReactTooltip from 'react-tooltip'

import FormattedDate from 'components/formattedDate'
import { UploadFailedIcon, UploadSuccessfulIcon } from 'components/icons'
import Table from 'components/table'

import style from './style.module.css'

class List extends PureComponent {
  componentDidMount() {
    ReactTooltip.rebuild()
  }

  render() {
    const { list, onUpload } = this.props
    const columns = [
      {
        Header: 'Test',
        accessor: 'testName',
        Cell: item => (
          <Link to={`${item.row.original.executionId}/${item.row.original.testIndex}`} title={item.value}>
            {item.value}
          </Link>
        ),
      },
      {
        Header: 'Upload time',
        accessor: 'uploadTime',
        Cell: item => <FormattedDate value={item.value} />,
        width: 140,
      },
      {
        Header: 'Issue',
        accessor: 'jiraLink',
        Cell: item => (
          item.value
            ? <a href={item.value} target="_blank" rel="noopener noreferrer">{item.row.original.issue}</a>
            : item.row.original.issue || ''
        ),
        width: 64,
      },
      {
        Header: 'Result',
        accessor: 'uploadIcon',
        width: 70,
      },
    ]
    const data = list.map((item) => {
      const uploadIcon = item.errorMessage ? (<UploadFailedIcon message={item.errorMessage} />) : <UploadSuccessfulIcon />
      return { ...item, uploadIcon }
    })

    return (
      <Card className={style.card}>
        <div className={style.overflowWrapper}>
          <CardHeader title="Jira Uploads" />
          <Table
            id="uploadList"
            columns={columns}
            data={data}
          />
          <div className={style.buttons}>
            <Button variant="contained" onClick={onUpload}>Upload</Button>
          </div>
        </div>
      </Card>
    )
  }
}

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onUpload: PropTypes.func.isRequired,
}

List.defaultProps = {
  list: [],
}

export default List
