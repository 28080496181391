import ExecutionListService from 'services/executionListService'
import { dispatcher, events } from 'core/dispatcher'
import downloadFile from 'utils/downloadFile'


export function handleToggleExportView(show) {
  dispatcher.dispatch({ type: events.csvExport.toggleFieldSelection, show })
}

export function handleSave(templates) {
  dispatcher.dispatch({ type: events.csvExport.templatesSaved, templates })
}

export function handleDownload(project, fields, query, metadata) {
  const url = ExecutionListService.createCsvUrl(project, query, fields, metadata)
  downloadFile(url)
  dispatcher.dispatch({ type: events.csvExport.toggleFieldSelection, show: false })
}
